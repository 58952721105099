/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AwardDto from '../model/AwardDto';
import AwardPayloadModel from '../model/AwardPayloadModel';
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import SearchingQueryResponseModelAwardDto from '../model/SearchingQueryResponseModelAwardDto';

/**
* Award service.
* @module api/AwardApi
* @version v1.0.0
*/
export default class AwardApi {

    /**
    * Constructs a new AwardApi. 
    * @alias module:api/AwardApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addAwardUsingPOST operation.
     * @callback module:api/AwardApi~addAwardUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AwardDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add Award
     * @param {Object} opts Optional parameters
     * @param {module:model/AwardPayloadModel} opts.awardPayloadModel 
     * @param {module:api/AwardApi~addAwardUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AwardDto}
     */
    addAwardUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['awardPayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = AwardDto;
      return this.apiClient.callApi(
        '/api/award/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteAwardUsingDELETE operation.
     * @callback module:api/AwardApi~deleteAwardUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete Award
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/AwardApi~deleteAwardUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteAwardUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/award/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAwardUsingGET operation.
     * @callback module:api/AwardApi~getAwardUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AwardDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get Award
     * @param {String} id id
     * @param {module:api/AwardApi~getAwardUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AwardDto}
     */
    getAwardUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getAwardUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = AwardDto;
      return this.apiClient.callApi(
        '/api/award/get/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getAwardsUsingGET operation.
     * @callback module:api/AwardApi~getAwardsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelAwardDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * search Award
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/AwardApi~getAwardsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelAwardDto}
     */
    getAwardsUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelAwardDto;
      return this.apiClient.callApi(
        '/api/award/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateAwardUsingPATCH operation.
     * @callback module:api/AwardApi~updateAwardUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AwardDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * update Award
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/AwardPayloadModel} opts.awardPayloadModel 
     * @param {module:api/AwardApi~updateAwardUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AwardDto}
     */
    updateAwardUsingPATCH(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['awardPayloadModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateAwardUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = AwardDto;
      return this.apiClient.callApi(
        '/api/award/update/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
