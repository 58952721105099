// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TextArea_textAreaPrimary__6OusX {\n    min-width: 100px;\n    min-height: 30px;\n    outline: none;\n    border: none;\n    border-radius: 25px;\n    background-color: var(--color-black-add90);\n    padding: 10px 20px 10px 20px;\n    width: 100%;\n}\n\n.TextArea_customBadge__v6QSi {\n    width:auto;\n    position:absolute;\n    margin-top: -17px;\n    color:var(--color-black-add30);\n    text-align:left;\n    margin-left: 16px;\n    padding-top:4px;\n}", "",{"version":3,"sources":["webpack://./src/components/Input/TextArea.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,0CAA0C;IAC1C,4BAA4B;IAC5B,WAAW;AACf;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,iBAAiB;IACjB,8BAA8B;IAC9B,eAAe;IACf,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".textAreaPrimary {\n    min-width: 100px;\n    min-height: 30px;\n    outline: none;\n    border: none;\n    border-radius: 25px;\n    background-color: var(--color-black-add90);\n    padding: 10px 20px 10px 20px;\n    width: 100%;\n}\n\n.customBadge {\n    width:auto;\n    position:absolute;\n    margin-top: -17px;\n    color:var(--color-black-add30);\n    text-align:left;\n    margin-left: 16px;\n    padding-top:4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textAreaPrimary": "TextArea_textAreaPrimary__6OusX",
	"customBadge": "TextArea_customBadge__v6QSi"
};
export default ___CSS_LOADER_EXPORT___;
