import React from "react";
import { connect } from 'react-redux';
import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
import "./background.css";

class Background extends React.Component {
  isFirst;
  constructor(props) {
    let _w = 270;
    let _h = 270;
    /*
    try {
      let rect = document.getElementsByClassName("epub-container")[0].getBoundingClientRect();
      _w = rect.width;
      _h = rect.height;
    } catch (err) {
      console.log("err", err);
    }
    */
    
    super(props);
    this.state = {
      isSingle:
        StorageUtil.getReaderConfig("readerMode") &&
        StorageUtil.getReaderConfig("readerMode") !== "double",
      scale: StorageUtil.getReaderConfig("scale") || 1,
      width: _w,
      height: _h, 
    };
    this.isFirst = true;
  }
  componentDidMount() {
    let background = document.querySelector(".background");
    if (!background) return;
    background?.setAttribute(
      "style",
      `background-color:${StorageUtil.getReaderConfig(
        "backgroundColor"
      )};filter: brightness(${StorageUtil.getReaderConfig("brightness") || 1
      }) invert(${StorageUtil.getReaderConfig("isInvert") === "yes" ? 1 : 0})`
    );
  }

  render() {
    return (
      <>
        {/* 
        <div
          className="background-box2"
          style={
            document.body.clientWidth < 570
              ? { left: 5, right: 8 }
              : this.state.isSingle
                ? {
                  left: `calc(50vw - ${270 * parseFloat(this.state.scale)
                    }px - ${this.state.isSingle ? "9" : "5"}px)`,
                  right: `calc(50vw - ${270 * parseFloat(this.state.scale)
                    }px - 7px)`,
                  boxShadow: "0 0 0px rgba(191, 191, 191, 1)",
                }
                : {}
          }
        ></div>

        <div
          className="background-box3"
          style={
            document.body.clientWidth < 570
              ? { left: 5, right: 10 }
              : this.state.isSingle
                ? {
                  left: `calc(50vw - ${270 * parseFloat(this.state.scale)
                    }px - 9px)`,
                  right: `calc(50vw - ${270 * parseFloat(this.state.scale)
                    }px - 9px)`,
                }
                : {}
          }
        >
          {(!StorageUtil.getReaderConfig("backgroundColor") &&
            (StorageUtil.getReaderConfig("appSkin") === "night" ||
              (StorageUtil.getReaderConfig("appSkin") === "system" &&
                StorageUtil.getReaderConfig("isOSNight") === "yes"))) ||
            StorageUtil.getReaderConfig("backgroundColor") ===
            "rgba(44,47,49,1)" ? (
            <div
              className="dark-spine-shadow-left"
              style={
                this.state.isSingle ||
                  (StorageUtil.getReaderConfig("backgroundColor") &&
                    StorageUtil.getReaderConfig("backgroundColor").startsWith(
                      "#"
                    ))
                  ? { display: "none" }
                  : {}
              }
            ></div>
          ) : (
            <div
              className="spine-shadow-left"
              style={
                this.state.isSingle ||
                  (StorageUtil.getReaderConfig("backgroundColor") &&
                    StorageUtil.getReaderConfig("backgroundColor").startsWith(
                      "#"
                    ))
                  ? { display: "none" }
                  : {}
              }
            ></div>
          )}
          <div
            className="book-spine"
            style={this.state.isSingle ? { display: "none" } : {}}
          ></div>
          {(!StorageUtil.getReaderConfig("backgroundColor") &&
            StorageUtil.getReaderConfig("appSkin") === "night") ||
            StorageUtil.getReaderConfig("backgroundColor") ===
            "rgba(44,47,49,1)" ? (
            <div
              className="dark-spine-shadow-right"
              style={
                StorageUtil.getReaderConfig("backgroundColor") &&
                  StorageUtil.getReaderConfig("backgroundColor").startsWith("#")
                  ? { display: "none" }
                  : this.state.isSingle
                    ? {
                      position: "relative",
                      right: 0,
                    }
                    : {}
              }
            ></div>
          ) : (
            <div
              className="spine-shadow-right"
              style={
                StorageUtil.getReaderConfig("backgroundColor") &&
                  StorageUtil.getReaderConfig("backgroundColor").startsWith("#")
                  ? { display: "none" }
                  : this.state.isSingle
                    ? {
                      position: "relative",
                      right: 0,
                    }
                    : {}
              }
            ></div>
          )}
        </div>

        <div
          className="background-box1"
          style={
            document.body.clientWidth < 570
              ? { left: 5, right: 6 }
              : this.state.isSingle
                ? {
                  left: `calc(50vw - ${270 * parseFloat(this.state.scale)
                    }px - ${this.state.isSingle ? "9" : "5"}px)`,
                  right: `calc(50vw - ${270 * parseFloat(this.state.scale)
                    }px - 5px)`,
                  boxShadow: "0 0 0px rgba(191, 191, 191, 1)",
                }
                : {}
          }
        ></div>
        */}
        <div
          className="background-box2"
          style={
            document.body.clientWidth < 570
              ? { left: 5, right: 8 }
              : this.state.isSingle
                ? {
                  left: 5, right: 8,
                  boxShadow: "0 0 0px rgba(191, 191, 191, 1)",
                }
                : {}
          }
        ></div>

        <div
          className="background-box3"
          style={
            document.body.clientWidth < 570
              ? { left: 5, right: 10 }
              : this.state.isSingle
                ? {
                  left: 5, right: 10,
                }
                : {}
          }
        >
          {(!StorageUtil.getReaderConfig("backgroundColor") &&
            (StorageUtil.getReaderConfig("appSkin") === "night" ||
              (StorageUtil.getReaderConfig("appSkin") === "system" &&
                StorageUtil.getReaderConfig("isOSNight") === "yes"))) ||
            StorageUtil.getReaderConfig("backgroundColor") ===
            "rgba(44,47,49,1)" ? (
            <div
              className="dark-spine-shadow-left"
              style={
                this.state.isSingle ||
                  (StorageUtil.getReaderConfig("backgroundColor") &&
                    StorageUtil.getReaderConfig("backgroundColor").startsWith(
                      "#"
                    ))
                  ? { display: "none" }
                  : {}
              }
            ></div>
          ) : (
            <div
              className="spine-shadow-left"
              style={
                this.state.isSingle ||
                  (StorageUtil.getReaderConfig("backgroundColor") &&
                    StorageUtil.getReaderConfig("backgroundColor").startsWith(
                      "#"
                    ))
                  ? { display: "none" }
                  : {}
              }
            ></div>
          )}
          <div
            className="book-spine"
            style={this.state.isSingle ? { display: "none" } : {}}
          ></div>
          {(!StorageUtil.getReaderConfig("backgroundColor") &&
            StorageUtil.getReaderConfig("appSkin") === "night") ||
            StorageUtil.getReaderConfig("backgroundColor") ===
            "rgba(44,47,49,1)" ? (
            <div
              className="dark-spine-shadow-right"
              style={
                StorageUtil.getReaderConfig("backgroundColor") &&
                  StorageUtil.getReaderConfig("backgroundColor").startsWith("#")
                  ? { display: "none" }
                  : this.state.isSingle
                    ? {
                      position: "relative",
                      right: 0,
                    }
                    : {}
              }
            ></div>
          ) : (
            <div
              className="spine-shadow-right"
              style={
                StorageUtil.getReaderConfig("backgroundColor") &&
                  StorageUtil.getReaderConfig("backgroundColor").startsWith("#")
                  ? { display: "none" }
                  : this.state.isSingle
                    ? {
                      position: "relative",
                      right: 0,
                    }
                    : {}
              }
            ></div>
          )}
        </div>

        <div
          className="background-box1"
          style={
            document.body.clientWidth < 570
              ? { left: 5, right: 6 }
              : this.state.isSingle
                ? {
                  left: 5, right: 6,
                  boxShadow: "0 0 0px rgba(191, 191, 191, 1)",
                }
                : {}
          }
        ></div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
  };
};
const actionCreator = {};
export default connect(mapStateToProps, actionCreator)(Background);

