import PropTypes from "prop-types";
import { Component } from "react";
import editIcon from "../../../../../assets/images/btn/edit.svg";
import deleteIcon from "../../../../../assets/images/btn/trash.svg";
import {
  CODE_LIBRARY_ORGANIZATION_DELETE,
  CODE_LIBRARY_ORGANIZATION_UPDATE,
  STYLETYPE_DEFAULT,
  STYLETYPE_IMG,
} from "../../../../../utils/Constants";
import Button from "../../../../Button/Button";
import Input from "../../../../Input/Input";
import style from "../BookTableCardView.module.css";

export default class LibraryTableCardView extends Component {
  static propTypes = {
    content: PropTypes.object.isRequired,
    callBack: PropTypes.func,
  };

  handleCheckedCallBack = (value) => {
    this.props.callBack({ action: "checkbox", value });
  };

  handleDeleteCallBack = (value) => {
    this.props.callBack({ action: "delete", value });
  };

  handleEditCallBack = (value) => {
    this.props.callBack({ action: "edit", value });
  };

  render() {
    const { content, checked } = this.props;
    return (
      <div className={style.tableItemCard}>
        <div className={style.tableItemCardContainer}>
          <div className={style.tableListItemGridView}>
            <div className={style.GridViewCol1}>{content.name}</div>
            <div className={style.GridViewCol2}>
              <Input
                showType={STYLETYPE_DEFAULT}
                type="checkbox"
                checked={checked}
                callBack={(value) => this.handleCheckedCallBack(value)}
              />
            </div>
            <div className={style.GridViewCol3}>{content.description}</div>
            <div className={style.GridViewCol4}></div>
            <div className={style.GridViewCol5}></div>

            <div className={style.GridViewCol6}>
              <Button
                styleType={STYLETYPE_IMG}
                code={CODE_LIBRARY_ORGANIZATION_UPDATE}
                callBack={(value) => this.handleEditCallBack(value)}
              >
                <img src={editIcon} />
              </Button>
              <Button
                styleType={STYLETYPE_IMG}
                code={CODE_LIBRARY_ORGANIZATION_DELETE}
                callBack={(value) => this.handleDeleteCallBack(value)}
              >
                <img src={deleteIcon} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
