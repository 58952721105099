import React, { Fragment, useEffect } from "react";
import "../assets/color.css";
import "../assets/main.css";
import Header from "../components/Header/Header";
import MainSection from "./section/main/MainSection";
import {
  getUserInfo,
  setAccessToken,
  setClientInfo,
  setRefreshToken,
  setUserInfo,
  getRemindInfo,
} from "../utils/LocalStorage";
import {
  HOME,
  LOGIN,
  RESETPW,
} from "../utils/Constants";
import moment from "moment";

const LayoutView = (props) => {
  useEffect(() => {
    const userInfo = getUserInfo();
    const remind = getRemindInfo();
    var date = new Date();
    var currentDate = moment(date).toDate().getTime();
    /*
    if (userInfo == null && window.location.pathname != LOGIN) {
      window.location = LOGIN;
    }
    */
    console.log("LayoutView");
    console.log("window.location.pathname", window.location.pathname);
    if (userInfo == null) {
      if (window.location.pathname == LOGIN) {

      } else if (window.location.pathname == RESETPW) {

      } else {
        window.location = LOGIN;
      }
    }

    /*
    if (userInfo == null && window.location.pathname != LOGIN) {
        window.location = LOGIN;
    }
    if (remind != null) {
        //window.location = LOGIN;
        return;
    }
    console.log("LayoutView");
    if (userInfo != null) {
        if (userInfo.accessTokenExp - currentDate > 0 && userInfo.remind) {
            console.log(" > 0 ");
            const userInfoObj = Object.assign(userInfo, {
                accessTokenExp: userInfo.accessTokenExp,
            });
            setUserInfo(JSON.stringify(userInfoObj));
             window.location = HOME;
            return;
        }

        if (userInfo.accessTokenExp - currentDate > 0 && !userInfo.remind) {
            console.log(" > 0 !");
             window.location = HOME;
            return;
        }

        if (userInfo.accessTokenExp - currentDate < 0 && userInfo.remind) {
            console.log(" < 0 ");
             window.location = LOGIN;
            return;
        }

        if (userInfo.remind && remind != null) {
            window.location = HOME;
        }
    }
    */
  }, []);

  return (
    <Fragment>
      <Header />
      <MainSection {...props} />
      {props.children}
    </Fragment>
  );
};

export default LayoutView;
