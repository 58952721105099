/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The BookFilterSearchingQueryModel model module.
 * @module model/BookFilterSearchingQueryModel
 * @version v1.0.0
 */
class BookFilterSearchingQueryModel {
    /**
     * Constructs a new <code>BookFilterSearchingQueryModel</code>.
     * @alias module:model/BookFilterSearchingQueryModel
     */
    constructor() { 
        
        BookFilterSearchingQueryModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BookFilterSearchingQueryModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BookFilterSearchingQueryModel} obj Optional instance to populate.
     * @return {module:model/BookFilterSearchingQueryModel} The populated <code>BookFilterSearchingQueryModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BookFilterSearchingQueryModel();

            if (data.hasOwnProperty('bookname')) {
                obj['bookname'] = ApiClient.convertToType(data['bookname'], [Object]);
            }
            if (data.hasOwnProperty('bookstatus')) {
                obj['bookstatus'] = ApiClient.convertToType(data['bookstatus'], [Object]);
            }
            if (data.hasOwnProperty('daterange')) {
                obj['daterange'] = ApiClient.convertToType(data['daterange'], [Object]);
            }
            if (data.hasOwnProperty('from')) {
                obj['from'] = ApiClient.convertToType(data['from'], 'Number');
            }
            if (data.hasOwnProperty('limit')) {
                obj['limit'] = ApiClient.convertToType(data['limit'], 'Number');
            }
            if (data.hasOwnProperty('orgId')) {
                obj['orgId'] = ApiClient.convertToType(data['orgId'], 'String');
            }
        }
        return obj;
    }


}

/**
 * BOOK_NAME
 * @member {Array.<Object>} bookname
 */
BookFilterSearchingQueryModel.prototype['bookname'] = undefined;

/**
 * BOOK_STATUS
 * @member {Array.<Object>} bookstatus
 */
BookFilterSearchingQueryModel.prototype['bookstatus'] = undefined;

/**
 * DATE_RANGE
 * @member {Array.<Object>} daterange
 */
BookFilterSearchingQueryModel.prototype['daterange'] = undefined;

/**
 * @member {Number} from
 */
BookFilterSearchingQueryModel.prototype['from'] = undefined;

/**
 * @member {Number} limit
 */
BookFilterSearchingQueryModel.prototype['limit'] = undefined;

/**
 * @member {String} orgId
 */
BookFilterSearchingQueryModel.prototype['orgId'] = undefined;






export default BookFilterSearchingQueryModel;

