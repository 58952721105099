//#region For react-redux

//#endregion

//#region Route Path

export const HOME = "/";
export const LOGIN = "/auth/login";
export const LOGINBYIP = "/auth/loginByIp";
export const LOGOUT = "/auth/logout";
export const VERIFY = "/auth/verify";
export const FORGET = "/auth/forget";
export const FORGETCONFIRM = "/auth/forgetconfirm";
export const RESETPW = "/auth/resetPassword";
export const ADMINISTRATIVE = "/administrative";
export const BOOKMANAGEMENT = "/bookmanagement";
export const SYSTEMSETTING = "/systemsetting";
export const ORGANIZATION = "/organization";
export const USER_INTERNAL = "/user/internal";
export const USER_PUBLISHER = "/user/publisher";
export const USER_PUBLISHER_BYID = "/user/publisher/:id";
export const USER_SCHOOL = "/user/school";
export const ALL_USER = "/user/all";
export const USER_CHANGE_PASSWORD = "/user/change-password";
export const ADD_USER = "/user/add";
export const DELETE_USER = "/user/delete";
export const UPDATE_USER = "/user/update";
export const ALL_SYSTEM = "/system/all";
export const ADD_SYSTEM = "/system/add";
export const DELETE_SYSTEM = "/system/delete";
export const UPDATE_SYSTEM = "/system/update";
export const ALL_ROLE = "/role/all";
export const ASSIGN_ROLE = "/role/assignRoleToUser";
export const UPDATE_ROLE = "/role/update";
export const ADD_ROLE = "/role/add";
export const DELETE_ROLE = "/role/delete";
export const IMPORT_BOOKS = "/file/import/books";
export const DOWNLOAD_FILE = "/file/download-file";
export const ALL_BOOK = "/book/all";
export const ADD_BOOK = "/book/add";
export const UPDATE_BOOK = "/book/update";
export const DELTE_BOOK = "/book/delete";
export const BASIC = "/book/basic/";
export const COPYRIGHT = "/book/copyright/";
export const LIMIT = "/book/limit/";
export const ALL_ORGANIZATION = "/organization/all";
export const ADD_ORGANIZATION = "/organization/add";
export const UPDATE_ORGANIZATION = "/organization/update";
export const DELETE_ORGANIZATION = "/organization/delete";
export const ADD_IP = "/white-list-ip/add";
export const ALL_IP = "/white-list-ip/all";
export const UPDATE_IP = "/white-list-ip/update";
export const DELETE_IP = "/white-list-ip/delete";
export const ALL_BISAC = "/bisac/all";
export const ADD_BISAC = "/bisac/add";
export const UPDATE_BISAC = "/bisac/update";
export const DELETE_BISAC = "/bisac/delete";
export const ALL_LOCATION = "/location/all";
export const ADD_LOCATION = "/location/add";
export const UPDATE_LOCATION = "/location/update";
export const DELETE_LOCATION = "/location/delete";
export const ALL_PUBLISH = "/publish-house/all";
export const ADD_PUBLISH = "/publish-house/add";
export const UPDATE_PUBLISH = "/publish-house/update";
export const DELETE_PUBLISH = "/publish-house/delete";
export const ALL_SUPPLIER = "/supplier/all";
export const ADD_SUPPLIER = "/supplier/add";
export const UPDATE_SUPPLIER = "/supplier/update";
export const DELETE_SUPPLIER = "/supplier/delete";
export const ALL_PLATFORM = "/platform/all";
export const ADD_PLATFORM = "/platform/add";
export const UPDATE_PLATFORM = "/platform/update";
export const DELETE_PLATFORM = "/platform/delete";
export const ALL_AWARD = "/award/all";
export const ADD_AWARD = "/award/add";
export const UPDATE_AWARD = "/award/update";
export const DELETE_AWARD = "/award/delete";
export const ALL_CATEGORY = "/category/all";
export const ADD_CATEGORY = "/category/add";
export const UPDATE_CATEGORY = "/category/update";
export const DELETE_CATEGORY = "/category/delete";
export const ALL_LANGUAGE = "/language/all";
export const ADD_LANGUAGE = "/language/add";
export const UPDATE_LANGUAGE = "/language/update";
export const DELETE_LANGUAGE = "/language/delete";
export const ALL_PRODUCT_TYPE = "/product-type/all";
export const ADD_PRODUCT_TYPE = "/product-type/add";
export const UPDATE_PRODUCT_TYPE = "/product-type/update";
export const DELETE_PRODUCT_TYPE = "/product-type/delete";
export const ALL_SUBJECT = "/subject/all";
export const ADD_SUBJECT = "/subject/add";
export const UPDATE_SUBJECT = "/subject/update";
export const DELETE_SUBJECT = "/subject/delete";

export const ALL_BATCH = "/batch/all";
export const ADD_BATCH = "/batch/add";
export const UPDATE_BATCH = "/batch/update";
export const DELETE_BATCH = "/batch/delete";
export const BATCH_DETAIL = "/batch/detail";

export const PURCHASE = "/purchase";
export const ALL_PURCHASE = "/purchase/all";
export const ADD_PURCHASE = "/purchase/add";
export const UPDATE_PURCHASE = "/purchase/update";
export const DELETE_PURCHASE = "/purchase/delete";

export const BOOKLIST = "/purchase/booklist";

export const BOOK_ORGANIZATION_PATH = "/book_organization";
export const BOOK_ORGANIZATION_PATH_BYID = "/book_organization/:id";

export const PURCHASERECORD = "/purchase/assign";
export const PURCHASEDETAIL_BYID = "/purchase/assign_detail";

export const PURCHASE_CLIENT = "/purchase/client";
export const PURCHASELIST_CLIENT_BYID = "/purchase/client_booklist";
export const PURCHASEDETAIL_CLIENT_BYID = "/purchase/client_book_detail";

export const PURCHASE_SELING_BOOKLIST = "/purchase/record";
// export const ALL_PURCHASE_PATH_BYID = "/purchase/all/:id";
export const ALL_PURCHASE_DETAILS = "/purchase/details";
export const PURCHASE_LIST_PATH = "/purchase/list";
// export const PURCHASE_LIST_PATH_BYID = "/purchase/list/:id";
export const PURCHASE_LIST_PATH_BYID = "/purchase/list_id";
export const ALL_EMAIL = "/system/email";
export const ALL_LIBRARY_USERS = "/system/library/users";
export const ALL_LIBRARY_ORGANIZATION = "/system/library/organization";

//#endregion

//#region Route Path Breadcrumb Name
export const ALL_LIBRARY_ORGANIZATION_BREADCRUMB_NAME = "圖書館供應商管理";
export const ALL_LIBRARY_USERS_BREADCRUMB_NAME = "圖書館用戶管理";
export const ALL_EMAIL_BREADCRUMB_NAME = "電郵管理";
export const HOME_BREADCRUMB_NAME = "主頁";
export const LOGIN_BREADCRUMB_NAME = "登入";
export const LOGOUT_BREADCRUMB_NAME = "登出";
export const VERIFY_BREADCRUMB_NAME = "驗證";
export const FORGET_BREADCRUMB_NAME = "忘記密碼";
export const FORGETCONFIRM_BREADCRUMB_NAME = "忘記";
export const RESETPW_BREADCRUMB_NAME = "更改密碼";
export const ADMINISTRATIVE_BREADCRUMB_NAME = "行政";
export const BOOKMANAGEMENT_BREADCRUMB_NAME = "書籍";
export const BOOK_MANAGEMENT_BREADCRUMB_NAME = "書籍管理";
export const ADD_BOOK_MANAGEMENT_BREADCRUMB_NAME = "新增書籍";
export const UPDATE_BOOK_MANAGEMENT_BREADCRUMB_NAME = "新增書籍";
export const ORGANIZATION_BREADCRUMB_NAME = "機構";
export const USER_MANAGEMENT_BREADCRUMB_NAME = "用戶管理";
export const SYSTEMSETTING_BREADCRUMB_NAME = "系統管理";
export const ROLE_MANAGEMENT_BREADCRUMB_NAME = "角色管理";
export const USER_INTERNAL_BREADCRUMB_NAME = "內部";
export const USER_PUBLISHER_BREADCRUMB_NAME = "出版商";
export const USER_SCHOOL_BREADCRUMB_NAME = "機構/學校";
export const ALL_PLATFORM_BREADCRUMB_NAME = "平台管理";
export const ALL_LANGUAGE_BREADCRUMB_NAME = "語言管理";
export const ALL_CATEGORY_BREADCRUMB_NAME = "圖書分類管理";
export const ALL_PRODUCT_TYPE_BREADCRUMB_NAME = "產品類型管理";
export const ALL_SUPPLIER_BREADCRUMB_NAME = "供應商管理";
export const ALL_PUBLISH_BREADCRUMB_NAME = "出版社管理";
export const ALL_AWARD_BREADCRUMB_NAME = "獲獎名單管理";
export const ALL_BOOK_CATEGORY_BREADCRUMB_NAME = "書籍分類管理";
export const PASSWORD_MANAGEMENT_BREADCRUMB_NAME = "更改我的密碼";

export const BATCH_MANAGEMENT_BREADCRUMB_NAME = "上傳詳情";
export const ADD_BATCH_MANAGEMENT_BREADCRUMB_NAME = "上傳書籍";
export const UPDATE_BATCH_MANAGEMENT_BREADCRUMB_NAME = "上傳書籍";

export const PURCHASE_BREADCRUMB_NAME = "採購";
export const PURCHASE_MANAGEMENT_BREADCRUMB_NAME = "書單管理";
export const ADD_PURCHASE_BREADCRUMB_NAME = "新增採購";
export const UPDATE_PURCHASE_BREADCRUMB_NAME = "修改採購";
export const PURCHASE_RECORD_BREADCRUMB_NAME = "採購任務管理";
export const PURCHASE_CLIENT_BREADCRUMB_NAME = "購買清單";
export const PURCHASE_BOOKLIST_BREADCRUMB_NAME = "書庫";
export const PURCHASE_LIST_BREADCRUMB_NAME = "訂書管理";
export const PURCHASE_CLIENT_BOOKLIST_BREADCRUMB_NAME = "書單清單";

//#endregion

//#region Permission Code
export const CODE_NO_NEED_AUTH = "VALID";

export const CODE_ADMINISTRATIVE = "ADMINISTRATIVE";
export const CODE_BOOKMANAGEMENT = "BOOKMANAGEMENT";
export const CODE_PURCHASE = "PURCHASE";
export const CODE_USER_LIST = "USER_LIST";
export const CODE_USER_ADD = "USER_ADD";
export const CODE_USER_UPDATE = "USER_UPDATE";
export const CODE_USER_DELETE = "USER_DELETE";
export const CODE_ROLE_LIST = "ROLE_LIST";
export const CODE_ROLE_ADD = "ROLE_ADD";
export const CODE_ROLE_UPDATE = "ROLE_UPDATE";
export const CODE_ROLE_DELETE = "ROLE_DELETE";
export const CODE_USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const CODE_ORGANIZATION_LIST = "ORGANIZATION_LIST";
export const CODE_ORGANIZATION_ADD = "ORGANIZATION_ADD";
export const CODE_ORGANIZATION_UPDATE = "ORGANIZATION_UPDATE";
export const CODE_ORGANIZATION_DELETE = "ORGANIZATION_DELETE";
export const CODE_LIBRARY_CAT_LIST = "LIBRARY_CAT_LIST";
export const CODE_LIBRARY_CAT_ADD = "LIBRARY_CAT_ADD";
export const CODE_LIBRARY_CAT_UPDATE = "LIBRARY_CAT_UPDATE";
export const CODE_LIBRARY_CAT_DELETE = "LIBRARY_CAT_DELETE";
export const CODE_PRODUCT_TYPE_LIST = "PRODUCT_TYPE_LIST";
export const CODE_PRODUCT_TYPE_ADD = "PRODUCT_TYPE_ADD";
export const CODE_PRODUCT_TYPE_UPDATE = "PRODUCT_TYPE_UPDATE";
export const CODE_PRODUCT_TYPE_DELETE = "PRODUCT_TYPE_DELETE";
export const CODE_LANGUAGE_LIST = "LANGUAGE_LIST";
export const CODE_LANGUAGE_ADD = "LANGUAGE_ADD";
export const CODE_LANGUAGE_UPDATE = "LANGUAGE_UPDATE";
export const CODE_LANGUAGE_DELETE = "LANGUAGE_DELETE";
export const CODE_LIBRARY_ORGANIZATION_LIST = "LIBRARY_ORGANIZATION_LIST";
export const CODE_LIBRARY_ORGANIZATION_ADD = "LIBRARY_ORGANIZATION_ADD";
export const CODE_LIBRARY_ORGANIZATION_UPDATE = "LIBRARY_ORGANIZATION_UPDATE";
export const CODE_LIBRARY_ORGANIZATION_DELETE = "LIBRARY_ORGANIZATION_DELETE";
export const CODE_LIBRARY_USER_LIST = "LIBRARY_USER_LIST";
export const CODE_LIBRARY_USER_ADD = "LIBRARY_USER_ADD";
export const CODE_LIBRARY_USER_UPDATE = "LIBRARY_USER_UPDATE";
export const CODE_LIBRARY_USER_DELETE = "LIBRARY_USER_DELETE";
export const CODE_BOOK_LIST = "BOOK_LIST";
export const CODE_BOOK_ADD = "BOOK_ADD";
export const CODE_BOOK_UPDATE = "BOOK_UPDATE";
export const CODE_BOOK_DELETE = "BOOK_DELETE";
export const CODE_BOOK_TEMP = "BOOK_TEMP";
export const CODE_BOOK_SUBMITTED = "BOOK_SUBMITTED";
export const CODE_BOOK_APPROVED = "BOOK_APPROVED";
export const CODE_BOOK_REJECTED = "BOOK_REJECTED";
export const CODE_BOOK_AVAILABLE = "BOOK_AVAILABLE";
export const CODE_BOOK_UNAVAILABLE = "BOOK_UNAVAILABLE";
export const CODE_PURCHASE_BOOKLIST_LIST = "PURCHASE_BOOKLIST_LIST";
export const CODE_PURCHASE_BOOKLIST_ADD = "PURCHASE_BOOKLIST_ADD";
export const CODE_PURCHASE_BOOKLIST_UPDATE = "PURCHASE_BOOKLIST_UPDATE";
export const CODE_PURCHASE_BOOKLIST_DELETE = "PURCHASE_BOOKLIST_DELETE";
export const CODE_PURCHASE_BOOKLIST_SELECT_BOOK =
  "PURCHASE_BOOKLIST_SELECT_BOOK";
export const CODE_PURCHASE_BOOKLIST_TEMP = "PURCHASE_BOOKLIST_TEMP";
export const CODE_PURCHASE_BOOKLIST_ADD_BOOK = "PURCHASE_BOOKLIST_ADD_BOOK";
export const CODE_PURCHASE_BOOKLIST_DELETE_BOOK =
  "PURCHASE_BOOKLIST_DELETE_BOOK";
export const CODE_PURCHASE_BOOKLIST_APPROVE = "PURCHASE_BOOKLIST_APPROVE";
export const CODE_PURCHASE_MISSION_LIST = "PURCHASE_MISSION_LIST";
export const CODE_PURCHASE_MISSION_ADD = "PURCHASE_MISSION_ADD";
export const CODE_PURCHASE_MISSION_TEMP = "PURCHASE_MISSION_TEMP";
export const CODE_PURCHASE_MISSION_APPROVE = "PURCHASE_MISSION_APPROVE";
export const CODE_PURCHASE_MISSION_CLONE = "PURCHASE_MISSION_CLONE";
export const CODE_PURCHASE_MISSION_DELETE = "PURCHASE_MISSION_DELETE";
export const CODE_PURCHASE_MISSION_UPDATE = "PURCHASE_MISSION_UPDATE";
export const CODE_PURCHASE_BOOK_LIST = "PURCHASE_BOOK_LIST";
export const CODE_PURCHASE_BOOK_TEMP = "PURCHASE_BOOK_TEMP";
export const CODE_PURCHASE_BOOK_APPROVE = "PURCHASE_BOOK_APPROVE";
export const CODE_PURCHASE_BOOK_CLONE = "PURCHASE_BOOK_CLONE";
export const CODE_PURCHASE_BOOK_FINISH = "PURCHASE_BOOK_FINISH";
export const CODE_PURCHASE_BOOK_ROLLBACK = "PURCHASE_BOOK_ROLLBACK";
export const CODE_PURCHASE_BOOK_CONFIRM = "PURCHASE_BOOK_CONFIRM";
export const CODE_PURCHASE_BUYLIST_LIST = "PURCHASE_BUYLIST_LIST";
export const CODE_PURCHASE_BUYLIST_UPDATE = "PURCHASE_BUYLIST_UPDATE";
export const CODE_PURCHASE_BUYLIST_SUBMIT = "PURCHASE_BUYLIST_SUBMIT";
export const CODE_PURCHASE_BUYLIST_CANCAL = "PURCHASE_BUYLIST_CANCAL";
export const CODE_PURCHASE_BUYLIST_SEND = "PURCHASE_BUYLIST_SEND";

// //TODO DELETE
// export const CODE_LIST_AD = "LIST_AD";
// export const CODE_LIST_BOOK = "LIST_BOOK";
// export const CODE_LIST_OR = "LIST_OR";
// export const CODE_LIST_INTERNAL = "LIST_INTERNAL";
// export const CODE_LIST_PUBLISHER = "LIST_PUBLISHER";
// export const CODE_LIST_SCHOOL = "LIST_SCHOOL";
// export const CODE_LOGIN = "LOGIN";
// export const CODE_IP_LOGIN = "IP_LOGIN";
// export const CODE_LOGOUT = "LOGOUT";
// export const CODE_HOME = "HOME";
// export const CODE_GET_USER_LIST = "GET_USER_LIST";
// //export const CODE_USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
// export const CODE_ADD_USER = "ADD_USER";
// export const CODE_DELETE_USER = "DELETE_USER";
// export const CODE_UPDATE_USER = "UPDATE_USER";
// export const CODE_GET_ROLE_LIST = "GET_ROLE_LIST";
// export const CODE_ASSIGN_ROLE = "ASSIGN_ROLE";
// export const CODE_UPDATE_ROLE = "UPDATE_ROLE";
// export const CODE_ADD_ROLE = "ADD_ROLE";
// export const CODE_DELETE_ROLE = "DELETE_ROLE";
// export const CODE_IMPORT_BOOKS = "IMPORT_BOOKS";
// export const CODE_SELECT_BOOK_FILES = "SELECT_BOOK_FILES";
// export const CODE_SELECT_ALL_BOOK = "SELECT_ALL_BOOK";
// export const CODE_ADD_BOOK = "ADD_BOOK";
// export const CODE_UPDATE_BOOK = "UPDATE_BOOK";
// export const CODE_DELETE_BOOK = "DELETE_BOOK";
// export const CODE_BOOK_APPROVE = "APPROVE_BOOK";
// //export const CODE_BOOK_REJECTED = "REJECTED_BOOK";
// //export const CODE_BOOK_AVAILABLE = "AVAILABLE_BOOK";
// //export const CODE_BOOK_UNAVAILABLE = "UNAVAILABLE_BOOK";
// export const CODE_ACCESS_BOOK_BASIC_INFO = "ACCESS_BOOK_BASIC_INFO";
// export const CODE_ACCESS_BOOK_COPYRIGHT_INFO = "ACCESS_BOOK_COPYRIGHT_INFO";
// export const CODE_ACCESS_BOOK_LIMIT_INFO = "ACCESS_BOOK_LIMIT_INFO";
// export const CODE_ADD_BOOK_BASIC_INFO = "ADD_BOOK_BASIC_INFO";
// export const CODE_ADD_BOOK_COPYRIGHT_INFO = "ADD_BOOK_COPYRIGHT_INFO";
// export const CODE_ADD_BOOK_LIMIT_INFO = "ADD_BOOK_LIMIT_INFO";
// export const CODE_UPDATE_BOOK_BASIC_INFO = "UPDATE_BOOK_BASIC_INFO";
// export const CODE_UPDATE_BOOK_COPYRIGHT_INFO = "UPDATE_BOOK_COPYRIGHT_INFO";
// export const CODE_UPDATE_BOOK_LIMIT_INFO = "UPDATE_BOOK_LIMIT_INFO";
// export const CODE_DELETE_BOOK_BASIC_INFO = "DELETE_BOOK_BASIC_INFO";
// export const CODE_DELETE_BOOK_COPYRIGHT_INFO = "DELETE_BOOK_COPYRIGHT_INFO";
// export const CODE_DELETE_BOOK_LIMIT_INFO = "DELETE_BOOK_LIMIT_INFO";
// export const CODE_DOWNLOAD_FILE = "DOWNLOAD_FILE";
// export const CODE_DOWNLOAD_FILE_BASE64 = "DOWNLOAD_FILE_BASE64";
// export const CODE_DOWNLOAD_ZIP_FILE = "DOWNLOAD_ZIP_FILE";
// export const CODE_DEV_ONLY_RUN_CRON = "DEV_ONLY_RUN_CRON";
// export const CODE_ADD_ORGANIZATION = "ADD_ORGANIZATION";
// export const CODE_SELECT_ORGANIZATION = "SELECT_ORGANIZATION";
// export const CODE_UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
// export const CODE_DELETE_ORGANIZATION = "DELETE_ORGANIZATION";
// export const CODE_ADD_IP = "ADD_IP";
// export const CODE_GET_IP = "GET_IP";
// export const CODE_DELETE_IP = "DELETE_IP";
// export const CODE_ADD_BISAC = "ADD_BISAC";
// export const CODE_SELECT_BISAC = "SELECT_BISAC";
// export const CODE_UPDATE_BISAC = "UPDATE_BISAC";
// export const CODE_DELETE_BISAC = "DELETE_BISAC";
// export const CODE_ADD_LOCATION = "ADD_LOCATION";
// export const CODE_SELECT_LOCATION = "SELECT_LOCATION";
// export const CODE_UPDATE_LOCATION = "UPDATE_LOCATION";
// export const CODE_DELETE_LOCATION = "DELETE_LOCATION";
// export const CODE_ADD_PUBLISH_HOUSE = "ADD_PUBLISH_HOUSE";
// export const CODE_SELECT_PUBLISH_HOUSE = "SELECT_PUBLISH_HOUSE";
// export const CODE_UPDATE_PUBLISH_HOUSE = "UPDATE_PUBLISH_HOUSE";
// export const CODE_DELETE_PUBLISH_HOUSE = "DELETE_PUBLISH_HOUSE";
// export const CODE_ADD_SUPPLIER = "ADD_SUPPLIER";
// export const CODE_SELECT_SUPPLIER = "SELECT_SUPPLIER";
// export const CODE_UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
// export const CODE_DELETE_SUPPLIER = "DELETE_SUPPLIER";
// export const CODE_ADD_PLATFORM = "ADD_PLATFORM";
// export const CODE_SELECT_PLATFORM = "SELECT_PLATFORM";
// export const CODE_UPDATE_PLATFORM = "UPDATE_PLATFORM";
// export const CODE_DELETE_PLATFORM = "DELETE_PLATFORM";
// export const CODE_ADD_AWARD = "ADD_AWARD";
// export const CODE_SELECT_AWARD = "SELECT_AWARD";
// export const CODE_UPDATE_AWARD = "UPDATE_AWARD";
// export const CODE_DELETE_AWARD = "DELETE_AWARD";
// //category
// export const CODE_ADD_CATEGORY = "ADD_CATEGORY";
// export const CODE_SELECT_CATEGORY = "SELECT_CATEGORY";
// export const CODE_UPDATE_CATEGORY = "UPDATE_CATEGORY";
// export const CODE_DELETE_CATEGORY = "DELETE_CATEGORY";
// //語言管理
// export const CODE_ADD_LANGUAGE = "ADD_LANGUAGE";
// export const CODE_SELECT_LANGUAGE = "SELECT_LANGUAGE";
// export const CODE_UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
// export const CODE_DELETE_LANGUAGE = "DELETE_LANGUAGE";
// //產品類型管理
// export const CODE_ADD_PRODUCT_TYPE = "ADD_PRODUCT_TYPE";
// export const CODE_SELECT_PRODUCT_TYPE = "SELECT_PRODUCT_TYPE";
// export const CODE_UPDATE_PRODUCT_TYPE = "UPDATE_PRODUCT_TYPE";
// export const CODE_DELETE_PRODUCT_TYPE = "DELETE_PRODUCT_TYPE";
// //圖書館供應商管理
// export const CODE_SELECT_LIBRARY_ORGANIZATIONS = "SELECT_LIBRARY_ORGANIZATIONS";
// export const CODE_ADD_LIBRARY_ORGANIZATIONS = "ADD_LIBRARY_ORGANIZATIONS";
// export const CODE_UPDATE_LIBRARY_ORGANIZATIONS = "UPDATE_LIBRARY_ORGANIZATIONS";
// export const CODE_DELETE_LIBRARY_ORGANIZATIONS = "DELETE_LIBRARY_ORGANIZATIONS";
// //圖書館用戶管理
// export const CODE_SELECT_LIBRARY_USERS = "SELECT_LIBRARY_USERS";
// export const CODE_ADD_LIBRARY_USERS = "ADD_LIBRARY_USERS";
// export const CODE_UPDATE_LIBRARY_USERS = "UPDATE_LIBRARY_USERS";
// export const CODE_DELETE_LIBRARY_USERS = "DELETE_LIBRARY_USERS";
// //書單管理
// export const CODE_BOOK_LIST_ALL = "ALL_BOOK_LIST";
// export const CODE_BOOK_LIST_ADD = "ADD_BOOK_LIST";
// export const CODE_BOOK_LIST_UPDATE = "UPDATE_BOOK_LIST";
// export const CODE_BOOK_LIST_DELETE = "DELETE_BOOK_LIST";
// export const CODE_BOOK_LIST_APPROVE = "APPROVE_BOOK_LIST";
// //採購任務管理
// export const CODE_PURCHASE_TASK_ADD = "ADD_PURCHASE_TASK";
// export const CODE_PURCHASE_TASK_CLONE = "CLONE_PURCHASE_TASK";
// export const CODE_PURCHASE_TASK_UPDATE = "UPDATE_PURCHASE_TASK";
// export const CODE_PURCHASE_TASK_DELETE = "DELETE_PURCHASE_TASK";
// export const CODE_PURCHASE_TASK_APPROVE = "APPROVE_PURCHASE_TASK";
// //訂書管理
// export const CODE_BOOK_ORDER_ALL = "ALL_BOOK_ORDER";
// export const CODE_BOOK_ORDER_SUBMIT = "SUBMIT_BOOK_ORDER";
// export const CODE_BOOK_ORDER_APPROVE = "APPROVE_BOOK_ORDER";
// export const CODE_BOOK_ORDER_FINISH = "FINISH_BOOK_ORDER";
// //購買清單
// export const CODE_PURCHASE_LIST_ALL = "ALL_PURCHASE_LIST";
// export const CODE_PURCHASE_LIST_SENT = "SENT_PURCHASE_LIST";
// export const CODE_PURCHASE_LIST_CHOOSE_BOOK = "CHOOSE_BOOK_PURCHASE_LIST";
// export const CODE_PURCHASE_LIST_SUBMIT_CHOOSE_BOOK =
//   "SUBMIT_CHOOSE_BOOK_PURCHASE_LIST";
// export const CODE_PURCHASE_LIST_CANCEL_CHOOSE_BOOK =
//   "CANCEL_CHOOSE_BOOK_PURCHASE_LIST";
// export const CODE_PURCHASE_LIST_APPROVE = "APPROVE_PURCHASE_LIST";
// export const CODE_PURCHASE_LIST_FINISH = "FINISH_PURCHASE_LIST";
// export const CODE_ALL_LIBRARY_ORGANIZATIONS = "ALL_LIBRARY_ORGANIZATIONS";
// export const CODE_ALL_LIBRARY_USERS = "ALL_LIBRARY_USERS";
// export const CODE_LIST_PURCHASE = "LIST_PURCHASE";
// export const CODE_ALL_PURCHASE_TASK = "ALL_PURCHASE_TASK";

// export const CODE_ADD_BATCH = "ADD_BATCH";
// export const CODE_SELECT_BATCH = "SELECT_BATCH";
// export const CODE_UPDATE_BATCH = "UPDATE_BATCH";
// export const CODE_DELETE_BATCH = "DELETE_BATCH";

// export const CODE_ADD_PURCHASE = "ADD_PURCHASE";
// export const CODE_SELECT_PURCHASE = "SELECT_PURCHASE";
// export const CODE_UPDATE_PURCHASE = "UPDATE_PURCHASE";
// export const CODE_DELETE_PURCHASE = "DELETE_PURCHASE";
// //TODO DELETE

//#endregion

//#region Catogary Name
export const CAT_MANAGEMENT = "MANAGEMENT";
export const CAT_BOOK = "BOOK";
export const CAT_BASIC = "BASIC";
export const CAT_PURCHASE = "PURCHASE";
//#endregion

//#region Contorl
export const ADD = "add";
export const UPDATE = "update";
export const DELETE = "delete";
export const EDIT = "edit";
export const SELECT = "select";
export const COPY = "copy";
export const CHECKBOX = "checkbox";
//#endregion

//#region session storage
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";
export const USER_INFO = "userInfo";
export const CLIENT_INFO = "clientInfo";
export const REMIND_INFO = "remindInfo";
//#endregion

//#region AuthApi
export const EMAIL_SENT = "Email Sent";
export const EMAIL_CHECK = "Please Check email";
//#endregion

//#region Component
export const CARDVIEW = "Card View";
export const LISTVIEW = "List View";
//#endregion

//#region TabListTitle
export const INTERNAL = "INTERNAL";
export const SUPPLIER = "SUPPLIER";
export const SCHOOL = "SCHOOL";
//#endregion

//#region Button / INPUT / SELECT OPTION
export const STYLETYPE_DEFAULT = "default";
export const STYLETYPE_MAIN = "Main";
export const STYLETYPE_SECOND = "Second";
export const STYLETYPE_THIRD = "Third";
export const STYLETYPE_FOUR = "Four";
export const STYLETYPE_FIVE = "Five";
export const STYLETYPE_SIX = "Six";
export const STYLETYPE_SEVEN = "Seven";
export const STYLETYPE_EIGHT = "Eight";
export const STYLETYPE_NINE = "Nine";
export const STYLETYPE_IMG = "Img";
export const STYLETYPE_CUSTOM = "custom";
export const STYLETYPE_SELECT_FILE = "file";
//#endregion

//#region Display Style
export const STYLE_LIST = "list_style";
export const STYLE_CARD = "list_card";
//#endregion

//#region Dialog Action
export const DIALOG_CANCEL = "Cancel";
export const DIALOG_COMPLETE = "Complete";
export const DIALOG_DELETE = "Delete";
export const DIALOG_EDIT = "Edit";
//#endregion

//#region File
export const CHUNK_SIZE = 10 * 1024 * 1024;
export const MAX_REQUEST_NUM = 6;
export const MAX_RETRY_NUM = 3;
//#endregion

export const TEMP = "TEMP";
export const SUBMITTED = "SUBMITTED";
export const APPROVED = "APPROVED";
export const REJECTED = "REJECTED";
export const AVAILABLE = "AVAILABLE";
export const UNAVAILABLE = "UNAVAILABLE";
export const APPLY_UNAVAILABLE = "APPLY_UNAVAILABLE";

//#region File Type
export const COVER = "cover";
export const EPUBPREVIEW = "ePubPreview";
export const EPUBPROD = "ePubProd";
export const MULTIMEDIA = "multimedia";
export const THUMB = "thumb";
//#endregion

//purchase mission status
export const MISSION_PUBLISHED = "Published";
export const MISSION_FINISH = "Finish";
export const MISSION_DRAFT = "Draft";
export const MISSION_SENT = "Sent";
export const MISSION_CONFIRM = "Confirm";
//end purchase mission status

// #region Date Pattern
export const FULLDATE = "YYYY-MM-DD";
export const YEARMONTH = "YYYY-MM";
export const DATETIME = "YYYY-MM-DD HH:mm";
export const FULLDATETIME = "YYYY-MM-DD HH:mm:ss";
// #endregion
