/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CustomerShoppingCartPurchaseMissionDto model module.
 * @module model/CustomerShoppingCartPurchaseMissionDto
 * @version v1.0.0
 */
class CustomerShoppingCartPurchaseMissionDto {
    /**
     * Constructs a new <code>CustomerShoppingCartPurchaseMissionDto</code>.
     * @alias module:model/CustomerShoppingCartPurchaseMissionDto
     */
    constructor() { 
        
        CustomerShoppingCartPurchaseMissionDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CustomerShoppingCartPurchaseMissionDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CustomerShoppingCartPurchaseMissionDto} obj Optional instance to populate.
     * @return {module:model/CustomerShoppingCartPurchaseMissionDto} The populated <code>CustomerShoppingCartPurchaseMissionDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CustomerShoppingCartPurchaseMissionDto();

            if (data.hasOwnProperty('lastUpdate')) {
                obj['lastUpdate'] = ApiClient.convertToType(data['lastUpdate'], 'Number');
            }
            if (data.hasOwnProperty('purchaseMissionId')) {
                obj['purchaseMissionId'] = ApiClient.convertToType(data['purchaseMissionId'], 'Number');
            }
            if (data.hasOwnProperty('referenceNo')) {
                obj['referenceNo'] = ApiClient.convertToType(data['referenceNo'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} lastUpdate
 */
CustomerShoppingCartPurchaseMissionDto.prototype['lastUpdate'] = undefined;

/**
 * @member {Number} purchaseMissionId
 */
CustomerShoppingCartPurchaseMissionDto.prototype['purchaseMissionId'] = undefined;

/**
 * @member {String} referenceNo
 */
CustomerShoppingCartPurchaseMissionDto.prototype['referenceNo'] = undefined;

/**
 * @member {module:model/CustomerShoppingCartPurchaseMissionDto.StatusEnum} status
 */
CustomerShoppingCartPurchaseMissionDto.prototype['status'] = undefined;

/**
 * @member {String} title
 */
CustomerShoppingCartPurchaseMissionDto.prototype['title'] = undefined;





/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
CustomerShoppingCartPurchaseMissionDto['StatusEnum'] = {

    /**
     * value: "Confirm"
     * @const
     */
    "Confirm": "Confirm",

    /**
     * value: "Draft"
     * @const
     */
    "Draft": "Draft",

    /**
     * value: "Finish"
     * @const
     */
    "Finish": "Finish",

    /**
     * value: "Published"
     * @const
     */
    "Published": "Published",

    /**
     * value: "Sent"
     * @const
     */
    "Sent": "Sent"
};



export default CustomerShoppingCartPurchaseMissionDto;

