/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BookFilePayloadModel from './BookFilePayloadModel';

/**
 * The BookPayloadModel model module.
 * @module model/BookPayloadModel
 * @version v1.0.0
 */
class BookPayloadModel {
    /**
     * Constructs a new <code>BookPayloadModel</code>.
     * @alias module:model/BookPayloadModel
     */
    constructor() { 
        
        BookPayloadModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BookPayloadModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BookPayloadModel} obj Optional instance to populate.
     * @return {module:model/BookPayloadModel} The populated <code>BookPayloadModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BookPayloadModel();

            if (data.hasOwnProperty('author')) {
                obj['author'] = ApiClient.convertToType(data['author'], 'String');
            }
            if (data.hasOwnProperty('bookComment')) {
                obj['bookComment'] = ApiClient.convertToType(data['bookComment'], 'String');
            }
            if (data.hasOwnProperty('bookIsbn')) {
                obj['bookIsbn'] = ApiClient.convertToType(data['bookIsbn'], 'String');
            }
            if (data.hasOwnProperty('categoryIds')) {
                obj['categoryIds'] = ApiClient.convertToType(data['categoryIds'], ['String']);
            }
            if (data.hasOwnProperty('ctsid')) {
                obj['ctsid'] = ApiClient.convertToType(data['ctsid'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('file')) {
                obj['file'] = BookFilePayloadModel.constructFromObject(data['file']);
            }
            if (data.hasOwnProperty('inventory')) {
                obj['inventory'] = ApiClient.convertToType(data['inventory'], 'Number');
            }
            if (data.hasOwnProperty('isRoot')) {
                obj['isRoot'] = ApiClient.convertToType(data['isRoot'], 'Boolean');
            }
            if (data.hasOwnProperty('languageIds')) {
                obj['languageIds'] = ApiClient.convertToType(data['languageIds'], ['String']);
            }
            if (data.hasOwnProperty('mainBookId')) {
                obj['mainBookId'] = ApiClient.convertToType(data['mainBookId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('organizationId')) {
                obj['organizationId'] = ApiClient.convertToType(data['organizationId'], 'Number');
            }
            if (data.hasOwnProperty('originalPrice')) {
                obj['originalPrice'] = ApiClient.convertToType(data['originalPrice'], 'Number');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('productTypeId')) {
                obj['productTypeId'] = ApiClient.convertToType(data['productTypeId'], 'String');
            }
            if (data.hasOwnProperty('publishDate')) {
                obj['publishDate'] = ApiClient.convertToType(data['publishDate'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('translator')) {
                obj['translator'] = ApiClient.convertToType(data['translator'], 'String');
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'String');
            }
        }
        return obj;
    }


}

/**
 * 作者
 * @member {String} author
 */
BookPayloadModel.prototype['author'] = undefined;

/**
 * 書評
 * @member {String} bookComment
 */
BookPayloadModel.prototype['bookComment'] = undefined;

/**
 * ISBN/產品編號
 * @member {String} bookIsbn
 */
BookPayloadModel.prototype['bookIsbn'] = undefined;

/**
 * 分類 ids
 * @member {Array.<String>} categoryIds
 */
BookPayloadModel.prototype['categoryIds'] = undefined;

/**
 * @member {String} ctsid
 */
BookPayloadModel.prototype['ctsid'] = undefined;

/**
 * 產品簡介
 * @member {String} description
 */
BookPayloadModel.prototype['description'] = undefined;

/**
 * @member {module:model/BookFilePayloadModel} file
 */
BookPayloadModel.prototype['file'] = undefined;

/**
 * inventory
 * @member {Number} inventory
 */
BookPayloadModel.prototype['inventory'] = undefined;

/**
 * is root
 * @member {Boolean} isRoot
 */
BookPayloadModel.prototype['isRoot'] = undefined;

/**
 * languages
 * @member {Array.<String>} languageIds
 */
BookPayloadModel.prototype['languageIds'] = undefined;

/**
 * main book Id
 * @member {String} mainBookId
 */
BookPayloadModel.prototype['mainBookId'] = undefined;

/**
 * 產品名稱
 * @member {String} name
 */
BookPayloadModel.prototype['name'] = undefined;

/**
 * 出版社Id
 * @member {Number} organizationId
 */
BookPayloadModel.prototype['organizationId'] = undefined;

/**
 * 原價
 * @member {Number} originalPrice
 */
BookPayloadModel.prototype['originalPrice'] = undefined;

/**
 * 售價
 * @member {Number} price
 */
BookPayloadModel.prototype['price'] = undefined;

/**
 * productTypeId
 * @member {String} productTypeId
 */
BookPayloadModel.prototype['productTypeId'] = undefined;

/**
 * 出版日期
 * @member {Number} publishDate
 */
BookPayloadModel.prototype['publishDate'] = undefined;

/**
 * 狀態
 * @member {module:model/BookPayloadModel.StatusEnum} status
 */
BookPayloadModel.prototype['status'] = undefined;

/**
 * 譯者
 * @member {String} translator
 */
BookPayloadModel.prototype['translator'] = undefined;

/**
 * version
 * @member {String} version
 */
BookPayloadModel.prototype['version'] = undefined;





/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
BookPayloadModel['StatusEnum'] = {

    /**
     * value: "APPLY_UNAVAILABLE"
     * @const
     */
    "APPLY_UNAVAILABLE": "APPLY_UNAVAILABLE",

    /**
     * value: "APPROVED"
     * @const
     */
    "APPROVED": "APPROVED",

    /**
     * value: "AVAILABLE"
     * @const
     */
    "AVAILABLE": "AVAILABLE",

    /**
     * value: "REJECTED"
     * @const
     */
    "REJECTED": "REJECTED",

    /**
     * value: "SUBMITTED"
     * @const
     */
    "SUBMITTED": "SUBMITTED",

    /**
     * value: "TEMP"
     * @const
     */
    "TEMP": "TEMP",

    /**
     * value: "UNAVAILABLE"
     * @const
     */
    "UNAVAILABLE": "UNAVAILABLE"
};



export default BookPayloadModel;

