import PropTypes from "prop-types";
import React, { Component } from "react";
import { STYLETYPE_MAIN, STYLETYPE_SECOND } from "../../utils/Constants";
import style from "./DialogBox.module.css";

export default class DialogBox extends Component {
  heightRef = React.createRef();

  static propTypes = {
    showDialog: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    styleType: PropTypes.string,
  };

  static defaultProps = {
    showDialog: false,
    title: "",
    styleType: STYLETYPE_MAIN,
  };

  componentDidMount() {}

  render() {
    const { title, showDialog, children, styleType } = this.props;
    return (
      <>
        {styleType === STYLETYPE_MAIN ? (
          <div
            className={style.container}
            style={{ display: showDialog ? "block" : "none" }}
          >
            <div className={style.contentContainer}>
              <div className={style.title}>
                <h3>{title}</h3>
              </div>

              <div className={style.content} ref={this.heightRef}>
                {children}
              </div>
            </div>
          </div>
        ) : styleType === STYLETYPE_SECOND ? (
          <div
            className={style.container2}
            style={{ display: showDialog ? "block" : "none" }}
          >
            <div className={style.contentContainer2}>
              <div className={style.title}>
                <h3>{title}</h3>
              </div>

              <div className={style.content} ref={this.heightRef}>
                {children}
              </div>
            </div>
          </div>
        ) : styleType === "AssigedUser" ? (
          <div
            className={style.container}
            style={{ display: showDialog ? "block" : "none" }}
          >
            <div className={style.contentContainerAssigedUser}>
              <div className={style.title}>
                <h3>{title}</h3>
              </div>

              <div className={style.content} ref={this.heightRef}>
                {children}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}
