import React from "react";
import { connect } from "react-redux";
import { handleFetchBooks } from "../../../../redux/actions/reader";

class DeletePopup extends React.Component {
  handleCancel = () => {
    this.props.handleDeletePopup(false);
  };
  handleComfirm = () => {
    //从列表删除和从图书库删除判断
    this.props.handleDeletePopup(false);
    this.props.handleDeleteOpearion();
    alert("删除成功");
  };
  render() {
    return (
      <div className="delete-dialog-container">
        <div className="delete-dialog-title">{this.props.title}</div>

        <div className="delete-dialog-book">
          <div className="delete-dialog-book-title">{this.props.name}</div>
        </div>

        <div className="delete-dialog-other-option">
          {this.props.description}
        </div>

        <div
          className="delete-dialog-cancel"
          onClick={() => {
            this.handleCancel();
          }}
        >
          Cancel
        </div>
        <div
          className="delete-dialog-comfirm"
          onClick={() => {
            this.handleComfirm();
          }}
        >
          Delete
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
const actionCreator = {
  handleFetchBooks,
};
export default connect(mapStateToProps, actionCreator)(DeletePopup);
