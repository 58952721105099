/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BookFileItemDto from './BookFileItemDto';

/**
 * The BookFileDto model module.
 * @module model/BookFileDto
 * @version v1.0.0
 */
class BookFileDto {
    /**
     * Constructs a new <code>BookFileDto</code>.
     * @alias module:model/BookFileDto
     */
    constructor() { 
        
        BookFileDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BookFileDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BookFileDto} obj Optional instance to populate.
     * @return {module:model/BookFileDto} The populated <code>BookFileDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BookFileDto();

            if (data.hasOwnProperty('audioBook')) {
                obj['audioBook'] = ApiClient.convertToType(data['audioBook'], [BookFileItemDto]);
            }
            if (data.hasOwnProperty('cover')) {
                obj['cover'] = ApiClient.convertToType(data['cover'], [BookFileItemDto]);
            }
            if (data.hasOwnProperty('epubPreview')) {
                obj['epubPreview'] = ApiClient.convertToType(data['epubPreview'], [BookFileItemDto]);
            }
            if (data.hasOwnProperty('epubProd')) {
                obj['epubProd'] = ApiClient.convertToType(data['epubProd'], [BookFileItemDto]);
            }
            if (data.hasOwnProperty('multimedia')) {
                obj['multimedia'] = ApiClient.convertToType(data['multimedia'], [BookFileItemDto]);
            }
            if (data.hasOwnProperty('thumb')) {
                obj['thumb'] = ApiClient.convertToType(data['thumb'], [BookFileItemDto]);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/BookFileItemDto>} audioBook
 */
BookFileDto.prototype['audioBook'] = undefined;

/**
 * @member {Array.<module:model/BookFileItemDto>} cover
 */
BookFileDto.prototype['cover'] = undefined;

/**
 * @member {Array.<module:model/BookFileItemDto>} epubPreview
 */
BookFileDto.prototype['epubPreview'] = undefined;

/**
 * @member {Array.<module:model/BookFileItemDto>} epubProd
 */
BookFileDto.prototype['epubProd'] = undefined;

/**
 * @member {Array.<module:model/BookFileItemDto>} multimedia
 */
BookFileDto.prototype['multimedia'] = undefined;

/**
 * @member {Array.<module:model/BookFileItemDto>} thumb
 */
BookFileDto.prototype['thumb'] = undefined;






export default BookFileDto;

