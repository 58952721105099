import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import editIcon from "../../assets/images/btn/edit.svg";
import userIcon from "../../assets/images/btn/main_menu/user.svg";
import deleteIcon from "../../assets/images/btn/trash.svg";
import { handleUserInfo } from "../../redux/actions"; //"../../redux/actions";
import {
  ALL_USER,
  CODE_LIBRARY_USER_LIST,
  STYLETYPE_DEFAULT,
  STYLETYPE_IMG,
} from "../../utils/Constants";
import Button from "../Button/Button";
import Input from "../Input/Input";
import "./OrgSearchFilter.css";

class OrgSearchFilter extends React.Component {
  state = {
    originData: [],
    data: [],
    childList: [],
    flagOpenMenu: true,
    expand: false,
    multiple: false,
    inputTextValue: "",
    // checkbox:false
  };

  static propTypes = {
    styleType: PropTypes.string,
    callBack: PropTypes.func,
    childList: PropTypes.array,
    data: PropTypes.array,
    flagOpenMenu: PropTypes.bool,
    expand: PropTypes.bool,
    canDelete: PropTypes.bool,
    canEdit: PropTypes.bool,
    multiple: PropTypes.bool,
    // checkbox:PropTypes.bool
  };

  static defaultProps = {
    originData: [],
    expand: false,
    data: [],
    childList: [],
    flagOpenMenu: true,
    multiple: false,
    canDelete: true,
    canEdit: true,
    // checkbox:false
  };

  initData = (obj, level) => {
    obj.forEach((_obj, index) => {
      _obj.showed = false;
      _obj.opened = false;
      _obj.isSelected = false;
    });
    return obj;
  };

  getRoot = (obj, level) => {
    this.childList = [];
    let rootList = [];

    obj.forEach((mainObj, index) => {
      console.log("mainObj", mainObj);
      if (mainObj.mainId === mainObj.id) {
        mainObj.showed = true;
        rootList.push(mainObj);
      }

      // if (mainObj.id === mainObj.mainId) {
      //   mainObj.showed = true;
      //   rootList.push(mainObj);
      // }
    });
    ////console.log("getRoot rootList", rootList);
    return rootList;
  };

  genHTML = (obj, level, isMultiple) => {
    //var childernList = [];

    if (obj instanceof Array) {
      obj.forEach((mainObj, index) => {
        // //get main
        //console.log("m", mainObj);
        //this.childList.push(this.getHTML(mainObj, level, isMultiple));
        this.childList.push(this.getHTML(mainObj, level, isMultiple));
        // //console.log('childList',this.childList)

        //find child
        if (mainObj.subIds.length > 0) {
          mainObj.subIds.map((subObj) => {
            //console.log("s1", subObj);
            var childObj = this.state.data.find((orObj) => orObj.id === subObj);

            ///console.log("dkhagdhjsagdhjsag", childObj);

            if (childObj && childObj.subIds) {
              // && childObj.subIds.length > 0) {
              this.genHTML(childObj, level + 1, isMultiple);
            }
          });
        }
        //childernList.push(this.childList);
      });
    } else {
      ////console.log("genHTML BBB", obj);
      ////console.log("a", obj.id);
      //this.childList.push(this.getHTML(obj, level - 1, isMultiple));
      this.childList.push(this.getHTML(obj, level, isMultiple));

      if (obj.subIds.length > 0) {
        obj.subIds.map((subObj) => {
          // //console.log("s2", subObj);
          var childObj = this.state.data.find((orObj) => orObj.id === subObj);

          this.childList.push(this.getHTML(childObj, level + 1, isMultiple));

          if (childObj && childObj.subIds.length > 0) {
            this.genHTML(childObj, level + 1, isMultiple);
          }
        });
      }
    }
    ////console.log("this.chidlist", this.childList);

    ////console.log(this.childList);
    return React.createElement("div", null, this.childList);
    ////console.log(childernList);
    ////console.log(childList);
  };

  onChange = (childObj) => {
    //console.log("onChange", childObj);
    let originData = this.state.originData;
    let selctedIdArr = originData.find((item) => item.id === childObj.id);
    //console.log("selctedIdArr", selctedIdArr);
    let finalSelectedArr = new Set();
    finalSelectedArr.add(selctedIdArr.id);

    function findAllRelatedid(idArr) {
      let finalArr = [idArr];

      let myObj = originData.filter((k) => k.id == idArr);
      //console.log("myObj", myObj);
      if (myObj[0].subIds.length >= 1) {
        for (let v of myObj[0].subIds) {
          finalArr.push(...findAllRelatedid(v));
        }
      } else {
        //console.log("finalArr", finalArr);
        return finalArr;
      }
      return finalArr;
    }
    // for(let v of selctedIdArr.subIds){
    let result = findAllRelatedid(selctedIdArr.id);
    // //console.log('result',result)
    result.forEach((k) => finalSelectedArr.add(k));

    // }

    //console.log(finalSelectedArr);
    let finalSelectedArrayRaw = Array.from(finalSelectedArr);

    let dddd = this.state.originData.map((item) => {
      // //console.log('dddd',finalSelectedArrayRaw)
      if (finalSelectedArrayRaw.indexOf(item.id) >= 0) {
        //console.log(111, "inside");
        item.isSelected = !item.isSelected;
        return item;
      } else {
        return item;
      }
    });

    // this.props.callBack(dddd);
    this.setState({
      data: dddd,
    });
  };

  showSubMenu = (childObj, level) => {
    console.log("showSubMenu", childObj, level);
    this.setState({
      data: this.state.originData.map((item) =>
        item.id === childObj.id
          ? { ...item, isSelected: !item.isSelected }
          : item
      ),
    });
    // //console.log('this.state',this.state.originData)
    // this.props.callBack(this.state.originData);
  };

  divChick = (childObj) => {
    this.props.callBack(childObj);
    this.setState({ flagOpenMenu: true });
  };

  getHTML = (childObj, level, isMultiple) => {
    // //console.log("getHTML", childObj, level, isMultiple);
    ////console.log("getHTML onChange", this.onChange);

    let _hasChild = false;

    if (!childObj) {
      return;
    }

    if (childObj.subIds.length > 0) {
      _hasChild = true;
    }

    //=====

    let _classList = [];
    _classList.push("result_item");
    _classList.push("osf_result_item");
    if (_hasChild) {
      _classList.push("hasChild");
    }
    if (childObj.showed) {
    } else {
      if (childObj.opened) {
      } else {
        _classList.push("hidden");
      }
    }
    let _classListStr = _classList.join(" ");

    //=====

    let _eb_classList = [];
    _eb_classList.push("expendBtn");
    if (childObj.opened) {
      _eb_classList.push("open");
    }
    let _eb_classListStr = _eb_classList.join(" ");

    //=====

    ////console.log("this.props", this.props);
    let _pl = 16 + level * 20 + "px";
    if (this.props.for && this.props.for === "smw") {
      _pl = 30 + 16 + level * 20 + "px";
    }
    let _l;
    if (this.props.for && this.props.for === "smw") {
      _l = 6 + level * 20 + "px";
    }

    return (
      <div
        className={_classListStr}
        id={childObj.id}
        mainid={childObj.mainId}
        level={level}
        style={{
          paddingLeft: _pl,
        }}
      >
        <div
          className="result_item_content"
          key={childObj.id}
          onClick={() => this.divChick(childObj)}
        >
          {isMultiple ? (
            <Input
              type="checkbox"
              showType={STYLETYPE_DEFAULT}
              checked={childObj.isSelected}
              callBack={() => this.onChange(childObj)}
            />
          ) : (
            <></>
          )}
          <div>
            <label
              value={childObj.id}
              onClick={(value) => this.showSubMenu(childObj, 0)}
              title={childObj.name}
            >
              {childObj.name}
            </label>
          </div>
          <div>
            <label>{childObj.description}</label>
          </div>
          <Button
            styleType={STYLETYPE_IMG}
            code={CODE_LIBRARY_USER_LIST}
            callBack={(value) =>
              this.props.history.push(`${ALL_USER}?id=${childObj.id}`, childObj)
            }
          >
            <img src={userIcon} style={{ width: "30px", height: "30px" }} />
          </Button>

          {this.props.canEdit ? (
            <Button
              styleType={STYLETYPE_IMG}
              callBack={(value) => this.handleEditCallBack(childObj)}
            >
              <img src={editIcon} />
            </Button>
          ) : (
            <></>
          )}

          {this.props.canDelete ? (
            <Button
              styleType={STYLETYPE_IMG}
              callBack={(value) => this.handleDeleteCallBack(childObj)}
            >
              <img src={deleteIcon} />
            </Button>
          ) : (
            <></>
          )}

          {_hasChild && (
            <div
              className={_eb_classListStr}
              key={childObj.id}
              mainid={childObj.id}
              onClick={(event) => this.handleExpendBtn(event)}
              style={{
                left: _l,
              }}
            ></div>
          )}
        </div>
      </div>
    );
  };

  handleCheckedCallBack = (value) => {
    value.action = "checkbox";
    this.props.callBack(value);
    // this.props.callBack({ action: "checkbox", value });
  };

  handleDeleteCallBack = (value) => {
    value.action = "delete";
    this.props.callBack(value);
    // this.props.callBack({ action: "delete", value });
  };

  handleEditCallBack = (value) => {
    value.action = "edit";
    this.props.callBack(value);
    //this.props.callBack({ action: "edit", value });
  };

  componentDidMount() {
    let _data = this.initData(this.props.data);
    let _multiple = this.props.multiple;
    let _expand = this.props.expand;
    this.setState({
      originData: _data,
      data: _data,
      multiple: _multiple,
      expand: _expand,
    });
  }

  componentWillReceiveProps(nextProps) {
    //console.log("nextProps", nextProps);
    if (nextProps !== this.props) {
      const { data, multiple, expand } = nextProps;
      let _data = this.initData(data);
      let _multiple = multiple;
      this.setState({
        originData: _data,
        data: _data,
        multiple: _multiple,
        flagOpenMenu: true,
      });
      // if (expand) {

      //     this.setState({ flagOpenMenu: true });
      // }
      return;
    }
  }

  handleSelectOnChange = (event) => {};

  handleSearchBtnClick = (event) => {
    //this.setState({ inputTextValue: "" });

    this.handleInputText("");

    if (!this.state.expand) {
      let _flagOpenMenu = !this.state.flagOpenMenu;
      this.setState({
        inputTextValue: "",
        flagOpenMenu: _flagOpenMenu,
      });
    } else {
      this.setState({
        inputTextValue: "",
        flagOpenMenu: !this.state.flagOpenMenu,
      });
    }
  };

  handleExpendBtn = (event) => {
    let _mainId = parseInt(event.target.getAttribute("mainid"));
    // //console.log(_mainId, event.target);

    //$(".result_item[mainid=" + _mainId + "]").removeClass("hidden");

    //let _roots = this.getRoot(this.state.data);
    //let _rootObj = _roots.find((_orObj) => _orObj.id === _mainId);

    const result = this.props.data.map((x) => {
      if (x.mainId === _mainId) {
        x.showed = !x.showed;
      }
      return x;
    });

    let _targetObj = this.state.data.find((_orObj) => _orObj.id === _mainId);

    if (_targetObj) {
      _targetObj.opened = !_targetObj.opened;
    }

    let _parentObj = this.state.data.find((_orObj) => _orObj.id === _mainId);
    let _data = this.state.data;

    // _data.map((_obj) => {
    //   var findResult = _obj.mainId.filter((it) => it === _mainId);
    //   console.log("findResult", findResult);

    //   if (findResult.length > 0) {
    //     _obj.showed = _parentObj.opened;
    //   }
    // });
    ////console.log(_data);//, JSON.stringify(_data) );
    const subObj = _parentObj;
    this.setState(
      {
        data: result,
      },
      () => {
        // //console.log(this.state.data);
      }
    );
  };

  handleInputText = (str) => {
    ////console.log(this.state.data);
    console.log(str);

    // let filtedData = this.state.originData.filter((v) =>
    //   v.name.toLowerCase().includes(str.toLowerCase())
    // );
    if (str === "") {
      this.setState({
        data: this.props.data,
        flagOpenMenu: true,
        inputTextValue: str,
      });
      return;
    }

    let objArr = this.state.data.filter((v) =>
      v.name.toLowerCase().includes(str.toLowerCase())
    );
    console.log("objArr", objArr);

    this.setState({
      data: objArr,
      flagOpenMenu: true,
      inputTextValue: str,
    });
    // var resultArr = objArr.map((it) => {
    //   it.mainId = [];
    //   it.subIds = [];
    //   return it;
    // });

    // // Assume these two items are selected by text input filter

    // // To store unique id
    // let uniqueId = new Set();

    // // Loop the objArr
    // for (let val of objArr) {
    //   uniqueId.add(val.id);

    //   let currentSearchObjMainId = val.mainId;
    //   let currentId = val.id;
    //   // recursion loop of mainID to root object
    //   while (currentSearchObjMainId !== currentId) {
    //     let nextObj = this.state.originData.filter(
    //       (v) => v.id === currentSearchObjMainId
    //     );
    //     //console.log("nextObj", nextObj);
    //     currentSearchObjMainId = nextObj[0].mainId;
    //     currentId = nextObj[0].id;
    //     uniqueId.add(nextObj[0].id);
    //   }
    // }

    // // turn set to array, then compare to original array
    // const uniqueIdArr = Array.from(uniqueId);
    // const finalArr = this.state.originData.filter(
    //   (v) => uniqueIdArr.indexOf(v.id) >= 0
    // );
    // // //console.log(this.state.inputTextValue);
    // if (!this.state.expand) {
    //   if (str.length <= 0) {
    //     this.setState({
    //       data: finalArr,
    //       flagOpenMenu: false,
    //       inputTextValue: str,
    //     });
    //   } else {
    //     this.setState({
    //       data: finalArr,
    //       flagOpenMenu: true,
    //       inputTextValue: str,
    //     });
    //   }
    // } else {
    //   if (str.length <= 0) {
    //     this.setState({
    //       data: finalArr,
    //       flagOpenMenu: true,
    //       inputTextValue: str,
    //     });
    //   } else {
    //     this.setState({
    //       data: finalArr,
    //       flagOpenMenu: true,
    //       inputTextValue: str,
    //     });
    //   }
    // }
  };

  render() {
    const { flagOpenMenu, multiple, expand, data } = this.state;

    ////console.log(12333, this.props);
    // //console.log("data", data); //, JSON.stringify(data) );
    //=====
    console.log("xxx", this.state.data);
    console.log("flagOpenMenu", flagOpenMenu);
    let _classList = [];
    _classList.push("result_part");
    if (flagOpenMenu || expand) {
      ////console.log(123124);
    } else {
      _classList.push("hidden");
    }
    let _classListStr = _classList.join(" ");

    //=====

    return (
      <div className="searchfilter org_search_filter">
        <div className="search_part">
          <input
            type="text"
            placeholder="Search"
            value={this.state.inputTextValue}
            onChange={(e) => this.handleInputText(e.target.value)}
          />
          {/* <input
            type="button"
            className={flagOpenMenu === false ? "" : "close"}
            value={flagOpenMenu === false ? "🔍" : "✖"}
            onClick={(event) => this.handleSearchBtnClick(event)}
          /> */}
        </div>

        {flagOpenMenu && (
          <div className="result_part">
            {data.length > 0 ? (
              this.genHTML(this.getRoot(data), 0, multiple)
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = { handleUserInfo };
export default connect(
  mapStateToProps,
  actionCreator
)(withRouter(OrgSearchFilter));
