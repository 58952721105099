/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ShoppingCartPreOrderBookListDto from './ShoppingCartPreOrderBookListDto';

/**
 * The ShoppingCartSummaryDto model module.
 * @module model/ShoppingCartSummaryDto
 * @version v1.0.0
 */
class ShoppingCartSummaryDto {
    /**
     * Constructs a new <code>ShoppingCartSummaryDto</code>.
     * @alias module:model/ShoppingCartSummaryDto
     */
    constructor() { 
        
        ShoppingCartSummaryDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>ShoppingCartSummaryDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ShoppingCartSummaryDto} obj Optional instance to populate.
     * @return {module:model/ShoppingCartSummaryDto} The populated <code>ShoppingCartSummaryDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ShoppingCartSummaryDto();

            if (data.hasOwnProperty('bookList')) {
                obj['bookList'] = ApiClient.convertToType(data['bookList'], [ShoppingCartPreOrderBookListDto]);
            }
            if (data.hasOwnProperty('customerName')) {
                obj['customerName'] = ApiClient.convertToType(data['customerName'], 'String');
            }
            if (data.hasOwnProperty('lastUpdate')) {
                obj['lastUpdate'] = ApiClient.convertToType(data['lastUpdate'], 'Number');
            }
            if (data.hasOwnProperty('referenceNo')) {
                obj['referenceNo'] = ApiClient.convertToType(data['referenceNo'], 'String');
            }
            if (data.hasOwnProperty('responsibleUserName')) {
                obj['responsibleUserName'] = ApiClient.convertToType(data['responsibleUserName'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('total')) {
                obj['total'] = ApiClient.convertToType(data['total'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/ShoppingCartPreOrderBookListDto>} bookList
 */
ShoppingCartSummaryDto.prototype['bookList'] = undefined;

/**
 * @member {String} customerName
 */
ShoppingCartSummaryDto.prototype['customerName'] = undefined;

/**
 * @member {Number} lastUpdate
 */
ShoppingCartSummaryDto.prototype['lastUpdate'] = undefined;

/**
 * @member {String} referenceNo
 */
ShoppingCartSummaryDto.prototype['referenceNo'] = undefined;

/**
 * @member {String} responsibleUserName
 */
ShoppingCartSummaryDto.prototype['responsibleUserName'] = undefined;

/**
 * @member {module:model/ShoppingCartSummaryDto.StatusEnum} status
 */
ShoppingCartSummaryDto.prototype['status'] = undefined;

/**
 * @member {Number} total
 */
ShoppingCartSummaryDto.prototype['total'] = undefined;





/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
ShoppingCartSummaryDto['StatusEnum'] = {

    /**
     * value: "Confirm"
     * @const
     */
    "Confirm": "Confirm",

    /**
     * value: "Draft"
     * @const
     */
    "Draft": "Draft",

    /**
     * value: "Finish"
     * @const
     */
    "Finish": "Finish",

    /**
     * value: "Published"
     * @const
     */
    "Published": "Published",

    /**
     * value: "Sent"
     * @const
     */
    "Sent": "Sent"
};



export default ShoppingCartSummaryDto;

