/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The BatchMissionBookItemDto model module.
 * @module model/BatchMissionBookItemDto
 * @version v1.0.0
 */
class BatchMissionBookItemDto {
    /**
     * Constructs a new <code>BatchMissionBookItemDto</code>.
     * @alias module:model/BatchMissionBookItemDto
     */
    constructor() { 
        
        BatchMissionBookItemDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BatchMissionBookItemDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BatchMissionBookItemDto} obj Optional instance to populate.
     * @return {module:model/BatchMissionBookItemDto} The populated <code>BatchMissionBookItemDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BatchMissionBookItemDto();

            if (data.hasOwnProperty('bookId')) {
                obj['bookId'] = ApiClient.convertToType(data['bookId'], 'String');
            }
            if (data.hasOwnProperty('bookIsbn')) {
                obj['bookIsbn'] = ApiClient.convertToType(data['bookIsbn'], 'String');
            }
            if (data.hasOwnProperty('bookName')) {
                obj['bookName'] = ApiClient.convertToType(data['bookName'], 'String');
            }
            if (data.hasOwnProperty('coverFileExists')) {
                obj['coverFileExists'] = ApiClient.convertToType(data['coverFileExists'], 'Boolean');
            }
            if (data.hasOwnProperty('ctsId')) {
                obj['ctsId'] = ApiClient.convertToType(data['ctsId'], 'String');
            }
            if (data.hasOwnProperty('multiMediaFileExists')) {
                obj['multiMediaFileExists'] = ApiClient.convertToType(data['multiMediaFileExists'], 'Boolean');
            }
            if (data.hasOwnProperty('previewFileExists')) {
                obj['previewFileExists'] = ApiClient.convertToType(data['previewFileExists'], 'Boolean');
            }
            if (data.hasOwnProperty('prodFileExists')) {
                obj['prodFileExists'] = ApiClient.convertToType(data['prodFileExists'], 'Boolean');
            }
            if (data.hasOwnProperty('thumbFileExists')) {
                obj['thumbFileExists'] = ApiClient.convertToType(data['thumbFileExists'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} bookId
 */
BatchMissionBookItemDto.prototype['bookId'] = undefined;

/**
 * @member {String} bookIsbn
 */
BatchMissionBookItemDto.prototype['bookIsbn'] = undefined;

/**
 * @member {String} bookName
 */
BatchMissionBookItemDto.prototype['bookName'] = undefined;

/**
 * @member {Boolean} coverFileExists
 */
BatchMissionBookItemDto.prototype['coverFileExists'] = undefined;

/**
 * @member {String} ctsId
 */
BatchMissionBookItemDto.prototype['ctsId'] = undefined;

/**
 * @member {Boolean} multiMediaFileExists
 */
BatchMissionBookItemDto.prototype['multiMediaFileExists'] = undefined;

/**
 * @member {Boolean} previewFileExists
 */
BatchMissionBookItemDto.prototype['previewFileExists'] = undefined;

/**
 * @member {Boolean} prodFileExists
 */
BatchMissionBookItemDto.prototype['prodFileExists'] = undefined;

/**
 * @member {Boolean} thumbFileExists
 */
BatchMissionBookItemDto.prototype['thumbFileExists'] = undefined;






export default BatchMissionBookItemDto;

