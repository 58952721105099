/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import RoleDto from '../model/RoleDto';
import RoleWithPermissionPayloadModel from '../model/RoleWithPermissionPayloadModel';
import SearchingQueryResponseModelRoleDto from '../model/SearchingQueryResponseModelRoleDto';

/**
* Role service.
* @module api/RoleApi
* @version v1.0.0
*/
export default class RoleApi {

    /**
    * Constructs a new RoleApi. 
    * @alias module:api/RoleApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addRoleUsingPOST operation.
     * @callback module:api/RoleApi~addRoleUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RoleDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Add Role With Permission
     * @param {String} organizationId organizationId
     * @param {Object} opts Optional parameters
     * @param {module:model/RoleWithPermissionPayloadModel} opts.roleWithPermissionPayloadModel 
     * @param {module:api/RoleApi~addRoleUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RoleDto}
     */
    addRoleUsingPOST(organizationId, opts, callback) {
      opts = opts || {};
      let postBody = opts['roleWithPermissionPayloadModel'];
      // verify the required parameter 'organizationId' is set
      if (organizationId === undefined || organizationId === null) {
        throw new Error("Missing the required parameter 'organizationId' when calling addRoleUsingPOST");
      }

      let pathParams = {
        'organizationId': organizationId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = RoleDto;
      return this.apiClient.callApi(
        '/api/role/organization/{organizationId}/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteRolesUsingDELETE operation.
     * @callback module:api/RoleApi~deleteRolesUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete role
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/RoleApi~deleteRolesUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteRolesUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/role/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the editRoleUsingPATCH operation.
     * @callback module:api/RoleApi~editRoleUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RoleDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Edit Role with Permission
     * @param {String} roleId roleId
     * @param {Object} opts Optional parameters
     * @param {module:model/RoleWithPermissionPayloadModel} opts.roleWithPermissionPayloadModel 
     * @param {module:api/RoleApi~editRoleUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RoleDto}
     */
    editRoleUsingPATCH(roleId, opts, callback) {
      opts = opts || {};
      let postBody = opts['roleWithPermissionPayloadModel'];
      // verify the required parameter 'roleId' is set
      if (roleId === undefined || roleId === null) {
        throw new Error("Missing the required parameter 'roleId' when calling editRoleUsingPATCH");
      }

      let pathParams = {
        'roleId': roleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = RoleDto;
      return this.apiClient.callApi(
        '/api/role/update/{roleId}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getRoleUsingGET operation.
     * @callback module:api/RoleApi~getRoleUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/RoleDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Role with Permission
     * @param {String} roleId roleId
     * @param {module:api/RoleApi~getRoleUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/RoleDto}
     */
    getRoleUsingGET(roleId, callback) {
      let postBody = null;
      // verify the required parameter 'roleId' is set
      if (roleId === undefined || roleId === null) {
        throw new Error("Missing the required parameter 'roleId' when calling getRoleUsingGET");
      }

      let pathParams = {
        'roleId': roleId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = RoleDto;
      return this.apiClient.callApi(
        '/api/role/{roleId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getRolesUsingGET operation.
     * @callback module:api/RoleApi~getRolesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelRoleDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Roles with Permission by Organization Id
     * @param {String} organizationId organizationId
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/RoleApi~getRolesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelRoleDto}
     */
    getRolesUsingGET(organizationId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'organizationId' is set
      if (organizationId === undefined || organizationId === null) {
        throw new Error("Missing the required parameter 'organizationId' when calling getRolesUsingGET");
      }

      let pathParams = {
        'organizationId': organizationId
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelRoleDto;
      return this.apiClient.callApi(
        '/api/role/organization/{organizationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getRolesUsingGET1 operation.
     * @callback module:api/RoleApi~getRolesUsingGET1Callback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelRoleDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Roles with permission
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/RoleApi~getRolesUsingGET1Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelRoleDto}
     */
    getRolesUsingGET1(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelRoleDto;
      return this.apiClient.callApi(
        '/api/role/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
