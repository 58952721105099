/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BookFileItemNoRefDto from './BookFileItemNoRefDto';

/**
 * The BookSelectedFileDto model module.
 * @module model/BookSelectedFileDto
 * @version v1.0.0
 */
class BookSelectedFileDto {
    /**
     * Constructs a new <code>BookSelectedFileDto</code>.
     * @alias module:model/BookSelectedFileDto
     */
    constructor() { 
        
        BookSelectedFileDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BookSelectedFileDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BookSelectedFileDto} obj Optional instance to populate.
     * @return {module:model/BookSelectedFileDto} The populated <code>BookSelectedFileDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BookSelectedFileDto();

            if (data.hasOwnProperty('audioBook')) {
                obj['audioBook'] = ApiClient.convertToType(data['audioBook'], [BookFileItemNoRefDto]);
            }
            if (data.hasOwnProperty('cover')) {
                obj['cover'] = ApiClient.convertToType(data['cover'], [BookFileItemNoRefDto]);
            }
            if (data.hasOwnProperty('epubPreview')) {
                obj['epubPreview'] = ApiClient.convertToType(data['epubPreview'], [BookFileItemNoRefDto]);
            }
            if (data.hasOwnProperty('epubProd')) {
                obj['epubProd'] = ApiClient.convertToType(data['epubProd'], [BookFileItemNoRefDto]);
            }
            if (data.hasOwnProperty('multimedia')) {
                obj['multimedia'] = ApiClient.convertToType(data['multimedia'], [BookFileItemNoRefDto]);
            }
            if (data.hasOwnProperty('thumb')) {
                obj['thumb'] = ApiClient.convertToType(data['thumb'], [BookFileItemNoRefDto]);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/BookFileItemNoRefDto>} audioBook
 */
BookSelectedFileDto.prototype['audioBook'] = undefined;

/**
 * @member {Array.<module:model/BookFileItemNoRefDto>} cover
 */
BookSelectedFileDto.prototype['cover'] = undefined;

/**
 * @member {Array.<module:model/BookFileItemNoRefDto>} epubPreview
 */
BookSelectedFileDto.prototype['epubPreview'] = undefined;

/**
 * @member {Array.<module:model/BookFileItemNoRefDto>} epubProd
 */
BookSelectedFileDto.prototype['epubProd'] = undefined;

/**
 * @member {Array.<module:model/BookFileItemNoRefDto>} multimedia
 */
BookSelectedFileDto.prototype['multimedia'] = undefined;

/**
 * @member {Array.<module:model/BookFileItemNoRefDto>} thumb
 */
BookSelectedFileDto.prototype['thumb'] = undefined;






export default BookSelectedFileDto;

