import { getAccessToken } from "../LocalStorage";

//const VERIFY_FILE_API = "http://localhost:8000/cts_backend/api/file/upload/prepare";
//const UPLOAD_CHUNK_FILE_API = "http://localhost:8000/cts_backend/api/file/upload/uploadChunk";
//const DOWNLOAD_FILE_API = "http://localhost:8000/cts_backend/api/file/"

const UPLOAD_SINGLE_FILE_API = `${process.env.REACT_APP_BACKEND_URL}/api/file/upload-one`;
const VERIFY_FILE_API = `${process.env.REACT_APP_BACKEND_URL}/api/file/upload/prepare`;
const UPLOAD_CHUNK_FILE_API = `${process.env.REACT_APP_BACKEND_URL}/api/file/upload/uploadChunk`;
const DOWNLOAD_FILE_API = `${process.env.REACT_APP_BACKEND_URL}/api/file/`;

function UploadFile({
  method = "POST",
  url,
  data,
  headers = {},
  onProgress = (e) => e,
  requestList,
}) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();

    xhr.upload.onprogress = onProgress;

    xhr.onload = () => {
      if (requestList) {
        const xhrIndex = requestList.findIndex((item) => item === xhr);
        requestList.splice(xhrIndex, 1);
      }
      if (xhr.status === 200) {
        const response = xhr.response;
        resolve(response);
      } else {
        reject({ status: xhr.status, res: xhr.response });
      }
    };

    xhr.onabort = () => {
      reject("Cancel");
    };

    xhr.onerror = (err) => {
      reject(err);
    };

    xhr.open(method, url, true);

    Object.keys(headers).forEach((key) =>
      xhr.setRequestHeader(key, headers[key])
    );

    xhr.setRequestHeader("Authorization", "Bearer " + getAccessToken());

    xhr.send(data);

    requestList?.push(xhr);
  });
}

function ajax({ method = "POST", url, data, headers = {}, responseType = "" }) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();

    xhr.onload = () => {
      if (xhr.status === 200) {
        const response = xhr.response;
        resolve(response);
      } else {
        reject({ status: xhr.status, res: xhr.response });
      }
    };

    xhr.onerror = (err) => {
      reject(err);
    };

    xhr.open(method, url, true);

    Object.keys(headers).forEach((key) =>
      xhr.setRequestHeader(key, headers[key])
    );

    xhr.responseType = responseType !== "" ? responseType : "";

    xhr.setRequestHeader("Authorization", "Bearer " + getAccessToken());

    xhr.send(data);
  });
}

export {
  UploadFile,
  ajax,
  UPLOAD_SINGLE_FILE_API,
  VERIFY_FILE_API,
  UPLOAD_CHUNK_FILE_API,
  DOWNLOAD_FILE_API,
};
