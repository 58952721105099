import axios from "axios";
import { getAccessToken, getClientInfo } from "../utils/LocalStorage";
//import { clearAccessToken } from "../utils/SessionStorage";


const baseURL = process.env.REACT_APP_BACKEND_URL;

export default (history = null) => {

    const axiosInstance = axios.create({
        baseURL: baseURL,
        headers: {          
            
        },
    })

    axiosInstance.interceptors.response.use(
        response =>
            new Promise((resolve, reject) => {
                resolve(response);
            }),
        error => {
            if (!error.response) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }
            if (error.response.status === 403) {
                // clearAccessToken()
                // if (history) {
                //     history.push(LOGIN);
                // } else {
                //     window.location = LOGIN;
                // }
            } else {
                return new Promise((resolve, reject) => {
                    console.log('eeererqaeafaesd', JSON.stringify(error))
                    reject(error);
                });
            }
        }
    );
    return axiosInstance;
};

//export default axiosInstance;