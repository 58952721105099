/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CategoryDto from '../model/CategoryDto';
import CategoryPayloadModel from '../model/CategoryPayloadModel';
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import SearchingQueryResponseModelCategoryDto from '../model/SearchingQueryResponseModelCategoryDto';

/**
* Category service.
* @module api/CategoryApi
* @version v1.0.0
*/
export default class CategoryApi {

    /**
    * Constructs a new CategoryApi. 
    * @alias module:api/CategoryApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addCategoryUsingPOST operation.
     * @callback module:api/CategoryApi~addCategoryUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CategoryDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add Category
     * @param {Object} opts Optional parameters
     * @param {module:model/CategoryPayloadModel} opts.categoryPayloadModel 
     * @param {module:api/CategoryApi~addCategoryUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CategoryDto}
     */
    addCategoryUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['categoryPayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CategoryDto;
      return this.apiClient.callApi(
        '/api/category/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteCategoryUsingDELETE operation.
     * @callback module:api/CategoryApi~deleteCategoryUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete Category
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/CategoryApi~deleteCategoryUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteCategoryUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/category/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCategoriesUsingGET operation.
     * @callback module:api/CategoryApi~getCategoriesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelCategoryDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * search Category
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/CategoryApi~getCategoriesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelCategoryDto}
     */
    getCategoriesUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelCategoryDto;
      return this.apiClient.callApi(
        '/api/category/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getCategoryUsingGET operation.
     * @callback module:api/CategoryApi~getCategoryUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CategoryDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get Category
     * @param {String} id id
     * @param {module:api/CategoryApi~getCategoryUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CategoryDto}
     */
    getCategoryUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getCategoryUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = CategoryDto;
      return this.apiClient.callApi(
        '/api/category/get/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateCategoryUsingPATCH operation.
     * @callback module:api/CategoryApi~updateCategoryUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CategoryDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * update Category
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/CategoryPayloadModel} opts.categoryPayloadModel 
     * @param {module:api/CategoryApi~updateCategoryUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CategoryDto}
     */
    updateCategoryUsingPATCH(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['categoryPayloadModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateCategoryUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CategoryDto;
      return this.apiClient.callApi(
        '/api/category/update/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
