// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewBookView_newBookView_container__t206w {\n    padding: 20px 4vw 0;\n}\n\n.NewBookView_newBookView_container__t206w h4 {\n    margin: 16px 30px 8px ;\n}\n\n.NewBookView_btn__Qoitu{\n    display: flex;\n    justify-content: flex-end;\n    padding: 0 30px 15px;\n}\n\n.NewBookView_backbtn__30QqY{\n    padding: 25px 35px 0;\n}\n\n\n@media screen and (max-width: 840px) {\n    .NewBookView_newBookView_container__t206w {\n        padding: 8px 2vw 0;\n    }\n    \n}", "",{"version":3,"sources":["webpack://./src/layout/book_management/new_book/NewBookView.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;AACxB;;;AAGA;IACI;QACI,kBAAkB;IACtB;;AAEJ","sourcesContent":[".newBookView_container {\n    padding: 20px 4vw 0;\n}\n\n.newBookView_container h4 {\n    margin: 16px 30px 8px ;\n}\n\n.btn{\n    display: flex;\n    justify-content: flex-end;\n    padding: 0 30px 15px;\n}\n\n.backbtn{\n    padding: 25px 35px 0;\n}\n\n\n@media screen and (max-width: 840px) {\n    .newBookView_container {\n        padding: 8px 2vw 0;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"newBookView_container": "NewBookView_newBookView_container__t206w",
	"btn": "NewBookView_btn__Qoitu",
	"backbtn": "NewBookView_backbtn__30QqY"
};
export default ___CSS_LOADER_EXPORT___;
