/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AwardDto from './AwardDto';
import BisacDto from './BisacDto';
import BookDto from './BookDto';
import CategoryDto from './CategoryDto';
import LanguageDto from './LanguageDto';
import LocationDto from './LocationDto';
import OrganizationIdDto from './OrganizationIdDto';
import PlatformDto from './PlatformDto';
import ProductTypeDto from './ProductTypeDto';

/**
 * The BookInfoResponseModel model module.
 * @module model/BookInfoResponseModel
 * @version v1.0.0
 */
class BookInfoResponseModel {
    /**
     * Constructs a new <code>BookInfoResponseModel</code>.
     * @alias module:model/BookInfoResponseModel
     */
    constructor() { 
        
        BookInfoResponseModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BookInfoResponseModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BookInfoResponseModel} obj Optional instance to populate.
     * @return {module:model/BookInfoResponseModel} The populated <code>BookInfoResponseModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BookInfoResponseModel();

            if (data.hasOwnProperty('awards')) {
                obj['awards'] = ApiClient.convertToType(data['awards'], [AwardDto]);
            }
            if (data.hasOwnProperty('bisacs')) {
                obj['bisacs'] = ApiClient.convertToType(data['bisacs'], [BisacDto]);
            }
            if (data.hasOwnProperty('books')) {
                obj['books'] = ApiClient.convertToType(data['books'], [BookDto]);
            }
            if (data.hasOwnProperty('categories')) {
                obj['categories'] = ApiClient.convertToType(data['categories'], [CategoryDto]);
            }
            if (data.hasOwnProperty('languages')) {
                obj['languages'] = ApiClient.convertToType(data['languages'], [LanguageDto]);
            }
            if (data.hasOwnProperty('locations')) {
                obj['locations'] = ApiClient.convertToType(data['locations'], [LocationDto]);
            }
            if (data.hasOwnProperty('organizations')) {
                obj['organizations'] = ApiClient.convertToType(data['organizations'], [OrganizationIdDto]);
            }
            if (data.hasOwnProperty('platforms')) {
                obj['platforms'] = ApiClient.convertToType(data['platforms'], [PlatformDto]);
            }
            if (data.hasOwnProperty('productTypes')) {
                obj['productTypes'] = ApiClient.convertToType(data['productTypes'], [ProductTypeDto]);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/AwardDto>} awards
 */
BookInfoResponseModel.prototype['awards'] = undefined;

/**
 * @member {Array.<module:model/BisacDto>} bisacs
 */
BookInfoResponseModel.prototype['bisacs'] = undefined;

/**
 * @member {Array.<module:model/BookDto>} books
 */
BookInfoResponseModel.prototype['books'] = undefined;

/**
 * @member {Array.<module:model/CategoryDto>} categories
 */
BookInfoResponseModel.prototype['categories'] = undefined;

/**
 * @member {Array.<module:model/LanguageDto>} languages
 */
BookInfoResponseModel.prototype['languages'] = undefined;

/**
 * @member {Array.<module:model/LocationDto>} locations
 */
BookInfoResponseModel.prototype['locations'] = undefined;

/**
 * @member {Array.<module:model/OrganizationIdDto>} organizations
 */
BookInfoResponseModel.prototype['organizations'] = undefined;

/**
 * @member {Array.<module:model/PlatformDto>} platforms
 */
BookInfoResponseModel.prototype['platforms'] = undefined;

/**
 * @member {Array.<module:model/ProductTypeDto>} productTypes
 */
BookInfoResponseModel.prototype['productTypes'] = undefined;






export default BookInfoResponseModel;

