import { useEffect } from "react";
import { connect } from "react-redux";
import LayoutView from "./layout/LayoutView";
import { handleUserInfo } from "./redux/actions";
import { getRouteEntry, setIdleLogout } from "./utils/GlobalFunction";
import {
  initSystemFont,
  initTheme,
} from "./utils/reader/serviceUtils/launchUtil"; //'./utils/reader/serviceUtils/launchUtil';

const App = (props) => {
  useEffect(() => {
    setIdleLogout();
    initTheme();
    initSystemFont();
  }, []);
  return <LayoutView>{getRouteEntry(props.userInfo)}</LayoutView>;
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = { handleUserInfo };
export default connect(mapStateToProps, actionCreator)(App);
