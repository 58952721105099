/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import ProductTypeDto from '../model/ProductTypeDto';
import ProductTypeRequestModel from '../model/ProductTypeRequestModel';
import SearchingQueryResponseModelProductTypeDto from '../model/SearchingQueryResponseModelProductTypeDto';

/**
* ProductType service.
* @module api/ProductTypeApi
* @version v1.0.0
*/
export default class ProductTypeApi {

    /**
    * Constructs a new ProductTypeApi. 
    * @alias module:api/ProductTypeApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addProductTypeUsingPOST operation.
     * @callback module:api/ProductTypeApi~addProductTypeUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProductTypeDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add Product Type
     * @param {Object} opts Optional parameters
     * @param {module:model/ProductTypeRequestModel} opts.productTypeRequestModel 
     * @param {module:api/ProductTypeApi~addProductTypeUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProductTypeDto}
     */
    addProductTypeUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['productTypeRequestModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = ProductTypeDto;
      return this.apiClient.callApi(
        '/api/product-type/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteProductTypeUsingDELETE operation.
     * @callback module:api/ProductTypeApi~deleteProductTypeUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete Product Type
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/ProductTypeApi~deleteProductTypeUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteProductTypeUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/product-type/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getProductTypeUsingGET operation.
     * @callback module:api/ProductTypeApi~getProductTypeUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProductTypeDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get Product Type
     * @param {String} id id
     * @param {module:api/ProductTypeApi~getProductTypeUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProductTypeDto}
     */
    getProductTypeUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getProductTypeUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = ProductTypeDto;
      return this.apiClient.callApi(
        '/api/product-type/get/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getProductTypesUsingGET operation.
     * @callback module:api/ProductTypeApi~getProductTypesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelProductTypeDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * search Product Type
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/ProductTypeApi~getProductTypesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelProductTypeDto}
     */
    getProductTypesUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelProductTypeDto;
      return this.apiClient.callApi(
        '/api/product-type/add', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateProductTypeUsingPATCH operation.
     * @callback module:api/ProductTypeApi~updateProductTypeUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ProductTypeDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * update Product Type
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/ProductTypeRequestModel} opts.productTypeRequestModel 
     * @param {module:api/ProductTypeApi~updateProductTypeUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ProductTypeDto}
     */
    updateProductTypeUsingPATCH(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['productTypeRequestModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateProductTypeUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = ProductTypeDto;
      return this.apiClient.callApi(
        '/api/product-type/update/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
