// import React from "react";
// import Input from "../../components/Input/Input";
// import { STYLETYPE_DEFAULT, STYLETYPE_MAIN, STYLETYPE_SECOND, STYLETYPE_THIRD } from "../../utils/Constant";
// import style from "./TestPage.module.css";

// import SearchFilter from "../../components/SearchFilter/SearchFilter";
import React from "react";
import Button from "../../components/Button/Button";
import SearchFilter from "../../components/SearchFilter/SearchFilter";
import MultiSelectOption from "../../components/SelectOption/MultiSelectOption";
import {
  CODE_BOOK_UNAVAILABLE,
  STYLETYPE_MAIN,
  UNAVAILABLE,
} from "../../utils/Constants";
// #region

// import { genApiConfig } from '../../utils/Fetch-caller'
// import UserApi from '../../fetcher/api/UserApi'
// import RoleApi from '../../fetcher/api/RoleApi';

// import BookCardView from '../../components/Cardview/Book/BookCardView';
// import UserTableCardView from '../../components/Table/TableCardView/User/UserTableCardView'
// import PublishingListView from '../../components/Table/TableListView/Publishing/PublishingListView'
// import { INTERNAL, STYLETYPE_DEFAULT, STYLETYPE_MAIN, STYLETYPE_THIRD, STYLETYPE_FOUR } from '../../utils/Constant';
// import style from './TestPage.module.css'
// import Input from '../../components/Input/Input';
// import { useEffect } from 'react';
// import { useRef } from 'react';

// export default class testPage extends Component {

//     //#region MyButton
//     handleClick = () => {
//         console.log('btnCallBack')
//     }
//     //#endregion

//     state = {
//         users: [],
//         rolesData: []
//     }

//     // componentDidMount() {
//     //     new UserApi(genApiConfig()).getUsersUsingGET({})
//     //   .then(res => {
//     //     if (res.data != null) {
//     //       const result = res.data.result
//     //       let arr = []
//     //       //setUsers(result)
//     //       result.map(userObj => {
//     //         arr.push(Object.assign(userObj, { isChecked: false }))
//     //       })
//     //       this.setState({users : arr})
//     //     }
//     //   })
//     //   .catch(err => {
//     //     console.log('err', err)
//     //   })

//         // new RoleApi(genApiConfig()).getRolesUsingGET({})
//         //     .then(res => {
//         //         if (Object.keys(res.data).length > 0) {
//         //             this.setState({ ...this.setState, rolesData: res.data })
//         //         }
//         //         console.log('res', res)
//         //     })
//         //     .catch(err => {
//         //         console.log('err', err)
//         //     })

//     }

//     render() {
//         return (
//             <>
//                 {/* <Button
//                     callBack={this.handleClick}>
//                     搜尋
//                 </Button>
//                 <Button
//                     showType={STYLETYPE_MAIN}
//                     callBack={this.handleClick}>
//                     搜尋
//                 </Button>
//                 <Button
//                     showType={STYLETYPE_CUSTOM}
//                     customStyle={{ 'border': 'none', 'backgroundColor': 'transparent', 'height': '30px', 'width': '30px' }}
//                     callBack={this.handleClick}
//                 >
//                     <img src={titleIcon} />
//                 </Button>

//                 <br />
//                 <br />
//                 <Input
//                     placeholder={"輸入名稱"}
//                     type={"password"}
//                     showType={STYLETYPE_MAIN}
//                     callBack={(value) => console.log(value)}
//                 />

//                 <br />
//                 <br />
//                 <SelectOption
//                     items={[
//                         { id: '1', name: 'name1' },
//                         { id: '2', name: 'name2' }
//                     ]}
//                     callBack={(id) => console.log(id)}
//                 />

//                 <br />
//                 <br />
//                 <Input type='checkbox' callBack={(value) => console.log(value)} />

//                 <br />
//                 <br />
//                 {/* <TableListCard />

//                 <br />
//                 <br />
//                 <div style={{ 'padding': '8px' }}>
//                     <Table
//                         colNames={[
//                             <Input
//                                 type='checkbox'
//                                 callBack={(value) => console.log(value)}
//                             />,
//                             "員工名稱",
//                             "角色分配"
//                         ]}
//                         list={this.state.users.map(user => {
//                             return (
//                                 <UserTableListView
//                                     content={{
//                                         id: user.id,
//                                         username: user.name,
//                                         description: user.description,
//                                         rolename: user.role.name
//                                     }}
//                                     callBack={(obj) => console.log(obj)}
//                                 />
//                             )
//                         })}
//                     ></Table>
//                 </div>

//                 <br/>
//                 <br/> */}
//                 {/* {
//                           this.state.users && this.state.users.length > 0 ?
//                           this.state.users.filter(organ => { return organ.organization.code === INTERNAL ? organ : null }).map((userObj, index) => {
//                               if(index == 0){
//                                 return (
//                                     <UserTableCardView
//                                       content={userObj}
//                                       checked={userObj.isChecked}
//                                       callBack={(obj) => console.log('adfasf', obj)}
//                                     />
//                                   )
//                               }

//                             })
//                             :
//                             []
//                         } */}
//                 {/* <UserSheet
//                     callBack={(obj) => console.log('userSheetCallBack', obj)}
//                 /> */}

//                 {/* <RoleTableCardView/> */}
//                 {/* <RoleSheet /> */}

//                 {/* <BookCardView   />
//                 <PublishingListView   /> */}
//             </>
//         );
//     }
// }

// export default function TestPage() {
//   const [searchText, setSearchText] = useState("");
//   const [styleType, setStyleType] = useState(STYLE_LIST);
//   const [books, setBooks] = useState([]);
//   const [displayData, setDisplayData] = useState([]);
//   const [currentSelected, setCurrentSelected] = useState(new PlatformDto());

//   const handleSearchClick = (value) => {};
//   const handleListViewClick = (value) => {
//     setStyleType(STYLE_LIST);
//   };

//   const handleCardViewClick = (value) => {
//     setStyleType(STYLE_CARD);
//   };

//   useEffect(() => {
//     getApiData();
//   }, []);

//   useEffect(() => {
//     console.log(books);
//   }, [books]);

//   const getApiData = () => {
//     new BookApi(genApiConfig())
//       .getBookInfoUsingGET()
//       .then((res) => {
//         if (res.data != null) {
//           const result = res.data.books;
//           setBooks(result);
//           setDisplayData(result);
//         }
//       })
//       .catch((err) => {
//         alert(err.body.message);
//         console.log("getBookInfoUsingGET", err.body.message);
//       });
//   };

//   const tableItemAction = (obj) => {
//     // let books = displayData.filter((book) => book.id == obj.id);
//     // console.log(books)
//     switch (obj.action) {
//       case "edit":
//         //   if(books != null){
//         displayData.map((book) => {
//           if (book.id == obj.id) {
//             setCurrentSelected(book);
//           }
//         });
//         console.log("edit");
//         //   }
//         break;
//       case "delete":
//         displayData.map((book) => {
//           if (book.id == obj.id) {
//             setCurrentSelected(book);
//           }
//           console.log("delete",book.id);
//         });
//         break;
//     }
//   };

//   return (
//     <>
//       <div className={style.container}>
//         <BackButton />
//         <h4>書籍管理</h4>
//         <div className={style.header}>
//           <div className={style.row}>
//             <Input
//               type="text"
//               placeholder={"輸入名稱"}
//               value={searchText}
//               styleType={STYLETYPE_MAIN}
//               callBack={(value) => setSearchText(value)}
//             />
//           </div>
//           <div className={style.row}>
//             <Button
//               styleType={STYLETYPE_MAIN}
//               //   callBack={(value) => handleSearchClick(value)}
//             >
//               搜尋
//             </Button>
//           </div>
//           <div className={style.row}></div>

//           <div className={style.rowSpan}>
//             <div className={style.rowSpanItem}>
//               <Button
//                 styleType={STYLETYPE_IMG}
//                 callBack={(value) => handleListViewClick(value)}
//               >
//                 <img src={listViewIcon} />
//               </Button>
//             </div>
//             <div className={style.rowSpanItem}>
//               <Button
//                 styleType={STYLETYPE_IMG}
//                 callBack={(value) => handleCardViewClick(value)}
//               >
//                 <img src={titleViewIcon} />
//               </Button>
//             </div>
//             <div className={style.border_line}></div>

//             <div className={style.rowSpanItem}>
//               <Button
//                 styleType={STYLETYPE_SECOND}
//                 code={CODE_ADD_PLATFORM}
//                 // callBack={(value) => handleAddClick(value)}
//               >
//                 新增平台
//               </Button>
//             </div>
//           </div>
//         </div>
//         {/*out of header*/}
//         {/* {
//           <div style={{ 'padding': '8px' }}>
//             <Table
//               showHeader={styleType === STYLE_LIST}
//               colNames={
//                 [
//                   "書籍",
//                   "檔案上載狀態",
//                   "上架狀態"
//                 ]
//               }
//               list={displayData && displayData.length >0 ? displayData.map((book)=>{
//                   return<Fragment>
//                       <BookManagementTableListView
//                       content={book}
//                       />
//                   </Fragment>
//               }):[]}
//             />
//           </div>
//         } */}
//         {styleType === STYLE_LIST ? (
//           <>
//             <div className={style.tableHeader}>
//               <div className={style.tableHeaderItemCol1}>
//                 <p>書籍</p>
//               </div>
//               <div></div>
//               <div className={style.tableHeaderItemCol2}>
//                 <p>檔案上存格式</p>
//               </div>
//               <div className={style.tableHeaderItem}>
//                 <p>上存狀態</p>
//               </div>
//             </div>
//             {displayData && displayData.length > 0
//               ? displayData.map((book) => {
//                   // console.log(displayData)
//                   return (
//                     <Fragment key={book.id}>
//                       <BookManagementTableView
//                         content={book}
//                         callBack={(obj) =>
//                           tableItemAction({ ...obj, id: book.id })
//                         }
//                       />
//                     </Fragment>
//                   );
//                 })
//               : []}
//           </>
//         ) : (
//           <div className={style.cardViewContainer}>
//             {displayData && displayData.length > 0
//               ? displayData.map((book, idx) => {
//                   return (
//                     <Fragment key={book.id}>
//                       <BookManagementTableView
//                         styles={CARDVIEW}
//                         content={book}
//                         callBack={(obj) =>
//                           tableItemAction({ ...obj, id: book.id })
//                         }
//                       />
//                     </Fragment>
//                   );
//                 })
//               : []}
//           </div>
//         )}
//       </div>
//     </>
//   );
// }

// const TestPage = () => {

//   const [searchText, setSearchText] = useState("")
//   const [itemData, setItemData] = useState([])
//   const [displayData, setdisplayData] = useState([])
//   const [hiddenMenu, setHiddenMenu] = useState(true)

//   const hanldeClick = (value) => {
//     const { action, item } = value
//     console.log('value : ', action, item)
//     switch (action) {
//       case "expand":
//         setHiddenMenu(!hiddenMenu)
//         break;
//       case "select":
//         const newSelectedData = displayData.map(it => {
//           if (it.value === item.value) {
//             it.isSelected = true
//           }
//           return it
//         })
//         setdisplayData([...newSelectedData])

//         break;
//       case "unSelect":
//         const newUnSelectedData = displayData.map(it => {
//           if (it.value === item.value) {
//             it.isSelected = false
//           }
//           return it
//         })
//         setdisplayData([...newUnSelectedData])
//         break;
//     }
//   }

//   const hanldeSearch = (e) => {
//     setHiddenMenu(false)
//     setSearchText(e.target.value)
//   }

//   useEffect(() => {
//     if (searchText === "") {
//       const newSelectdData = displayData.map(it => {
//         it.isSearch = true
//         return it
//       })
//       setdisplayData([...newSelectdData])
//       return
//     }

//     const newSelectdData = displayData.map(it => {
//       if (it.value.includes(searchText)) {
//         it.isSearch = true
//       } else {
//         it.isSearch = false
//       }
//       return it
//     })

//     setdisplayData([...newSelectdData])
//   }, [searchText])

//   useEffect(() => {
//     setdisplayData(itemData)
//   }, [itemData])

//   useEffect(() => {
//     setItemData(
//       [
//         { id: 0, value: "aaa", seq: 8, isSelected: false, isSearch: true },
//         { id: 1, value: "bbb", seq: 6, isSelected: false, isSearch: true },
//         { id: 2, value: "ccc", seq: 1, isSelected: false, isSearch: true },
//         { id: 3, value: "dddc", seq: 1, isSelected: false, isSearch: true },
//       ]
//     )
//   }, [])

//   return (
//     <>
//       <div style={{ padding: '16px' }}>
//         {/* test components */}
//         <div className={style.control} onClick={() => hanldeClick({ action: 'expand' })}>
//           <div className={style.selectTitleContainer}>
//             <div className={style.selectTitleItem}>
//               選擇&nbsp;&nbsp;&#9660;
//             </div>
//           </div>
//           <div className={style.itemContanier}>
//             {/* Loop Selected Item */}
//             {
//               displayData && displayData !== undefined ?
//                 displayData.sort((a, b) => { return a.seq - b.seq }).map(obj => {
//                   return (
//                     obj.isSelected ?
//                       <Fragment key={obj.id}>
//                         <div key={obj.id} className={style.multiValue} onClick={() => {
//                           hanldeClick({ action: 'unSelect', item: { id: obj.id, value: obj.value } })
//                         }}>
//                           <div className={style.itemValue}>{obj.value}</div>
//                           <button className={style.buttonValue}>x</button>
//                         </div>
//                       </Fragment>
//                       :
//                       <></>
//                   )
//                 })
//                 :
//                 <></>
//             }
//             {/* Loop Item */}
//             <div className={style.inputContainer}>
//               <input className={style.inputArea} onChange={(e) => hanldeSearch(e)} />
//             </div>
//           </div>
//         </div>

//         <div hidden={hiddenMenu}>
//           <div className={style.controlMenu}>
//             {/* Loop unSelected Item */}
//             {
//               displayData && displayData.filter(it => it.isSelected).length === displayData.length ?
//                 <Fragment key={-1}>
//                   <div className={style.menuItem}>
//                     沒有項目選擇
//                   </div>
//                 </Fragment>
//                 :

//                 displayData.filter(it => !it.isSearch).length === displayData.length ?
//                   <Fragment key={-1}>
//                     <div className={style.menuItem}>
//                       找不到項目
//                     </div>
//                   </Fragment>
//                   :
//                   displayData.sort((a, b) => { return a.seq - b.seq }).map((obj, index) => {
//                     return (
//                       obj.isSelected ?
//                         <></>
//                         :
//                         obj.isSearch ?
//                           <Fragment key={obj.id}>
//                             <div key={obj.id} onClick={() => hanldeClick({ action: 'select', item: { id: obj.id, value: obj.value } })} className={style.menuItem}>
//                               {obj.value}
//                             </div>
//                             {
//                               index !== displayData.filter(it => it.isSearch).length - 1 &&
//                                 displayData.filter(it => it.isSearch).length !== 1
//                                 ? <div className={style.borderLine}></div> : <></>
//                             }
//                           </Fragment>
//                           :
//                           <></>
//                     )
//                   })

//             }
//             {/* Loop unSelected Item */}
//           </div>
//         </div>
//         {/* test components */}
//       </div>

//       <BTableListView></BTableListView>
//       <BTableCardView />
//       <BatchFiles />
//       <BatchTableListView></BatchTableListView>
//       <VersionList></VersionList>

//     </>
//   )
// }
// #endregion

// #region
// const handleClick = () => {
//   new AuthApi(genApiConfig())
//     .refreshTokenUsingGET()
//     .then((res) => {
//       console.log("res", res);
//       if (res.response.ok) {
//         setAccessToken(res.response.body.access_token);
//         setRefreshToken(res.response.body.refresh_token);
//       }
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

// const getHTML = () => {
//   if (mainElem !== null) {
//     var childern = loopData.map((it, index) => {
//       var childId = `child${index}`;
//       //return React.createElement("div", { id: childId }, it.name);
//       return <div id={childId}>{it.name}</div>;
//     });
//   }
// };
// #endregion

//#region list sub data
/*
let mainElement = React.createElement(
  "div",
  { id: "treeView" },
  React.createElement("input", { id: "a" })
);
*/

// #region searchFilter
let loopData = [
  {
    id: 15,
    name: "SUPPLIER TEST 0",
    code: "SUPPLIER",
    description: "SUPPLIER TEST",
    shortName: "Octopus",
    fullName: "Octopus",
    seq: 14,
    logoUrl: "",
    bannerUrl: "",
    subIds: [16, 17],
    mainId: 15,
    roles: [],
  },
  {
    id: 16,
    name: "SUPPLIER TEST 1",
    code: "SUPPLIER",
    description: "SUPPLIER TEST 1",
    shortName: "Octopus 1",
    fullName: "Octopus 1",
    seq: 15,
    logoUrl: "",
    bannerUrl: "",
    subIds: [18, 19],
    mainId: 15,
    roles: [],
  },
  {
    id: 17,
    name: "SUPPLIER TEST 2",
    code: "SUPPLIER",
    description: "SUPPLIER TEST 2",
    shortName: "Octopus 2",
    fullName: "Octopus 2",
    seq: 16,
    logoUrl: "",
    bannerUrl: "",
    subIds: [20, 21],
    mainId: 15,
    roles: [],
  },
  {
    id: 18,
    name: "SUPPLIER TEST 1.1",
    code: "SUPPLIER",
    description: "SUPPLIER TEST 1.1",
    shortName: "Octopus 1.1",
    fullName: "Octopus 1.1",
    seq: 17,
    logoUrl: "",
    bannerUrl: "",
    subIds: [],
    mainId: 16,
    roles: [],
  },
  {
    id: 19,
    name: "SUPPLIER TEST 1.2",
    code: "SUPPLIER",
    description: "SUPPLIER TEST 1.2",
    shortName: "Octopus 1.2",
    fullName: "Octopus 1.2",
    seq: 18,
    logoUrl: "",
    bannerUrl: "",
    subIds: [],
    mainId: 16,
    roles: [],
  },
  {
    id: 20,
    name: "SUPPLIER TEST 2.1",
    code: "SUPPLIER",
    description: "SUPPLIER TEST 2.1",
    shortName: "Octopus 2.1",
    fullName: "Octopus 2.1",
    seq: 19,
    logoUrl: "",
    bannerUrl: "",
    subIds: [],
    mainId: 17,
    roles: [],
  },
  {
    id: 21,
    name: "SUPPLIER TEST 2.2",
    code: "SUPPLIER",
    description: "SUPPLIER TEST 2.2",
    shortName: "Octopus 2.2",
    fullName: "Octopus 2.2",
    seq: 20,
    logoUrl: "",
    bannerUrl: "",
    subIds: [],
    mainId: 17,
    roles: [],
  },
];

// const TestPage = () => {
//   let isMultiple = false;
//   return <div style={{display: "inline-block", width: "400px", position: "absolute", top: "70px", left: "100px"}}>
//     <SearchFilter data={loopData} multiple={isMultiple} />
//   </div>;
// };

// export default TestPage;
// #endregion searchFilter

const loopData2 = [
  {
    id: "62872b9f7d342d712abcf876",
    value: "電子書",
    seq: 1,
    isSelected: false,
    isSearch: true,
  },
  {
    id: "62872b9f7d342d712abcf877",
    value: "有聲書",
    seq: 2,
    isSelected: false,
    isSearch: true,
  },
  {
    id: "62872b9f7d342d712abcf878",
    value: "視頻",
    seq: 3,
    isSelected: false,
    isSearch: true,
  },
  {
    id: "62872b9f7d342d712abcf879",
    value: "其他",
    seq: 4,
    isSelected: false,
    isSearch: true,
  },
];

const TestPage = () => {
  return (
    <Button
      styleType={STYLETYPE_MAIN}
      code={CODE_BOOK_UNAVAILABLE}
      callBack={(value) => {
        {
        }
      }}
    >
      下架
    </Button>

    // <div style={{ width: "500px", padding: "8px" }}>
    //   <MultiSelectOption
    //     placeholder={"placeHolder"}
    //     multi={true}
    //     info={loopData}
    //   />

    //   <input />
    //   <SearchFilter data={loopData} multiple={true} />
    // </div>
  );
};

export default TestPage;
