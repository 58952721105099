import React from "react";
import { connect } from "react-redux";
import {
  handleChangeDirection,
  handleMenuMode,
  handleNoteKey,
  handleOpenMenu,
  handleSelection,
  handleFetchNotes,
} from "../../../../redux/actions/reader";
import {
  getIframeDoc2,
  getPDFIframeDoc,
} from "../../../../utils/reader/serviceUtils/docUtil";
import StorageUtil from "../../../../utils/reader/serviceUtils/storageUtil";
import PopupNote from "../popupNote/component";
import PopupOption from "../popupOption/component";
import PopupTrans from "../popupTrans/component";
import "./popupMenu.css";

import { getHightlightCoords } from "../../../../utils/reader/fileUtils/pdfUtil";
import RecordLocation from "../../../../utils/reader/readUtils/recordLocation";
import Note from "../../../../model/Note";
import localforage from "localforage";
import { async } from "q";

let colors = ["#fac106", "#ebe702", "#0be603", "#0493e6"];
let lines = ["#FF0000", "#000080", "#0000FF", "#2EFF2E"];

class PopupMenu extends React.Component {
  highlighter;
  highlighter2;
  voices;
  timer;
  key;
  mode;
  showNote;
  isFirstShow;
  rect;
  leftOrRight;
  //Selection HT
  selection;
  constructor(props) {
    //console.log("PopupMenuprops", props);
    super(props);
    this.showNote = false;
    this.isFirstShow = false;
    this.highlighter = null;
    this.highlighter2 = null;
    this.voices = [];
    this.mode = "";
    //Selection HT
    this.selection = this.props.selection;
    this.state = {
      deleteKey: "",
      rect: this.props.rect,
      //leftOrRight:this.props.leftOrRight
    };
    this.leftOrRight = this.props.leftOrRight;
  }

  componentDidMount() {
    //console.log(
    //  "this.props.leftOrRightthis.props.leftOrRight",
    //  this.leftOrRight
    //);
    this.props.rendition.on("rendered", () => {
      new Promise((resolve, reject) => {
        //console.log("getHighlighter 1111");
        this.getHighlighter();

        resolve();
      }).then(() => {
        this.renderHighlighters();
      });
      let doc = getIframeDoc2();
      if (!doc) return;
      doc.addEventListener("mousedown", this.openMenu);
      if (this.props.currentBook.format === "PDF") {
        setTimeout(() => {
          this.renderHighlighters();
        }, 1000);

        doc.addEventListener("mousewheel", () => {
          this.renderHighlighters();
        });
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    // console.log('componentWillReceiveProps 1', nextProps === this.props)
    // console.log('componentWillReceiveProps 2', this.props)
    // console.log('componentWillReceiveProps 3', this.state)
    // console.log('componentWillReceiveProps 4', this)
    // console.log('componentWillReceiveProps 4', this.key)

    //console.log("nextPropsnextPropsnextProps",nextProps.selection.toString());

    if (this.key === undefined) {
      if (this.props.notes.length > 0) {
        this.key = this.props.notes[this.props.notes.length - 1].key;
      }
    }

    if (this.key !== undefined) {
      this.key = this.props.notes[this.props.notes.length - 1].key;
    }
    if (nextProps.rect !== this.props.rect) {
      this.setState(
        {
          rect: nextProps.rect,
        },
        () => {
          this.openMenu();
        }
      );
    }
  }

  //新建高亮
  //TODO
  getHighlighter = () => {
    //console.log("11111111111");
    // 注意点一
    // 为了每次切换章节时都有与当前文档相关联的 pen
    //console.log("this.props.leftOrRight222");
    let doc = getIframeDoc2(0);
    if (!doc) return;
    //console.log("sss");

    this.highlighter = window.rangy.createHighlighter(doc);
    let classes = [
      "color-0",
      "color-1",
      "color-2",
      "color-3",
      "line-0",
      "line-1",
      "line-2",
      "line-3",
    ];

    //console.log('11111111111', this.props.notes)
    classes.forEach((item) => {
      let config = {
        ignoreWhiteSpace: true,
        elementTagName: "span",
        elementProperties: {
          onclick: this.handleNoteClick,
        },
        onElementCreate: (element) => {
          //console.log('onElementCreateonElementCreateonElementCreate', element, this.props.notes)
          ///console.log("this.key", this.key, this.props.notes[this.props.notes.length - 1].key, this, element.dataset.key);
          element.dataset.key =
            this.key || this.props.notes[this.props.notes.length - 1].key;
          //console.log("element.dataset.key", element.dataset.key);
        },
      };
      let applier = window.rangy.createClassApplier(item, config);
      //console.log("applier", applier);
      this.highlighter.addClassApplier(applier);
    });

    let doc2 = getIframeDoc2(1);
    if (!doc2) return;
    //console.log("sss");
    this.highlighter2 = window.rangy.createHighlighter(doc2);

    classes.forEach((item) => {
      let config = {
        ignoreWhiteSpace: true,
        elementTagName: "span",
        elementProperties: {
          onclick: this.handleNoteClick,
        },
        onElementCreate: (element) => {
          element.dataset.key =
            this.key || this.props.notes[this.props.notes.length - 1].key;
        },
      };
      let applier = window.rangy.createClassApplier(item, config);
      this.highlighter2.addClassApplier(applier);
    });
  };

  handleNoteClick = (event) => {
    if (
      window.parent[0].document.childNodes[0].innerHTML.includes(
        event.target.innerHTML
      )
    ) {
      this.leftOrRight = 0;
    } else {
      this.leftOrRight = 1;
    }

    //console.log("handleNoteClick222", this.leftOrRight);
    let doc = getIframeDoc2(this.leftOrRight);
    if (!doc) return;
    this.props.handleMenuMode("note");
    let sel = doc.getSelection();
    //console.log("selselselsel handleNoteClick",sel)
    if (!sel) return;

    let range = sel.getRangeAt(0);
    sel.removeAllRanges();
    sel.addRange(range);

    // re
    let rect = range.getBoundingClientRect();

    let currentFramePosition;
    currentFramePosition = document
      .querySelectorAll("iframe[id^=epubjs-view")
      [this.leftOrRight].getBoundingClientRect();
    ////console.log("currentFramePosition",currentFramePosition);

    // re
    // https://localcoder.org/getboundingclientrect-from-within-iframe
    ////console.log("rect", rect);
    ////console.log("currentFramePosition", currentFramePosition);
    let elemTop = rect.top + currentFramePosition.y;
    let elemBottom = rect.bottom + currentFramePosition.y;
    let elemLeft = rect.left + currentFramePosition.x;
    let elemRight = rect.right + currentFramePosition.x;

    let temp = {
      bottom: elemBottom, // rect.bottom,
      height: rect.height,
      left: elemLeft, // rect.left,
      right: elemRight, //rect.right,
      top: elemTop, // rect.top,
      width: rect.width,
      x: rect.x,
      y: rect.y,
    };
    rect = temp;

    // re end

    /*
    this.setState({ rect: range.getBoundingClientRect() }, () => {
      this.showMenu();
      this.handleClickHighlighter(event.currentTarget.dataset.key);
      event.stopPropagation();
    });
    */
    this.setState({ rect: rect }, () => {
      this.showMenu();
      this.handleClickHighlighter(event.currentTarget.dataset.key);
      event.stopPropagation();
    });
  };
  handlePDFClick = (event) => {
    this.props.handleMenuMode("note");
    //console.log("handlePDFClick");
    this.setState({ rect: event.currentTarget.getBoundingClientRect() }, () => {
      this.showMenu();
      this.handleClickHighlighter(event.currentTarget.getAttribute("key"));
      event.stopPropagation();
    });
  };

  showHighlight = (selected, colorCode, noteKey) => {
    //console.log("showHighlight");

    let iWin = getPDFIframeDoc();
    if (!iWin) return;
    var pageIndex = selected.page;
    if (!iWin.PDFViewerApplication.pdfViewer) return;
    var page = iWin.PDFViewerApplication.pdfViewer.getPageView(pageIndex);
    if (page && page.div && page.textLayer && page.textLayer.textLayerDiv) {
      var pageElement =
        colorCode.indexOf("color") > -1
          ? page.textLayer.textLayerDiv
          : page.div;

      var viewport = page.viewport;
      selected.coords.forEach((rect) => {
        var bounds = viewport.convertToViewportRectangle(rect);
        var el = iWin.document.createElement("div");

        el?.setAttribute(
          "style",
          "position: absolute;" +
            (colorCode.indexOf("color") > -1
              ? "background-color: "
              : "border-bottom: ") +
            (colorCode.indexOf("color") > -1
              ? colors[colorCode.split("-")[1]]
              : `2px solid ${lines[colorCode.split("-")[1]]}`) +
            "; left:" +
            Math.min(bounds[0], bounds[2]) +
            "px; top:" +
            Math.min(bounds[1], bounds[3]) +
            "px;" +
            "width:" +
            Math.abs(bounds[0] - bounds[2]) +
            "px; height:" +
            Math.abs(bounds[1] - bounds[3]) +
            "px; z-index:0;"
        );
        el?.setAttribute("key", noteKey);
        el?.addEventListener("click", (event) => {
          this.handlePDFClick(event);
        });

        pageElement.appendChild(el);
      });
    }
  };
  handleClickHighlighter = (key) => {
    //console.log("handleClickHighlighter key", key);
    let dialog = document.querySelector(".editor-box");
    if (!dialog) return;
    let note = this.props.notes.filter((item) => item.key === key)[0];

    if (note && note.notes) {
      dialog.value = note.notes;
    }
    dialog?.focus();
    this.props.handleNoteKey(key);
  };
  handleShowDelete = (deleteKey) => {
    this.setState({ deleteKey });
  };

  showMenu = () => {
    //console.log("show menu");
    let rect = this.state.rect;
    if (!rect) return;

    ////console.log("rect", rect);
    //console.log("this.props", this.props);

    this.props.handleChangeDirection(false);
    // const rect = this.rect;
    let page = { offsetLeft: 0 };
    if (this.props.currentBook.format !== "PDF") {
      page = document.getElementById("page-area");
      if (!page.clientWidth) return;
    }

    //console.log("page", page);
    let height = 200;
    /*
    let x =
      this.props.currentBook.format === "PDF"
        ? rect.left
        : StorageUtil.getReaderConfig("readerMode") === "single"
          ? (rect.x % this.props.pageWidth) + page.offsetLeft
          : StorageUtil.getReaderConfig("readerMode") === "scroll"
            ? rect.left + page.offsetLeft
            : rect.x % this.props.pageWidth;
    */
    let x =
      this.props.currentBook.format === "PDF"
        ? rect.left
        : StorageUtil.getReaderConfig("readerMode") === "single"
        ? rect.left
        : StorageUtil.getReaderConfig("readerMode") === "scroll"
        ? rect.left
        : rect.left;

    //let y = rect.y % this.props.pageHeight;
    let y = rect.top;

    let popupMenu = document.querySelector(".popup-menu-container");
    //let posX = x + rect.width / 2 - 20;
    let posX = x + rect.width / 2; // - 20;

    //console.log("x", x, "rect.width", rect.width, "posX", posX);

    //防止menu超出图书
    /*
    let rightEdge =
      this.props.menuMode === "note" || this.props.menuMode === "trans"
        ? this.props.pageWidth - 310 + page.offsetLeft * 2
        : this.props.pageWidth - 200 + page.offsetLeft * 2;
    */
    let _pageWidth =
      this.props.currentBook.format === "PDF"
        ? this.props.pageWidth
        : StorageUtil.getReaderConfig("readerMode") === "single"
        ? this.props.pageWidth
        : StorageUtil.getReaderConfig("readerMode") === "scroll"
        ? this.props.pageWidth
        : this.props.pageWidth * 2;
    let rightEdge =
      this.props.menuMode === "note" || this.props.menuMode === "trans"
        ? _pageWidth - 310 + page.offsetLeft * 2
        : _pageWidth - 200 + page.offsetLeft * 2;
    let posY;
    //控制menu方向
    if (y < height) {
      this.props.handleChangeDirection(true);
      //posY = y + 67;
      posY = y + 16 + rect.height; // 16 is arrow
    } else {
      //posY = y - height / 2 - 57;
      posY = y - 188;
    }
    //console.log("rightEdge", rightEdge, "posX", posX);
    posX = posX > rightEdge ? rightEdge : posX;

    //console.log("posX", posX, "posY", posY);

    this.props.handleOpenMenu(true);

    let _margin_left = 158 / -2;
    popupMenu?.setAttribute(
      "style",
      `left:${posX}px;top:${posY}px;margin-left:${_margin_left}px;`
    );
    //this.setState({ rect: null });
  };
  //渲染高亮
  renderHighlighters = () => {
    //console.log("renderHighlighters", this.props.notes);
    let highlighters = this.props.notes;
    if (!highlighters) return;
    let highlightersByChapter = highlighters.filter((item) => {
      return (
        item.chapterIndex === this.props.chapterIndex &&
        item.chapter === this.props.chapter &&
        item.bookKey === this.props.currentBook.key
      );
    });
    //console.log("renderHighlighters", highlightersByChapter);

    this.highlighter && this.highlighter.removeAllHighlights(); // 为了避免下次反序列化失败，必须先清除已有的高亮
    try {
      this.highlighter2 && this.highlighter2.removeAllHighlights();
    } catch (e) {}

    let classes = [
      "color-0",
      "color-1",
      "color-2",
      "color-3",
      "line-0",
      "line-1",
      "line-2",
      "line-3",
    ];

    let pageArea = document.getElementById("page-area");
    if (!pageArea) return;
    //TODO save left or right for render
    let iframe = pageArea.getElementsByTagName("iframe")[0];

    if (!iframe || !iframe.contentWindow) return;

    let iWin; // = iframe.contentWindow || iframe.contentDocument?.defaultView;
    let iWin2;
    //console.log(highlightersByChapter, "highlightersByChapter");
    highlightersByChapter &&
      highlightersByChapter.forEach((item) => {
        this.key = item.key;
        //console.log('highlightersByChapter', item, this.keys, this.props)
        //控制渲染指定图书的指定高亮
        if (item.bookKey === this.props.currentBook.key) {
          try {
            if (this.props.currentBook.format === "PDF") {
              this.showHighlight(
                JSON.parse(item.range),
                classes[item.color],
                item.key
              );
            } else {
              let temp = JSON.parse(item.range);
              temp = [temp];
              if (item.leftOrRight == 0) {
                iframe = pageArea.getElementsByTagName("iframe")[0];
                iWin =
                  iframe.contentWindow || iframe.contentDocument?.defaultView;
                let doc = iframe.contentDocument;

                ////console.log("iframe",iframe);
                ////console.log("temp AAA", temp, doc, this.highlighter);
                window.rangy
                  .getSelection(iframe)
                  .restoreCharacterRanges(doc, temp);
                ////console.log("this.highlighter", this.highlighter);
                this.highlighter.highlightSelection(classes[item.color]);
              } else {
                iframe = pageArea.getElementsByTagName("iframe")[1];
                iWin2 =
                  iframe.contentWindow || iframe.contentDocument?.defaultView;
                let doc = iframe.contentDocument;

                ////console.log("iframe",iframe);
                ////console.log("temp BBB", temp, doc, this.highlighter2);
                window.rangy
                  .getSelection(iframe)
                  .restoreCharacterRanges(doc, temp);
                ////console.log("this.highlighter2", this.highlighter2);
                this.highlighter2.highlightSelection(classes[item.color]);
              }
            }
          } catch (e) {
            console.warn(
              e,
              "Exception has been caught when restore character ranges."
            );
            return;
          }
        }
      });
    /*
    if (!iWin || !iWin.getSelection()) return;
    iWin.getSelection()?.empty(); // 清除文本选取

    if (!iWin2 || !iWin2.getSelection()) return;
    iWin2.getSelection()?.empty(); // 清除文本选取
    */
    if (!iWin || !iWin.getSelection()) {
    } else {
      iWin.getSelection()?.empty(); // 清除文本选取
    }

    if (!iWin2 || !iWin2.getSelection()) {
    } else {
      iWin2.getSelection()?.empty(); // 清除文本选取
    }

    // this.props.isOpenMenu &&
    //   window.rangy.deserializeSelection(serial, null, iWin); // （为了选取文本后不被上一行代码清除掉）恢复原本的文本选取
  };
  //控制弹窗
  openMenu = () => {
    if (this.props.currentBook.format === "PDF") {
      return;
    }

    //console.log("openMenu", this.props);
    this.setState({ deleteKey: "" });
    let pageArea = document.getElementById("page-area");
    if (!pageArea) return;

    //let iframe = pageArea.getElementsByTagName("iframe")[this.leftOrRight];
    let iframe;
    if (this.props.currentBook.format === "PDF") {
      iframe = pageArea.getElementsByTagName("iframe")[0];
    } else {
      iframe = pageArea.getElementsByTagName("iframe")[this.leftOrRight];
    }
    if (!iframe) return;

    let doc = iframe.contentDocument;
    if (!doc) return;
    //console.log("rrr");
    let sel = doc.getSelection();

    let range = window.rangy
      .getSelection(iframe)
      .saveCharacterRanges(doc.body)[0];

    //console.log("range",JSON.stringify(range));
    localStorage.setItem("range", JSON.stringify(range));

    //Selection HT
    //console.log("ssssssseeeeeelllll",this.props.selection);
    // if(sel.toString() != ""){
    //   this.selection = sel;
    // }
    //console.log("[propssssss]",this.selection);
    this.props.handleChangeDirection(false);
    // 如果 popmenu正在被展示，则隐藏
    if (this.props.isOpenMenu) {
      //console.log("opened");
      this.props.handleMenuMode("menu");
      this.props.handleOpenMenu(false);
      this.props.handleNoteKey("");
    }
    ////console.log("selselsel",sel)

    // 使弹出菜单更加灵活可控;
    if (sel.isCollapsed) {
      //console.log("isCollapsed");
      this.props.isOpenMenu && this.props.handleOpenMenu(false);
      this.props.handleMenuMode("menu");
      this.props.handleNoteKey("");
      //This make can't show menu
      //return;
    }
    this.showMenu();
    this.props.handleMenuMode("menu");
  };
  //添加高亮
  handleHighlight() {
    //Selection HT
    console.log("handleHighlight");
    //console.log("handleHighlight()", this.hightlighter);
    let pageArea = document.getElementById("page-area");
    if (!pageArea) return;
    //console.log("handleHighlight1()");

    //let iframe = pageArea.getElementsByTagName("iframe")[this.leftOrRight];
    let iframe;
    if (this.props.currentBook.format === "PDF") {
      iframe = pageArea.getElementsByTagName("iframe")[0];
    } else {
      iframe = pageArea.getElementsByTagName("iframe")[this.leftOrRight];
    }
    if (!iframe) return;

    //console.log("handleHighlight2()");
    let doc = iframe.contentDocument;
    if (!doc) return;
    //console.log("handleHighlight3()");
    let color = this.props.color;
    let classes = [
      "color-0",
      "color-1",
      "color-2",
      "color-3",
      "line-0",
      "line-1",
      "line-2",
      "line-3",
    ];
    if (!this.highlighter) return;
    //console.log("handleHighlight4()");
    //console.log("this.highlight2", this.highlighter);
    this.highlighter.highlightSelection(classes[color]);
    this.props.handleMenuMode("menu");
    this.props.handleOpenMenu(false);

    doc.getSelection()?.empty();
    this.props.handleMenuMode("menu");
    this.highlighter && this.highlighter.removeAllHighlights();
    new Promise(async (resolve) => {
      //console.log("getHighlighter 2222");
      await this.getHighlighter();
      resolve();
    }).then(() => {
      //console.log("getHighlighter 3333");
      this.renderHighlighters();
    });
  }
  render() {
    if (this.props.menuMode === "highlight") {
      // this.getHighlighter();
      //console.log("handleHighlight start");
      this.handleHighlight();
    }
    const PopupProps = {
      pageWidth: this.props.pageWidth,
      pageHeight: this.props.pageHeight,
      chapterIndex: this.props.chapterIndex,
      chapter: this.props.chapter,
      voices: this.props.voices,
      leftOrRight: this.props.leftOrRight,
      selection: this.props.selection,
      readerType: this.props.readerType,
      handleRenderBook: this.props.handleRenderBook,
    };
    ////console.log("PopupProps.selectionPopupProps.selection",PopupProps.selection.toString());
    return (
      <div>
        {/* For Testing */}
        {/*
          this.state.rect && (
            <div className="test_rect" style={{
              "position": "absolute",
              "top": this.state.rect.top,
              "left": this.state.rect.left,
              "width": this.state.rect.width,
              "height": this.state.rect.height,
              "backgroundColor": "black"
            }}></div>
          )
        */}
        {/* For Testing end */}
        <div
          className="popup-menu-container"
          style={this.props.isOpenMenu ? {} : { display: "none" }}
        >
          <div className="popup-menu-box">
            {this.props.menuMode === "menu" ? (
              <PopupOption {...PopupProps} />
            ) : this.props.menuMode === "note" ? (
              <PopupNote {...PopupProps} />
            ) : this.props.menuMode === "trans" ? (
              <PopupTrans {...PopupProps} />
            ) : null}
            {/* style={this.props.isChangeDirection ? { top: "170px" } : {}} */}
            <span
              className="icon-close popup-close"
              onClick={() => {
                this.props.handleOpenMenu(false);
              }}
              style={
                this.props.isChangeDirection
                  ? { top: "-10px" }
                  : { top: "-10px" }
              }
            ></span>
          </div>
          {this.props.isChangeDirection ? (
            <span
              className="icon-popup popup-menu-triangle-up"
              style={
                this.props.menuMode === "highlight" ? { bottom: "110px" } : {}
              }
            ></span>
          ) : (
            <span className="icon-popup popup-menu-triangle-down"></span>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    digests: state.reader.digests,
    notes: state.reader.notes,
    noteKey: state.reader.noteKey,
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    isOpenMenu: state.viewArea.isOpenMenu,
    menuMode: state.viewArea.menuMode,
    color: state.reader.color,
    isChangeDirection: state.viewArea.isChangeDirection,
    //leftOrRight:state.reader.leftOrRight
  };
};
const actionCreator = {
  handleSelection,
  handleOpenMenu,
  handleMenuMode,
  handleChangeDirection,
  handleNoteKey,
  handleFetchNotes,
};
export default connect(mapStateToProps, actionCreator)(PopupMenu);
