import { lazy } from "react";

export const HomeView = lazy(() => import("./home/Home"));
export const LoginView = lazy(() => import("./login/LoginView"));
export const LogoutView = lazy(() => import("./logout/LogoutView"));
export const VerifyView = lazy(() => import("./verify/VerifyView"));
export const ForgetView = lazy(() => import("./forget/ForgetView"));
export const ForgetConfirmView = lazy(() => import("./forget/ForgetConfirmView"));
export const ResetPassword = lazy(() => import("./forget/ResetPassword"));
export const AdministrativeView = lazy(() => import("./administrative/AdministrativeView"));
export const BookManagementView = lazy(() => import("./book_management/BookManagementView"));
export const OrganizationView = lazy(() => import("./organization/OrganizationView"));
export const UserManagementView = lazy(() => import("./user_management/UserManagementView"));
export const UsersView = lazy(async () => await import("./administrative/user_management/users/UsersView"));
export const SystemView = lazy(async () => await import("./administrative/system_management/SystemView"));
export const RoleView = lazy(() => import("./administrative/user_management/roles/RolesView"));
export const BookView = lazy(() => import("./book_management/book/BookView"));
export const NewBookView = lazy(() => import("./book_management/new_book/NewBookView"));
export const PublisherView = lazy(() => import("./administrative/user_management/publisher/PublisherView"));
export const PlatformView = lazy(() => import("./book_management/platform/PlatformView"));
export const LanguageView = lazy(() => import("./book_management/language/LanguageView"));
export const CategoryView = lazy(() => import("./book_management/category/CategoryView"));
export const ProductTypeView = lazy(() => import("./book_management/productType/ProductTypeView"));
export const SupplierView = lazy(() => import("./book_management/supplier/SupplierView"));
export const PublishHouseView = lazy(() => import("./book_management/publishHouse/PublishHouseView"));
export const AwardView = lazy(() => import("./book_management/award/AwardView"));
export const UpdateBookView = lazy(() => import("./book_management/update_book/UpdateBookView"));
export const SupplierManagementView = lazy(() => import("./administrative/supplier_management/SupplierManagementView"));
export const BatchView = lazy(() => import("./book_management/batch/BatchView"));
export const UploadBatchView = lazy(() => import("./book_management/batch/UploadBatchView"));
export const UpdateBatchView = lazy(() => import("./book_management/batch/UpdateBatchView"));
export const BatchDetail = lazy(() => import("./book_management/batch/BatchDetail"));
export const BookOrganizationView = lazy(() => import("./book_management/book/organizations/BookOrganizationView"));
export const PurchaseView = lazy(() => import("./purchase/PurchaseView"));
export const AddPurchaseView = lazy(() => import("./purchase/add_purchase/AddPurchaseView"));
export const UpdatePurchaseView = lazy(() => import("./purchase/update_purchase/UpdatePurchaseView"));
export const PurchaseManagementView = lazy(() => import("./purchase/purchase_management/PurchaseManagementView"));
export const BookCategoryView = lazy(() => import("./purchase/purchase_management/BookCategory/BookCategoryView"));
export const BookListView = lazy(() => import("./purchase/bookList/BookListView"));
export const PurchaseRecordView = lazy(() => import("./purchase/purchase_management/purchaseRecord/PurchaseRecordView"));
export const PurchaseDetailView = lazy(() => import("./purchase/purchase_management/purchaseRecord/purchaseDetails/PurchaseDetailView"));
export const PurchaseListView = lazy(() => import("./purchase/purchase_management/purchaseList/PurchaseListView"));
export const PurchaseListDetails = lazy(() => import("./purchase/purchase_management/purchaseList/purchaseListDetails/PurchaseListDetails"));
export const EmailManagementView = lazy(() => import("./administrative/email_management/EmailManagementView"));

export const PurchaseClientView = lazy(() => import("./purchase/purchase_management/purchaseRecord/Client/PurchaseClientView"));
export const PurchaseClientBookListView = lazy(() => import("./purchase/purchase_management/purchaseRecord/Client/PurchaseClientBookListView"));
export const PurchaseClientBookDetailsView = lazy(() => import("./purchase/purchase_management/purchaseRecord/Client/PurchaseClientBookDetailsView"));
export const SelingBookList = lazy(() => import("./purchase/purchase_management/purchaseManagement/SelingBookList"));

export const LibraryUsersView = lazy(() => import("./administrative/system_management/library_users/LibraryUsersView"));

export const LibraryOrgView = lazy(() => {
    import("./administrative/system_management/library_organization/LibraryOrgView");
});

// export const ChangePW = lazy(() => {
//   import("./administrative/user_management/change_password/ChangePW");
// });
