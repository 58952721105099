/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import UserOrganizationPayloadItemModel from './UserOrganizationPayloadItemModel';

/**
 * The AddUserPayloadModel model module.
 * @module model/AddUserPayloadModel
 * @version v1.0.0
 */
class AddUserPayloadModel {
    /**
     * Constructs a new <code>AddUserPayloadModel</code>.
     * @alias module:model/AddUserPayloadModel
     * @param email {String} user email (required)
     * @param name {String} user name (required)
     * @param password {String} user password (required)
     */
    constructor(email, name, password) { 
        
        AddUserPayloadModel.initialize(this, email, name, password);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, email, name, password) { 
        obj['email'] = email;
        obj['name'] = name;
        obj['password'] = password;
    }

    /**
     * Constructs a <code>AddUserPayloadModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AddUserPayloadModel} obj Optional instance to populate.
     * @return {module:model/AddUserPayloadModel} The populated <code>AddUserPayloadModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AddUserPayloadModel();

            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('mapping')) {
                obj['mapping'] = ApiClient.convertToType(data['mapping'], [UserOrganizationPayloadItemModel]);
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
        }
        return obj;
    }


}

/**
 * user active status
 * @member {Boolean} active
 */
AddUserPayloadModel.prototype['active'] = undefined;

/**
 * user description
 * @member {String} description
 */
AddUserPayloadModel.prototype['description'] = undefined;

/**
 * user email (required)
 * @member {String} email
 */
AddUserPayloadModel.prototype['email'] = undefined;

/**
 * mapping
 * @member {Array.<module:model/UserOrganizationPayloadItemModel>} mapping
 */
AddUserPayloadModel.prototype['mapping'] = undefined;

/**
 * user name (required)
 * @member {String} name
 */
AddUserPayloadModel.prototype['name'] = undefined;

/**
 * user password (required)
 * @member {String} password
 */
AddUserPayloadModel.prototype['password'] = undefined;






export default AddUserPayloadModel;

