/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from './ApiClient';
import AddBookReaderResponsePayload from './model/AddBookReaderResponsePayload';
import AddPurchaseMissionPayloadModel from './model/AddPurchaseMissionPayloadModel';
import AddUserPayloadModel from './model/AddUserPayloadModel';
import AuthResponseModel from './model/AuthResponseModel';
import AwardDto from './model/AwardDto';
import AwardPayloadModel from './model/AwardPayloadModel';
import BatchMissionBookItemDto from './model/BatchMissionBookItemDto';
import BatchMissionDto from './model/BatchMissionDto';
import BatchMissionWithBookDto from './model/BatchMissionWithBookDto';
import BisacDto from './model/BisacDto';
import BisacMainDto from './model/BisacMainDto';
import BisacPayloadModel from './model/BisacPayloadModel';
import BookBaseDto from './model/BookBaseDto';
import BookBaseSimpleDto from './model/BookBaseSimpleDto';
import BookDto from './model/BookDto';
import BookFileDto from './model/BookFileDto';
import BookFileItemDto from './model/BookFileItemDto';
import BookFileItemNoRefDto from './model/BookFileItemNoRefDto';
import BookFilePayloadItemModel from './model/BookFilePayloadItemModel';
import BookFilePayloadModel from './model/BookFilePayloadModel';
import BookFilterSearchingQueryModel from './model/BookFilterSearchingQueryModel';
import BookInfoByOidResponseModel from './model/BookInfoByOidResponseModel';
import BookInfoResponseModel from './model/BookInfoResponseModel';
import BookInfoSearchingQueryModel from './model/BookInfoSearchingQueryModel';
import BookListDto from './model/BookListDto';
import BookListIsCheckedDto from './model/BookListIsCheckedDto';
import BookListIsCheckedWithPaginationDto from './model/BookListIsCheckedWithPaginationDto';
import BookListPayloadModel from './model/BookListPayloadModel';
import BookPayloadModel from './model/BookPayloadModel';
import BookSearchingQueryModel from './model/BookSearchingQueryModel';
import BookSelectedFileDto from './model/BookSelectedFileDto';
import BookVersionListItemResponseModel from './model/BookVersionListItemResponseModel';
import BookVersionListResponseModel from './model/BookVersionListResponseModel';
import CategoryBookItemResponseModel from './model/CategoryBookItemResponseModel';
import CategoryDto from './model/CategoryDto';
import CategoryPayloadModel from './model/CategoryPayloadModel';
import CommonDeletePayloadModel from './model/CommonDeletePayloadModel';
import CommonResponseModel from './model/CommonResponseModel';
import CustomerIsCheckedDto from './model/CustomerIsCheckedDto';
import CustomerShoppingCartBookListBookCartDto from './model/CustomerShoppingCartBookListBookCartDto';
import CustomerShoppingCartBookListBookDto from './model/CustomerShoppingCartBookListBookDto';
import CustomerShoppingCartBookListDto from './model/CustomerShoppingCartBookListDto';
import CustomerShoppingCartBookListNoBookDto from './model/CustomerShoppingCartBookListNoBookDto';
import CustomerShoppingCartDto from './model/CustomerShoppingCartDto';
import CustomerShoppingCartNoBookDto from './model/CustomerShoppingCartNoBookDto';
import CustomerShoppingCartPurchaseMissionDto from './model/CustomerShoppingCartPurchaseMissionDto';
import DoubleRangeModel from './model/DoubleRangeModel';
import EditBatchMissionPayloadModel from './model/EditBatchMissionPayloadModel';
import EditPurchaseMissionPayloadModel from './model/EditPurchaseMissionPayloadModel';
import FileDto from './model/FileDto';
import FilePrepareResponseModel from './model/FilePrepareResponseModel';
import GetSystemSettingsResponseModel from './model/GetSystemSettingsResponseModel';
import IdLongModel from './model/IdLongModel';
import IdStringModel from './model/IdStringModel';
import IntegerRangeModel from './model/IntegerRangeModel';
import LanguageDto from './model/LanguageDto';
import LanguagePayloadModel from './model/LanguagePayloadModel';
import LocationDto from './model/LocationDto';
import LocationMainDto from './model/LocationMainDto';
import LocationPayloadModel from './model/LocationPayloadModel';
import LongRangeModel from './model/LongRangeModel';
import MongoFileChunk from './model/MongoFileChunk';
import NewRoleOrgWithRoleAndUserPayloadModel from './model/NewRoleOrgWithRoleAndUserPayloadModel';
import OrgWithRoleAndUserPayloadModel from './model/OrgWithRoleAndUserPayloadModel';
import OrgWithRolePayloadModel from './model/OrgWithRolePayloadModel';
import OrganizationCategoryBookItemResponseModel from './model/OrganizationCategoryBookItemResponseModel';
import OrganizationDtoAndRoleObjectDto from './model/OrganizationDtoAndRoleObjectDto';
import OrganizationIdBaseDto from './model/OrganizationIdBaseDto';
import OrganizationIdDto from './model/OrganizationIdDto';
import OrganizationObjectBaseDto from './model/OrganizationObjectBaseDto';
import OrganizationObjectDto from './model/OrganizationObjectDto';
import OrganizationObjectMainDto from './model/OrganizationObjectMainDto';
import OrganizationObjectSubDto from './model/OrganizationObjectSubDto';
import PaginationModel from './model/PaginationModel';
import PatchUserPasswordPayloadModel from './model/PatchUserPasswordPayloadModel';
import PatchUserPayloadModel from './model/PatchUserPayloadModel';
import PermissionDetailsDto from './model/PermissionDetailsDto';
import PermissionDto from './model/PermissionDto';
import PlatformDto from './model/PlatformDto';
import ProductTypeDto from './model/ProductTypeDto';
import ProductTypeRequestModel from './model/ProductTypeRequestModel';
import PublishHouseDto from './model/PublishHouseDto';
import PublishHouseMainDto from './model/PublishHouseMainDto';
import PublishHouseRequestModel from './model/PublishHouseRequestModel';
import PurchaseMissionClonePayloadModel from './model/PurchaseMissionClonePayloadModel';
import PurchaseMissionDto from './model/PurchaseMissionDto';
import PurchaseMissionInfoList from './model/PurchaseMissionInfoList';
import PurchaseMissionMarkUpRecordDto from './model/PurchaseMissionMarkUpRecordDto';
import Resource from './model/Resource';
import RoleDto from './model/RoleDto';
import RoleObjectDto from './model/RoleObjectDto';
import RoleWithPermissionPayloadModel from './model/RoleWithPermissionPayloadModel';
import SearchingQueryResponseMapModelstringListPermissionDetailsDto from './model/SearchingQueryResponseMapModelstringListPermissionDetailsDto';
import SearchingQueryResponseModelAwardDto from './model/SearchingQueryResponseModelAwardDto';
import SearchingQueryResponseModelBatchMissionDto from './model/SearchingQueryResponseModelBatchMissionDto';
import SearchingQueryResponseModelBisacDto from './model/SearchingQueryResponseModelBisacDto';
import SearchingQueryResponseModelBookBaseSimpleDto from './model/SearchingQueryResponseModelBookBaseSimpleDto';
import SearchingQueryResponseModelBookDto from './model/SearchingQueryResponseModelBookDto';
import SearchingQueryResponseModelBookListDto from './model/SearchingQueryResponseModelBookListDto';
import SearchingQueryResponseModelCategoryDto from './model/SearchingQueryResponseModelCategoryDto';
import SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto from './model/SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto';
import SearchingQueryResponseModelLanguageDto from './model/SearchingQueryResponseModelLanguageDto';
import SearchingQueryResponseModelLocationDto from './model/SearchingQueryResponseModelLocationDto';
import SearchingQueryResponseModelOrganizationIdDto from './model/SearchingQueryResponseModelOrganizationIdDto';
import SearchingQueryResponseModelPlatformDto from './model/SearchingQueryResponseModelPlatformDto';
import SearchingQueryResponseModelProductTypeDto from './model/SearchingQueryResponseModelProductTypeDto';
import SearchingQueryResponseModelPublishHouseDto from './model/SearchingQueryResponseModelPublishHouseDto';
import SearchingQueryResponseModelPurchaseMissionDto from './model/SearchingQueryResponseModelPurchaseMissionDto';
import SearchingQueryResponseModelRoleDto from './model/SearchingQueryResponseModelRoleDto';
import SearchingQueryResponseModelSupplierDto from './model/SearchingQueryResponseModelSupplierDto';
import SearchingQueryResponseModelUserDto from './model/SearchingQueryResponseModelUserDto';
import SearchingQueryResponseModelWhiteListIpDto from './model/SearchingQueryResponseModelWhiteListIpDto';
import SetShoppingCartBookModel from './model/SetShoppingCartBookModel';
import SetUserActivePayloadModel from './model/SetUserActivePayloadModel';
import SetUserDeletePayloadModel from './model/SetUserDeletePayloadModel';
import ShoppingCartPreOrderBookListBookItemDto from './model/ShoppingCartPreOrderBookListBookItemDto';
import ShoppingCartPreOrderBookListDto from './model/ShoppingCartPreOrderBookListDto';
import ShoppingCartSummaryDto from './model/ShoppingCartSummaryDto';
import SupplierDto from './model/SupplierDto';
import SupplierMainDto from './model/SupplierMainDto';
import SupplierRequestModel from './model/SupplierRequestModel';
import SystemSettingCronJobModel from './model/SystemSettingCronJobModel';
import SystemSettingDoubleModel from './model/SystemSettingDoubleModel';
import SystemSettingDto from './model/SystemSettingDto';
import SystemSettingIntegerModel from './model/SystemSettingIntegerModel';
import TemplateBookDto from './model/TemplateBookDto';
import UpdateUserListOrgAndRolePayloadModel from './model/UpdateUserListOrgAndRolePayloadModel';
import UserDto from './model/UserDto';
import UserOrganizationPayloadItemModel from './model/UserOrganizationPayloadItemModel';
import WhiteListIpDto from './model/WhiteListIpDto';
import AuthApi from './api/AuthApi';
import AwardApi from './api/AwardApi';
import BisacApi from './api/BisacApi';
import BookApi from './api/BookApi';
import BookBatchMissionApi from './api/BookBatchMissionApi';
import BookListApi from './api/BookListApi';
import CategoryApi from './api/CategoryApi';
import CustomerShoppingCartApi from './api/CustomerShoppingCartApi';
import FileApi from './api/FileApi';
import LanguageApi from './api/LanguageApi';
import LocationApi from './api/LocationApi';
import OrganizationApi from './api/OrganizationApi';
import PermissionApi from './api/PermissionApi';
import PlatformApi from './api/PlatformApi';
import ProductTypeApi from './api/ProductTypeApi';
import PublishHouseApi from './api/PublishHouseApi';
import PurchaseMissionApi from './api/PurchaseMissionApi';
import RoleApi from './api/RoleApi';
import SupplierApi from './api/SupplierApi';
import SystemSettingApi from './api/SystemSettingApi';
import UserApi from './api/UserApi';
import WhiteListIpApi from './api/WhiteListIpApi';


/**
* JS API client generated by OpenAPI Generator.<br>
* The <code>index</code> module provides access to constructors for all the classes which comprise the public API.
* <p>
* An AMD (recommended!) or CommonJS application will generally do something equivalent to the following:
* <pre>
* var CtsBackend = require('index'); // See note below*.
* var xxxSvc = new CtsBackend.XxxApi(); // Allocate the API class we're going to use.
* var yyyModel = new CtsBackend.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* <em>*NOTE: For a top-level AMD script, use require(['index'], function(){...})
* and put the application logic within the callback function.</em>
* </p>
* <p>
* A non-AMD browser application (discouraged) might do something like this:
* <pre>
* var xxxSvc = new CtsBackend.XxxApi(); // Allocate the API class we're going to use.
* var yyy = new CtsBackend.Yyy(); // Construct a model instance.
* yyyModel.someProperty = 'someValue';
* ...
* var zzz = xxxSvc.doSomething(yyyModel); // Invoke the service.
* ...
* </pre>
* </p>
* @module index
* @version v1.0.0
*/
export {
    /**
     * The ApiClient constructor.
     * @property {module:ApiClient}
     */
    ApiClient,

    /**
     * The AddBookReaderResponsePayload model constructor.
     * @property {module:model/AddBookReaderResponsePayload}
     */
    AddBookReaderResponsePayload,

    /**
     * The AddPurchaseMissionPayloadModel model constructor.
     * @property {module:model/AddPurchaseMissionPayloadModel}
     */
    AddPurchaseMissionPayloadModel,

    /**
     * The AddUserPayloadModel model constructor.
     * @property {module:model/AddUserPayloadModel}
     */
    AddUserPayloadModel,

    /**
     * The AuthResponseModel model constructor.
     * @property {module:model/AuthResponseModel}
     */
    AuthResponseModel,

    /**
     * The AwardDto model constructor.
     * @property {module:model/AwardDto}
     */
    AwardDto,

    /**
     * The AwardPayloadModel model constructor.
     * @property {module:model/AwardPayloadModel}
     */
    AwardPayloadModel,

    /**
     * The BatchMissionBookItemDto model constructor.
     * @property {module:model/BatchMissionBookItemDto}
     */
    BatchMissionBookItemDto,

    /**
     * The BatchMissionDto model constructor.
     * @property {module:model/BatchMissionDto}
     */
    BatchMissionDto,

    /**
     * The BatchMissionWithBookDto model constructor.
     * @property {module:model/BatchMissionWithBookDto}
     */
    BatchMissionWithBookDto,

    /**
     * The BisacDto model constructor.
     * @property {module:model/BisacDto}
     */
    BisacDto,

    /**
     * The BisacMainDto model constructor.
     * @property {module:model/BisacMainDto}
     */
    BisacMainDto,

    /**
     * The BisacPayloadModel model constructor.
     * @property {module:model/BisacPayloadModel}
     */
    BisacPayloadModel,

    /**
     * The BookBaseDto model constructor.
     * @property {module:model/BookBaseDto}
     */
    BookBaseDto,

    /**
     * The BookBaseSimpleDto model constructor.
     * @property {module:model/BookBaseSimpleDto}
     */
    BookBaseSimpleDto,

    /**
     * The BookDto model constructor.
     * @property {module:model/BookDto}
     */
    BookDto,

    /**
     * The BookFileDto model constructor.
     * @property {module:model/BookFileDto}
     */
    BookFileDto,

    /**
     * The BookFileItemDto model constructor.
     * @property {module:model/BookFileItemDto}
     */
    BookFileItemDto,

    /**
     * The BookFileItemNoRefDto model constructor.
     * @property {module:model/BookFileItemNoRefDto}
     */
    BookFileItemNoRefDto,

    /**
     * The BookFilePayloadItemModel model constructor.
     * @property {module:model/BookFilePayloadItemModel}
     */
    BookFilePayloadItemModel,

    /**
     * The BookFilePayloadModel model constructor.
     * @property {module:model/BookFilePayloadModel}
     */
    BookFilePayloadModel,

    /**
     * The BookFilterSearchingQueryModel model constructor.
     * @property {module:model/BookFilterSearchingQueryModel}
     */
    BookFilterSearchingQueryModel,

    /**
     * The BookInfoByOidResponseModel model constructor.
     * @property {module:model/BookInfoByOidResponseModel}
     */
    BookInfoByOidResponseModel,

    /**
     * The BookInfoResponseModel model constructor.
     * @property {module:model/BookInfoResponseModel}
     */
    BookInfoResponseModel,

    /**
     * The BookInfoSearchingQueryModel model constructor.
     * @property {module:model/BookInfoSearchingQueryModel}
     */
    BookInfoSearchingQueryModel,

    /**
     * The BookListDto model constructor.
     * @property {module:model/BookListDto}
     */
    BookListDto,

    /**
     * The BookListIsCheckedDto model constructor.
     * @property {module:model/BookListIsCheckedDto}
     */
    BookListIsCheckedDto,

    /**
     * The BookListIsCheckedWithPaginationDto model constructor.
     * @property {module:model/BookListIsCheckedWithPaginationDto}
     */
    BookListIsCheckedWithPaginationDto,

    /**
     * The BookListPayloadModel model constructor.
     * @property {module:model/BookListPayloadModel}
     */
    BookListPayloadModel,

    /**
     * The BookPayloadModel model constructor.
     * @property {module:model/BookPayloadModel}
     */
    BookPayloadModel,

    /**
     * The BookSearchingQueryModel model constructor.
     * @property {module:model/BookSearchingQueryModel}
     */
    BookSearchingQueryModel,

    /**
     * The BookSelectedFileDto model constructor.
     * @property {module:model/BookSelectedFileDto}
     */
    BookSelectedFileDto,

    /**
     * The BookVersionListItemResponseModel model constructor.
     * @property {module:model/BookVersionListItemResponseModel}
     */
    BookVersionListItemResponseModel,

    /**
     * The BookVersionListResponseModel model constructor.
     * @property {module:model/BookVersionListResponseModel}
     */
    BookVersionListResponseModel,

    /**
     * The CategoryBookItemResponseModel model constructor.
     * @property {module:model/CategoryBookItemResponseModel}
     */
    CategoryBookItemResponseModel,

    /**
     * The CategoryDto model constructor.
     * @property {module:model/CategoryDto}
     */
    CategoryDto,

    /**
     * The CategoryPayloadModel model constructor.
     * @property {module:model/CategoryPayloadModel}
     */
    CategoryPayloadModel,

    /**
     * The CommonDeletePayloadModel model constructor.
     * @property {module:model/CommonDeletePayloadModel}
     */
    CommonDeletePayloadModel,

    /**
     * The CommonResponseModel model constructor.
     * @property {module:model/CommonResponseModel}
     */
    CommonResponseModel,

    /**
     * The CustomerIsCheckedDto model constructor.
     * @property {module:model/CustomerIsCheckedDto}
     */
    CustomerIsCheckedDto,

    /**
     * The CustomerShoppingCartBookListBookCartDto model constructor.
     * @property {module:model/CustomerShoppingCartBookListBookCartDto}
     */
    CustomerShoppingCartBookListBookCartDto,

    /**
     * The CustomerShoppingCartBookListBookDto model constructor.
     * @property {module:model/CustomerShoppingCartBookListBookDto}
     */
    CustomerShoppingCartBookListBookDto,

    /**
     * The CustomerShoppingCartBookListDto model constructor.
     * @property {module:model/CustomerShoppingCartBookListDto}
     */
    CustomerShoppingCartBookListDto,

    /**
     * The CustomerShoppingCartBookListNoBookDto model constructor.
     * @property {module:model/CustomerShoppingCartBookListNoBookDto}
     */
    CustomerShoppingCartBookListNoBookDto,

    /**
     * The CustomerShoppingCartDto model constructor.
     * @property {module:model/CustomerShoppingCartDto}
     */
    CustomerShoppingCartDto,

    /**
     * The CustomerShoppingCartNoBookDto model constructor.
     * @property {module:model/CustomerShoppingCartNoBookDto}
     */
    CustomerShoppingCartNoBookDto,

    /**
     * The CustomerShoppingCartPurchaseMissionDto model constructor.
     * @property {module:model/CustomerShoppingCartPurchaseMissionDto}
     */
    CustomerShoppingCartPurchaseMissionDto,

    /**
     * The DoubleRangeModel model constructor.
     * @property {module:model/DoubleRangeModel}
     */
    DoubleRangeModel,

    /**
     * The EditBatchMissionPayloadModel model constructor.
     * @property {module:model/EditBatchMissionPayloadModel}
     */
    EditBatchMissionPayloadModel,

    /**
     * The EditPurchaseMissionPayloadModel model constructor.
     * @property {module:model/EditPurchaseMissionPayloadModel}
     */
    EditPurchaseMissionPayloadModel,

    /**
     * The FileDto model constructor.
     * @property {module:model/FileDto}
     */
    FileDto,

    /**
     * The FilePrepareResponseModel model constructor.
     * @property {module:model/FilePrepareResponseModel}
     */
    FilePrepareResponseModel,

    /**
     * The GetSystemSettingsResponseModel model constructor.
     * @property {module:model/GetSystemSettingsResponseModel}
     */
    GetSystemSettingsResponseModel,

    /**
     * The IdLongModel model constructor.
     * @property {module:model/IdLongModel}
     */
    IdLongModel,

    /**
     * The IdStringModel model constructor.
     * @property {module:model/IdStringModel}
     */
    IdStringModel,

    /**
     * The IntegerRangeModel model constructor.
     * @property {module:model/IntegerRangeModel}
     */
    IntegerRangeModel,

    /**
     * The LanguageDto model constructor.
     * @property {module:model/LanguageDto}
     */
    LanguageDto,

    /**
     * The LanguagePayloadModel model constructor.
     * @property {module:model/LanguagePayloadModel}
     */
    LanguagePayloadModel,

    /**
     * The LocationDto model constructor.
     * @property {module:model/LocationDto}
     */
    LocationDto,

    /**
     * The LocationMainDto model constructor.
     * @property {module:model/LocationMainDto}
     */
    LocationMainDto,

    /**
     * The LocationPayloadModel model constructor.
     * @property {module:model/LocationPayloadModel}
     */
    LocationPayloadModel,

    /**
     * The LongRangeModel model constructor.
     * @property {module:model/LongRangeModel}
     */
    LongRangeModel,

    /**
     * The MongoFileChunk model constructor.
     * @property {module:model/MongoFileChunk}
     */
    MongoFileChunk,

    /**
     * The NewRoleOrgWithRoleAndUserPayloadModel model constructor.
     * @property {module:model/NewRoleOrgWithRoleAndUserPayloadModel}
     */
    NewRoleOrgWithRoleAndUserPayloadModel,

    /**
     * The OrgWithRoleAndUserPayloadModel model constructor.
     * @property {module:model/OrgWithRoleAndUserPayloadModel}
     */
    OrgWithRoleAndUserPayloadModel,

    /**
     * The OrgWithRolePayloadModel model constructor.
     * @property {module:model/OrgWithRolePayloadModel}
     */
    OrgWithRolePayloadModel,

    /**
     * The OrganizationCategoryBookItemResponseModel model constructor.
     * @property {module:model/OrganizationCategoryBookItemResponseModel}
     */
    OrganizationCategoryBookItemResponseModel,

    /**
     * The OrganizationDtoAndRoleObjectDto model constructor.
     * @property {module:model/OrganizationDtoAndRoleObjectDto}
     */
    OrganizationDtoAndRoleObjectDto,

    /**
     * The OrganizationIdBaseDto model constructor.
     * @property {module:model/OrganizationIdBaseDto}
     */
    OrganizationIdBaseDto,

    /**
     * The OrganizationIdDto model constructor.
     * @property {module:model/OrganizationIdDto}
     */
    OrganizationIdDto,

    /**
     * The OrganizationObjectBaseDto model constructor.
     * @property {module:model/OrganizationObjectBaseDto}
     */
    OrganizationObjectBaseDto,

    /**
     * The OrganizationObjectDto model constructor.
     * @property {module:model/OrganizationObjectDto}
     */
    OrganizationObjectDto,

    /**
     * The OrganizationObjectMainDto model constructor.
     * @property {module:model/OrganizationObjectMainDto}
     */
    OrganizationObjectMainDto,

    /**
     * The OrganizationObjectSubDto model constructor.
     * @property {module:model/OrganizationObjectSubDto}
     */
    OrganizationObjectSubDto,

    /**
     * The PaginationModel model constructor.
     * @property {module:model/PaginationModel}
     */
    PaginationModel,

    /**
     * The PatchUserPasswordPayloadModel model constructor.
     * @property {module:model/PatchUserPasswordPayloadModel}
     */
    PatchUserPasswordPayloadModel,

    /**
     * The PatchUserPayloadModel model constructor.
     * @property {module:model/PatchUserPayloadModel}
     */
    PatchUserPayloadModel,

    /**
     * The PermissionDetailsDto model constructor.
     * @property {module:model/PermissionDetailsDto}
     */
    PermissionDetailsDto,

    /**
     * The PermissionDto model constructor.
     * @property {module:model/PermissionDto}
     */
    PermissionDto,

    /**
     * The PlatformDto model constructor.
     * @property {module:model/PlatformDto}
     */
    PlatformDto,

    /**
     * The ProductTypeDto model constructor.
     * @property {module:model/ProductTypeDto}
     */
    ProductTypeDto,

    /**
     * The ProductTypeRequestModel model constructor.
     * @property {module:model/ProductTypeRequestModel}
     */
    ProductTypeRequestModel,

    /**
     * The PublishHouseDto model constructor.
     * @property {module:model/PublishHouseDto}
     */
    PublishHouseDto,

    /**
     * The PublishHouseMainDto model constructor.
     * @property {module:model/PublishHouseMainDto}
     */
    PublishHouseMainDto,

    /**
     * The PublishHouseRequestModel model constructor.
     * @property {module:model/PublishHouseRequestModel}
     */
    PublishHouseRequestModel,

    /**
     * The PurchaseMissionClonePayloadModel model constructor.
     * @property {module:model/PurchaseMissionClonePayloadModel}
     */
    PurchaseMissionClonePayloadModel,

    /**
     * The PurchaseMissionDto model constructor.
     * @property {module:model/PurchaseMissionDto}
     */
    PurchaseMissionDto,

    /**
     * The PurchaseMissionInfoList model constructor.
     * @property {module:model/PurchaseMissionInfoList}
     */
    PurchaseMissionInfoList,

    /**
     * The PurchaseMissionMarkUpRecordDto model constructor.
     * @property {module:model/PurchaseMissionMarkUpRecordDto}
     */
    PurchaseMissionMarkUpRecordDto,

    /**
     * The Resource model constructor.
     * @property {module:model/Resource}
     */
    Resource,

    /**
     * The RoleDto model constructor.
     * @property {module:model/RoleDto}
     */
    RoleDto,

    /**
     * The RoleObjectDto model constructor.
     * @property {module:model/RoleObjectDto}
     */
    RoleObjectDto,

    /**
     * The RoleWithPermissionPayloadModel model constructor.
     * @property {module:model/RoleWithPermissionPayloadModel}
     */
    RoleWithPermissionPayloadModel,

    /**
     * The SearchingQueryResponseMapModelstringListPermissionDetailsDto model constructor.
     * @property {module:model/SearchingQueryResponseMapModelstringListPermissionDetailsDto}
     */
    SearchingQueryResponseMapModelstringListPermissionDetailsDto,

    /**
     * The SearchingQueryResponseModelAwardDto model constructor.
     * @property {module:model/SearchingQueryResponseModelAwardDto}
     */
    SearchingQueryResponseModelAwardDto,

    /**
     * The SearchingQueryResponseModelBatchMissionDto model constructor.
     * @property {module:model/SearchingQueryResponseModelBatchMissionDto}
     */
    SearchingQueryResponseModelBatchMissionDto,

    /**
     * The SearchingQueryResponseModelBisacDto model constructor.
     * @property {module:model/SearchingQueryResponseModelBisacDto}
     */
    SearchingQueryResponseModelBisacDto,

    /**
     * The SearchingQueryResponseModelBookBaseSimpleDto model constructor.
     * @property {module:model/SearchingQueryResponseModelBookBaseSimpleDto}
     */
    SearchingQueryResponseModelBookBaseSimpleDto,

    /**
     * The SearchingQueryResponseModelBookDto model constructor.
     * @property {module:model/SearchingQueryResponseModelBookDto}
     */
    SearchingQueryResponseModelBookDto,

    /**
     * The SearchingQueryResponseModelBookListDto model constructor.
     * @property {module:model/SearchingQueryResponseModelBookListDto}
     */
    SearchingQueryResponseModelBookListDto,

    /**
     * The SearchingQueryResponseModelCategoryDto model constructor.
     * @property {module:model/SearchingQueryResponseModelCategoryDto}
     */
    SearchingQueryResponseModelCategoryDto,

    /**
     * The SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto model constructor.
     * @property {module:model/SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto}
     */
    SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto,

    /**
     * The SearchingQueryResponseModelLanguageDto model constructor.
     * @property {module:model/SearchingQueryResponseModelLanguageDto}
     */
    SearchingQueryResponseModelLanguageDto,

    /**
     * The SearchingQueryResponseModelLocationDto model constructor.
     * @property {module:model/SearchingQueryResponseModelLocationDto}
     */
    SearchingQueryResponseModelLocationDto,

    /**
     * The SearchingQueryResponseModelOrganizationIdDto model constructor.
     * @property {module:model/SearchingQueryResponseModelOrganizationIdDto}
     */
    SearchingQueryResponseModelOrganizationIdDto,

    /**
     * The SearchingQueryResponseModelPlatformDto model constructor.
     * @property {module:model/SearchingQueryResponseModelPlatformDto}
     */
    SearchingQueryResponseModelPlatformDto,

    /**
     * The SearchingQueryResponseModelProductTypeDto model constructor.
     * @property {module:model/SearchingQueryResponseModelProductTypeDto}
     */
    SearchingQueryResponseModelProductTypeDto,

    /**
     * The SearchingQueryResponseModelPublishHouseDto model constructor.
     * @property {module:model/SearchingQueryResponseModelPublishHouseDto}
     */
    SearchingQueryResponseModelPublishHouseDto,

    /**
     * The SearchingQueryResponseModelPurchaseMissionDto model constructor.
     * @property {module:model/SearchingQueryResponseModelPurchaseMissionDto}
     */
    SearchingQueryResponseModelPurchaseMissionDto,

    /**
     * The SearchingQueryResponseModelRoleDto model constructor.
     * @property {module:model/SearchingQueryResponseModelRoleDto}
     */
    SearchingQueryResponseModelRoleDto,

    /**
     * The SearchingQueryResponseModelSupplierDto model constructor.
     * @property {module:model/SearchingQueryResponseModelSupplierDto}
     */
    SearchingQueryResponseModelSupplierDto,

    /**
     * The SearchingQueryResponseModelUserDto model constructor.
     * @property {module:model/SearchingQueryResponseModelUserDto}
     */
    SearchingQueryResponseModelUserDto,

    /**
     * The SearchingQueryResponseModelWhiteListIpDto model constructor.
     * @property {module:model/SearchingQueryResponseModelWhiteListIpDto}
     */
    SearchingQueryResponseModelWhiteListIpDto,

    /**
     * The SetShoppingCartBookModel model constructor.
     * @property {module:model/SetShoppingCartBookModel}
     */
    SetShoppingCartBookModel,

    /**
     * The SetUserActivePayloadModel model constructor.
     * @property {module:model/SetUserActivePayloadModel}
     */
    SetUserActivePayloadModel,

    /**
     * The SetUserDeletePayloadModel model constructor.
     * @property {module:model/SetUserDeletePayloadModel}
     */
    SetUserDeletePayloadModel,

    /**
     * The ShoppingCartPreOrderBookListBookItemDto model constructor.
     * @property {module:model/ShoppingCartPreOrderBookListBookItemDto}
     */
    ShoppingCartPreOrderBookListBookItemDto,

    /**
     * The ShoppingCartPreOrderBookListDto model constructor.
     * @property {module:model/ShoppingCartPreOrderBookListDto}
     */
    ShoppingCartPreOrderBookListDto,

    /**
     * The ShoppingCartSummaryDto model constructor.
     * @property {module:model/ShoppingCartSummaryDto}
     */
    ShoppingCartSummaryDto,

    /**
     * The SupplierDto model constructor.
     * @property {module:model/SupplierDto}
     */
    SupplierDto,

    /**
     * The SupplierMainDto model constructor.
     * @property {module:model/SupplierMainDto}
     */
    SupplierMainDto,

    /**
     * The SupplierRequestModel model constructor.
     * @property {module:model/SupplierRequestModel}
     */
    SupplierRequestModel,

    /**
     * The SystemSettingCronJobModel model constructor.
     * @property {module:model/SystemSettingCronJobModel}
     */
    SystemSettingCronJobModel,

    /**
     * The SystemSettingDoubleModel model constructor.
     * @property {module:model/SystemSettingDoubleModel}
     */
    SystemSettingDoubleModel,

    /**
     * The SystemSettingDto model constructor.
     * @property {module:model/SystemSettingDto}
     */
    SystemSettingDto,

    /**
     * The SystemSettingIntegerModel model constructor.
     * @property {module:model/SystemSettingIntegerModel}
     */
    SystemSettingIntegerModel,

    /**
     * The TemplateBookDto model constructor.
     * @property {module:model/TemplateBookDto}
     */
    TemplateBookDto,

    /**
     * The UpdateUserListOrgAndRolePayloadModel model constructor.
     * @property {module:model/UpdateUserListOrgAndRolePayloadModel}
     */
    UpdateUserListOrgAndRolePayloadModel,

    /**
     * The UserDto model constructor.
     * @property {module:model/UserDto}
     */
    UserDto,

    /**
     * The UserOrganizationPayloadItemModel model constructor.
     * @property {module:model/UserOrganizationPayloadItemModel}
     */
    UserOrganizationPayloadItemModel,

    /**
     * The WhiteListIpDto model constructor.
     * @property {module:model/WhiteListIpDto}
     */
    WhiteListIpDto,

    /**
    * The AuthApi service constructor.
    * @property {module:api/AuthApi}
    */
    AuthApi,

    /**
    * The AwardApi service constructor.
    * @property {module:api/AwardApi}
    */
    AwardApi,

    /**
    * The BisacApi service constructor.
    * @property {module:api/BisacApi}
    */
    BisacApi,

    /**
    * The BookApi service constructor.
    * @property {module:api/BookApi}
    */
    BookApi,

    /**
    * The BookBatchMissionApi service constructor.
    * @property {module:api/BookBatchMissionApi}
    */
    BookBatchMissionApi,

    /**
    * The BookListApi service constructor.
    * @property {module:api/BookListApi}
    */
    BookListApi,

    /**
    * The CategoryApi service constructor.
    * @property {module:api/CategoryApi}
    */
    CategoryApi,

    /**
    * The CustomerShoppingCartApi service constructor.
    * @property {module:api/CustomerShoppingCartApi}
    */
    CustomerShoppingCartApi,

    /**
    * The FileApi service constructor.
    * @property {module:api/FileApi}
    */
    FileApi,

    /**
    * The LanguageApi service constructor.
    * @property {module:api/LanguageApi}
    */
    LanguageApi,

    /**
    * The LocationApi service constructor.
    * @property {module:api/LocationApi}
    */
    LocationApi,

    /**
    * The OrganizationApi service constructor.
    * @property {module:api/OrganizationApi}
    */
    OrganizationApi,

    /**
    * The PermissionApi service constructor.
    * @property {module:api/PermissionApi}
    */
    PermissionApi,

    /**
    * The PlatformApi service constructor.
    * @property {module:api/PlatformApi}
    */
    PlatformApi,

    /**
    * The ProductTypeApi service constructor.
    * @property {module:api/ProductTypeApi}
    */
    ProductTypeApi,

    /**
    * The PublishHouseApi service constructor.
    * @property {module:api/PublishHouseApi}
    */
    PublishHouseApi,

    /**
    * The PurchaseMissionApi service constructor.
    * @property {module:api/PurchaseMissionApi}
    */
    PurchaseMissionApi,

    /**
    * The RoleApi service constructor.
    * @property {module:api/RoleApi}
    */
    RoleApi,

    /**
    * The SupplierApi service constructor.
    * @property {module:api/SupplierApi}
    */
    SupplierApi,

    /**
    * The SystemSettingApi service constructor.
    * @property {module:api/SystemSettingApi}
    */
    SystemSettingApi,

    /**
    * The UserApi service constructor.
    * @property {module:api/UserApi}
    */
    UserApi,

    /**
    * The WhiteListIpApi service constructor.
    * @property {module:api/WhiteListIpApi}
    */
    WhiteListIpApi
};
