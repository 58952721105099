import localforage from "localforage";
import React from "react";
import { connect } from "react-redux";
import {
  handleFetchBookmarks,
  handleFetchNotes,
  handleShowBookmark,
} from "../../../redux/actions/reader";
import TagUtil from "../../../utils/reader/readUtils/tagUtil";
import DeletePopup from "../dialogs/deletePopup/component";
import "./deleteIcon.css";

class DeleteIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteIndex: -1,
      isOpenDelete: false,
    };
  }

  handleDelete = () => {
    let deleteItems =
      this.props.mode === "notes"
        ? this.props.notes
        : this.props.mode === "tags"
        ? TagUtil.getAllTags()
        : this.props.bookmarks;
    let deleteFunc =
      this.props.mode === "notes"
        ? this.props.handleFetchNotes
        : this.props.handleFetchBookmarks;
    deleteItems.forEach((item, index) => {
      if (this.props.mode === "tags") {
        item === this.props.tagName && TagUtil.clear(item);
        return;
      }
      if (item.key === this.props.itemKey) {
        deleteItems.splice(index, 1);
        if (deleteItems.length === 0) {
          localforage
            .removeItem(this.props.mode)
            .then(() => {
              deleteFunc();
              alert("删除成功");
            })
            .catch(() => {
              console.log("删除失败");
            });
        } else {
          localforage
            .setItem(this.props.mode, deleteItems)
            .then(() => {
              deleteFunc();
              alert("删除成功");
            })
            .catch(() => {
              console.log("修改失败");
            });
        }
      }
    });
  };
  handleDeletePopup = (isOpenDelete) => {
    this.setState({ isOpenDelete });
    if (!isOpenDelete) {
      this.props.handleChangeTag(this.props.index);
    }
  };
  render() {
    const deletePopupProps = {
      name: this.props.tagName,
      title: "Delete this tag",
      description: "This action will clear and remove this tag",
      handleDeletePopup: this.handleDeletePopup,
      handleDeleteOpearion: this.handleDelete,
    };
    return (
      <>
        {this.state.isOpenDelete && <DeletePopup {...deletePopupProps} />}
        <div
          className="delete-digest-button"
          onClick={() => {
            this.props.mode === "tags"
              ? this.handleDeletePopup(true)
              : this.handleDelete();
          }}
        >
          <span className="icon-close delete-digest-icon"></span>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    digests: state.reader.digests,
    bookmarks: state.reader.bookmarks,
    notes: state.reader.notes,
    isReading: state.book.isReading,
  };
};
const actionCreator = {
  handleFetchBookmarks,
  handleFetchNotes,
  handleShowBookmark,
};
export default connect(mapStateToProps, actionCreator)(DeleteIcon);
