/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import CommonResponseModel from '../model/CommonResponseModel';
import FileDto from '../model/FileDto';
import FilePrepareResponseModel from '../model/FilePrepareResponseModel';
import Resource from '../model/Resource';

/**
* File service.
* @module api/FileApi
* @version v1.0.0
*/
export default class FileApi {

    /**
    * Constructs a new FileApi. 
    * @alias module:api/FileApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the deleteFileUsingDELETE operation.
     * @callback module:api/FileApi~deleteFileUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete files
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/FileApi~deleteFileUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteFileUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/file/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the downloadBase64UsingGET operation.
     * @callback module:api/FileApi~downloadBase64UsingGETCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * download file by file name
     * @param {String} bookId book Id (required)
     * @param {String} filename file name (required)
     * @param {module:api/FileApi~downloadBase64UsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    downloadBase64UsingGET(bookId, filename, callback) {
      let postBody = null;
      // verify the required parameter 'bookId' is set
      if (bookId === undefined || bookId === null) {
        throw new Error("Missing the required parameter 'bookId' when calling downloadBase64UsingGET");
      }
      // verify the required parameter 'filename' is set
      if (filename === undefined || filename === null) {
        throw new Error("Missing the required parameter 'filename' when calling downloadBase64UsingGET");
      }

      let pathParams = {
      };
      let queryParams = {
        'bookId': bookId,
        'filename': filename
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/file/download-base64', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the downloadByBookIdUsingGET operation.
     * @callback module:api/FileApi~downloadByBookIdUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Resource} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * download zip file by book id
     * @param {String} bookId book Id (required)
     * @param {module:api/FileApi~downloadByBookIdUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Resource}
     */
    downloadByBookIdUsingGET(bookId, callback) {
      let postBody = null;
      // verify the required parameter 'bookId' is set
      if (bookId === undefined || bookId === null) {
        throw new Error("Missing the required parameter 'bookId' when calling downloadByBookIdUsingGET");
      }

      let pathParams = {
      };
      let queryParams = {
        'bookId': bookId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Resource;
      return this.apiClient.callApi(
        '/api/file/download-by-bookId', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the downloadFileUsingGET operation.
     * @callback module:api/FileApi~downloadFileUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Resource} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * download file by file name
     * @param {String} bookId book Id (required)
     * @param {String} filename file name (required)
     * @param {module:api/FileApi~downloadFileUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Resource}
     */
    downloadFileUsingGET(bookId, filename, callback) {
      let postBody = null;
      // verify the required parameter 'bookId' is set
      if (bookId === undefined || bookId === null) {
        throw new Error("Missing the required parameter 'bookId' when calling downloadFileUsingGET");
      }
      // verify the required parameter 'filename' is set
      if (filename === undefined || filename === null) {
        throw new Error("Missing the required parameter 'filename' when calling downloadFileUsingGET");
      }

      let pathParams = {
      };
      let queryParams = {
        'bookId': bookId,
        'filename': filename
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Resource;
      return this.apiClient.callApi(
        '/api/file/download-file', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getFileUsingGET operation.
     * @callback module:api/FileApi~getFileUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Resource} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get file
     * @param {String} bookIsbn bookIsbn
     * @param {String} bookType bookType
     * @param {String} fileName fileName
     * @param {module:api/FileApi~getFileUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Resource}
     */
    getFileUsingGET(bookIsbn, bookType, fileName, callback) {
      let postBody = null;
      // verify the required parameter 'bookIsbn' is set
      if (bookIsbn === undefined || bookIsbn === null) {
        throw new Error("Missing the required parameter 'bookIsbn' when calling getFileUsingGET");
      }
      // verify the required parameter 'bookType' is set
      if (bookType === undefined || bookType === null) {
        throw new Error("Missing the required parameter 'bookType' when calling getFileUsingGET");
      }
      // verify the required parameter 'fileName' is set
      if (fileName === undefined || fileName === null) {
        throw new Error("Missing the required parameter 'fileName' when calling getFileUsingGET");
      }

      let pathParams = {
        'bookIsbn': bookIsbn,
        'bookType': bookType,
        'fileName': fileName
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Resource;
      return this.apiClient.callApi(
        '/api/file/{bookIsbn}/{bookType}/{fileName}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the importBookZipFileHandlerUsingPOST operation.
     * @callback module:api/FileApi~importBookZipFileHandlerUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * upload book files (zip)
     * @param {File} file file
     * @param {module:api/FileApi~importBookZipFileHandlerUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    importBookZipFileHandlerUsingPOST(file, callback) {
      let postBody = null;
      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling importBookZipFileHandlerUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = ['Authorization'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/file/import/books/files', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the prepareFileUsingPOST operation.
     * @callback module:api/FileApi~prepareFileUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/FilePrepareResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * upload prepare, check file upload or not
     * @param {String} bookIsbn bookIsbn
     * @param {String} bookInfo bookInfo
     * @param {Number} fileSize fileSize
     * @param {String} bookType bookType
     * @param {String} contentHash contentHash
     * @param {String} md5 md5
     * @param {module:api/FileApi~prepareFileUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/FilePrepareResponseModel}
     */
    prepareFileUsingPOST(bookIsbn, bookInfo, fileSize, bookType, contentHash, md5, callback) {
      let postBody = null;
      // verify the required parameter 'bookIsbn' is set
      if (bookIsbn === undefined || bookIsbn === null) {
        throw new Error("Missing the required parameter 'bookIsbn' when calling prepareFileUsingPOST");
      }
      // verify the required parameter 'bookInfo' is set
      if (bookInfo === undefined || bookInfo === null) {
        throw new Error("Missing the required parameter 'bookInfo' when calling prepareFileUsingPOST");
      }
      // verify the required parameter 'fileSize' is set
      if (fileSize === undefined || fileSize === null) {
        throw new Error("Missing the required parameter 'fileSize' when calling prepareFileUsingPOST");
      }
      // verify the required parameter 'bookType' is set
      if (bookType === undefined || bookType === null) {
        throw new Error("Missing the required parameter 'bookType' when calling prepareFileUsingPOST");
      }
      // verify the required parameter 'contentHash' is set
      if (contentHash === undefined || contentHash === null) {
        throw new Error("Missing the required parameter 'contentHash' when calling prepareFileUsingPOST");
      }
      // verify the required parameter 'md5' is set
      if (md5 === undefined || md5 === null) {
        throw new Error("Missing the required parameter 'md5' when calling prepareFileUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
        'bookIsbn': bookIsbn,
        'bookInfo': bookInfo,
        'fileSize': fileSize,
        'bookType': bookType,
        'contentHash': contentHash,
        'md5': md5
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = FilePrepareResponseModel;
      return this.apiClient.callApi(
        '/api/file/upload/prepare', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the selectByBookIdUsingGET operation.
     * @callback module:api/FileApi~selectByBookIdUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/FileDto>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * select Files by Book id
     * @param {String} bookId book Id (required)
     * @param {module:api/FileApi~selectByBookIdUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/FileDto>}
     */
    selectByBookIdUsingGET(bookId, callback) {
      let postBody = null;
      // verify the required parameter 'bookId' is set
      if (bookId === undefined || bookId === null) {
        throw new Error("Missing the required parameter 'bookId' when calling selectByBookIdUsingGET");
      }

      let pathParams = {
      };
      let queryParams = {
        'bookId': bookId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = [FileDto];
      return this.apiClient.callApi(
        '/api/file/select-by-book-Id', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadMongoChunkFileUsingPOST operation.
     * @callback module:api/FileApi~uploadMongoChunkFileUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * upload chunk
     *  return true => continue upload  return {fileId} => upload finished
     * @param {Object} opts Optional parameters
     * @param {String} opts.bookIsbn 
     * @param {String} opts.bookType 
     * @param {String} opts.chunkName 
     * @param {Number} opts.chunkNum 
     * @param {Number} opts.end 
     * @param {String} opts.fileHash 
     * @param {String} opts.fileName 
     * @param {String} opts.fullPath 
     * @param {Number} opts.index 
     * @param {String} opts.md5 
     * @param {Number} opts.start 
     * @param {Number} opts.total 
     * @param {File} opts.body 
     * @param {module:api/FileApi~uploadMongoChunkFileUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    uploadMongoChunkFileUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
        'bookIsbn': opts['bookIsbn'],
        'bookType': opts['bookType'],
        'chunkName': opts['chunkName'],
        'chunkNum': opts['chunkNum'],
        'end': opts['end'],
        'fileHash': opts['fileHash'],
        'fileName': opts['fileName'],
        'fullPath': opts['fullPath'],
        'index': opts['index'],
        'md5': opts['md5'],
        'start': opts['start'],
        'total': opts['total']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json', 'application/octet-stream'];
      let accepts = ['*/*'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/api/file/upload/uploadChunk', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the uploadOneFileUsingPOST operation.
     * @callback module:api/FileApi~uploadOneFileUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * upload one file
     * @param {File} file file
     * @param {module:api/FileApi~uploadOneFileUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    uploadOneFileUsingPOST(file, callback) {
      let postBody = null;
      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling uploadOneFileUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = ['Authorization'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/file/upload-one', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
