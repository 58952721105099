import { Redirect, Route } from "react-router-dom";
import { LOGIN } from "./Constants";

const PrivateRoute = (props) => {
  const { route, isAuth, ...rest } = props;
  //console.log("PrivateRoute", props);
  return route.isPrivate ? (
    <Route
      path={route.path}
      exact={route.routerExact == null ? false : route.routerExact}
      {...rest}
      render={(routeProps) =>
        isAuth ? (
          <route.Component
            {...routeProps}
            {...rest}
            subRoutes={route.subRoute}
          />
        ) : (
          <Redirect
            to={{
              pathname: LOGIN,
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  ) : (
    <Route
      path={route.path}
      exact={route.routerExact == null ? false : route.routerExact}
      {...rest}
      render={(routeProps) => <route.Component {...routeProps} {...rest} />}
    />
  );
};
export default PrivateRoute;
