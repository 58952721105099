import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import logo from "../../assets/images/200_200_bird_book.svg";
import logout from "../../assets/images/btn/exit.svg";
import DialogBox from "../../components/DialogBox/DialogBox";
import { handleUserInfo } from "../../redux/actions";
import {
  FORGET,
  HOME,
  LOGIN,
  STYLETYPE_DEFAULT,
  STYLETYPE_SECOND,
  VERIFY,
} from "../../utils/Constants";
import {
  clearAccessToken,
  clearRefreshToken,
  clearRemind,
  clearUserInfo,
  getUserInfo,
  setUserInfoByOrganization,
  clearRemindInfo,
  getRemindInfo,
} from "../../utils/LocalStorage";
import Button from "../Button/Button";
import Input from "../Input/Input";
import SelectOrgSearchFilter from "../SearchFilter/SelectOrgSearchFilter";
import style from "./Header.module.css";

const notShow = [LOGIN, VERIFY, FORGET];

class Header extends Component {
  count = 0;

  state = {
    userInfo: "",
    organizations: [],
    displayOrganizations: [],
    ishide: false,
    content: {},
    currentSelected: {},
    searchText: "",
  };

  handlehidden = (event) => {
    this.resetState();

    this.state.ishide = !this.state.ishide;
    let _ishide = this.state.ishide;
    this.setState({
      ...this.state,
      ishide: _ishide,
    });
  };

  componentDidMount() {
    console.debug("header", this.count);
    this.resetState();
  }

  logout = () => {
    //1. check if remind === true

    // if (getRemind() !== null) {
    //     console.log("re true");
    // } else {
    //     console.log("re false");
    // }
    clearRemindInfo();
    clearAccessToken();
    clearRefreshToken();
    clearUserInfo();

    this.props.history.push(LOGIN);
  };

  home = () => {
    this.props.history.push(HOME);
  };

  handleSearchClick = (data) => {
    let text = this.state.searchText;

    var tempData = this.state.organizations;

    if (text === "") {
      this.setState({
        ...this.state,
        displayOrganizations: tempData.map((it) => {
          it.isSearch = true;

          return it;
        }),
      });
      return;
    }

    var newOrganizationObj = tempData.filter((obj) => {
      if (obj.name.toLowerCase().includes(text.toLowerCase())) {
        obj.isSearch = true;
      } else {
        obj.isSearch = false;
      }
      return obj;
    });

    this.setState({
      ...this.state,
      displayOrganizations: newOrganizationObj,
    });
  };

  handleConfirm = (value) => {
    const { organizations } = this.state;
    var userOrganizationsObj = this.props.userInfo?.organizations;

    this.setState({
      ...this.state,
      currentSelected: organizations.find((it) => it.isSelected),
    });

    let resultObj = Object.assign(this.props.userInfo, {
      organizations: organizations,
    });
    setUserInfoByOrganization(resultObj);
    this.resetState();
    window.location.reload(false);
  };

  handleCancel = (value) => {
    this.resetState();
    this.state.ishide = false;
    let _ishide = this.state.ishide;

    //console.log("this", this.state.currentSelected);

    this.state.organizations.map((it) => {
      if (it.id === this.state.currentSelected.id) {
        it.isSelected = true;
      } else {
        it.isSelected = false;
      }
      return it;
    });

    this.setState({
      ...this.state,
      ishide: _ishide,
    });
  };

  // resetState = () => {
  //   let userInfo = this.props.userInfo;
  //   console.log("userInfo", userInfo);

  //   if (userInfo != null) {
  //     if (userInfo.organizations.length > 0) {
  //       this.setState({
  //         ...this.state,
  //         searchText: "",
  //         userInfo: userInfo.name,
  //         organizations: userInfo.organizations,
  //         displayOrganizations: userInfo.organizations.map((it) => {
  //           it.isSearch = true;
  //           return it;
  //         }),
  //         currentSelected: userInfo.organizations.find((it) => it.isSelected),
  //         ishide: true,
  //       });
  //     } else {
  //       this.setState({
  //         ...this.state,
  //         searchText: "",
  //         userInfo: userInfo.name,
  //         organizations: userInfo.organizations,
  //         displayOrganizations: userInfo.organizations.map((it) => {
  //           it.isSearch = true;
  //           return it;
  //         }),
  //         currentSelected: userInfo.organizations.find((it) => it.isSelected),
  //         ishide: false,
  //       });
  //     }
  //   }
  // };

  handlehidden = (event) => {
    this.resetState();

    this.state.ishide = !this.state.ishide;
    let _ishide = this.state.ishide;
    this.setState({
      ishide: _ishide,
    });
  };

  componentDidMount() {
    this.resetState();
  }

  logout = () => {
    const userInfo = getUserInfo();
    if (userInfo !== null) {
      if (userInfo.remind !== true) {
        clearRemindInfo();
      }
    }
    clearAccessToken();
    clearRefreshToken();
    clearUserInfo();
    this.props.history.push(LOGIN);
  };

  home = () => {
    this.props.history.push(HOME);
  };

  handleSearchClick = (data) => {
    let text = this.state.searchText;

    var tempData = this.state.organizations;

    if (text === "") {
      this.setState({
        ...this.state,
        displayOrganizations: tempData.map((it) => {
          it.isSearch = true;

          return it;
        }),
      });
      return;
    }

    var newOrganizationObj = tempData.filter((obj) => {
      if (obj.name.toLowerCase().includes(text.toLowerCase())) {
        obj.isSearch = true;
      } else {
        obj.isSearch = false;
      }
      return obj;
    });

    this.setState({
      ...this.state,
      displayOrganizations: newOrganizationObj,
    });
  };

  handleConfirm = (value) => {
    const { organizations } = this.state;
    var userOrganizationsObj = this.props.userInfo.organizations;

    this.setState({
      ...this.state,
      currentSelected: organizations.find((it) => it.isSelected),
    });

    let resultObj = Object.assign(this.props.userInfo, {
      organizations: organizations,
    });
    setUserInfoByOrganization(resultObj);
    this.resetState();
    window.location.reload(false);
  };

  handleCancel = (value) => {
    this.resetState();
    this.state.ishide = false;
    let _ishide = this.state.ishide;

    //console.log("this", this.state.currentSelected);

    this.state.organizations.map((it) => {
      if (it.id === this.state.currentSelected.id) {
        it.isSelected = true;
      } else {
        it.isSelected = false;
      }
      return it;
    });

    this.setState({
      ...this.state,
      ishide: _ishide,
    });
  };

  resetState = () => {
    let userInfo = this.props.userInfo;

    if (userInfo.organizations === undefined) {
      userInfo = null;
    }
    if (userInfo !== null && userInfo != undefined) {
      let temp = [];
      userInfo.organizations.map((it) => {
        temp.push({
          id: it.id,
          name: it.name,
          sub: it.sub,
        });
      });

      this.setState({
        ...this.state,
        searchText: "",
        userInfo: userInfo.name,
        organizations: userInfo.organizations,
        displayOrganizations: userInfo.organizations.map((it) => {
          it.isSearch = true;
          return it;
        }),
        currentSelected: userInfo.organizations.find((it) => it.isSelected),
        ishide: false,
      });
    }
  };

  setSearchText = (value) => {
    this.setState({
      searchText: value,
    });
  };

  onChangeRadio = (elem) => {
    this.setState({ ...this.state, displayOrganizations: elem, ishide: false });

    // const { displayOrganizations, organizations } = this.state;

    // var tempDisplayOrganizations = displayOrganizations;
    // var newArr = displayOrganizations.map((it) => {
    //   if (it.id === elem.id) {
    //     //1. if sub exsits
    //     // if (it.sub.length > 0) {
    //     //   Object.assign(...it, { action: "ex" });
    //     // }

    //     it.isSelected = true;
    //   } else {
    //     it.isSelected = false;
    //   }
    //   return it;
    // });
    // console.log("onChangeRadio");
    // this.setState({ ...this.state, displayOrganizations: newArr });
    //this.handleConfirm(null);
  };

  showSubMenu = (value, level) => {
    this.state.showSub = !this.state.showSub;
    let _showSub = this.state.showSub;
    this.setState({
      showSub: _showSub,
    });
  };

  genHTML = (obj) => {
    let a = this.genMainHTML(obj);
    //console.log("a", a);

    if (obj.sub.length > 0) {
      obj.sub.map((it) => {
        this.genHTML(it);
      });
    }
  };

  genMainHTML = (obj) => {
    return (
      <div className={style.ContentItemlistColumn} key={obj.id}>
        <div className={style.ContentItemlist} key={obj.id}>
          <Input
            type="radio"
            showType={STYLETYPE_DEFAULT}
            checked={obj.isSelected}
            callBack={(value) => this.onChangeRadio(obj)}
          />
          <p
            value={obj.id}
            className={style.margin_7px}
            onClick={(value) => this.showSubMenu(obj, 0)}
          >
            {obj.name}
          </p>
        </div>
      </div>
    );
  };

  render() {
    const {
      userInfo,
      organizations,
      displayOrganizations,
      ishide,
      content,
      searchText,
      showSub,
    } = this.state;

    // let _do = displayOrganizations.filter((it) => it.isSearch);
    // console.log("_do", _do);

    return window.location.pathname.includes("epub") !== true ? (
      window.location.pathname.includes("pdf") !== true ? (
        <>
          <header>
            <div className={style.header_title} onClick={() => this.home()}>
              <img className={style.logo} src={logo} />
              <h6>{process.env.REACT_APP_APP_DISPLAY_NAME}</h6>
            </div>
            {notShow.filter((it) => it === window.location.pathname).length >
              0 && this.props.userInfo.organizations !== undefined ? (
              <></>
            ) : (
              <>
                {this.props.userInfo.organizations !== undefined ? (
                  <>
                    <label onClick={(e) => this.handlehidden(e)} id="userName">
                      {`${this.props.userInfo.name} (${
                        this.props.userInfo.organizations.find(
                          (it) => it.isSelected
                        )?.name
                      } @ ${
                        this.props.userInfo.organizations.find(
                          (it) => it.isSelected
                        )?.role.name
                      })`}
                    </label>

                    <img
                      className={style.logout}
                      src={logout}
                      onClick={() => this.logout()}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </header>

          <DialogBox showDialog={ishide} title={"選擇"}>
            <div className={style.header_contain}>
              <SelectOrgSearchFilter
                for={"header_contain"}
                data={displayOrganizations}
                multiple={false}
                expand={true}
                callBack={(obj) => this.onChangeRadio(obj)}
              />
              {/* hide */}
              {/*
              <div className={style.row}>
                <Input
                  type="text"
                  placeholder={"輸入名稱"}
                  value={searchText}
                  styleType={STYLETYPE_FIVE}
                  callBack={(value) => this.setSearchText(value)}
                />
                <div className={style.RowBtn}>
                  <Button
                    styleType={STYLETYPE_MAIN}
                    callBack={(value) => this.handleSearchClick(value)}
                  >
                    搜尋
                  </Button>
                </div>
              </div>

              <div
                className={style.organizations_select}
                id="organizations_select"
              >
                {displayOrganizations
                  .filter((it) => it.isSearch)
                  .map((obj, index) => {
                    return obj.sub.length === 0 ? (
                      <>
                        {
                          <div
                            className={style.ContentItemlistColumn}
                            key={index}
                          >
                            <div className={style.ContentItemlist} key={index}>
                              <Input
                                type="radio"
                                showType={STYLETYPE_DEFAULT}
                                checked={obj.isSelected}
                                callBack={(value) => this.onChangeRadio(obj)}
                              />
                              <p
                                value={obj.id}
                                className={style.margin_7px}
                                onClick={(value) => this.showSubMenu(obj, 0)}
                              >
                                {obj.name}
                              </p>
                            </div>
                          </div>
                        }
                      </>
                    ) : (
                      <>{this.genHTML(obj)}</>
                    );
                  })}
                <div></div>
              </div>
              */}
              {/* hide end */}
            </div>
            <div style={{ display: "flex", marginTop: "15px" }}>
              <Button
                styleType={STYLETYPE_SECOND}
                callBack={(value) => this.handleCancel(value)}
              >
                取消
              </Button>

              {/* <Button
                styleType={STYLETYPE_SECOND}
                callBack={(value) => this.handleConfirm(value)}
                //hidden={}
              >
                確定
              </Button> */}
            </div>
          </DialogBox>
        </>
      ) : (
        <></>
      )
    ) : (
      <></>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = { handleUserInfo };
export default connect(mapStateToProps, actionCreator)(withRouter(Header));
