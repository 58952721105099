/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import UserDto from './UserDto';

/**
 * The AuthResponseModel model module.
 * @module model/AuthResponseModel
 * @version v1.0.0
 */
class AuthResponseModel {
    /**
     * Constructs a new <code>AuthResponseModel</code>.
     * @alias module:model/AuthResponseModel
     */
    constructor() { 
        
        AuthResponseModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AuthResponseModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AuthResponseModel} obj Optional instance to populate.
     * @return {module:model/AuthResponseModel} The populated <code>AuthResponseModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AuthResponseModel();

            if (data.hasOwnProperty('accessToken')) {
                obj['accessToken'] = ApiClient.convertToType(data['accessToken'], 'String');
            }
            if (data.hasOwnProperty('accessTokenExp')) {
                obj['accessTokenExp'] = ApiClient.convertToType(data['accessTokenExp'], 'Number');
            }
            if (data.hasOwnProperty('refreshToken')) {
                obj['refreshToken'] = ApiClient.convertToType(data['refreshToken'], 'String');
            }
            if (data.hasOwnProperty('userInfo')) {
                obj['userInfo'] = UserDto.constructFromObject(data['userInfo']);
            }
        }
        return obj;
    }


}

/**
 * access token
 * @member {String} accessToken
 */
AuthResponseModel.prototype['accessToken'] = undefined;

/**
 * access token exp
 * @member {Number} accessTokenExp
 */
AuthResponseModel.prototype['accessTokenExp'] = undefined;

/**
 * refresh token
 * @member {String} refreshToken
 */
AuthResponseModel.prototype['refreshToken'] = undefined;

/**
 * @member {module:model/UserDto} userInfo
 */
AuthResponseModel.prototype['userInfo'] = undefined;






export default AuthResponseModel;

