import bookIcon from "../assets/images/btn/main_menu/bk.svg";
import bkCatIcon from "../assets/images/btn/main_menu/bk_cat.svg";
import ChangePwIcon from "../assets/images/btn/main_menu/change_pw.svg";
import languageIcon from "../assets/images/btn/main_menu/language.svg";
import libraryOrgIcon from "../assets/images/btn/main_menu/library.svg";
import libraryUserIcon from "../assets/images/btn/main_menu/library_user.svg";
import prdTypeIcon from "../assets/images/btn/main_menu/prd_type.svg";
import publisherIcon from "../assets/images/btn/main_menu/publisher.svg";
import pBookPurchaseIcon from "../assets/images/btn/main_menu/purchase/bk_purchase.svg";
import pBookListIcon from "../assets/images/btn/main_menu/purchase/booklist.svg";
import pBuyingListIcon from "../assets/images/btn/main_menu/purchase/buying_list.svg";
import pMissionIcon from "../assets/images/btn/main_menu/purchase/mission.svg";
import roleIcon from "../assets/images/btn/main_menu/role.svg";
import userIcon from "../assets/images/btn/main_menu/user.svg";
import {
  AddPurchaseView,
  AdministrativeView,
  BookCategoryView,
  BookListView,
  BookManagementView,
  BookOrganizationView,
  BookView,
  CategoryView,
  ForgetConfirmView,
  ForgetView,
  HomeView,
  LanguageView,
  LibraryUsersView,
  LoginView,
  ProductTypeView,
  PublisherView,
  PurchaseClientBookDetailsView,
  PurchaseClientBookListView,
  PurchaseClientView,
  PurchaseDetailView,
  PurchaseListDetails,
  PurchaseListView,
  PurchaseManagementView,
  PurchaseRecordView,
  PurchaseView,
  ResetPassword,
  RoleView,
  SelingBookList,
  SupplierManagementView,
  SystemView,
  UpdatePurchaseView,
  UsersView,
  VerifyView,
} from "../layout";
import LibraryOrgView from "../layout/administrative/system_management/library_organization/LibraryOrgView";
import ChangePW from "../layout/administrative/user_management/change_password/ChangePW";
import NewBookView from "../layout/book_management/new_book/NewBookView";
import UpdateBookView from "../layout/book_management/update_book/UpdateBookView";
import EpubReader from "../layout/reader/epubPage/component";
import PdfReader from "../layout/reader/pdfReader/component";
import testAudioBookPage from "../layout/test/TestAudioBookPage";
import TestPage from "../layout/test/TestPage";
import TestUploadFile from "../layout/test/TestUploadFile";
import {
  ADD_BOOK,
  ADD_BOOK_MANAGEMENT_BREADCRUMB_NAME,
  ADD_PURCHASE,
  ADD_PURCHASE_BREADCRUMB_NAME,
  ADMINISTRATIVE,
  ADMINISTRATIVE_BREADCRUMB_NAME,
  ALL_BOOK,
  ALL_CATEGORY,
  ALL_CATEGORY_BREADCRUMB_NAME,
  ALL_LANGUAGE,
  ALL_LANGUAGE_BREADCRUMB_NAME,
  ALL_LIBRARY_ORGANIZATION,
  ALL_LIBRARY_ORGANIZATION_BREADCRUMB_NAME,
  ALL_LIBRARY_USERS,
  ALL_LIBRARY_USERS_BREADCRUMB_NAME,
  ALL_ORGANIZATION,
  ALL_PRODUCT_TYPE,
  ALL_PRODUCT_TYPE_BREADCRUMB_NAME,
  ALL_PURCHASE,
  ALL_PURCHASE_DETAILS,
  ALL_ROLE,
  ALL_SUPPLIER_BREADCRUMB_NAME,
  ALL_USER,
  BOOKLIST,
  BOOKMANAGEMENT,
  BOOKMANAGEMENT_BREADCRUMB_NAME,
  BOOK_MANAGEMENT_BREADCRUMB_NAME,
  BOOK_ORGANIZATION_PATH_BYID,
  CODE_ADMINISTRATIVE,
  CODE_BOOKMANAGEMENT,
  CODE_BOOK_ADD,
  CODE_BOOK_LIST,
  CODE_BOOK_UPDATE,
  CODE_LANGUAGE_LIST,
  CODE_LIBRARY_CAT_LIST,
  CODE_LIBRARY_ORGANIZATION_LIST,
  CODE_LIBRARY_USER_LIST,
  CODE_ORGANIZATION_LIST,
  CODE_PRODUCT_TYPE_LIST,
  CODE_PURCHASE,
  CODE_PURCHASE_BOOKLIST_LIST,
  CODE_PURCHASE_BOOK_LIST,
  CODE_PURCHASE_MISSION_LIST,
  CODE_ROLE_LIST,
  CODE_USER_CHANGE_PASSWORD,
  CODE_USER_LIST,
  FORGET,
  FORGETCONFIRM,
  FORGETCONFIRM_BREADCRUMB_NAME,
  FORGET_BREADCRUMB_NAME,
  HOME,
  HOME_BREADCRUMB_NAME,
  LOGIN,
  LOGIN_BREADCRUMB_NAME,
  PASSWORD_MANAGEMENT_BREADCRUMB_NAME,
  PURCHASE,
  PURCHASEDETAIL_BYID,
  PURCHASEDETAIL_CLIENT_BYID,
  PURCHASELIST_CLIENT_BYID,
  PURCHASERECORD,
  PURCHASE_BOOKLIST_BREADCRUMB_NAME,
  PURCHASE_BREADCRUMB_NAME,
  PURCHASE_CLIENT,
  PURCHASE_CLIENT_BOOKLIST_BREADCRUMB_NAME,
  PURCHASE_CLIENT_BREADCRUMB_NAME,
  PURCHASE_LIST_BREADCRUMB_NAME,
  PURCHASE_LIST_PATH,
  PURCHASE_LIST_PATH_BYID,
  PURCHASE_MANAGEMENT_BREADCRUMB_NAME,
  PURCHASE_RECORD_BREADCRUMB_NAME,
  PURCHASE_SELING_BOOKLIST,
  RESETPW,
  RESETPW_BREADCRUMB_NAME,
  ROLE_MANAGEMENT_BREADCRUMB_NAME,
  SYSTEMSETTING,
  SYSTEMSETTING_BREADCRUMB_NAME,
  UPDATE_BOOK,
  UPDATE_BOOK_MANAGEMENT_BREADCRUMB_NAME,
  UPDATE_PURCHASE,
  UPDATE_PURCHASE_BREADCRUMB_NAME,
  USER_CHANGE_PASSWORD,
  USER_MANAGEMENT_BREADCRUMB_NAME,
  USER_PUBLISHER_BREADCRUMB_NAME,
  USER_PUBLISHER_BYID,
  VERIFY,
  VERIFY_BREADCRUMB_NAME,
} from "../utils/Constants";
/**
 * checkPermission: userInfo !== undefined ?  userInfo.organizations.filter(org => org.code === "INTERNAL").length > 0  : false,
 * userInfo !== null ? userInfo.organizations.filter(it => it.isSelected).role.permissions.filter(per => per.content === "RoutePath").length > 0 || userInfo.organizations.filter(org => org.code === "INTERNAL").length > 0: false
 */

export const routes = (userInfo) => [
  //{Component : LayoutView,},
  {
    path: "/testupload",
    Component: TestUploadFile,
    routerExact: true,
  },
  {
    path: "/testpage",
    Component: TestPage,
    breadcrumbName: "TEST",
    routerExact: true,
  },
  {
    path: "/testaudiobookpage",
    Component: testAudioBookPage,
  },
  {
    path: HOME,
    Component: HomeView,
    breadcrumbName: HOME_BREADCRUMB_NAME,
    routerExact: true,
    isNav: false,
    isPrivate: true,
    checkPermission: true, //userPermission !== null ? userPermission.filter(per => per.content === HOME).length > 0 : false,
  },
  {
    path: LOGIN,
    Component: LoginView,
    breadcrumbName: LOGIN_BREADCRUMB_NAME,
    routerExact: false,
    isNav: false,
    isPrivate: false,
    checkPermission: true,
  },
  {
    path: VERIFY,
    Component: VerifyView,
    breadcrumbName: VERIFY_BREADCRUMB_NAME,
    isNav: false,
    isPrivate: false,
    checkPermission: true,
  },
  {
    path: FORGET,
    Component: ForgetView,
    breadcrumbName: FORGET_BREADCRUMB_NAME,
    routerExact: true,
    isNav: false,
    isPrivate: false,
    checkPermission: false,
    subRoute: [
      {
        path: FORGETCONFIRM,
        Component: ForgetConfirmView,
        breadcrumbName: FORGETCONFIRM_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: false,
        routerExact: true,
        checkPermission: true,
      },
      {
        path: RESETPW,
        Component: ResetPassword,
        breadcrumbName: RESETPW_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: false,
        routerExact: true,
        checkPermission: false,
      },
    ],
  },
  {
    path: ADMINISTRATIVE,
    Component: AdministrativeView,
    breadcrumbName: ADMINISTRATIVE_BREADCRUMB_NAME,
    routerExact: true,
    isNav: true,
    isPrivate: true,
    checkPermission:
      userInfo !== null
        ? userInfo.organizations !== undefined
          ? userInfo.organizations
              .filter((it) => it.isSelected)
              .filter((role) =>
                role.role.permissions.find(
                  (per) => per.code === CODE_ADMINISTRATIVE
                )
              ).length > 0
          : true
        : false,
    // userInfo !== null
    //   ? userInfo.organizations !== undefined
    //     ? userInfo.organizations.filter((org) => org.code === "INTERNAL")
    //         .length > 0
    //     : true
    //   : false, //userPermission !== null ? userPermission.filter(per => per.content === ADMINISTRATIVE).length > 0 : false,
    subRoute: [
      {
        path: ALL_USER,
        Component: UsersView,
        breadcrumbName: USER_MANAGEMENT_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        icon: userIcon,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_USER_LIST
                    )
                  ).length > 0
              : true
            : false,
        subRoute: [
          {
            path: USER_PUBLISHER_BYID,
            Component: PublisherView,
            breadcrumbName: USER_PUBLISHER_BREADCRUMB_NAME,
            isNav: false,
            isPrivate: true,
            routerExact: true,
            checkPermission: true,
          },
        ],
      },
      {
        path: ALL_ROLE,
        Component: RoleView,
        breadcrumbName: ROLE_MANAGEMENT_BREADCRUMB_NAME,
        icon: roleIcon,
        isNav: false,
        isPrivate: true,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_ROLE_LIST
                    )
                  ).length > 0
              : true
            : false,
      },
      {
        path: USER_CHANGE_PASSWORD,
        Component: ChangePW,
        breadcrumbName: PASSWORD_MANAGEMENT_BREADCRUMB_NAME,
        icon: ChangePwIcon,
        isNav: false,
        isPrivate: true,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_USER_CHANGE_PASSWORD
                    )
                  ).length > 0
              : true
            : false,
      },
    ],
  },
  {
    path: SYSTEMSETTING,
    Component: SystemView,
    breadcrumbName: SYSTEMSETTING_BREADCRUMB_NAME,
    routerExact: true,
    isNav: false,
    isPrivate: true,
    checkPermission: true,
    // userInfo !== null
    //   ? userInfo.organizations !== undefined
    //     ? userInfo.organizations.filter((org) => org.code === "INTERNAL")
    //         .length > 0
    //     : true
    //   : false,
    subRoute: [
      {
        path: ALL_ORGANIZATION,
        Component: SupplierManagementView,
        breadcrumbName: ALL_SUPPLIER_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        icon: publisherIcon,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_ORGANIZATION_LIST
                    )
                  ).length > 0
              : true
            : false,
      },
      {
        path: ALL_CATEGORY,
        Component: CategoryView,
        breadcrumbName: ALL_CATEGORY_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        icon: bkCatIcon,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_LIBRARY_CAT_LIST
                    )
                  ).length > 0
              : true
            : false, //userPermission !== null ? userPermission.filter(per => per.content === ALL_CATEGORY).length > 0 : false,
      },
      {
        path: ALL_PRODUCT_TYPE,
        Component: ProductTypeView,
        breadcrumbName: ALL_PRODUCT_TYPE_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        icon: prdTypeIcon,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_PRODUCT_TYPE_LIST
                    )
                  ).length > 0
              : true
            : false, // userPermission !== null ? userPermission.filter(per => per.content === ALL_PRODUCT_TYPE).length > 0 : false,
      },
      {
        path: ALL_LANGUAGE,
        Component: LanguageView,
        breadcrumbName: ALL_LANGUAGE_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        icon: languageIcon,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_LANGUAGE_LIST
                    )
                  ).length > 0
              : true
            : false, // userPermission !== null ? userPermission.filter(per => per.content === ALL_LANGUAGE).length > 0 : false,
      },
      {
        path: ALL_LIBRARY_ORGANIZATION,
        Component: LibraryOrgView,
        breadcrumbName: ALL_LIBRARY_ORGANIZATION_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        icon: libraryOrgIcon,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_LIBRARY_ORGANIZATION_LIST
                    )
                  ).length > 0
              : true
            : false,
      },
      {
        path: ALL_LIBRARY_USERS,
        Component: LibraryUsersView,
        breadcrumbName: ALL_LIBRARY_USERS_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        icon: libraryUserIcon,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_LIBRARY_USER_LIST
                    )
                  ).length > 0
              : true
            : false,
      },
    ],
  },
  {
    path: BOOKMANAGEMENT,
    Component: BookManagementView,
    breadcrumbName: BOOKMANAGEMENT_BREADCRUMB_NAME,
    isNav: true,
    isPrivate: true,
    checkPermission:
      userInfo !== null
        ? userInfo.organizations !== undefined
          ? userInfo.organizations
              .filter((it) => it.isSelected)
              .filter((role) =>
                role.role.permissions.find(
                  (per) => per.code === CODE_BOOKMANAGEMENT
                )
              ).length > 0
          : true
        : false, //userPermission !== null ? userPermission.filter(per => per.content === BOOKMANAGEMENT).length > 0 : false,
    subRoute: [
      {
        path: `${ALL_BOOK}?id=${
          userInfo.organizations !== undefined
            ? userInfo.organizations.find((it) => it.isSelected)?.id
            : ""
          //getCurrentOrganization() != null ? getCurrentOrganization().id : ""
        }`,
        Component: BookView,
        breadcrumbName: BOOK_MANAGEMENT_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        checkPermission: true,
        icon: bookIcon,
      },
      //region TODO Batch need open it on suep
      // {
      //     path: ALL_BATCH,
      //     Component: BatchView,
      //     breadcrumbName: BATCH_MANAGEMENT_BREADCRUMB_NAME,
      //     isNav: false,
      //     isPrivate: true,
      //     checkPermission: true,
      //     icon: bookIcon,
      //     subRoute: [
      //         {
      //             path: ADD_BATCH,
      //             Component: UploadBatchView,
      //             breadcrumbName: ADD_BATCH_MANAGEMENT_BREADCRUMB_NAME,
      //             isNav: false,
      //             isPrivate: true,
      //             checkPermission: true,
      //         },
      //         {
      //             path: UPDATE_BATCH,
      //             Component: UpdateBatchView,
      //             breadcrumbName: ADD_BATCH_MANAGEMENT_BREADCRUMB_NAME,
      //             isNav: false,
      //             isPrivate: true,
      //             checkPermission: true,
      //         },
      //         {
      //             path: BATCH_DETAIL,
      //             Component: BatchDetail,
      //             breadcrumbName: ADD_BATCH_MANAGEMENT_BREADCRUMB_NAME,
      //             isNav: false,
      //             isPrivate: true,
      //             checkPermission: true,
      //         },
      //     ],
      // },
      //endregion TODO Batch need open it on suep
    ],
  },
  {
    path: BOOK_ORGANIZATION_PATH_BYID,
    Component: BookOrganizationView,
    breadcrumbName: BOOK_MANAGEMENT_BREADCRUMB_NAME,
    isNav: false,
    isPrivate: true,
    icon: bookIcon,
    checkPermission: true,
    subRoute: [
      {
        path: ALL_BOOK,
        Component: BookView,
        breadcrumbName: "",
        isNav: false,
        isPrivate: true,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_BOOK_LIST
                    )
                  ).length > 0
              : true
            : false,
      },
      {
        path: ADD_BOOK,
        Component: NewBookView,
        breadcrumbName: ADD_BOOK_MANAGEMENT_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_BOOK_ADD
                    )
                  ).length > 0
              : true
            : false,
      },
      {
        path: UPDATE_BOOK,
        Component: UpdateBookView,
        breadcrumbName: UPDATE_BOOK_MANAGEMENT_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_BOOK_UPDATE
                    )
                  ).length > 0
              : true
            : false,
      },
    ],
  },
  // #region Purchase
  {
    path: PURCHASE,
    Component: PurchaseView,
    breadcrumbName: PURCHASE_BREADCRUMB_NAME,
    routerExact: true,
    isNav: true,
    isPrivate: true,
    checkPermission:
      userInfo !== null
        ? userInfo.organizations !== undefined
          ? userInfo.organizations
              .filter((it) => it.isSelected)
              .filter((role) =>
                role.role.permissions.find((per) => per.code === CODE_PURCHASE)
              ).length > 0
          : true
        : false,
    subRoute: [
      {
        path: ALL_PURCHASE,
        Component: PurchaseManagementView,
        breadcrumbName: PURCHASE_MANAGEMENT_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        icon: pBookListIcon,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_PURCHASE_BOOK_LIST
                    )
                  ).length > 0
              : true
            : false,
      },
      {
        path: PURCHASERECORD,
        Component: PurchaseRecordView,
        breadcrumbName: PURCHASE_RECORD_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        icon: pMissionIcon,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_PURCHASE_MISSION_LIST
                    )
                  ).length > 0
              : true
            : false,
        subRoute: [
          {
            path: ADD_PURCHASE,
            Component: AddPurchaseView,
            breadcrumbName: ADD_PURCHASE_BREADCRUMB_NAME,
            isNav: false,
            isPrivate: true,
            routerExact: true,
            checkPermission: true,
          },
          {
            path: UPDATE_PURCHASE,
            Component: UpdatePurchaseView,
            breadcrumbName: UPDATE_PURCHASE_BREADCRUMB_NAME,
            isNav: false,
            isPrivate: true,
            routerExact: true,
            checkPermission: true,
          },

          {
            path: PURCHASE_LIST_PATH,
            Component: PurchaseListView,
            breadcrumbName: PURCHASE_LIST_BREADCRUMB_NAME,
            isNav: false,
            isPrivate: true,
            checkPermission: true,
            subRoute: [
              {
                path: PURCHASE_LIST_PATH_BYID,
                Component: PurchaseListDetails,
                breadcrumbName: PURCHASE_LIST_BREADCRUMB_NAME,
                isNav: false,
                isPrivate: true,
                checkPermission: true,
              },
            ],
          },
        ],
      },
      {
        path: PURCHASE_SELING_BOOKLIST,
        Component: SelingBookList,
        breadcrumbName: PURCHASE_LIST_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        icon: pBuyingListIcon,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations
                  .filter((it) => it.isSelected)
                  .filter((role) =>
                    role.role.permissions.find(
                      (per) => per.code === CODE_PURCHASE_BOOKLIST_LIST
                    )
                  ).length > 0
              : true
            : false,
      },
      {
        path: PURCHASE_CLIENT,
        Component: PurchaseClientView,
        breadcrumbName: PURCHASE_CLIENT_BREADCRUMB_NAME,
        isNav: false,
        isPrivate: true,
        icon: pBookPurchaseIcon,
        //true,
        checkPermission:
          userInfo !== null
            ? userInfo.organizations !== undefined
              ? userInfo.organizations.filter((org) => org.code === "LIBRARY")
                  .length > 0
              : true
            : false,
        subRoute: [
          {
            path: PURCHASELIST_CLIENT_BYID,
            Component: PurchaseClientBookListView,
            breadcrumbName: PURCHASE_CLIENT_BOOKLIST_BREADCRUMB_NAME,
            isNav: false,
            isPrivate: true,
            checkPermission: true,
            subRoute: [
              {
                path: PURCHASEDETAIL_CLIENT_BYID,
                Component: PurchaseClientBookDetailsView,
                breadcrumbName: PURCHASE_CLIENT_BOOKLIST_BREADCRUMB_NAME,
                isNav: false,
                isPrivate: true,
                checkPermission: true,
              },
              {
                path: PURCHASEDETAIL_BYID,
                Component: PurchaseDetailView,
                breadcrumbName: PURCHASE_RECORD_BREADCRUMB_NAME,
                isNav: false,
                isPrivate: true,
                checkPermission: true,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: BOOKLIST,
    Component: BookListView,
    breadcrumbName: PURCHASE_BOOKLIST_BREADCRUMB_NAME,
    isNav: false,
    isPrivate: true,
    icon: bookIcon,
    checkPermission: true,
  },
  {
    path: ALL_PURCHASE_DETAILS,
    Component: BookCategoryView,
    breadcrumbName: PURCHASE_MANAGEMENT_BREADCRUMB_NAME,
    isNav: false,
    isPrivate: true,
    icon: bookIcon,
    checkPermission: true,
  },
  // #endregion
  {
    path: "/epub",
    Component: EpubReader,
    checkPermission: true,
  },
  {
    path: "/pdf",
    Component: PdfReader,
    checkPermission: true,
  },
];
