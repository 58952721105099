/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import NewRoleOrgWithRoleAndUserPayloadModel from './NewRoleOrgWithRoleAndUserPayloadModel';
import OrgWithRoleAndUserPayloadModel from './OrgWithRoleAndUserPayloadModel';

/**
 * The UpdateUserListOrgAndRolePayloadModel model module.
 * @module model/UpdateUserListOrgAndRolePayloadModel
 * @version v1.0.0
 */
class UpdateUserListOrgAndRolePayloadModel {
    /**
     * Constructs a new <code>UpdateUserListOrgAndRolePayloadModel</code>.
     * @alias module:model/UpdateUserListOrgAndRolePayloadModel
     * @param assignUserList {Array.<module:model/OrgWithRoleAndUserPayloadModel>} assign User List  (required)
     * @param unAssignUserList {Array.<module:model/OrgWithRoleAndUserPayloadModel>} unassign User List  (required)
     * @param updateAssignUserList {Array.<module:model/NewRoleOrgWithRoleAndUserPayloadModel>} update Assign User List  (required)
     */
    constructor(assignUserList, unAssignUserList, updateAssignUserList) { 
        
        UpdateUserListOrgAndRolePayloadModel.initialize(this, assignUserList, unAssignUserList, updateAssignUserList);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, assignUserList, unAssignUserList, updateAssignUserList) { 
        obj['assignUserList'] = assignUserList;
        obj['unAssignUserList'] = unAssignUserList;
        obj['updateAssignUserList'] = updateAssignUserList;
    }

    /**
     * Constructs a <code>UpdateUserListOrgAndRolePayloadModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UpdateUserListOrgAndRolePayloadModel} obj Optional instance to populate.
     * @return {module:model/UpdateUserListOrgAndRolePayloadModel} The populated <code>UpdateUserListOrgAndRolePayloadModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UpdateUserListOrgAndRolePayloadModel();

            if (data.hasOwnProperty('assignUserList')) {
                obj['assignUserList'] = ApiClient.convertToType(data['assignUserList'], [OrgWithRoleAndUserPayloadModel]);
            }
            if (data.hasOwnProperty('unAssignUserList')) {
                obj['unAssignUserList'] = ApiClient.convertToType(data['unAssignUserList'], [OrgWithRoleAndUserPayloadModel]);
            }
            if (data.hasOwnProperty('updateAssignUserList')) {
                obj['updateAssignUserList'] = ApiClient.convertToType(data['updateAssignUserList'], [NewRoleOrgWithRoleAndUserPayloadModel]);
            }
        }
        return obj;
    }


}

/**
 * assign User List  (required)
 * @member {Array.<module:model/OrgWithRoleAndUserPayloadModel>} assignUserList
 */
UpdateUserListOrgAndRolePayloadModel.prototype['assignUserList'] = undefined;

/**
 * unassign User List  (required)
 * @member {Array.<module:model/OrgWithRoleAndUserPayloadModel>} unAssignUserList
 */
UpdateUserListOrgAndRolePayloadModel.prototype['unAssignUserList'] = undefined;

/**
 * update Assign User List  (required)
 * @member {Array.<module:model/NewRoleOrgWithRoleAndUserPayloadModel>} updateAssignUserList
 */
UpdateUserListOrgAndRolePayloadModel.prototype['updateAssignUserList'] = undefined;






export default UpdateUserListOrgAndRolePayloadModel;

