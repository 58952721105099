/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BookListIsCheckedWithPaginationDto from './BookListIsCheckedWithPaginationDto';
import CustomerIsCheckedDto from './CustomerIsCheckedDto';
import PurchaseMissionDto from './PurchaseMissionDto';

/**
 * The PurchaseMissionInfoList model module.
 * @module model/PurchaseMissionInfoList
 * @version v1.0.0
 */
class PurchaseMissionInfoList {
    /**
     * Constructs a new <code>PurchaseMissionInfoList</code>.
     * @alias module:model/PurchaseMissionInfoList
     */
    constructor() { 
        
        PurchaseMissionInfoList.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PurchaseMissionInfoList</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PurchaseMissionInfoList} obj Optional instance to populate.
     * @return {module:model/PurchaseMissionInfoList} The populated <code>PurchaseMissionInfoList</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PurchaseMissionInfoList();

            if (data.hasOwnProperty('bookList')) {
                obj['bookList'] = BookListIsCheckedWithPaginationDto.constructFromObject(data['bookList']);
            }
            if (data.hasOwnProperty('customerList')) {
                obj['customerList'] = ApiClient.convertToType(data['customerList'], [CustomerIsCheckedDto]);
            }
            if (data.hasOwnProperty('purchaseMission')) {
                obj['purchaseMission'] = PurchaseMissionDto.constructFromObject(data['purchaseMission']);
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/BookListIsCheckedWithPaginationDto} bookList
 */
PurchaseMissionInfoList.prototype['bookList'] = undefined;

/**
 * @member {Array.<module:model/CustomerIsCheckedDto>} customerList
 */
PurchaseMissionInfoList.prototype['customerList'] = undefined;

/**
 * @member {module:model/PurchaseMissionDto} purchaseMission
 */
PurchaseMissionInfoList.prototype['purchaseMission'] = undefined;






export default PurchaseMissionInfoList;

