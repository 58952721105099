/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The RoleWithPermissionPayloadModel model module.
 * @module model/RoleWithPermissionPayloadModel
 * @version v1.0.0
 */
class RoleWithPermissionPayloadModel {
    /**
     * Constructs a new <code>RoleWithPermissionPayloadModel</code>.
     * @alias module:model/RoleWithPermissionPayloadModel
     * @param active {Boolean} active status (required)
     * @param description {String} description (required)
     * @param name {String} name (required)
     * @param permissionIds {Array.<Number>} permission array
     * @param priority {Number} priority (required)
     */
    constructor(active, description, name, permissionIds, priority) { 
        
        RoleWithPermissionPayloadModel.initialize(this, active, description, name, permissionIds, priority);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, active, description, name, permissionIds, priority) { 
        obj['active'] = active;
        obj['description'] = description;
        obj['name'] = name;
        obj['permissionIds'] = permissionIds;
        obj['priority'] = priority;
    }

    /**
     * Constructs a <code>RoleWithPermissionPayloadModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RoleWithPermissionPayloadModel} obj Optional instance to populate.
     * @return {module:model/RoleWithPermissionPayloadModel} The populated <code>RoleWithPermissionPayloadModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RoleWithPermissionPayloadModel();

            if (data.hasOwnProperty('active')) {
                obj['active'] = ApiClient.convertToType(data['active'], 'Boolean');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('permissionIds')) {
                obj['permissionIds'] = ApiClient.convertToType(data['permissionIds'], ['Number']);
            }
            if (data.hasOwnProperty('priority')) {
                obj['priority'] = ApiClient.convertToType(data['priority'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * active status (required)
 * @member {Boolean} active
 */
RoleWithPermissionPayloadModel.prototype['active'] = undefined;

/**
 * description (required)
 * @member {String} description
 */
RoleWithPermissionPayloadModel.prototype['description'] = undefined;

/**
 * name (required)
 * @member {String} name
 */
RoleWithPermissionPayloadModel.prototype['name'] = undefined;

/**
 * permission array
 * @member {Array.<Number>} permissionIds
 */
RoleWithPermissionPayloadModel.prototype['permissionIds'] = undefined;

/**
 * priority (required)
 * @member {Number} priority
 */
RoleWithPermissionPayloadModel.prototype['priority'] = undefined;






export default RoleWithPermissionPayloadModel;

