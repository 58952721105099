/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AuthResponseModel from '../model/AuthResponseModel';
import CommonResponseModel from '../model/CommonResponseModel';

/**
* Auth service.
* @module api/AuthApi
* @version v1.0.0
*/
export default class AuthApi {

    /**
    * Constructs a new AuthApi. 
    * @alias module:api/AuthApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the authLoginByIpUsingPOST operation.
     * @callback module:api/AuthApi~authLoginByIpUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Login By IP
     * @param {String} ip ip
     * @param {module:api/AuthApi~authLoginByIpUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    authLoginByIpUsingPOST(ip, callback) {
      let postBody = null;
      // verify the required parameter 'ip' is set
      if (ip === undefined || ip === null) {
        throw new Error("Missing the required parameter 'ip' when calling authLoginByIpUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
        'ip': ip
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/api/auth/loginByIp', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the authLoginUsingPOST operation.
     * @callback module:api/AuthApi~authLoginUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AuthResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Login
     * @param {String} email email
     * @param {String} password password
     * @param {module:api/AuthApi~authLoginUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AuthResponseModel}
     */
    authLoginUsingPOST(email, password, callback) {
      let postBody = null;
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling authLoginUsingPOST");
      }
      // verify the required parameter 'password' is set
      if (password === undefined || password === null) {
        throw new Error("Missing the required parameter 'password' when calling authLoginUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
        'email': email,
        'password': password
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = AuthResponseModel;
      return this.apiClient.callApi(
        '/api/auth/login', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the authLogoutUsingPOST operation.
     * @callback module:api/AuthApi~authLogoutUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Logout
     * @param {module:api/AuthApi~authLogoutUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    authLogoutUsingPOST(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/auth/logout', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the authVerifyUsingPOST operation.
     * @callback module:api/AuthApi~authVerifyUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/AuthResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * verify
     * @param {String} code verify code (required)
     * @param {String} email user email (required)
     * @param {String} userId user id (required)
     * @param {module:api/AuthApi~authVerifyUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/AuthResponseModel}
     */
    authVerifyUsingPOST(code, email, userId, callback) {
      let postBody = null;
      // verify the required parameter 'code' is set
      if (code === undefined || code === null) {
        throw new Error("Missing the required parameter 'code' when calling authVerifyUsingPOST");
      }
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling authVerifyUsingPOST");
      }
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling authVerifyUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
        'code': code,
        'email': email,
        'userId': userId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = AuthResponseModel;
      return this.apiClient.callApi(
        '/api/auth/verify', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the changePasswordWithResetPasswordTokenUsingPOST operation.
     * @callback module:api/AuthApi~changePasswordWithResetPasswordTokenUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Change Password with Reset Password Token (no need fill in oldPassword)
     * @param {String} newPassword new password (required)
     * @param {String} token reset password token (required)
     * @param {module:api/AuthApi~changePasswordWithResetPasswordTokenUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    changePasswordWithResetPasswordTokenUsingPOST(newPassword, token, callback) {
      let postBody = null;
      // verify the required parameter 'newPassword' is set
      if (newPassword === undefined || newPassword === null) {
        throw new Error("Missing the required parameter 'newPassword' when calling changePasswordWithResetPasswordTokenUsingPOST");
      }
      // verify the required parameter 'token' is set
      if (token === undefined || token === null) {
        throw new Error("Missing the required parameter 'token' when calling changePasswordWithResetPasswordTokenUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
        'newPassword': newPassword,
        'token': token
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/api/auth/reset-password/password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the reSendVerifyCodeUsingPOST operation.
     * @callback module:api/AuthApi~reSendVerifyCodeUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * re-send verify code
     * @param {String} email user email (required)
     * @param {module:api/AuthApi~reSendVerifyCodeUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    reSendVerifyCodeUsingPOST(email, callback) {
      let postBody = null;
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling reSendVerifyCodeUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
        'email': email
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/api/auth/reSendVerifyCode', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the refreshTokenUsingGET operation.
     * @callback module:api/AuthApi~refreshTokenUsingGETCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Refresh Token
     * @param {module:api/AuthApi~refreshTokenUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     */
    refreshTokenUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/auth/token/refresh', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the requestResetPasswordEmailUsingPOST operation.
     * @callback module:api/AuthApi~requestResetPasswordEmailUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Request Reset Password Email
     * @param {String} email user email (required)
     * @param {module:api/AuthApi~requestResetPasswordEmailUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    requestResetPasswordEmailUsingPOST(email, callback) {
      let postBody = null;
      // verify the required parameter 'email' is set
      if (email === undefined || email === null) {
        throw new Error("Missing the required parameter 'email' when calling requestResetPasswordEmailUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
        'email': email
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/auth/reset-password/email', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
