/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import CommonResponseModel from '../model/CommonResponseModel';
import SearchingQueryResponseModelWhiteListIpDto from '../model/SearchingQueryResponseModelWhiteListIpDto';

/**
* WhiteListIp service.
* @module api/WhiteListIpApi
* @version v1.0.0
*/
export default class WhiteListIpApi {

    /**
    * Constructs a new WhiteListIpApi. 
    * @alias module:api/WhiteListIpApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addWhiteListIpUsingPOST operation.
     * @callback module:api/WhiteListIpApi~addWhiteListIpUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add White List Ip
     * @param {String} ipAddress ip (required)
     * @param {String} userId user id (required)
     * @param {module:api/WhiteListIpApi~addWhiteListIpUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    addWhiteListIpUsingPOST(ipAddress, userId, callback) {
      let postBody = null;
      // verify the required parameter 'ipAddress' is set
      if (ipAddress === undefined || ipAddress === null) {
        throw new Error("Missing the required parameter 'ipAddress' when calling addWhiteListIpUsingPOST");
      }
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling addWhiteListIpUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
        'ipAddress': ipAddress,
        'userId': userId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/white-list-ip/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getWhiteListIpsUsingGET operation.
     * @callback module:api/WhiteListIpApi~getWhiteListIpsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelWhiteListIpDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get White List Ips
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/WhiteListIpApi~getWhiteListIpsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelWhiteListIpDto}
     */
    getWhiteListIpsUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelWhiteListIpDto;
      return this.apiClient.callApi(
        '/api/white-list-ip/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the removeWhiteListIpUsingDELETE operation.
     * @callback module:api/WhiteListIpApi~removeWhiteListIpUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete White List Ip
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/CommonDeletePayloadModel} opts.commonDeletePayloadModel 
     * @param {module:api/WhiteListIpApi~removeWhiteListIpUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    removeWhiteListIpUsingDELETE(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling removeWhiteListIpUsingDELETE");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/white-list-ip/delete/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
