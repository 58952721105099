/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BatchMissionBookItemDto from './BatchMissionBookItemDto';

/**
 * The BatchMissionWithBookDto model module.
 * @module model/BatchMissionWithBookDto
 * @version v1.0.0
 */
class BatchMissionWithBookDto {
    /**
     * Constructs a new <code>BatchMissionWithBookDto</code>.
     * @alias module:model/BatchMissionWithBookDto
     */
    constructor() { 
        
        BatchMissionWithBookDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BatchMissionWithBookDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BatchMissionWithBookDto} obj Optional instance to populate.
     * @return {module:model/BatchMissionWithBookDto} The populated <code>BatchMissionWithBookDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BatchMissionWithBookDto();

            if (data.hasOwnProperty('bookCount')) {
                obj['bookCount'] = ApiClient.convertToType(data['bookCount'], 'Number');
            }
            if (data.hasOwnProperty('books')) {
                obj['books'] = ApiClient.convertToType(data['books'], [BatchMissionBookItemDto]);
            }
            if (data.hasOwnProperty('createAt')) {
                obj['createAt'] = ApiClient.convertToType(data['createAt'], 'Number');
            }
            if (data.hasOwnProperty('cronJobTime')) {
                obj['cronJobTime'] = ApiClient.convertToType(data['cronJobTime'], 'Number');
            }
            if (data.hasOwnProperty('failsList')) {
                obj['failsList'] = ApiClient.convertToType(data['failsList'], ['String']);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('sftpPath')) {
                obj['sftpPath'] = ApiClient.convertToType(data['sftpPath'], 'String');
            }
            if (data.hasOwnProperty('sftpPort')) {
                obj['sftpPort'] = ApiClient.convertToType(data['sftpPort'], 'Number');
            }
            if (data.hasOwnProperty('sftpProtocol')) {
                obj['sftpProtocol'] = ApiClient.convertToType(data['sftpProtocol'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} bookCount
 */
BatchMissionWithBookDto.prototype['bookCount'] = undefined;

/**
 * @member {Array.<module:model/BatchMissionBookItemDto>} books
 */
BatchMissionWithBookDto.prototype['books'] = undefined;

/**
 * @member {Number} createAt
 */
BatchMissionWithBookDto.prototype['createAt'] = undefined;

/**
 * @member {Number} cronJobTime
 */
BatchMissionWithBookDto.prototype['cronJobTime'] = undefined;

/**
 * @member {Array.<String>} failsList
 */
BatchMissionWithBookDto.prototype['failsList'] = undefined;

/**
 * @member {Number} id
 */
BatchMissionWithBookDto.prototype['id'] = undefined;

/**
 * @member {String} name
 */
BatchMissionWithBookDto.prototype['name'] = undefined;

/**
 * @member {String} sftpPath
 */
BatchMissionWithBookDto.prototype['sftpPath'] = undefined;

/**
 * @member {Number} sftpPort
 */
BatchMissionWithBookDto.prototype['sftpPort'] = undefined;

/**
 * @member {String} sftpProtocol
 */
BatchMissionWithBookDto.prototype['sftpProtocol'] = undefined;

/**
 * @member {module:model/BatchMissionWithBookDto.StatusEnum} status
 */
BatchMissionWithBookDto.prototype['status'] = undefined;





/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
BatchMissionWithBookDto['StatusEnum'] = {

    /**
     * value: "confirmXls"
     * @const
     */
    "confirmXls": "confirmXls",

    /**
     * value: "finish"
     * @const
     */
    "finish": "finish",

    /**
     * value: "pending"
     * @const
     */
    "pending": "pending",

    /**
     * value: "pendingBatch"
     * @const
     */
    "pendingBatch": "pendingBatch",

    /**
     * value: "processBatchFile"
     * @const
     */
    "processBatchFile": "processBatchFile"
};



export default BatchMissionWithBookDto;

