/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BookFileDto from './BookFileDto';
import BookSelectedFileDto from './BookSelectedFileDto';
import CategoryDto from './CategoryDto';
import LanguageDto from './LanguageDto';
import OrganizationObjectDto from './OrganizationObjectDto';

/**
 * The BookBaseSimpleDto model module.
 * @module model/BookBaseSimpleDto
 * @version v1.0.0
 */
class BookBaseSimpleDto {
    /**
     * Constructs a new <code>BookBaseSimpleDto</code>.
     * @alias module:model/BookBaseSimpleDto
     */
    constructor() { 
        
        BookBaseSimpleDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BookBaseSimpleDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BookBaseSimpleDto} obj Optional instance to populate.
     * @return {module:model/BookBaseSimpleDto} The populated <code>BookBaseSimpleDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BookBaseSimpleDto();

            if (data.hasOwnProperty('author')) {
                obj['author'] = ApiClient.convertToType(data['author'], 'String');
            }
            if (data.hasOwnProperty('bookIsbn')) {
                obj['bookIsbn'] = ApiClient.convertToType(data['bookIsbn'], 'String');
            }
            if (data.hasOwnProperty('categories')) {
                obj['categories'] = ApiClient.convertToType(data['categories'], [CategoryDto]);
            }
            if (data.hasOwnProperty('ctsId')) {
                obj['ctsId'] = ApiClient.convertToType(data['ctsId'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('inventory')) {
                obj['inventory'] = ApiClient.convertToType(data['inventory'], 'Number');
            }
            if (data.hasOwnProperty('languages')) {
                obj['languages'] = ApiClient.convertToType(data['languages'], [LanguageDto]);
            }
            if (data.hasOwnProperty('mainBookId')) {
                obj['mainBookId'] = ApiClient.convertToType(data['mainBookId'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('onSaleDate')) {
                obj['onSaleDate'] = ApiClient.convertToType(data['onSaleDate'], 'Number');
            }
            if (data.hasOwnProperty('organization')) {
                obj['organization'] = OrganizationObjectDto.constructFromObject(data['organization']);
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('publishDate')) {
                obj['publishDate'] = ApiClient.convertToType(data['publishDate'], 'Number');
            }
            if (data.hasOwnProperty('root')) {
                obj['root'] = ApiClient.convertToType(data['root'], 'Boolean');
            }
            if (data.hasOwnProperty('selectedFiles')) {
                obj['selectedFiles'] = BookSelectedFileDto.constructFromObject(data['selectedFiles']);
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('translator')) {
                obj['translator'] = ApiClient.convertToType(data['translator'], 'String');
            }
            if (data.hasOwnProperty('uploadDate')) {
                obj['uploadDate'] = ApiClient.convertToType(data['uploadDate'], 'Number');
            }
            if (data.hasOwnProperty('uploadedFiles')) {
                obj['uploadedFiles'] = BookFileDto.constructFromObject(data['uploadedFiles']);
            }
            if (data.hasOwnProperty('version')) {
                obj['version'] = ApiClient.convertToType(data['version'], 'String');
            }
        }
        return obj;
    }


}

/**
 * 作者
 * @member {String} author
 */
BookBaseSimpleDto.prototype['author'] = undefined;

/**
 * ISBN/產品編號
 * @member {String} bookIsbn
 */
BookBaseSimpleDto.prototype['bookIsbn'] = undefined;

/**
 * 分類
 * @member {Array.<module:model/CategoryDto>} categories
 */
BookBaseSimpleDto.prototype['categories'] = undefined;

/**
 * CTS編號
 * @member {String} ctsId
 */
BookBaseSimpleDto.prototype['ctsId'] = undefined;

/**
 * 產品簡介
 * @member {String} description
 */
BookBaseSimpleDto.prototype['description'] = undefined;

/**
 * book id
 * @member {String} id
 */
BookBaseSimpleDto.prototype['id'] = undefined;

/**
 * inventory
 * @member {Number} inventory
 */
BookBaseSimpleDto.prototype['inventory'] = undefined;

/**
 * 語言
 * @member {Array.<module:model/LanguageDto>} languages
 */
BookBaseSimpleDto.prototype['languages'] = undefined;

/**
 * mainBookId
 * @member {String} mainBookId
 */
BookBaseSimpleDto.prototype['mainBookId'] = undefined;

/**
 * 產品名稱
 * @member {String} name
 */
BookBaseSimpleDto.prototype['name'] = undefined;

/**
 * 上架日期
 * @member {Number} onSaleDate
 */
BookBaseSimpleDto.prototype['onSaleDate'] = undefined;

/**
 * @member {module:model/OrganizationObjectDto} organization
 */
BookBaseSimpleDto.prototype['organization'] = undefined;

/**
 * 售價
 * @member {Number} price
 */
BookBaseSimpleDto.prototype['price'] = undefined;

/**
 * 紙本書出版日期
 * @member {Number} publishDate
 */
BookBaseSimpleDto.prototype['publishDate'] = undefined;

/**
 * @member {Boolean} root
 */
BookBaseSimpleDto.prototype['root'] = undefined;

/**
 * @member {module:model/BookSelectedFileDto} selectedFiles
 */
BookBaseSimpleDto.prototype['selectedFiles'] = undefined;

/**
 * 狀態
 * @member {module:model/BookBaseSimpleDto.StatusEnum} status
 */
BookBaseSimpleDto.prototype['status'] = undefined;

/**
 * 譯者
 * @member {String} translator
 */
BookBaseSimpleDto.prototype['translator'] = undefined;

/**
 * update date
 * @member {Number} uploadDate
 */
BookBaseSimpleDto.prototype['uploadDate'] = undefined;

/**
 * @member {module:model/BookFileDto} uploadedFiles
 */
BookBaseSimpleDto.prototype['uploadedFiles'] = undefined;

/**
 * version
 * @member {String} version
 */
BookBaseSimpleDto.prototype['version'] = undefined;





/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
BookBaseSimpleDto['StatusEnum'] = {

    /**
     * value: "APPLY_UNAVAILABLE"
     * @const
     */
    "APPLY_UNAVAILABLE": "APPLY_UNAVAILABLE",

    /**
     * value: "APPROVED"
     * @const
     */
    "APPROVED": "APPROVED",

    /**
     * value: "AVAILABLE"
     * @const
     */
    "AVAILABLE": "AVAILABLE",

    /**
     * value: "REJECTED"
     * @const
     */
    "REJECTED": "REJECTED",

    /**
     * value: "SUBMITTED"
     * @const
     */
    "SUBMITTED": "SUBMITTED",

    /**
     * value: "TEMP"
     * @const
     */
    "TEMP": "TEMP",

    /**
     * value: "UNAVAILABLE"
     * @const
     */
    "UNAVAILABLE": "UNAVAILABLE"
};



export default BookBaseSimpleDto;

