import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import {
  STYLETYPE_DEFAULT,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_THIRD,
} from "../../utils/Constants";
import "./SearchFilter.css";

class SearchFilter extends React.Component {
  state = {
    originData: [],
    data: [],
    childList: [],
    flagOpenMenu: false,
    multiple: false,
    inputTextValue: "",
    // checkbox:false
  };

  static propTypes = {
    styleType: PropTypes.string,
    callBack: PropTypes.func,
    childList: PropTypes.array,
    data: PropTypes.array,
    flagOpenMenu: PropTypes.bool,
    multiple: PropTypes.bool,
    // checkbox:PropTypes.bool
  };

  static defaultProps = {
    originData: [],
    data: [],
    childList: [],
    flagOpenMenu: false,
    multiple: false,
    inputTextValue: "",
    // checkbox:false
  };

  initData = (obj, level) => {
    obj.forEach((_obj, index) => {
      _obj.showed = false;
      _obj.opened = false;
      _obj.isSelected = false;
    });
    return obj;
  };

  // genRoot = (obj)=>{
  //   let rootList = []
  //   let childList = []

  //   obj.forEach((mainObj, index) => {
  //     if (mainObj.id === mainObj.mainId) {
  //       mainObj.showed = true;
  //       rootList.push(mainObj);
  //     }
  //   });

  //   const renderObj =() =>{

  //   }
  //   return  React.createElement("div", null, childList);

  // }

  getRoot = (obj, level) => {
    // console.log("getRoot", obj);
    this.childList = [];
    let rootList = [];

    obj.forEach((mainObj, index) => {
      if (mainObj.id === mainObj.mainId) {
        mainObj.showed = true;
        rootList.push(mainObj);
      }
    });
    // console.log("getRoot rootList", rootList);
    return rootList;
  };

  genHTML = (obj, level, isMultiple) => {
    // console.log("level", level, obj instanceof Array);
    //var childernList = [];

    if (obj instanceof Array) {
      // console.log("genHTML AAA", obj);
      obj.forEach((mainObj, index) => {
        // //get main
        //console.log("m", mainObj.id);
        //this.childList.push(this.getHTML(mainObj, level, isMultiple));
        this.childList.push(this.getHTML(mainObj, level, isMultiple));
        // console.log('childList',this.childList)

        //find child
        if (mainObj.subIds.length > 0) {
          mainObj.subIds.map((subObj) => {
            // console.log("s1", subObj);
            var childObj = this.state.data.find((orObj) => orObj.id === subObj);

            // console.log("dkhagdhjsagdhjsag", childObj);

            if (childObj && childObj.subIds && childObj.subIds.length > 0) {
              this.genHTML(childObj, level + 1, isMultiple);
            }
          });
        }
        //childernList.push(this.childList);
      });
    } else {
      // console.log("genHTML BBB", obj);
      //console.log("a", obj.id);
      //this.childList.push(this.getHTML(obj, level - 1, isMultiple));
      this.childList.push(this.getHTML(obj, level, isMultiple));

      if (obj.subIds.length > 0) {
        obj.subIds.map((subObj) => {
          // console.log("s2", subObj);
          var childObj = this.state.data.find((orObj) => orObj.id === subObj);

          this.childList.push(this.getHTML(childObj, level + 1, isMultiple));

          if (childObj && childObj.subIds.length > 0) {
            this.genHTML(childObj, level + 1, isMultiple);
          }
        });
      }
    }

    //console.log(this.childList);
    return React.createElement("div", null, this.childList);
    //console.log(childernList);
    //console.log(childList);
  };

  onChange = (childObj) => {
    console.log("onChange", childObj);
    let originData = this.state.originData;
    let selctedIdArr = originData.find((item) => item.id === childObj.id);
    console.log("selctedIdArr", selctedIdArr);
    let finalSelectedArr = new Set();
    finalSelectedArr.add(selctedIdArr.id);

    function findAllRelatedid(idArr) {
      let finalArr = [idArr];

      let myObj = originData.filter((k) => k.id == idArr);
      console.log("myObj", myObj);
      if (myObj[0].subIds.length >= 1) {
        for (let v of myObj[0].subIds) {
          finalArr.push(...findAllRelatedid(v));
        }
      } else {
        console.log("finalArr", finalArr);
        return finalArr;
      }
      return finalArr;
    }
    // for(let v of selctedIdArr.subIds){
    let result = findAllRelatedid(selctedIdArr.id);
    // console.log('result',result)
    result.forEach((k) => finalSelectedArr.add(k));

    // }

    console.log(finalSelectedArr);
    let finalSelectedArrayRaw = Array.from(finalSelectedArr);

    let dddd = this.state.originData.map((item) => {
      // console.log('dddd',finalSelectedArrayRaw)
      if (finalSelectedArrayRaw.indexOf(item.id) >= 0) {
        console.log(111, "inside");
        item.isSelected = !item.isSelected;
        return item;
      } else {
        return item;
      }
    });

    // this.props.callBack(dddd);
    this.setState({
      data: dddd,
    });
  };
  showSubMenu = (childObj, level) => {
    console.log("showSubMenu", childObj, level);
    this.setState({
      originData: this.state.originData.map((item) =>
        item.id === childObj.id
          ? { ...item, isSelected: !item.isSelected }
          : item
      ),
    });
    // console.log('this.state',this.state.originData)
    // this.props.callBack(this.state.originData);
  };
  getHTML = (childObj, level, isMultiple) => {
    // console.log("getHTML", childObj, level, isMultiple);
    //console.log("getHTML onChange", this.onChange);
    let _hasChild = false;

    if (!childObj) {
      return;
    }

    if (childObj.subIds.length > 0) {
      _hasChild = true;
    }
    //console.log("getHTML _hasChild", _hasChild);

    //=====

    let _classList = [];
    _classList.push("result_item");
    _classList.push("sf_result_item");
    if (_hasChild) {
      _classList.push("hasChild");
    }
    if (childObj.showed) {
    } else {
      if (childObj.opened) {
      } else {
        _classList.push("hidden");
      }
    }
    let _classListStr = _classList.join(" ");

    //=====

    let _eb_classList = [];
    _eb_classList.push("expendBtn");
    if (childObj.opened) {
      _eb_classList.push("open");
    }
    let _eb_classListStr = _eb_classList.join(" ");

    //=====

    return (
      <div
        className={_classListStr}
        id={childObj.id}
        mainid={childObj.mainId}
        level={level}
        style={{
          paddingLeft: 16 + level * 20 + "px",
        }}
      >
        <div className="result_item_content" key={childObj.id}>
          {isMultiple ? (
            <Input
              type="checkbox"
              showType={STYLETYPE_DEFAULT}
              checked={childObj.isSelected}
              callBack={() => this.onChange(childObj)}
            />
          ) : (
            <></>
          )}
          <div>
            <label
              value={childObj.id}
              onClick={(value) => this.showSubMenu(childObj, 0)}
              title={childObj.name}
              // callBack={() => this.onChange(childObj)}
            >
              {childObj.name}
              {/* - {childObj.id} - {childObj.mainId} - {level}*/}
            </label>
          </div>
          {_hasChild && (
            <div
              className={_eb_classListStr}
              key={childObj.id}
              mainid={childObj.id}
              onClick={(event) => this.handleExpendBtn(event)}
            ></div>
          )}
        </div>
      </div>
    );
  };

  componentDidMount() {
    // console.log("componentDidMount");

    let _data = this.initData(this.props.data);
    let _multiple = this.props.multiple;
    this.setState({
      originData: _data,
      data: _data,
      multiple: _multiple,
    });
  }

  componentWillReceiveProps(nextProps) {}

  handleSelectOnChange = (event) => {};

  handleSearchBtnClick = (event) => {
    console.log("event");
    let _flagOpenMenu = !this.state.flagOpenMenu;

    this.setState({
      flagOpenMenu: _flagOpenMenu,
    });
  };

  handleExpendBtn = (event) => {
    let _mainId = parseInt(event.target.getAttribute("mainid"));
    // console.log(_mainId, event.target);

    //$(".result_item[mainid=" + _mainId + "]").removeClass("hidden");

    let _roots = this.getRoot(this.state.data);
    let _rootObj = _roots.find((_orObj) => _orObj.id === _mainId);
    let _targetObj = this.state.data.find((_orObj) => _orObj.id === _mainId);
    // console.log("_targetObj", _targetObj);
    if (_targetObj) {
      _targetObj.opened = !_targetObj.opened;
    }

    let _parentObj = this.state.data.find((_orObj) => _orObj.id === _mainId);
    let _data = this.state.data;
    _data.map((_obj) => {
      if (_obj.id === _obj.mainId) {
        // 自己 自己
        // console.log("AAA", _obj.id); //, _rootObj);
      } else if (_mainId === _obj.mainId) {
        // console.log("BBB", _obj.id);
        _obj.showed = _parentObj.opened;
      } else {
        //console.log("CCC", _obj.id, _obj);
      }
    });
    //console.log(_data);//, JSON.stringify(_data) );
    this.setState(
      {
        data: _data,
      },
      () => {
        // console.log(this.state.data);
      }
    );
  };

  handleInputText = (str) => {
    console.log(this.state.data);
    console.log(str);

    // let filtedData = this.state.originData.filter((v) =>
    //   v.name.toLowerCase().includes(str.toLowerCase())
    // );

    let objArr = this.state.originData.filter((v) =>
      v.name.toLowerCase().includes(str.toLowerCase())
    );
    console.log("objArr", objArr);

    // Assume these two items are selected by text input filter

    // To store unique id
    let uniqueId = new Set();

    // Loop the objArr
    for (let val of objArr) {
      uniqueId.add(val.id);

      let currentSearchObjMainId = val.mainId;
      let currentId = val.id;
      // recursion loop of mainID to root object
      while (currentSearchObjMainId !== currentId) {
        let nextObj = this.state.originData.filter(
          (v) => v.id === currentSearchObjMainId
        );
        console.log("nextObj", nextObj);
        currentSearchObjMainId = nextObj[0].mainId;
        currentId = nextObj[0].id;
        uniqueId.add(nextObj[0].id);
      }
    }

    // turn set to array, then compare to original array
    const uniqueIdArr = Array.from(uniqueId);
    const finalArr = this.state.originData.filter(
      (v) => uniqueIdArr.indexOf(v.id) >= 0
    );
    // console.log(this.state.inputTextValue);
    if (str.length <= 0) {
      this.setState({
        data: finalArr,
        flagOpenMenu: false,
        inputTextValue: str,
      });
    } else {
      this.setState({
        data: finalArr, // jbdfhsfhghjjhg
        flagOpenMenu: true,
        inputTextValue: str,
      });
    }

    // let selectedIdArr = [16,17];

    // for(let v of selectedIdArr){
    //   let myObj = this.state.originData.filter( k => k.id == v );

    //   let targetArr = []
    //   if(myObj[0].subIds.length >= 1){

    //   }

    // }

    // recrusive for findings all roots data
    // let originData = this.state.originData

    // function findAllRelatedid(idArr){
    //   let finalArr = [idArr];

    //   let myObj = originData.filter( k => k.id == idArr );
    //   if(myObj[0].subIds.length >= 1){

    //     for(let v of myObj[0].subIds){
    //       finalArr.push(...findAllRelatedid(v))
    //     }

    //   }
    //   else{
    //     return finalArr
    //   }

    //   return finalArr
    // }

    // let selctedIdArr = [20]
    // let finalSelectedArr = new Set();

    // for(let v of selctedIdArr){
    //   let result = findAllRelatedid(v);
    //   result.forEach( k => finalSelectedArr.add(k) );

    // }

    // console.log(finalSelectedArr);
    // let finalSelectedArrayRaw = Array.from(finalSelectedArr)

    // let dddd =  this.state.originData.map(item => {
    //   if(finalSelectedArrayRaw.indexOf(item.id) >= 0){
    //     return{
    //       ...item,
    //       selected: true
    //     }
    //   }
    //   else{
    //     return item
    //   }
    // })

    // console.log(999,finalSelectedArrayRaw);
    // console.log(dddd)

    // this.setState({
    //   data: dddd
    // })
  };

  render() {
    const { flagOpenMenu, multiple } = this.state;
    let data = this.state.data;
    console.log("flagOpenMenu", flagOpenMenu);
    // console.log("data", data); //, JSON.stringify(data) );

    //=====

    let _classList = [];
    _classList.push("result_part");
    if (flagOpenMenu) {
    } else {
      _classList.push("hidden");
    }
    let _classListStr = _classList.join(" ");

    //=====

    return (
      <div className="searchfilter">
        <div className="search_part">
          <input
            type="text"
            placeholder="Search"
            value={this.inputTextValue}
            onChange={(e) => this.handleInputText(e.target.value)}
          />
          <input
            type="button"
            className={flagOpenMenu === false ? "" : "close"}
            value={flagOpenMenu === false ? "🔍" : "✖"}
            onClick={
              (event) => console.log("13", event) //this.handleSearchBtnClick(event)
            }
          />
        </div>
        {flagOpenMenu && (
          <div className="result_part">
            {console.log("123")}
            {this.genHTML(this.getRoot(data), 0, multiple)}
            {/* {this.genRoot(data)} */}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  console.log("mapStateToProps", state);
  return {
    //currentEpub: state.book.currentEpub,
    //currentBook: state.book.currentBook,
  };
};
const actionCreator = {};
export default connect(mapStateToProps, actionCreator)(SearchFilter);
