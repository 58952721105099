import {
  ACCESS_TOKEN,
  CLIENT_INFO,
  REFRESH_TOKEN,
  USER_INFO,
  REMIND_INFO,
} from "./Constants";

var CryptoJS = require("crypto-js");

//8,16,32
export const aseKey = "36344330023443252425b66617756134";

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN);
}

export function setAccessToken(token) {
  localStorage.setItem(ACCESS_TOKEN, token);
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN);
}

export function setRefreshToken(token) {
  localStorage.setItem(REFRESH_TOKEN, token);
}

export function getUserInfo() {
  //console.log("getUserInfo");
  const userInfo = localStorage.getItem(USER_INFO);

  if (userInfo !== null) {
    //console.log(USER_INFO, JSON.parse(decryptString(userInfo, aseKey)));
  }
  return userInfo == null ? null : JSON.parse(decryptString(userInfo, aseKey));
}

export function setUserInfo(info) {
  const infoJSON = JSON.parse(info);
  var newOrganizationObj = null;
  var newInfoJSON = null;
  /*
  if (getUserInfo() === null) {
    */
  newOrganizationObj = infoJSON.organizations.map((it, index) => {
    if (index === 0) {
      return { ...it, isSelected: true, isSearch: true };
    } else {
      return { ...it, isSelected: false, isSearch: true };
    }
  });
  newInfoJSON = { ...infoJSON, organizations: newOrganizationObj };
  /*
  } else {
    newInfoJSON = { ...infoJSON };
  }
  */

  var encryptedUserInfo = encryptString(JSON.stringify(newInfoJSON), aseKey);

  localStorage.setItem(USER_INFO, encryptedUserInfo);
}

export function getRemindInfo() {
  const remind = localStorage.getItem(REMIND_INFO);

  if (remind !== null) {
    //console.log(REMIND_INFO, JSON.parse(decryptString(remind, aseKey)));
  }
  return remind == null ? null : JSON.parse(decryptString(remind, aseKey));
}

export function setRemindInfo(info) {
  if (info !== null) {
    var encryptedremind = encryptString(JSON.stringify(info), aseKey);

    localStorage.setItem(REMIND_INFO, encryptedremind);
  }
}

export function setUserInfoByOrganization(info) {
  if (info !== null) {
    clearUserInfo();

    var encryptedUserInfo = encryptString(JSON.stringify(info), aseKey);

    localStorage.setItem(USER_INFO, encryptedUserInfo);
  }
}

export function getCurrentOrganization() {
  var userInfo = getUserInfo();
  return userInfo !== null
    ? userInfo.organizations.find((it) => it.isSelected)
    : null;
}

export function getUserRole() {
  const userInfo = localStorage.getItem(USER_INFO);

  return userInfo == null
    ? null
    : JSON.parse(decryptString(userInfo, aseKey)).role;
}

export function getUserPermission() {
  const userInfo = localStorage.getItem(USER_INFO);

  return userInfo == null ? null : JSON.parse(decryptString(userInfo, aseKey));
}

export function setClientInfo(info) {
  var encryptedUserInfo = encryptString(info, aseKey);
  localStorage.setItem(CLIENT_INFO, encryptedUserInfo);
}

export function getClientInfo() {
  const clientInfo = localStorage.getItem(CLIENT_INFO);
  return clientInfo == null ? null : decryptString(clientInfo, aseKey);
}

export function setSelectedOrganization(value) {}

export function clearAccessToken() {
  if (getAccessToken() != null) {
    localStorage.removeItem(ACCESS_TOKEN);
  }
}

export function clearRefreshToken() {
  if (getRefreshToken() != null) {
    localStorage.removeItem(REFRESH_TOKEN);
  }
}

export function clearUserInfo() {
  if (getUserInfo() != null) {
    localStorage.removeItem(USER_INFO);
  }
}

export function clearClientInfo() {
  if (getClientInfo() != null) {
    localStorage.removeItem(CLIENT_INFO);
  }
}

export function clearRemindInfo() {
  if (getRemindInfo() != null) {
    localStorage.removeItem(REMIND_INFO);
  }
}

export function encryptString(data) {
  return CryptoJS.AES.encrypt(
    data.toString(),
    CryptoJS.enc.Utf8.parse(aseKey),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();
}

export function decryptString(encryptString) {
  return CryptoJS.AES.decrypt(encryptString, CryptoJS.enc.Utf8.parse(aseKey), {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString(CryptoJS.enc.Utf8);
}
