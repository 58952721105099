import moment from "moment";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import {
  STYLETYPE_DEFAULT,
  STYLETYPE_FIVE,
  STYLETYPE_FOUR,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_SELECT_FILE,
  STYLETYPE_SIX,
  STYLETYPE_THIRD,
  STYLETYPE_SEVEN,
  STYLETYPE_EIGHT,
} from "../../utils/Constants";
import style from "./Input.module.css";

export default class Input extends Component {
  state = {
    textLength: 0,
    validData: {},
    showError: false,
    errorStyle: {},
  };

  static propTypes = {
    showTitleHolder: PropTypes.bool,
    styleType: PropTypes.string,
    type: PropTypes.string,
    checked: PropTypes.bool,
    hidden: PropTypes.bool,
    customStyle: PropTypes.object,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    enable: PropTypes.bool,
    id: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    minlength: PropTypes.number,
    maxlength: PropTypes.number,
    size: PropTypes.number,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    accept: PropTypes.string,
    needed: PropTypes.bool,
    callBack: PropTypes.func,
    step: PropTypes.number,
  };

  static defaultProps = {
    disabled: false,
    type: "text",
    checked: false,
    styleType: STYLETYPE_DEFAULT,
    showTitleHolder: true,
    customStyle: {},
    value: "",
  };

  resetState = () => {
    this.setState({ textLength: 0, validData: {}, showError: false });
  };

  hanldeOnChangeText = (event) => {
    this.resetState();

    if (event.target.value === "") {
      this.setState({ textLength: event.target.value.length });
    }

    //console.log("event Input", event.target.value);

    switch (event.target.type) {
      case "text":
      case "password":
      case "email":
        this.setState({ ...this.state, textLength: event.target.value.length });
        this.props.callBack(event.target.value, this.state.showError);
        break;
      case "number":
        if (event.target.value !== "") {
          this.setState({ textLength: 1 });
        } else {
          this.setState({ textLength: 0 });
        }
        this.props.callBack(event.target.value);
        break;
      case "checkbox":
      case "radio":
        this.props.callBack(event.target.checked);
        break;
      case "file":
        this.props.callBack(event.nativeEvent.target.files);
        break;
      case "date":
        if (moment(event.target.value, "YYYY-MM-DD", true).isValid()) {
          this.setState({
            ...this.state,
            validData: { month: true },
            showError: false,
          });
          this.props.callBack(event.target.value);
        } else {
          this.setState({
            ...this.state,
            validData: { month: false },
            showError: true,
          });
        }
        break;
      case "datetime-local":
        if (moment(event.target.value, "YYYY-MM-DDThh:mm", true).isValid()) {
          this.setState({
            ...this.state,
            validData: { month: true },
            showError: false,
          });
          this.props.callBack(event.target.value);
        } else {
          this.setState({
            ...this.state,
            validData: { month: false },
            showError: true,
          });
        }
        break;
      case "month":
        if (moment(event.target.value, "YYYY-MM", true).isValid()) {
          this.setState({
            ...this.state,
            validData: { month: true },
            showError: false,
          });
          this.props.callBack(event.target.value);
        } else {
          this.setState({
            ...this.state,
            validData: { month: false },
            showError: true,
          });
        }
        break;
    }
  };

  getErrorMsg = () => {
    const { validData } = this.state;
    if (validData.hasOwnProperty("month")) {
      if (!validData["month"]) {
        return <>請輸入正確日期</>;
      }
    }

    if (validData.hasOwnProperty("date")) {
      if (!validData["month"]) {
        return <>請輸入正確日期</>;
      }
    }
  };

  render() {
    const {
      styleType,
      type,
      placeholder,
      multiple,
      accept,
      minlength,
      maxlength,
      checked,
      value,
      hidden,
      id,
      name,
      children,
      min,
      max,
      size,
      showTitleHolder,
      needed,
      enable,
      disabled,
      step,
    } = this.props;
    const { showError, errorStyle, textLength } = this.state;
    return (
      <Fragment>
        {(type === "date" ||
          type === "month" ||
          type === "number" ||
          value === 0 ||
          textLength > 0 ||
          value?.length > 0) &&
        showTitleHolder ? (
          <label className={style.customBadge}>{placeholder}</label>
        ) : (
          <></>
        )}
        <div
          className={style.errorMsg}
          style={{ display: this.getErrorMsg() ? "block" : "none" }}
        >
          {this.getErrorMsg()}
        </div>
        <input
          //autocomplete="off"
          autoComplete="new-password"
          type={type}
          placeholder={placeholder}
          checked={checked}
          onChange={this.hanldeOnChangeText}
          id={id}
          min={min}
          max={max}
          minLength={minlength}
          size={size}
          value={type === "number" ? (value === "" ? 0 : value) : value || ""}
          hidden={hidden}
          enable={enable}
          multiple={multiple ? multiple : ""}
          name={multiple ? "files" : name}
          accept={accept}
          maxLength={maxlength}
          disabled={disabled}
          step={step}
          className={
            styleType === STYLETYPE_MAIN
              ? style.inputPrimary
              : styleType === STYLETYPE_SECOND
              ? style.inputSecond
              : styleType === STYLETYPE_THIRD
              ? style.inputThird
              : styleType === STYLETYPE_FOUR
              ? style.inputF
              : styleType === STYLETYPE_FIVE
              ? style.inputFive
              : styleType === STYLETYPE_SIX
              ? style.inputSix
              : styleType === STYLETYPE_SEVEN
              ? style.inputSeven
              : styleType === STYLETYPE_EIGHT
              ? style.inputEight
              : styleType === STYLETYPE_SELECT_FILE
              ? style.inputSelectFile
              : ""
          }
          style={showError ? { border: "1px solid red" } : {}}
        />
        {children}
      </Fragment>
    );
  }
}
