/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import PublishHouseDto from '../model/PublishHouseDto';
import PublishHouseRequestModel from '../model/PublishHouseRequestModel';
import SearchingQueryResponseModelPublishHouseDto from '../model/SearchingQueryResponseModelPublishHouseDto';

/**
* PublishHouse service.
* @module api/PublishHouseApi
* @version v1.0.0
*/
export default class PublishHouseApi {

    /**
    * Constructs a new PublishHouseApi. 
    * @alias module:api/PublishHouseApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addPublishHouseUsingPOST operation.
     * @callback module:api/PublishHouseApi~addPublishHouseUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PublishHouseDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add Publish House
     * @param {Object} opts Optional parameters
     * @param {module:model/PublishHouseRequestModel} opts.publishHouseRequestModel 
     * @param {module:api/PublishHouseApi~addPublishHouseUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PublishHouseDto}
     */
    addPublishHouseUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['publishHouseRequestModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = PublishHouseDto;
      return this.apiClient.callApi(
        '/api/publish-house/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deletePublishHouseUsingDELETE operation.
     * @callback module:api/PublishHouseApi~deletePublishHouseUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete Publish House
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/PublishHouseApi~deletePublishHouseUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deletePublishHouseUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/publish-house/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPublishHouseUsingGET operation.
     * @callback module:api/PublishHouseApi~getPublishHouseUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PublishHouseDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get publish house
     * @param {String} id id
     * @param {module:api/PublishHouseApi~getPublishHouseUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PublishHouseDto}
     */
    getPublishHouseUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getPublishHouseUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PublishHouseDto;
      return this.apiClient.callApi(
        '/api/publish-house/get/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPublishHousesUsingGET operation.
     * @callback module:api/PublishHouseApi~getPublishHousesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelPublishHouseDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * search Publish House
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/PublishHouseApi~getPublishHousesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelPublishHouseDto}
     */
    getPublishHousesUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelPublishHouseDto;
      return this.apiClient.callApi(
        '/api/publish-house/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updatePublishHouseUsingPATCH operation.
     * @callback module:api/PublishHouseApi~updatePublishHouseUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PublishHouseDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * update Publish House
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/PublishHouseRequestModel} opts.publishHouseRequestModel 
     * @param {module:api/PublishHouseApi~updatePublishHouseUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PublishHouseDto}
     */
    updatePublishHouseUsingPATCH(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['publishHouseRequestModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updatePublishHouseUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = PublishHouseDto;
      return this.apiClient.callApi(
        '/api/publish-house/update/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
