import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { handleUserInfo } from "../../redux/actions";
import { STYLETYPE_DEFAULT, STYLETYPE_SECOND } from "../../utils/Constants";
import { setUserInfoByOrganization } from "../../utils/LocalStorage";
import Input from "../Input/Input";
import "./SelectOrgSearchFilter.css";

class SelectOrgSearchFilter extends React.Component {
  state = {
    originData: [],
    data: [],
    childList: [],
    flagOpenMenu: true,
    multiple: false,
    inputTextValue: "",
    styleType: STYLETYPE_DEFAULT,
    expand: true,
    placeholder: "Search",
    // checkbox:false
  };

  static propTypes = {
    styleType: PropTypes.string,
    callBack: PropTypes.func,
    childList: PropTypes.array,
    data: PropTypes.array,
    flagOpenMenu: PropTypes.bool,
    multiple: PropTypes.bool,
    expand: PropTypes.bool,
    placeholder: PropTypes.string,
    // checkbox:PropTypes.bool
  };

  static defaultProps = {
    originData: [],
    data: [],
    childList: [],
    flagOpenMenu: true,
    multiple: false,
    inputTextValue: "",
    expand: true,
    // checkbox:false
  };

  initData = (obj, level) => {
    obj.forEach((_obj, index) => {
      _obj.showed = false;
      _obj.opened = false;
      _obj.isSelected = false;
    });
    return obj;
  };

  // genRoot = (obj)=>{
  //   let rootList = []
  //   let childList = []

  //   obj.forEach((mainObj, index) => {
  //     if (mainObj.id === mainObj.mainId) {
  //       mainObj.showed = true;
  //       rootList.push(mainObj);
  //     }
  //   });

  //   const renderObj =() =>{

  //   }
  //   return  React.createElement("div", null, childList);

  // }

  componentDidMount() {
    //console.log("componentDidMount");
    //console.log("this.props.data", this.props.data, JSON.stringify(this.props.data));

    let _data = this.initData(this.props.data);
    //console.log("_data", _data, JSON.stringify(_data) );
    let _multiple = this.props.multiple;
    this.setState({
      originData: _data,
      data: _data,
      multiple: _multiple,
    });
    //console.log("componentDidMount", _data, _multiple);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps !== undefined || nextProps !== null) {
      this.setState({
        originData: nextProps.data,
        data: nextProps.data,
        styleType: nextProps.styleType,
        flagOpenMenu: nextProps.expand,
        expand: nextProps.expand,
        placeholder:
          nextProps.placeholder !== undefined
            ? nextProps.placeholder
            : this.state.placeholder,
      });
      let selectedObj = nextProps.data.filter((it) => {
        return it.isSelected;
      });
      if (nextProps.styleType === STYLETYPE_SECOND) {
        if (selectedObj.length > 0)
          this.setState({ inputTextValue: selectedObj[0].name });
      }
    }
  }

  getRoot = (obj, level) => {
    //console.log("getRoot", obj);
    this.childList = [];
    let rootList = [];

    obj.forEach((mainObj, index) => {
      //if (mainObj.id === mainObj.mainId) {
      if (mainObj.main === null) {
        mainObj.showed = true;
        rootList.push(mainObj);
      }
    });
    // console.log("getRoot rootList", rootList);
    return rootList;
  };

  genHTML = (obj, level, isMultiple) => {
    //console.log("level", level, obj instanceof Array);
    //var childernList = [];
    let _key = {};
    if (obj instanceof Array) {
      //console.log("genHTML AAA", obj);
      obj.forEach((mainObj, index) => {
        // //get main
        //console.log("m", mainObj.id);
        //this.childList.push(this.getHTML(mainObj, level, isMultiple));
        this.childList.push(this.getHTML(mainObj, level, isMultiple));
        _key = {key: `org-${mainObj.id}`};
        // console.log('childList',this.childList)

        //find child
        if (mainObj.sub && mainObj.sub.length > 0) {
          mainObj.sub.map((subObj) => {
            //console.log("s1", subObj);
            var childObj = this.state.data.find(
              (orObj) => orObj.id === subObj.id
            );

            //console.log("dkhagdhjsagdhjsag", childObj);

            //if (childObj && childObj.sub && childObj.sub.length > 0) {
            if (childObj !== undefined)
              this.genHTML(childObj, level + 1, isMultiple);
            //}
          });
        }
        //childernList.push(this.childList);
      });
    } else {
      //console.log("genHTML BBB", obj);
      //this.childList.push(this.getHTML(obj, level - 1, isMultiple));
      this.childList.push(this.getHTML(obj, level, isMultiple));
      _key = {key: `org-${obj.id}`};

      if (obj.sub && obj.sub.length > 0) {
        obj.sub.map((subObj) => {
          // console.log("s2", subObj);
          var childObj = this.state.data.find(
            (orObj) => orObj.id === subObj.id
          );
          if (childObj !== undefined)
            this.genHTML(childObj, level + 1, isMultiple);
        });
      }
    }

    //console.log(this.childList);
    return React.createElement("div", _key, this.childList);
    //console.log(childernList);
    //console.log(childList);
  };

  onChange = (childObj) => {
    let originData = this.state.originData;
    let selctedIdArr = originData.find((item) => item.id === childObj.id);
    let finalSelectedArr = new Set();
    finalSelectedArr.add(selctedIdArr.id);

    function findAllRelatedid(idArr) {
      let finalArr = [idArr];

      let myObj = originData.filter((k) => k.id == idArr);
      if (myObj[0].subIds.length >= 1) {
        for (let v of myObj[0].subIds) {
          finalArr.push(...findAllRelatedid(v));
        }
      } else {
        return finalArr;
      }
      return finalArr;
    }
    // for(let v of selctedIdArr.subIds){
    let result = findAllRelatedid(selctedIdArr.id);
    // console.log('result',result)
    result.forEach((k) => finalSelectedArr.add(k));

    // }

    console.log(finalSelectedArr);
    let finalSelectedArrayRaw = Array.from(finalSelectedArr);

    let dddd = this.state.originData.map((item) => {
      // console.log('dddd',finalSelectedArrayRaw)
      if (finalSelectedArrayRaw.indexOf(item.id) >= 0) {
        item.isSelected = !item.isSelected;
        return item;
      } else {
        return item;
      }
    });

    // this.props.callBack(dddd);
    this.setState({
      data: dddd,
    });
  };
  showSubMenu = (childObj, level) => {
    if (this.state.styleType === STYLETYPE_SECOND)
      this.setState({ inputTextValue: childObj.name });
    var userInfo = this.props.userInfo;
    var userOrganizationsObjs = userInfo.organizations;
    var newArr = userOrganizationsObjs.map((it) => {
      if (it.id === childObj.id) {
        it.isSelected = true;
      } else {
        it.isSelected = false;
      }
      return it;
    });
    this.props.handleUserInfo(userInfo);
    setUserInfoByOrganization(userInfo);

    this.props.callBack(newArr);

    setTimeout(function() {
      window.location.reload();
    }, 0);
  };
  getHTML = (childObj, level, isMultiple) => {
    // console.log("getHTML", childObj, level, isMultiple);
    //console.log("getHTML onChange", this.onChange);
    let _hasChild = false;

    if (!childObj) {
      return;
    }
    let subItems = [];

    childObj.sub.map((subItem) => {
      let filteredItems = this.state.originData.filter((it) => {
        return it.id === subItem.id;
      });
      if (filteredItems.length > 0) {
        subItems.push(filteredItems);
      }
    });

    if (subItems.length > 0) {
      _hasChild = true;
    } else {
      _hasChild = false;
    }

    // if (childObj.sub && childObj.sub.length > 0) {
    //   _hasChild = true;
    // }
    // console.log("getHTML _hasChild", _hasChild);

    //=====

    let _classList = [];
    _classList.push("result_item");
    _classList.push("sosf_result_item");
    if (_hasChild) {
      _classList.push("hasChild");
    }
    if (childObj.showed) {
    } else {
      if (childObj.opened) {
      } else {
        _classList.push("hidden");
      }
    }
    let _classListStr = _classList.join(" ");

    //=====

    let _eb_classList = [];
    _eb_classList.push("expendBtn");
    if (childObj.opened) {
      _eb_classList.push("open");
    }
    let _eb_classListStr = _eb_classList.join(" ");

    //=====

    return (
      <div
        className={_classListStr}
        id={childObj.id}
        mainid={childObj.mainId}
        level={level}
        style={{
          paddingLeft: 36 + level * 20 + "px",
        }}
      >
        <div className="result_item_content" key={childObj.id}>
          {isMultiple ? (
            <Input
              type="checkbox"
              showType={STYLETYPE_DEFAULT}
              checked={childObj.isSelected}
              callBack={() => this.onChange(childObj)}
            />
          ) : (
            <></>
          )}
          <label
            value={childObj.id}
            onClick={(value) => this.showSubMenu(childObj, 0)}
            title={childObj.name}
            // callBack={() => this.onChange(childObj)}
          >
            {childObj.name}
            {/* - {childObj.id} - {childObj.mainId} - {level}*/}
          </label>
          {_hasChild && (
            <div
              className={_eb_classListStr}
              key={childObj.id}
              mainid={childObj.id}
              onClick={(event) => this.handleExpendBtn(event)}
            ></div>
          )}
        </div>
      </div>
    );
  };

  handleSearchBtnClick = (event) => {
    let _flagOpenMenu = !this.state.flagOpenMenu;
    this.setState({
      data: this.state.originData,
      flagOpenMenu: this.state.expand ? true : _flagOpenMenu,
      inputTextValue: "",
    });
  };

  handleExpendBtn = (event) => {
    let _mainId = parseInt(event.target.getAttribute("mainid"));

    //$(".result_item[mainid=" + _mainId + "]").removeClass("hidden");

    let _roots = this.getRoot(this.state.data);
    let _targetObj = this.state.data.find((_orObj) => _orObj.id === _mainId);
    //console.log("_targetObj", _targetObj);
    if (_targetObj) {
      _targetObj.opened = !_targetObj.opened;
    }

    let _parentObj = this.props.data.find((_orObj) => _orObj.id === _mainId);
    // console.log("_parentObj",_parentObj)
    let _data = this.state.data;
    _data.map((_obj) => {
      if (_obj.main !== null) {
        if (_obj.main.id === _mainId) {
          _obj.showed = _parentObj.opened;
        }
      }
    });
    //console.log("_data",_data);//, JSON.stringify(_data) );
    this.setState(
      {
        data: _data,
      },
      () => {
        // console.log(this.state.data);
      }
    );
  };

  handleInputText = (str) => {
    console.log(this.state.data);
    console.log(str);

    // let filtedData = this.state.originData.filter((v) =>
    //   v.name.toLowerCase().includes(str.toLowerCase())
    // );

    //console.log("this.state.originData", this.state.originData);
    let objArr = this.props.data.filter((v) =>
      //let objArr = this.state.originData.filter((v) =>
      v.name.toLowerCase().includes(str.toLowerCase())
    );
    //console.log("objArr", objArr);
    this.setState({
      data: objArr,
      flagOpenMenu: true,
      inputTextValue: str,
    });
  };

  render() {
    const { data, flagOpenMenu, multiple, styleType } = this.state;
    //let data = this.state.data;
    // console.log("flagOpenMenu", flagOpenMenu);
    //console.log("datafffff", this.state.data); //, JSON.stringify(data) );
    //console.log("this.props.data", this.props.data);
    //console.log("data", data);

    //=====

    let _classList = [];
    _classList.push("result_part");
    if (flagOpenMenu) {
    } else {
      _classList.push("hidden");
    }
    let _classListStr = _classList.join(" ");

    //=====

    return (
      <>
        {styleType === STYLETYPE_SECOND ? (
          <div className="so_sf styletype_second">
            <div className="searchfilter">
              <div className="search_part">
                <input
                  type="text"
                  placeholder={this.state.placeholder}
                  value={this.state.inputTextValue}
                  onChange={(e) => this.handleInputText(e.target.value)}
                />
                <input
                  type="button"
                  className={flagOpenMenu === false ? "" : "close"}
                  value={flagOpenMenu === false ? "🔍" : "✖"}
                  onClick={(event) => this.handleSearchBtnClick(event)}
                />
              </div>
              {flagOpenMenu && (
                <div className="result_part">
                  {/*this.genHTML(this.getRoot(data), 0, multiple)*/}
                  {this.genHTML(this.getRoot(data), 0, multiple)}
                  {/* {this.genRoot(data)} */}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="so_sf">
            <div className="searchfilter">
              <div className="search_part">
                <input
                  type="text"
                  placeholder="Search"
                  value={this.state.inputTextValue}
                  onChange={(e) => this.handleInputText(e.target.value)}
                />
                <input
                  type="button"
                  className={flagOpenMenu === false ? "" : "close"}
                  value={flagOpenMenu === false ? "🔍" : "✖"}
                  onClick={(event) => this.handleSearchBtnClick(event)}
                />
              </div>
              {flagOpenMenu && (
                <div className="result_part">
                  {/*this.genHTML(this.getRoot(data), 0, multiple)*/}
                  {this.genHTML(this.getRoot(data), 0, multiple)}
                  {/* {this.genRoot(data)} */}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
    //currentEpub: state.book.currentEpub,
    //currentBook: state.book.currentBook,
  };
};
const actionCreator = { handleUserInfo };
export default connect(mapStateToProps, actionCreator)(SelectOrgSearchFilter);
