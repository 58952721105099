import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import BookModel from "../model/Book";
import BookmarkModel from "../model/Bookmark";
import HtmlBookModel from "../model/HtmlBook";
import NoteModel from "../model/Note";
import { backupPage } from "./reducers/reader/backupPage";
import { book } from "./reducers/reader/book";
import { manager } from "./reducers/reader/manager";
import { progressPanel } from "./reducers/reader/progressPanel"; //"./reducers/reader/progressPanel";
import { reader } from "./reducers/reader/reader";
import { sidebar } from "./reducers/reader/sidebar";
import { viewArea } from "./reducers/reader/viewArea";
import { userInfo } from "./reducers/userInfo";

export default createStore(
  combineReducers({
    backupPage,
    progressPanel,
    reader,
    sidebar,
    viewArea,
    book,
    manager,
    userInfo,
  }),
  composeWithDevTools(applyMiddleware(thunk))
);

export type stateType = {
  manager: {
    books: BookModel[];
    deletedBooks: BookModel[];
    searchResults: number[];
    isSearch: boolean;
    isBookSort: boolean;
    isSettingOpen: boolean;
    viewMode: string;
    isSortDisplay: boolean;
    isAboutOpen: boolean;
    isShowLoading: boolean;
    isShowNew: boolean;
    isNewWarning: boolean;
    isSelectBook: boolean;
    selectedBooks: string[];
    isTipDialog: boolean;
    bookSortCode: { sort: number; order: number };
    noteSortCode: { sort: number; order: number };
    tip: string;
  };
  book: {
    isOpenEditDialog: boolean;
    isOpenDeleteDialog: boolean;
    isOpenAddDialog: boolean;
    isOpenActionDialog: boolean;
    isReading: boolean;
    dragItem: string;
    currentBook: BookModel;
    currentEpub: any;
    renderFunc: () => void;
  };
  backupPage: {
    isBackup: boolean;
    isOpenTokenDialog: boolean;
  };
  progressPanel: {
    percentage: number;
    locations: any[];
  };
  reader: {
    bookmarks: BookmarkModel[];
    notes: NoteModel[];
    digests: NoteModel[];
    color: number;
    chapters: any[];
    flattenChapters: any;
    noteKey: string;
    currentChapter: string;
    originalText: string;
    htmlBook: HtmlBookModel;
  };
  sidebar: {
    mode: string;
    shelfIndex: number;
    isCollapsed: boolean;
  };
  viewArea: {
    selection: string;
    menuMode: string;
    isOpenMenu: boolean;
    isChangeDirection: boolean;
    isShowBookmark: boolean;
  };
};
