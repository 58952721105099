/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import CustomerShoppingCartBookListBookCartDto from './CustomerShoppingCartBookListBookCartDto';
import CustomerShoppingCartBookListNoBookDto from './CustomerShoppingCartBookListNoBookDto';

/**
 * The CustomerShoppingCartNoBookDto model module.
 * @module model/CustomerShoppingCartNoBookDto
 * @version v1.0.0
 */
class CustomerShoppingCartNoBookDto {
    /**
     * Constructs a new <code>CustomerShoppingCartNoBookDto</code>.
     * @alias module:model/CustomerShoppingCartNoBookDto
     */
    constructor() { 
        
        CustomerShoppingCartNoBookDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CustomerShoppingCartNoBookDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CustomerShoppingCartNoBookDto} obj Optional instance to populate.
     * @return {module:model/CustomerShoppingCartNoBookDto} The populated <code>CustomerShoppingCartNoBookDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CustomerShoppingCartNoBookDto();

            if (data.hasOwnProperty('bookList')) {
                obj['bookList'] = ApiClient.convertToType(data['bookList'], [CustomerShoppingCartBookListNoBookDto]);
            }
            if (data.hasOwnProperty('cart')) {
                obj['cart'] = ApiClient.convertToType(data['cart'], [CustomerShoppingCartBookListBookCartDto]);
            }
            if (data.hasOwnProperty('purchaseMissionId')) {
                obj['purchaseMissionId'] = ApiClient.convertToType(data['purchaseMissionId'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/CustomerShoppingCartBookListNoBookDto>} bookList
 */
CustomerShoppingCartNoBookDto.prototype['bookList'] = undefined;

/**
 * @member {Array.<module:model/CustomerShoppingCartBookListBookCartDto>} cart
 */
CustomerShoppingCartNoBookDto.prototype['cart'] = undefined;

/**
 * @member {Number} purchaseMissionId
 */
CustomerShoppingCartNoBookDto.prototype['purchaseMissionId'] = undefined;

/**
 * @member {module:model/CustomerShoppingCartNoBookDto.StatusEnum} status
 */
CustomerShoppingCartNoBookDto.prototype['status'] = undefined;

/**
 * @member {String} title
 */
CustomerShoppingCartNoBookDto.prototype['title'] = undefined;





/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
CustomerShoppingCartNoBookDto['StatusEnum'] = {

    /**
     * value: "Confirm"
     * @const
     */
    "Confirm": "Confirm",

    /**
     * value: "Draft"
     * @const
     */
    "Draft": "Draft",

    /**
     * value: "Finish"
     * @const
     */
    "Finish": "Finish",

    /**
     * value: "Published"
     * @const
     */
    "Published": "Published",

    /**
     * value: "Sent"
     * @const
     */
    "Sent": "Sent"
};



export default CustomerShoppingCartNoBookDto;

