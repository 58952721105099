/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import IdLongModel from './IdLongModel';
import IdStringModel from './IdStringModel';

/**
 * The BookInfoSearchingQueryModel model module.
 * @module model/BookInfoSearchingQueryModel
 * @version v1.0.0
 */
class BookInfoSearchingQueryModel {
    /**
     * Constructs a new <code>BookInfoSearchingQueryModel</code>.
     * @alias module:model/BookInfoSearchingQueryModel
     */
    constructor() { 
        
        BookInfoSearchingQueryModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BookInfoSearchingQueryModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BookInfoSearchingQueryModel} obj Optional instance to populate.
     * @return {module:model/BookInfoSearchingQueryModel} The populated <code>BookInfoSearchingQueryModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BookInfoSearchingQueryModel();

            if (data.hasOwnProperty('author')) {
                obj['author'] = ApiClient.convertToType(data['author'], 'String');
            }
            if (data.hasOwnProperty('bookDBType')) {
                obj['bookDBType'] = ApiClient.convertToType(data['bookDBType'], 'Number');
            }
            if (data.hasOwnProperty('bookIsbn')) {
                obj['bookIsbn'] = ApiClient.convertToType(data['bookIsbn'], 'String');
            }
            if (data.hasOwnProperty('bookListId')) {
                obj['bookListId'] = ApiClient.convertToType(data['bookListId'], 'Number');
            }
            if (data.hasOwnProperty('bookStatus')) {
                obj['bookStatus'] = ApiClient.convertToType(data['bookStatus'], 'String');
            }
            if (data.hasOwnProperty('categories')) {
                obj['categories'] = ApiClient.convertToType(data['categories'], [IdStringModel]);
            }
            if (data.hasOwnProperty('ctsid')) {
                obj['ctsid'] = ApiClient.convertToType(data['ctsid'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('from')) {
                obj['from'] = ApiClient.convertToType(data['from'], 'Number');
            }
            if (data.hasOwnProperty('inventory')) {
                obj['inventory'] = ApiClient.convertToType(data['inventory'], 'Number');
            }
            if (data.hasOwnProperty('languages')) {
                obj['languages'] = ApiClient.convertToType(data['languages'], [IdStringModel]);
            }
            if (data.hasOwnProperty('limit')) {
                obj['limit'] = ApiClient.convertToType(data['limit'], 'Number');
            }
            if (data.hasOwnProperty('maxPrice')) {
                obj['maxPrice'] = ApiClient.convertToType(data['maxPrice'], 'Number');
            }
            if (data.hasOwnProperty('minPrice')) {
                obj['minPrice'] = ApiClient.convertToType(data['minPrice'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('organizations')) {
                obj['organizations'] = ApiClient.convertToType(data['organizations'], [IdLongModel]);
            }
            if (data.hasOwnProperty('productTypes')) {
                obj['productTypes'] = ApiClient.convertToType(data['productTypes'], [IdStringModel]);
            }
            if (data.hasOwnProperty('publishEndDate')) {
                obj['publishEndDate'] = ApiClient.convertToType(data['publishEndDate'], 'Number');
            }
            if (data.hasOwnProperty('publishStartDate')) {
                obj['publishStartDate'] = ApiClient.convertToType(data['publishStartDate'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {String} author
 */
BookInfoSearchingQueryModel.prototype['author'] = undefined;

/**
 * @member {Number} bookDBType
 */
BookInfoSearchingQueryModel.prototype['bookDBType'] = undefined;

/**
 * @member {String} bookIsbn
 */
BookInfoSearchingQueryModel.prototype['bookIsbn'] = undefined;

/**
 * @member {Number} bookListId
 */
BookInfoSearchingQueryModel.prototype['bookListId'] = undefined;

/**
 * @member {module:model/BookInfoSearchingQueryModel.BookStatusEnum} bookStatus
 */
BookInfoSearchingQueryModel.prototype['bookStatus'] = undefined;

/**
 * @member {Array.<module:model/IdStringModel>} categories
 */
BookInfoSearchingQueryModel.prototype['categories'] = undefined;

/**
 * @member {String} ctsid
 */
BookInfoSearchingQueryModel.prototype['ctsid'] = undefined;

/**
 * @member {String} description
 */
BookInfoSearchingQueryModel.prototype['description'] = undefined;

/**
 * @member {Number} from
 */
BookInfoSearchingQueryModel.prototype['from'] = undefined;

/**
 * @member {Number} inventory
 */
BookInfoSearchingQueryModel.prototype['inventory'] = undefined;

/**
 * @member {Array.<module:model/IdStringModel>} languages
 */
BookInfoSearchingQueryModel.prototype['languages'] = undefined;

/**
 * @member {Number} limit
 */
BookInfoSearchingQueryModel.prototype['limit'] = undefined;

/**
 * @member {Number} maxPrice
 */
BookInfoSearchingQueryModel.prototype['maxPrice'] = undefined;

/**
 * @member {Number} minPrice
 */
BookInfoSearchingQueryModel.prototype['minPrice'] = undefined;

/**
 * @member {String} name
 */
BookInfoSearchingQueryModel.prototype['name'] = undefined;

/**
 * @member {Array.<module:model/IdLongModel>} organizations
 */
BookInfoSearchingQueryModel.prototype['organizations'] = undefined;

/**
 * @member {Array.<module:model/IdStringModel>} productTypes
 */
BookInfoSearchingQueryModel.prototype['productTypes'] = undefined;

/**
 * @member {Number} publishEndDate
 */
BookInfoSearchingQueryModel.prototype['publishEndDate'] = undefined;

/**
 * @member {Number} publishStartDate
 */
BookInfoSearchingQueryModel.prototype['publishStartDate'] = undefined;





/**
 * Allowed values for the <code>bookStatus</code> property.
 * @enum {String}
 * @readonly
 */
BookInfoSearchingQueryModel['BookStatusEnum'] = {

    /**
     * value: "APPLY_UNAVAILABLE"
     * @const
     */
    "APPLY_UNAVAILABLE": "APPLY_UNAVAILABLE",

    /**
     * value: "APPROVED"
     * @const
     */
    "APPROVED": "APPROVED",

    /**
     * value: "AVAILABLE"
     * @const
     */
    "AVAILABLE": "AVAILABLE",

    /**
     * value: "REJECTED"
     * @const
     */
    "REJECTED": "REJECTED",

    /**
     * value: "SUBMITTED"
     * @const
     */
    "SUBMITTED": "SUBMITTED",

    /**
     * value: "TEMP"
     * @const
     */
    "TEMP": "TEMP",

    /**
     * value: "UNAVAILABLE"
     * @const
     */
    "UNAVAILABLE": "UNAVAILABLE"
};



export default BookInfoSearchingQueryModel;

