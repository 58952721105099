/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The BatchMissionDto model module.
 * @module model/BatchMissionDto
 * @version v1.0.0
 */
class BatchMissionDto {
    /**
     * Constructs a new <code>BatchMissionDto</code>.
     * @alias module:model/BatchMissionDto
     */
    constructor() { 
        
        BatchMissionDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BatchMissionDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BatchMissionDto} obj Optional instance to populate.
     * @return {module:model/BatchMissionDto} The populated <code>BatchMissionDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BatchMissionDto();

            if (data.hasOwnProperty('bookCount')) {
                obj['bookCount'] = ApiClient.convertToType(data['bookCount'], 'Number');
            }
            if (data.hasOwnProperty('createAt')) {
                obj['createAt'] = ApiClient.convertToType(data['createAt'], 'Number');
            }
            if (data.hasOwnProperty('cronJobTime')) {
                obj['cronJobTime'] = ApiClient.convertToType(data['cronJobTime'], 'Number');
            }
            if (data.hasOwnProperty('failsList')) {
                obj['failsList'] = ApiClient.convertToType(data['failsList'], ['String']);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} bookCount
 */
BatchMissionDto.prototype['bookCount'] = undefined;

/**
 * @member {Number} createAt
 */
BatchMissionDto.prototype['createAt'] = undefined;

/**
 * @member {Number} cronJobTime
 */
BatchMissionDto.prototype['cronJobTime'] = undefined;

/**
 * @member {Array.<String>} failsList
 */
BatchMissionDto.prototype['failsList'] = undefined;

/**
 * @member {Number} id
 */
BatchMissionDto.prototype['id'] = undefined;

/**
 * @member {String} name
 */
BatchMissionDto.prototype['name'] = undefined;

/**
 * @member {module:model/BatchMissionDto.StatusEnum} status
 */
BatchMissionDto.prototype['status'] = undefined;





/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
BatchMissionDto['StatusEnum'] = {

    /**
     * value: "confirmXls"
     * @const
     */
    "confirmXls": "confirmXls",

    /**
     * value: "finish"
     * @const
     */
    "finish": "finish",

    /**
     * value: "pending"
     * @const
     */
    "pending": "pending",

    /**
     * value: "pendingBatch"
     * @const
     */
    "pendingBatch": "pendingBatch",

    /**
     * value: "processBatchFile"
     * @const
     */
    "processBatchFile": "processBatchFile"
};



export default BatchMissionDto;

