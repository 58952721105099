/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BisacDto from '../model/BisacDto';
import BisacPayloadModel from '../model/BisacPayloadModel';
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import SearchingQueryResponseModelBisacDto from '../model/SearchingQueryResponseModelBisacDto';

/**
* Bisac service.
* @module api/BisacApi
* @version v1.0.0
*/
export default class BisacApi {

    /**
    * Constructs a new BisacApi. 
    * @alias module:api/BisacApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addBisacUsingPOST operation.
     * @callback module:api/BisacApi~addBisacUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BisacDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add bisac
     * @param {Object} opts Optional parameters
     * @param {module:model/BisacPayloadModel} opts.bisacPayloadModel 
     * @param {module:api/BisacApi~addBisacUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BisacDto}
     */
    addBisacUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['bisacPayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = BisacDto;
      return this.apiClient.callApi(
        '/api/bisac/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteBisacUsingDELETE operation.
     * @callback module:api/BisacApi~deleteBisacUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete bisac
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/BisacApi~deleteBisacUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteBisacUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/bisac/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBisacUsingGET operation.
     * @callback module:api/BisacApi~getBisacUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BisacDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get bisac
     * @param {String} id id
     * @param {module:api/BisacApi~getBisacUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BisacDto}
     */
    getBisacUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getBisacUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = BisacDto;
      return this.apiClient.callApi(
        '/api/bisac/get/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBisacsUsingGET operation.
     * @callback module:api/BisacApi~getBisacsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelBisacDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * search bisac
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/BisacApi~getBisacsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelBisacDto}
     */
    getBisacsUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelBisacDto;
      return this.apiClient.callApi(
        '/api/bisac/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateBisacUsingPATCH operation.
     * @callback module:api/BisacApi~updateBisacUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BisacDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * update bisac
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/BisacPayloadModel} opts.bisacPayloadModel 
     * @param {module:api/BisacApi~updateBisacUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BisacDto}
     */
    updateBisacUsingPATCH(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['bisacPayloadModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateBisacUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = BisacDto;
      return this.apiClient.callApi(
        '/api/bisac/update/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
