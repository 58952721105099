import SparkMD5 from "spark-md5";
import BookUtil from "./bookUtil";
import { addEpub } from "./epubUtil";

var pdfjsLib = window["pdfjs-dist/build/pdf"];
export const fetchMD5 = (file) => {
    if (file["name"] !== undefined) {
        return new Promise((resolve, reject) => {
            try {
                if (file.name.indexOf(".pdf") > -1) {
                    let fileReader = new FileReader();
                    fileReader.readAsArrayBuffer(file);
                    fileReader.onload = (ev) => {
                        console.log("ev", ev);
                        pdfjsLib
                            .getDocument({ data: ev.target.result })
                            .promise.then((pdfDoc) => {
                                resolve(pdfDoc._pdfInfo.fingerprint);
                            })
                            .catch((err) => {
                                resolve(file.name + "-" + file.size);
                            });
                    };
                } else {
                    var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice,
                        chunkSize = 2097152, // 以每片2MB大小来逐次读取
                        chunks = Math.ceil(file.size / chunkSize),
                        currentChunk = 0,
                        spark = new SparkMD5(), //创建SparkMD5的实例
                        fileReader = new FileReader();
                    fileReader.onload = async (e) => {
                        if (!e.target) {
                            reject("");

                            throw new Error();
                        }
                        spark.appendBinary(e.target.result); // append array buffer
                        currentChunk += 1;
                        if (currentChunk < chunks) {
                            loadNext();
                        } else {
                            resolve(spark.end());
                        }
                    };

                    const loadNext = () => {
                        var start = currentChunk * chunkSize,
                            end = start + chunkSize >= file.size ? file.size : start + chunkSize;

                        fileReader.readAsBinaryString(blobSlice.call(file, start, end));
                    };

                    loadNext();
                }
            } catch (error) {
                reject(error);
            }
        });
    }
};

//获取书籍md5
export const getMd5WithBrowser = async (file) => {
    //console.log("md5", file);
    //: any
    console.log("file", file);
    return new Promise(async (resolve, reject) => {
        // < void>
        const md5 = await fetchMD5(file);
        console.log("md5", md5);
        if (!md5) {
            reject({ msg: "Import Failed" });
        } else {
            resolve(md5);
        }
    });
};

export const genBookData = (file, md5) => {
    return new Promise((resolve, reject) => {
        if (file === undefined) {
            reject({ msg: "找不到文件" });
        } else {
            let extension = file.name.split(".").reverse()[0];
            let bookName = file.name.substr(0, file.name.length - extension.length - 1);
            let result;
            let reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = async (e) => {
                if (!e.target) {
                    reject({ msg: "Import Failed" });
                    throw new Error();
                }
                if (extension === "pdf") {
                    let reader = new FileReader();
                    reader.onload = async (event) => {
                        const file_content = event.target.result;
                        result = await BookUtil.generateBook(bookName, extension, md5, file.size, file.path, file_content);
                        //console.log("result", result);
                        if (!result) {
                            reject({
                                msg: "You may see this error when the book you're importing is not supported, try converting it with Calibre",
                            });
                            return;
                        }
                        resolve(result);
                    };
                    reader.readAsArrayBuffer(file);
                } else {
                    result = await addEpub(file, md5);
                    if (!result) {
                        reject({ msg: "Import Failed" });
                        throw new Error();
                    } else {
                        resolve(result);
                    }
                }
            };
        }
    });
};
