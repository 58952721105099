/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AddUserPayloadModel from '../model/AddUserPayloadModel';
import CommonResponseModel from '../model/CommonResponseModel';
import OrgWithRolePayloadModel from '../model/OrgWithRolePayloadModel';
import PatchUserPasswordPayloadModel from '../model/PatchUserPasswordPayloadModel';
import PatchUserPayloadModel from '../model/PatchUserPayloadModel';
import SearchingQueryResponseModelUserDto from '../model/SearchingQueryResponseModelUserDto';
import SetUserActivePayloadModel from '../model/SetUserActivePayloadModel';
import SetUserDeletePayloadModel from '../model/SetUserDeletePayloadModel';
import UpdateUserListOrgAndRolePayloadModel from '../model/UpdateUserListOrgAndRolePayloadModel';
import UserDto from '../model/UserDto';

/**
* User service.
* @module api/UserApi
* @version v1.0.0
*/
export default class UserApi {

    /**
    * Constructs a new UserApi. 
    * @alias module:api/UserApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addUserUsingPOST operation.
     * @callback module:api/UserApi~addUserUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Insert User
     * @param {Object} opts Optional parameters
     * @param {module:model/AddUserPayloadModel} opts.addUserPayloadModel 
     * @param {module:api/UserApi~addUserUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserDto}
     */
    addUserUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['addUserPayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = UserDto;
      return this.apiClient.callApi(
        '/api/user/insert', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the assignOrgWithRoleUsingPOST operation.
     * @callback module:api/UserApi~assignOrgWithRoleUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * assign user to organization with role
     * @param {String} userId userId
     * @param {Object} opts Optional parameters
     * @param {module:model/OrgWithRolePayloadModel} opts.orgWithRolePayloadModel 
     * @param {module:api/UserApi~assignOrgWithRoleUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserDto}
     */
    assignOrgWithRoleUsingPOST(userId, opts, callback) {
      opts = opts || {};
      let postBody = opts['orgWithRolePayloadModel'];
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling assignOrgWithRoleUsingPOST");
      }

      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = UserDto;
      return this.apiClient.callApi(
        '/api/user/{userId}/assign-organization-with-role', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getUserOrgRoleUsingGET operation.
     * @callback module:api/UserApi~getUserOrgRoleUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Object} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get user organization with role
     * @param {String} id id
     * @param {module:api/UserApi~getUserOrgRoleUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object}
     */
    getUserOrgRoleUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getUserOrgRoleUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Object;
      return this.apiClient.callApi(
        '/api/user/{id}/organization/role', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getUserUsingGET operation.
     * @callback module:api/UserApi~getUserUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get user by param or currentUser
     * @param {Object} opts Optional parameters
     * @param {String} opts.userId userId
     * @param {module:api/UserApi~getUserUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserDto}
     */
    getUserUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'userId': opts['userId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = UserDto;
      return this.apiClient.callApi(
        '/api/user', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getUsersUsingGET operation.
     * @callback module:api/UserApi~getUsersUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelUserDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Users
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/UserApi~getUsersUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelUserDto}
     */
    getUsersUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelUserDto;
      return this.apiClient.callApi(
        '/api/users', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the removeOrgAndRoleUsingDELETE operation.
     * @callback module:api/UserApi~removeOrgAndRoleUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/UserDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * remove user in the organization with role
     * @param {String} userId userId
     * @param {Object} opts Optional parameters
     * @param {module:model/OrgWithRolePayloadModel} opts.orgWithRolePayloadModel 
     * @param {module:api/UserApi~removeOrgAndRoleUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/UserDto}
     */
    removeOrgAndRoleUsingDELETE(userId, opts, callback) {
      opts = opts || {};
      let postBody = opts['orgWithRolePayloadModel'];
      // verify the required parameter 'userId' is set
      if (userId === undefined || userId === null) {
        throw new Error("Missing the required parameter 'userId' when calling removeOrgAndRoleUsingDELETE");
      }

      let pathParams = {
        'userId': userId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = UserDto;
      return this.apiClient.callApi(
        '/api/user/{userId}/remove-organization-with-role', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setUserActiveStatusUsingPOST operation.
     * @callback module:api/UserApi~setUserActiveStatusUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set User active status
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/SetUserActivePayloadModel>} opts.setUserActivePayloadModel 
     * @param {module:api/UserApi~setUserActiveStatusUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    setUserActiveStatusUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['setUserActivePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/users/active', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setUserDeleteStatusUsingDELETE operation.
     * @callback module:api/UserApi~setUserDeleteStatusUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set User Delete Status
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/SetUserDeletePayloadModel>} opts.setUserDeletePayloadModel 
     * @param {module:api/UserApi~setUserDeleteStatusUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    setUserDeleteStatusUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['setUserDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/users/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateUserListOrgAndRoleUsingPOST operation.
     * @callback module:api/UserApi~updateUserListOrgAndRoleUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/UserDto>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * assign/remove/update userList in the organization with role
     * @param {Object} opts Optional parameters
     * @param {module:model/UpdateUserListOrgAndRolePayloadModel} opts.updateUserListOrgAndRolePayloadModel 
     * @param {module:api/UserApi~updateUserListOrgAndRoleUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/UserDto>}
     */
    updateUserListOrgAndRoleUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['updateUserListOrgAndRolePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = [UserDto];
      return this.apiClient.callApi(
        '/api/user/update-userList-organization-with-role', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateUserUsingPATCH operation.
     * @callback module:api/UserApi~updateUserUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Update User
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/PatchUserPayloadModel} opts.patchUserPayloadModel 
     * @param {module:api/UserApi~updateUserUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    updateUserUsingPATCH(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['patchUserPayloadModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateUserUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/user/update/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the userChangePasswordUsingPATCH operation.
     * @callback module:api/UserApi~userChangePasswordUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * User change Password
     * @param {Object} opts Optional parameters
     * @param {module:model/PatchUserPasswordPayloadModel} opts.patchUserPasswordPayloadModel 
     * @param {module:api/UserApi~userChangePasswordUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    userChangePasswordUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['patchUserPasswordPayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/user/change-password', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
