import moment from "moment";
import { routes } from "../global/Routes";
import PrivateRoute from "./PrivateRoute";
import {
  getUserInfo,
  clearAccessToken,
  clearUserInfo,
  clearRefreshToken,
} from "./LocalStorage";
import { LOGIN } from "./Constants";

//check email
export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

//check password
export const validatePassword = (password) => {
  return (
    String(password)
      .toLowerCase()
      .match(/^[a-zA-Z0-9]{8,}$/g) &&
    String(password)
      .toLowerCase()
      .match(/^(((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/)
  );
  //(/^(?=.*\d)(?=.*[a-z])(?=.*[0-9])(?=.*[a-z0-9]).{8,}$/);
  //(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/);
};

// //check password no cointain special characters
// export const validatePasswordNotCointainSpecialChar = (password) => {
//     return String(password)
//         .toLowerCase()
//         .match(/^[a-zA-Z0-9]$/g);
//     //(/^(?=.*\d)(?=.*[a-z])(?=.*[0-9])(?=.*[a-z0-9]).{8,}$/);
//     //(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/);
// };

// //check password more than eight number
// export const validatePasswordMoreThanEightNumber = (password) => {
//     return String(password)
//         .toLowerCase()
//         .match(/^\d{8,}$/g);
//     //(/^(?=.*\d)(?=.*[a-z])(?=.*[0-9])(?=.*[a-z0-9]).{8,}$/);
//     //(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/);
// };

// //check password no cointain special characters
// export const validatePasswordCointainNumChar = (password) => {
//     return String(password)
//         .toLowerCase()
//         .match(/^(((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/);
//     //(/^(?=.*\d)(?=.*[a-z])(?=.*[0-9])(?=.*[a-z0-9]).{8,}$/);
//     //(/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})/);
// };

export const isAuth = (checkPermission, userInfo) => {
  return userInfo !== null && (checkPermission == null || checkPermission);
};

export const getRouteEntry = (userInfo) => {
  // if(getUserPermission() !== undefined){

  var innerRoutes = (routes(userInfo) || []).map((it) =>
    getRoute(it, userInfo)
  );
  var inner =
    innerRoutes.length > 0 ? innerRoutes.reduce((a, b) => a.concat(b)) : [];
  return inner;
  // }
};
export const getRoute = (route, userInfo) => {
  var innerRoutes = (route.subRoute || []).map((it) => getRoute(it));
  var inner =
    innerRoutes.length > 0 ? innerRoutes.reduce((a, b) => a.concat(b)) : [];
  var selfElement = [];
  if (route.Component && route.path)
    selfElement.push(
      <PrivateRoute
        isAuth={isAuth(route.checkPermission, userInfo)}
        route={route}
        key={route.path}
      />
    );
  return [...selfElement, ...inner];
};

export function getArgsQuery() {
  var searchStr =
    window.location.search.length > 0
      ? window.location.search.substring(1)
      : "";

  var searchStrArray = searchStr.length > 0 ? searchStr.split("&") : [];

  var args = {};
  searchStrArray.forEach(function (item) {
    var name = decodeURIComponent(item.split("=")[0]);

    var value = decodeURIComponent(item.split("=")[1]);
    args[name] = value;
  });
  return args;
}

export const timestampToDate = (value, pattern) => {
  return moment(value).format(pattern).toString();
};

export function getFileName(val) {
  var filename = val.split("\\").pop().split("/").pop();
  filename = filename.substring(0, filename.lastIndexOf("."));
  return filename;
}

export function getFileExtension(filename) {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
}

export function setIdleLogout() {
  var idleTime = 0;

  setInterval(timerIncrement, 1000);

  const handleMovment = (e) => {
    idleTime = 0;
  };

  function timerIncrement() {
    if (getUserInfo()) {
      idleTime = idleTime + 1;
      //console.log("idleTime", idleTime);

      if (idleTime > 60 * 30) {
        clearAccessToken();
        clearRefreshToken();
        clearUserInfo();
        window.location = LOGIN;
        idleTime = 0;
      }
    }
  }
  if (document.readyState === "interactive") {
    document.removeEventListener("keydown", handleMovment);
    document.addEventListener("keydown", handleMovment);
    document.removeEventListener("mousemove", handleMovment);
    document.addEventListener("mousemove", handleMovment);
  }
}
