import localforage from "localforage";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../utils/withRouter";
//import BackToMain from '../../../components/Reader/ba';"../../components/backToMain";
import PopupMenu from "../../../components/Reader/popups/popupMenu/component";
import {
  handleActionDialog,
  handleFetchBookmarks,
  handleFetchBooks,
  handleFetchNotes,
  handleReadingBook,
  handleReadingEpub,
  handleReadingState,
  handleReaderMode,
  handleReaderLog,
} from "../../../redux/actions/reader";
import { getArgsQuery } from "../../../utils/GlobalFunction";
import BookUtil from "../../../utils/reader/fileUtils/bookUtil"; //"../../../utils/fileUtils/bookUtil";
import { handleLinkJump } from "../../../utils/reader/readUtils/linkUtil";
import RecentBooks from "../../../utils/reader/readUtils/recordRecent"; //"../../../utils/readUtils/recordRecent";
import { pdfMouseEvent } from "../../../utils/reader/serviceUtils/mouseEvent";
import { withSocket } from "../../../utils/withSocket";
import ReadingTime from "../../../utils/reader/readUtils/readingTime";
//import { ReaderLogControllerApi } from "../../../fetcher";
import { genApiConfig } from "../../../utils/fetch-caller";
import { getCustHightlightCoords } from "../../../utils/reader/fileUtils/pdfUtil";
import bookmarkIcon from "../../../assets/images/toolbarButton-bookmark.svg";
import DialogBox from "../../../components/DialogBox/DialogBox";
import * as PATH from "../../../utils/Constants"; //"../../../utils/Contants";

import { Trans } from "react-i18next";
import Button from "../../../components/Button/Button";

class PdfReader extends React.Component {
  //epub: any;
  tickTimer;
  countReadingTime;
  constructor(props) {
    super(props);
    this.state = {
      href: "",
      title: "",
      cfiRange: null,
      contents: null,
      rect: null,
      pageWidth: 0,
      pageHeight: 0,
      loading: true,
      countPage: 0,
      countTime: 0,
      hightlightCoords: {},
      hightlightRange: {},
      time: ReadingTime.getTime(this.props.currentBook.key),
      openBookmarkDialog: false,
      bookmarkRecord: [],
    };
  }
  componentWillMount() {
    this.countReadingTime = setInterval(() => {
      if (
        this.props.readerLog === undefined &&
        this.props.userInfo?.member !== undefined
      ) {
        // new ReaderLogControllerApi(genApiConfig())
        //   .getLogUsingGET({
        //     memberId: this.props.userInfo.member.memberId,
        //     productId: getArgsQuery()?.bookId,
        //   })
        //   .then((res) => {
        //     if (res.content !== undefined) {
        //       let contentObj = JSON.parse(res.content);
        //       this.props.handleReaderLog(contentObj);
        //     }
        //   })
        //   .catch((err) => {
        //     console.log("readerLog err", err);
        //   });
      } else {
        let readerLogObj = this.props.readerLog;

        const pdfJsHistory = localStorage.getItem("pdfjs.history");

        if (readerLogObj !== undefined && pdfJsHistory !== null) {
          if (readerLogObj.time === undefined) {
            readerLogObj.time = 1;
          } else {
            readerLogObj.time++;
            this.setState({
              ...this.state,
              countTime: this.state.countTime + 1,
            });
          }

          if (pdfJsHistory !== null) {
            try {
              let pdfJsHistoryObj = JSON.parse(pdfJsHistory);
              pdfJsHistoryObj.files.map((pdfPage) => {
                if (pdfPage.fingerprint === pdfJsHistoryObj.current) {
                  //percentage
                  readerLogObj.percentage = pdfPage.page / pdfPage.total;

                  //bookmark
                  if (readerLogObj.bookmark !== undefined) {
                    this.setState({
                      ...this.state,
                      bookmarkRecord: readerLogObj.bookmark,
                    });
                    let bookmarkPage = readerLogObj.bookmark.find(
                      (mark) => mark === pdfPage.page
                    );

                    const allIframe = document.querySelectorAll("iframe");
                    const currentFrame = allIframe[0];
                    const viewerContainer =
                      currentFrame.contentWindow.document.getElementById(
                        "viewerContainer"
                      );
                    if (bookmarkPage !== undefined) {
                      if (
                        viewerContainer !== null &&
                        viewerContainer !== undefined
                      ) {
                        let bookmarkPageDiv =
                          currentFrame.contentWindow.document.getElementById(
                            `markDiv-${pdfPage.page}`
                          );

                        if (bookmarkPageDiv === null) {
                          let bookmarkDiv = document.createElement("div");
                          bookmarkDiv.id = `markDiv-${pdfPage.page}`;
                          bookmarkDiv.className = "bookmarkDiv";
                          bookmarkDiv.style.backgroundRepeat = "no-repeat";
                          bookmarkDiv.style.backgroundSize = "contain";
                          bookmarkDiv.style.margin = "16px";
                          bookmarkDiv.style.position = "absolute";
                          bookmarkDiv.style.top = "0%";
                          bookmarkDiv.style.right = "0%";
                          bookmarkDiv.style.width = 30 + "px";
                          bookmarkDiv.style.height = 30 + "px";
                          let http = "http";
                          if (
                            window.location.host.split(":")[0] !== "localhost"
                          ) {
                            http = "https";
                          }
                          bookmarkDiv.style.backgroundImage = `url(${http}://${window.location.host}/lib/pdf/web/images/toolbarButton-bookmark.svg)`;
                          viewerContainer.appendChild(bookmarkDiv);
                        }
                      }
                    } else {
                      const bookmarkDiv =
                        currentFrame.contentWindow.document.getElementsByClassName(
                          "bookmarkDiv"
                        );

                      for (var i = 0, len = bookmarkDiv.length; i != len; ++i) {
                        bookmarkDiv[0].parentNode.removeChild(bookmarkDiv[0]);
                      }
                    }
                  }
                }
              });
            } catch (e) {}
          }

          if (
            this.props.userInfo.member !== undefined &&
            this.state.countTime >= 10
          ) {
            this.setState({ ...this.state, countTime: 0 });
            //console.log("saveLogUsingPOST", readerLogObj);
            // new ReaderLogControllerApi(genApiConfig())
            //   .saveLogUsingPOST({
            //     memberId: this.props.userInfo.member.memberId,
            //     productId: getArgsQuery()?.bookId,
            //     body: readerLogObj,
            //   })
            //   .then((res) => {
            //     let contentObj = JSON.parse(res.content);
            //     this.props.handleReaderLog(contentObj);
            //   })
            //   .catch((err) => {
            //     console.log("reader log save err", err);
            //   });
          }
        }
      }
    }, 1000);

    this.props.handleFetchBookmarks();
    this.props.handleFetchNotes();
    this.props.handleFetchBooks();
  }

  componentWillReceiveProps(nextProps) {
    //console.log("nextprops", nextProps);
    // if (
    //   Object.keys(nextProps.currentBook).length > 0 &&
    //   this.props.readerLog === undefined &&
    //   this.props.userInfo.member !== undefined
    // ) {
    // new ReaderLogControllerApi(genApiConfig())
    //   .getLogUsingGET({
    //     memberId: this.props.userInfo.member.memberId,
    //     productId: getArgsQuery()?.bookId,
    //   })
    //   .then((res) => {
    //     if (res !== null) {
    //       let contentObj = JSON.parse(res.content);
    //       if (contentObj !== undefined) {
    //         if (contentObj.time === undefined) {
    //           contentObj.time = 0;
    //         }
    //         const readerConfig = localStorage.getItem("readerConfig");
    //         let readerConfigObj;
    //         try {
    //           readerConfigObj = JSON.parse(readerConfig);
    //         } catch (e) {
    //           console.error("readerConfig", e);
    //         }
    //         if (contentObj !== undefined) {
    //           if (readerConfigObj !== undefined) {
    //             if (readerConfigObj.rm !== undefined) {
    //               if (readerConfigObj.rm === "p") {
    //                 if (contentObj.trycount === undefined) {
    //                   contentObj.trycount = 1;
    //                 } else {
    //                   contentObj.trycount++;
    //                 }
    //               } else {
    //                 if (contentObj.viewcount === undefined) {
    //                   contentObj.viewcount = 1;
    //                 } else {
    //                   contentObj.viewcount++;
    //                 }
    //               }
    //             }
    //           }
    //         }
    //         // if (contentObj.viewcount === undefined) {
    //         //   contentObj.viewcount = 1;
    //         // } else {
    //         //   contentObj.viewcount++;
    //         // }
    //       }
    //       this.props.handleReaderLog(contentObj);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("reader log get err", err);
    //   });
    //}
  }

  componentWillUnmount() {
    clearInterval(this.countReadingTime);
  }

  componentDidMount() {
    let url = document.location.href.split("/");
    let key = url[url.length - 1].split("?")[0];
    localforage.getItem("books").then((result) => {
      let book;
      //console.log("rrrrr", this.props);
      //console.log("rrrrr", result);
      if (this.props.currentBook.key) {
        book = this.props.currentBook;
      } else {
        book = result;
        //result[_.findIndex(result, { key })] ||
        //JSON.parse(localStorage.getItem("tempBook") || "{}");
      }
      document.title = book.name;
      this.props.handleReadingState(true);
      RecentBooks.setRecent(key);
      this.props.handleReadingBook(book);
      this.setState({ title: book.name });
      this.setState({ href: BookUtil.getPDFUrl(book) });
    });
    document
      .querySelector(".ebook-viewer")
      ?.setAttribute("style", "height:100%");
    let pageArea = document.getElementById("page-area");
    if (!pageArea) return;
    let iframe = pageArea.getElementsByTagName("iframe")[0];
    if (!iframe) return;
    iframe.onload = () => {
      let doc = iframe.contentWindow || iframe.contentDocument?.defaultView;
      this.setState({ loading: false });
      pdfMouseEvent();
      doc.document.addEventListener("click", (event) => {
        // console.log("click1", event);
        // console.log("click2", this.state);
        // console.log("click3", event.target.id);
        // console.log("click3", event.target);

        //#region Bookmark save
        if (event.target.id === "viewBookmark") {
          this.setState({ ...this.state, openBookmarkDialog: true });
        }
        //#endregion

        //#region Bookmark remove
        if (event.target.id.includes("markDiv")) {
          //this.removeBookmarkRecord(null);
        }
        //#endregion

        //#region check pdf preview is over
        //TODO
        if (event.target.id === "next2") {
          const readerConfig = localStorage.getItem("readerConfig");
          if (readerConfig !== null) {
            const readerConfigObj = JSON.parse(readerConfig);
            if (readerConfigObj.rm === "p") {
              const pdfJsHistoryObj = JSON.parse(
                localStorage.getItem("pdfjs.history")
              );
              pdfJsHistoryObj.files.map((value) => {
                if (
                  pdfJsHistoryObj.current === value.fingerprint &&
                  value.page > 10
                ) {
                  document.getElementsByClassName(
                    "dialogBoxClass"
                  )[0].style.display = "block";
                }
              });
            }
          }
        }
        //#endregion
        event.preventDefault();
        handleLinkJump(event);
      });

      doc.document.addEventListener("mouseup", () => {
        try {
          let { page, coords } = getCustHightlightCoords();

          let currentPdf;
          if (page === 0) {
            const pdfJsHistory = localStorage.getItem("pdfjs.history");
            if (pdfJsHistory !== null) {
              const pdfJsHistoryObj = JSON.parse(pdfJsHistory);
              for (let i = 0; i < pdfJsHistoryObj.files.length; i++) {
                if (
                  pdfJsHistoryObj.current ===
                  pdfJsHistoryObj.files[i].fingerprint
                ) {
                  page = pdfJsHistoryObj.files[i].page;
                  currentPdf = pdfJsHistoryObj.files[i];
                }
              }
            }
          }

          this.setState({
            ...this.state,
            hightlightCoords: { coords, currentPdf },
            hightlightRange: doc.getSelection().getRangeAt(0),
          });

          if (doc === null) return;
          if (doc.getSelection() === null) return;
          var rect = doc.getSelection().getRangeAt(0).getBoundingClientRect();
          this.setState({
            rect,
            pageWidth: doc.document.body.scrollWidth,
            pageHeight: doc.document.body.scrollHeight,
          });
        } catch (e) {
          console.log("mouseup e", e);
        }
      });
    };
    let siteSerachParams = getArgsQuery();
    let bookObj = JSON.stringify({ bookId: siteSerachParams.bookId });

    this.props.setBookPage(bookObj);
  }

  saveBookmarkRecord() {
    const pdfJsHistory = localStorage.getItem("pdfjs.history");

    if (pdfJsHistory !== null) {
      const pdfJsHistoryObj = JSON.parse(pdfJsHistory);
      for (let i = 0; i < pdfJsHistoryObj.files.length; i++) {
        if (pdfJsHistoryObj.files[i].fingerprint === pdfJsHistoryObj.current) {
          const currentPage = pdfJsHistoryObj.files[i];

          if (this.props.userInfo.member !== undefined) {
            let readerLogObj = this.props.readerLog;
            if (readerLogObj["bookmark"] === undefined) {
              readerLogObj["bookmark"] = [];
              readerLogObj["bookmark"].push(currentPage.page);
            } else {
              if (currentPage.spreadMode === 0) {
                let markPage = readerLogObj["bookmark"].find(
                  (markPage) => markPage === currentPage.page
                );
                if (markPage === undefined) {
                  readerLogObj["bookmark"].push(currentPage.page);
                }
              }

              if (currentPage.spreadMode > 0) {
                let markPage = readerLogObj["bookmark"].find(
                  (markPage) => markPage === currentPage.page
                );

                if (markPage === undefined) {
                  readerLogObj["bookmark"].push(currentPage.page);
                }

                let markPage2 = readerLogObj["bookmark"].find(
                  (markPage) => markPage === currentPage.page + 1
                );

                if (markPage2 === undefined) {
                  readerLogObj["bookmark"].push(currentPage.page + 1);
                }
              }
            }

            // new ReaderLogControllerApi(genApiConfig())
            //   .saveLogUsingPOST({
            //     memberId: this.props.userInfo.member.memberId,
            //     productId: getArgsQuery()?.bookId,
            //     body: readerLogObj,
            //   })
            //   .then((res) => {
            //     let contentObj = JSON.parse(res.content);
            //     this.props.handleReaderLog(contentObj);
            //   })
            //   .catch((err) => {
            //     console.log("reader log save err", err);
            //   });
          }
        }
      }
    }
  }

  removeBookmarkRecord(page) {
    const pdfJsHistory = localStorage.getItem("pdfjs.history");
    if (pdfJsHistory !== null) {
      const pdfJsHistoryObj = JSON.parse(pdfJsHistory);
      for (let i = 0; i < pdfJsHistoryObj.files.length; i++) {
        let currentPdf = pdfJsHistoryObj.files[i];

        if (page !== null) {
          currentPdf.page = page;
        }

        if (currentPdf.fingerprint === pdfJsHistoryObj.current) {
          let readerLogObj = this.props.readerLog;

          let findElementIndex = readerLogObj.bookmark.findIndex(
            (mark) => mark === currentPdf.page
          );

          if (findElementIndex > -1) {
            readerLogObj.bookmark.splice(findElementIndex, 1);
          }

          // if (currentPdf.spreadMode === 0) {
          //   let findElementIndex = readerLogObj.bookmark.findIndex(
          //     (mark) => mark === currentPdf.page
          //   );

          //   if (findElementIndex > -1) {
          //     readerLogObj.bookmark.splice(findElementIndex, 1);
          //   }
          // }

          // if (currentPdf.spreadMode > 0) {
          //   let findElementIndex = readerLogObj.bookmark.findIndex(
          //     (mark) => mark === currentPdf.page
          //   );

          //   if (findElementIndex > -1) {
          //     readerLogObj.bookmark.splice(findElementIndex, 1);
          //   }

          //   let findElementIndex2 = readerLogObj.bookmark.findIndex(
          //     (mark) => mark === currentPdf.page + 1
          //   );

          //   if (findElementIndex2 > -1) {
          //     readerLogObj.bookmark.splice(findElementIndex2, 1);
          //   }
          // }

          // new ReaderLogControllerApi(genApiConfig())
          //   .saveLogUsingPOST({
          //     memberId: this.props.userInfo.member.memberId,
          //     productId: getArgsQuery()?.bookId,
          //     body: readerLogObj,
          //   })
          //   .then((res) => {
          //     let contentObj = JSON.parse(res.content);
          //     this.props.handleReaderLog(contentObj);
          //   })
          //   .catch((err) => {
          //     console.log("reader log save err", err);
          //   });
        }
      }
    }
  }

  goToPage(page) {
    const pdfJsHistory = localStorage.getItem("pdfjs.history");
    if (pdfJsHistory !== null) {
      const pdfJsHistoryObj = JSON.parse(pdfJsHistory);
      let readerLogObj = this.props.readerLog;
      for (let i = 0; i < pdfJsHistoryObj.files.length; i++) {
        if (pdfJsHistoryObj.files[i].fingerprint === pdfJsHistoryObj.current) {
          pdfJsHistoryObj.files[i].page = page;
          readerLogObj.page = page;
        }
      }
      this.props.handleReaderLog(readerLogObj);
      localStorage.setItem("pdfjs.history", JSON.stringify(pdfJsHistoryObj));
      this.setState({ ...this.state, openBookmarkDialog: false });
    }
  }

  render() {
    return (
      <>
        <div className="ebook-viewer" id="page-area">
          {!this.state.loading && (
            <PopupMenu
              {...{
                rendition: {
                  on: (status, callback) => {
                    callback();
                  },
                },
                rect: this.state.rect,
                pageWidth: this.state.pageWidth,
                pageHeight: this.state.pageHeight,
                chapterIndex: 0,
                chapter: "0",
                readerType: "PDF",
              }}
            />
          )}
          <iframe
            src={this.state.href}
            title={this.state.title}
            width="100%"
            height="100%"
          >
            Loading
          </iframe>
          {/* <Toaster /> */}
        </div>
        <DialogBox
          styleType={PATH.STYLETYPE_FIVE}
          title={<Trans>MyBookmark</Trans>}
          p1={this.state.bookmarkRecord
            .sort((a, b) => {
              return a - b;
            })
            .map((pageNumber) => {
              return (
                <>
                  <div class="bookmarkRecord_row">
                    <span style={{ flex: "0 1 100%" }}>
                      <Trans i18nKey="Book Page" count={pageNumber}>
                        {{ pageNumber }}
                      </Trans>
                    </span>
                    <div class="bookmarkRecord_row_btn">
                      <Button
                        styleType={"submitButton"}
                        callBack={() => this.goToPage(pageNumber)}
                      >
                        <Trans>Go To</Trans>
                      </Button>
                      <div
                        class="delete_btn"
                        onClick={() => this.removeBookmarkRecord(pageNumber)}
                      >
                        X
                      </div>
                    </div>
                  </div>
                  <br />
                </>
              );
            })}
          showDialog={this.state.openBookmarkDialog}
          children={
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  styleType={"cancelButton"}
                  callBack={() =>
                    this.setState({ ...this.state, openBookmarkDialog: false })
                  }
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  styleType={"submitButton"}
                  callBack={() => this.saveBookmarkRecord()}
                >
                  <Trans>addFavorites</Trans>
                </Button>
              </div>
            </>
          }
        />
        {/* TODO preview dialog */}
        <DialogBox
          styleType={PATH.STYLETYPE_SIX}
          title="試閱完"
          children={
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "15px",
              }}
            >
              <button
                style={{
                  color: "white",
                  background:
                    "linear-gradient(90deg, rgba(232 112 60) 0%, rgba(181 60 63) 100%)",
                  width: "100%",
                  borderRadius: "50px",
                  height: "35px",
                  maxWidth: "90px",
                  margin: "0 3px",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  document.getElementsByClassName(
                    "dialogBoxClass"
                  )[0].style.display = "none";
                }}
              >
                確定
              </button>
            </div>
          }
        />
        {/* TODO preview dialog */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOpenActionDialog: state.book.isOpenActionDialog,
    currentBook: state.book.currentBook,
    isReading: state.book.isReading,
    userInfo: state.userInfo.userInfo,
    readerLog: state.reader.readerLog,
  };
};
const actionCreator = {
  handleReadingState,
  handleReadingBook,
  handleReadingEpub,
  handleActionDialog,
  handleFetchNotes,
  handleFetchBookmarks,
  handleFetchBooks,
  handleReaderMode,
  handleReaderLog,
};
export default connect(mapStateToProps, actionCreator)(withSocket(PdfReader));
