import { nanoid } from "nanoid";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import { connect } from "react-redux";
import eyeIcon from "../../../assets/images/btn/eye.svg";
import loadingGif from "../../../assets/images/loading.gif";
import { UserApi } from "../../../fetcher";
import { handleUserInfo } from "../../../redux/actions";
import {
  DIALOG_CANCEL,
  DIALOG_COMPLETE,
  STYLETYPE_FOUR,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
} from "../../../utils/Constants";
import { genApiConfig } from "../../../utils/fetch-caller";
import { validatePassword } from "../../../utils/GlobalFunction";
import Button from "../../Button/Button";
import Input from "../../Input/Input";
import style from "./ChangePassWordSheet.module.css";

var sha512 = require("js-sha512");

class ChangePassWordSheet extends Component {
  state = {
    content: { oldpw: "", newpw: "", confirmPw: "" },
    useHash: false,
    showError: false,
    loading: false,
    showPwdOld: false,
    showPwdNew: false,
    showPwdConfirm: false,
  };

  static propTypes = {
    actionType: PropTypes.string.isRequired,
    callBack: PropTypes.func,
  };

  static defaultProps = {
    content: {},
  };

  handleCancel = (value) => {
    this.resetState();
    this.props.callBack({ action: DIALOG_CANCEL });
  };

  handleSubmit = (value) => {
    if (!this.checkFormValid()) return;

    this.setState({ ...this.state, loading: true });
    if (!this.submitFormData() || this.submitFormData() === undefined) {
      alert("發生錯誤，請再次登入");
      this.props.callBack({ action: DIALOG_COMPLETE });
      return;
    }

    new UserApi(genApiConfig())
      .userChangePasswordUsingPATCH(this.submitFormData())
      .then((res) => {
        // console.log("res,res", res);
        if (res.response.ok) {
          if (res.data.success) {
            alert("更新成功");
            this.props.callBack({ action: DIALOG_COMPLETE });
          } else {
            alert("舊密碼不正確");
          }
        }
      })
      .catch((err) => {
        alert(err.body.message);
        console.log("userChangePasswordUsingPATCH", err.body.message);
      });
    this.resetState();
    this.setState({ ...this.state, loading: false });
    return;
  };

  submitFormData = () => {
    const { newpw, oldpw } = this.state.content;

    var userInfo = this.props.userInfo;

    if (userInfo == null) {
      return;
    }

    var hashnewPw = "";
    var hasholdPw = "";

    hashnewPw = sha512(userInfo.email + newpw);
    hasholdPw = sha512(userInfo.email + oldpw);

    let opts = {};

    opts["patchUserPasswordPayloadModel"] = {
      newPassword: hashnewPw,
      oldPassword: hasholdPw,
    };
    return opts;
  };

  resetState = () => {
    this.setState({
      content: {
        oldpw: "",
        newpw: "",
        confirmPw: "",
      },

      showError: false,
      loading: false,
    });
  };

  handleShowPwdOld = () => {
    const { showPwdOld } = this.state;
    this.setState({ ...this.state, showPwdOld: !showPwdOld });
  };
  handleShowPwdNew = () => {
    const { showPwdNew } = this.state;
    this.setState({ ...this.state, showPwdNew: !showPwdNew });
  };
  handleShowPwdConfirm = () => {
    const { showPwdConfirm } = this.state;
    this.setState({ ...this.state, showPwdConfirm: !showPwdConfirm });
  };

  checkFormValid = () => {
    // this.state.content.oldpw.length < 4
    if (
      this.state.content.oldpw === "" ||
      this.state.content.oldpw === undefined
    ) {
      alert("請輸入舊密碼");

      return false;
    }

    if (
      this.state.content.newpw === "" ||
      this.state.content.newpw === undefined
    ) {
      alert("請輸入新密碼");

      return false;
    }

    if (this.state.content.newpw.length < 8) {
      alert("新密碼需8位字元長度");

      return false;
    }

    if (validatePassword(this.state.content.newpw) == null) {
      alert("請輸入8個位英文字母及數字");

      return false;
    }

    if (
      this.state.content.confirmPw === "" ||
      this.state.content.confirmPw === undefined
    ) {
      alert("請輸入確認密碼");

      return false;
    }

    if (this.state.content.confirmPw !== this.state.content.newpw) {
      alert("請確認兩次輸入的新密碼相同");

      return false;
    }

    if (
      this.state.content.oldpw === this.state.content.newpw ||
      this.state.content.oldpw === this.state.content.confirmPw
    ) {
      alert("新密碼不能與舊密碼相同");

      return false;
    }

    return true;
  };

  handleRandomPwd = () => {
    var newRandomPwd = nanoid(8).replace(
      /[#-_.]/g,
      parseInt(Math.random() * 10)
    );

    //    const { newpw } = this.state.content;
    var newContentObj = Object.assign(this.state.content, {
      newpw: newRandomPwd,
      confirmPw: newRandomPwd,
    });

    this.setState({ ...this.state, content: newContentObj });
  };

  render() {
    const {
      content,
      showError,
      loading,
      showPwdOld,
      showPwdNew,
      showPwdConfirm,
    } = this.state;

    return (
      <Fragment>
        <div className={style.content_height}>
          <div className={style.container}>
            <div className={style.gridRowErrorMsg}>
              <label
                hidden={
                  showError ? (content.oldpw.length === 0 ? false : true) : true
                }
                style={{ color: "red" }}
              >
                密碼必須輸入
              </label>
            </div>
            <label>舊密碼</label>
            <div className={style.row1} style={{ display: "flex" }}>
              <Input
                type={showPwdOld ? "text" : "password"}
                value={content.oldpw}
                styleType={STYLETYPE_MAIN}
                callBack={(value) => {
                  this.setState({
                    ...this.state,
                    content: Object.assign(content, { oldpw: value }),
                  });
                }}
              />
              <div
                className={
                  content.oldpw.length === 0 ? style.RowBtn : style.RowBtn2
                }
              >
                <img
                  hidden={content.oldpw.length !== 0 ? false : true}
                  src={eyeIcon}
                  className={style.eyeicon}
                  onClick={(value) => this.handleShowPwdOld(value)}
                ></img>
              </div>
            </div>

            <div className={style.gridRowErrorMsg}>
              <label
                hidden={
                  showError
                    ? validatePassword(content.newpw) == null
                      ? false
                      : true
                    : true
                }
                style={{ color: "red" }}
              >
                請輸入8個位英文字母及數字
              </label>
            </div>
            <label>新密碼</label>
            <div
              className={content.newpw.length === 0 ? style.row3 : style.row2}
              style={{ display: "flex" }}
            >
              <Input
                type={showPwdNew ? "text" : "password"}
                value={content.newpw}
                styleType={STYLETYPE_MAIN}
                callBack={(value) => {
                  this.setState({
                    ...this.state,
                    content: Object.assign(content, { newpw: value }),
                  });
                }}
              />
              <div
                className={
                  content.newpw.length === 0 ? style.RowBtn : style.RowBtn2
                }
              >
                <img
                  hidden={content.newpw.length !== 0 ? false : true}
                  src={eyeIcon}
                  className={style.eyeicon}
                  onClick={(value) => this.handleShowPwdNew(value)}
                ></img>

                <Button
                  styleType={STYLETYPE_FOUR}
                  callBack={(value) => this.handleRandomPwd(value)}
                >
                  隨機生成
                </Button>
              </div>
            </div>

            <label>再次輸入新密碼</label>
            <div className={style.row1} style={{ display: "flex" }}>
              <Input
                type={showPwdConfirm ? "text" : "password"}
                value={content.confirmPw}
                styleType={STYLETYPE_MAIN}
                callBack={(value) => {
                  this.setState({
                    ...this.state,
                    content: Object.assign(content, { confirmPw: value }),
                  });
                }}
              />
              <div
                className={
                  content.confirmPw.length === 0 ? style.RowBtn : style.RowBtn2
                }
              >
                <img
                  hidden={content.confirmPw.length !== 0 ? false : true}
                  src={eyeIcon}
                  className={style.eyeicon}
                  onClick={(value) => this.handleShowPwdConfirm(value)}
                ></img>
              </div>
            </div>
          </div>
        </div>

        <div className={style.button_on_right}>
          <Button
            styleType={STYLETYPE_SECOND}
            callBack={(value) => this.handleCancel(value)}
          >
            取消
          </Button>

          <Button
            styleType={STYLETYPE_MAIN}
            callBack={(value) => this.handleSubmit(value)}
            disabled={loading}
          >
            {loading ? "" : "儲存"}
            <img
              src={loadingGif}
              style={{ width: "20px", height: "20px" }}
              hidden={!loading}
            />
          </Button>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = { handleUserInfo };
export default connect(mapStateToProps, actionCreator)(ChangePassWordSheet);
