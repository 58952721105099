import PropTypes from "prop-types";
import ApiClient from "../fetcher/ApiClient";
import axiosInstance from "../helper/axiosHelper";
import { getAccessToken, getClientInfo, setClientInfo } from "./LocalStorage";

export const genApiConfig = (noCache = true) => {
  genApiConfig.propTypes = {
    noCache: PropTypes.bool,
  };

  genApiConfig.defaultProps = {
    noCache: true,
  };

  if (getClientInfo() == null) {
    axiosInstance()
      .get("https://json.geoiplookup.io")
      .then((res, err) => {
        if (res.status == 200) {
          setClientInfo(res.data.ip);
        }
      });
  }

  if (getClientInfo() == null) {
    axiosInstance()
      .get("https://ipapi.co/json/")
      .then((res, err) => {
        if (res.status == 200) {
          setClientInfo(res.data.ip);
        }
      });
  }

  // var date = new Date();
  // var currentDate = moment(date).toDate().getTime();

  // var userInfo = getUserInfo();

  // if (userInfo !== null) {
  //   if (userInfo.accessTokenExp - currentDate < 0) {
  //     var fetchPromise = fetch(
  //       process.env.REACT_APP_BACKEND_URL + "/api/auth/token/refresh",
  //       {
  //         headers: {
  //           Authorization: "Bearer " + getRefreshToken(),
  //         },
  //         method: "GET",
  //       }
  //     );

  //     fetchPromise
  //       .then((res) => {
  //         return res.json();
  //       })
  //       .then((data) => {
  //         console.log("fetch", data);

  //         if (data["error message"].includes("The Token has expired")) {
  //         } else {
  //           setAccessToken(data.token.accessToken);
  //           setRefreshToken(data.token.refreshToken);
  //           const userInfoObj = Object.assign(userInfo, {
  //             accessTokenExp: data.token.accessTokenExp,
  //           });

  //           setUserInfo(JSON.stringify(userInfoObj));
  //         }
  //         //data[Object.keys(data)[0]].includes("The Token has expired")
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   }
  // }

  var conf = new ApiClient();

  conf.cache = noCache;
  conf.authentications = {
    Authorization: {
      type: "bearer",
      accessToken: getAccessToken(), //== null ? null : SessionStorage.getItem('accessToken')
    },
  };

  conf.defaultHeaders = {
    "x-forwarded-for": getClientInfo(), //currentIp == null ? "" : currentIp.replaceAll('-','.'),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  };

  return conf;
};
