/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PatchUserPasswordPayloadModel model module.
 * @module model/PatchUserPasswordPayloadModel
 * @version v1.0.0
 */
class PatchUserPasswordPayloadModel {
    /**
     * Constructs a new <code>PatchUserPasswordPayloadModel</code>.
     * @alias module:model/PatchUserPasswordPayloadModel
     * @param newPassword {String} new Password [required]
     * @param oldPassword {String} oldPassword [required]
     */
    constructor(newPassword, oldPassword) { 
        
        PatchUserPasswordPayloadModel.initialize(this, newPassword, oldPassword);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, newPassword, oldPassword) { 
        obj['newPassword'] = newPassword;
        obj['oldPassword'] = oldPassword;
    }

    /**
     * Constructs a <code>PatchUserPasswordPayloadModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PatchUserPasswordPayloadModel} obj Optional instance to populate.
     * @return {module:model/PatchUserPasswordPayloadModel} The populated <code>PatchUserPasswordPayloadModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PatchUserPasswordPayloadModel();

            if (data.hasOwnProperty('newPassword')) {
                obj['newPassword'] = ApiClient.convertToType(data['newPassword'], 'String');
            }
            if (data.hasOwnProperty('oldPassword')) {
                obj['oldPassword'] = ApiClient.convertToType(data['oldPassword'], 'String');
            }
        }
        return obj;
    }


}

/**
 * new Password [required]
 * @member {String} newPassword
 */
PatchUserPasswordPayloadModel.prototype['newPassword'] = undefined;

/**
 * oldPassword [required]
 * @member {String} oldPassword
 */
PatchUserPasswordPayloadModel.prototype['oldPassword'] = undefined;






export default PatchUserPasswordPayloadModel;

