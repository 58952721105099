/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AddPurchaseMissionPayloadModel from '../model/AddPurchaseMissionPayloadModel';
import EditPurchaseMissionPayloadModel from '../model/EditPurchaseMissionPayloadModel';
import PurchaseMissionClonePayloadModel from '../model/PurchaseMissionClonePayloadModel';
import PurchaseMissionDto from '../model/PurchaseMissionDto';
import PurchaseMissionInfoList from '../model/PurchaseMissionInfoList';
import SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto from '../model/SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto';
import SearchingQueryResponseModelPurchaseMissionDto from '../model/SearchingQueryResponseModelPurchaseMissionDto';

/**
* PurchaseMission service.
* @module api/PurchaseMissionApi
* @version v1.0.0
*/
export default class PurchaseMissionApi {

    /**
    * Constructs a new PurchaseMissionApi. 
    * @alias module:api/PurchaseMissionApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addPurchaseMissionUsingPOST operation.
     * @callback module:api/PurchaseMissionApi~addPurchaseMissionUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PurchaseMissionDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add Mission
     * @param {Object} opts Optional parameters
     * @param {module:model/AddPurchaseMissionPayloadModel} opts.addPurchaseMissionPayloadModel 
     * @param {module:api/PurchaseMissionApi~addPurchaseMissionUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PurchaseMissionDto}
     */
    addPurchaseMissionUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['addPurchaseMissionPayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = PurchaseMissionDto;
      return this.apiClient.callApi(
        '/api/purchase/mission/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the clonePurchaseMissionUsingPOST operation.
     * @callback module:api/PurchaseMissionApi~clonePurchaseMissionUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PurchaseMissionDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * clone Mission
     * @param {Number} missionId missionId
     * @param {Object} opts Optional parameters
     * @param {module:model/PurchaseMissionClonePayloadModel} opts.purchaseMissionClonePayloadModel 
     * @param {module:api/PurchaseMissionApi~clonePurchaseMissionUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PurchaseMissionDto}
     */
    clonePurchaseMissionUsingPOST(missionId, opts, callback) {
      opts = opts || {};
      let postBody = opts['purchaseMissionClonePayloadModel'];
      // verify the required parameter 'missionId' is set
      if (missionId === undefined || missionId === null) {
        throw new Error("Missing the required parameter 'missionId' when calling clonePurchaseMissionUsingPOST");
      }

      let pathParams = {
        'missionId': missionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = PurchaseMissionDto;
      return this.apiClient.callApi(
        '/api/purchase/mission/{missionId}/clone', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deletePurchaseMissionUsingDELETE operation.
     * @callback module:api/PurchaseMissionApi~deletePurchaseMissionUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Boolean} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Delete Mission
     * @param {Number} missionId missionId
     * @param {module:api/PurchaseMissionApi~deletePurchaseMissionUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Boolean}
     */
    deletePurchaseMissionUsingDELETE(missionId, callback) {
      let postBody = null;
      // verify the required parameter 'missionId' is set
      if (missionId === undefined || missionId === null) {
        throw new Error("Missing the required parameter 'missionId' when calling deletePurchaseMissionUsingDELETE");
      }

      let pathParams = {
        'missionId': missionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/api/purchase/mission/{missionId}/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the editPurchaseMissionUsingPATCH operation.
     * @callback module:api/PurchaseMissionApi~editPurchaseMissionUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PurchaseMissionDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * edit Mission
     * @param {Number} missionId missionId
     * @param {Object} opts Optional parameters
     * @param {module:model/EditPurchaseMissionPayloadModel} opts.editPurchaseMissionPayloadModel 
     * @param {module:api/PurchaseMissionApi~editPurchaseMissionUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PurchaseMissionDto}
     */
    editPurchaseMissionUsingPATCH(missionId, opts, callback) {
      opts = opts || {};
      let postBody = opts['editPurchaseMissionPayloadModel'];
      // verify the required parameter 'missionId' is set
      if (missionId === undefined || missionId === null) {
        throw new Error("Missing the required parameter 'missionId' when calling editPurchaseMissionUsingPATCH");
      }

      let pathParams = {
        'missionId': missionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = PurchaseMissionDto;
      return this.apiClient.callApi(
        '/api/purchase/mission/{missionId}/edit', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPurchaseMissionIsSentAndFinishUsingGET operation.
     * @callback module:api/PurchaseMissionApi~getPurchaseMissionIsSentAndFinishUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Sent and Finished PurchaseMission
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Number} opts.limit return size
     * @param {module:api/PurchaseMissionApi~getPurchaseMissionIsSentAndFinishUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto}
     */
    getPurchaseMissionIsSentAndFinishUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto;
      return this.apiClient.callApi(
        '/api/purchase/mission/getSentAndFinish', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPurchaseMissionUsingGET operation.
     * @callback module:api/PurchaseMissionApi~getPurchaseMissionUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PurchaseMissionDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Mission
     * @param {Number} missionId missionId
     * @param {module:api/PurchaseMissionApi~getPurchaseMissionUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PurchaseMissionDto}
     */
    getPurchaseMissionUsingGET(missionId, callback) {
      let postBody = null;
      // verify the required parameter 'missionId' is set
      if (missionId === undefined || missionId === null) {
        throw new Error("Missing the required parameter 'missionId' when calling getPurchaseMissionUsingGET");
      }

      let pathParams = {
        'missionId': missionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PurchaseMissionDto;
      return this.apiClient.callApi(
        '/api/purchase/mission/{missionId}/get', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPurchaseMissionWithInfoListUsingGET operation.
     * @callback module:api/PurchaseMissionApi~getPurchaseMissionWithInfoListUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/PurchaseMissionInfoList} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get mission request data
     * @param {Number} missionId missionId
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from from
     * @param {Number} opts.limit limit
     * @param {module:api/PurchaseMissionApi~getPurchaseMissionWithInfoListUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/PurchaseMissionInfoList}
     */
    getPurchaseMissionWithInfoListUsingGET(missionId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'missionId' is set
      if (missionId === undefined || missionId === null) {
        throw new Error("Missing the required parameter 'missionId' when calling getPurchaseMissionWithInfoListUsingGET");
      }

      let pathParams = {
        'missionId': missionId
      };
      let queryParams = {
        'from': opts['from'],
        'limit': opts['limit']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = PurchaseMissionInfoList;
      return this.apiClient.callApi(
        '/api/purchase/mission/{missionId}/getWithInfoList', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPurchaseMissionsUsingGET operation.
     * @callback module:api/PurchaseMissionApi~getPurchaseMissionsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelPurchaseMissionDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * User get Missions
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {module:model/String} opts.status status
     * @param {Boolean} opts.withRole 
     * @param {module:api/PurchaseMissionApi~getPurchaseMissionsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelPurchaseMissionDto}
     */
    getPurchaseMissionsUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'status': opts['status'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelPurchaseMissionDto;
      return this.apiClient.callApi(
        '/api/purchase/missions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
