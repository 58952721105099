import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import loadingGif from "../../assets/images/loading.gif";
import { handleUserInfo } from "../../redux/actions";
import {
  CODE_BOOK_UNAVAILABLE,
  CODE_NO_NEED_AUTH,
  STYLETYPE_DEFAULT,
  STYLETYPE_FIVE,
  STYLETYPE_FOUR,
  STYLETYPE_IMG,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_SEVEN,
  STYLETYPE_SIX,
  STYLETYPE_THIRD,
} from "../../utils/Constants";
import style from "./Button.module.css";

class Button extends Component {
  static propTypes = {
    styleType: PropTypes.string,
    disabled: PropTypes.bool,
    hidden: PropTypes.bool,
    buttonType: PropTypes.string,
    customStyle: PropTypes.object,
    code: PropTypes.string.isRequired,
    callBack: PropTypes.func,
    selected: PropTypes.bool,
    isLoading: PropTypes.bool,
    loadingStyle: PropTypes.object,
  };

  static defaultProps = {
    buttonType: "button",
    disabled: false,
    hidden: false,
    styleType: STYLETYPE_DEFAULT,
    code: CODE_NO_NEED_AUTH,
    customStyle: {},
    loadingStyle: {},
    isLoading: false,
    selected: false,
  };

  handleClick = () => {
    this.props.callBack(true);
  };

  render() {
    const {
      buttonType,
      styleType,
      hidden,
      disabled,
      code,
      selected,
      isLoading,
      loadingStyle,
    } = this.props;

    return (
      <>
        {process.env.REACT_APP_DEBUG === true ? (
          <>
            <button
              hidden={hidden}
              disabled={disabled}
              selected={selected}
              type={buttonType}
              onClick={this.handleClick}
              className={
                styleType === STYLETYPE_MAIN
                  ? style.btnPrimary
                  : styleType === STYLETYPE_SECOND
                  ? style.btnSecond
                  : styleType === STYLETYPE_THIRD
                  ? style.btnThird
                  : styleType === STYLETYPE_FOUR
                  ? style.btnFour
                  : styleType === STYLETYPE_FIVE
                  ? style.btnFive
                  : styleType === STYLETYPE_SIX
                  ? style.btnSix
                  : styleType === STYLETYPE_SEVEN
                  ? style.btnSeven
                  : styleType === STYLETYPE_IMG
                  ? style.btnImg
                  : ""
              }
            >
              {isLoading ? (
                <>
                  <img
                    src={loadingGif}
                    style={
                      loadingStyle !== {}
                        ? loadingStyle
                        : { width: "20px", height: "20px" }
                    }
                    hidden={!isLoading}
                    alt={loadingGif}
                  />
                </>
              ) : (
                this.props.children
              )}
            </button>
          </>
        ) : (
          <>
            {(this.props.userInfo &&
              this.props.userInfo.organizations
                ?.find((it) => it.isSelected)
                .role.permissions.find((per) => per.code === code) !==
                undefined) ||
            code === CODE_NO_NEED_AUTH ||
            code === true ? (
              <button
                hidden={hidden}
                disabled={disabled}
                selected={selected}
                type={buttonType}
                onClick={this.handleClick}
                className={
                  styleType === STYLETYPE_MAIN
                    ? style.btnPrimary
                    : styleType === STYLETYPE_SECOND
                    ? style.btnSecond
                    : styleType === STYLETYPE_THIRD
                    ? style.btnThird
                    : styleType === STYLETYPE_FOUR
                    ? style.btnFour
                    : styleType === STYLETYPE_FIVE
                    ? style.btnFive
                    : styleType === STYLETYPE_SIX
                    ? style.btnSix
                    : styleType === STYLETYPE_SEVEN
                    ? style.btnSeven
                    : styleType === STYLETYPE_IMG
                    ? style.btnImg
                    : ""
                }
              >
                {isLoading ? (
                  <>
                    <img
                      src={loadingGif}
                      style={
                        loadingStyle !== {}
                          ? loadingStyle
                          : { width: "20px", height: "20px" }
                      }
                      hidden={!isLoading}
                      alt={loadingGif}
                    />
                  </>
                ) : (
                  this.props.children
                )}
              </button>
            ) : (
              <></>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = { handleUserInfo };
export default connect(mapStateToProps, actionCreator)(Button);
