/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BatchMissionDto from '../model/BatchMissionDto';
import BatchMissionWithBookDto from '../model/BatchMissionWithBookDto';
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import EditBatchMissionPayloadModel from '../model/EditBatchMissionPayloadModel';
import Resource from '../model/Resource';
import SearchingQueryResponseModelBatchMissionDto from '../model/SearchingQueryResponseModelBatchMissionDto';
import TemplateBookDto from '../model/TemplateBookDto';

/**
* BookBatchMission service.
* @module api/BookBatchMissionApi
* @version v1.0.0
*/
export default class BookBatchMissionApi {

    /**
    * Constructs a new BookBatchMissionApi. 
    * @alias module:api/BookBatchMissionApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the batchDownloadTemplateUsingGET operation.
     * @callback module:api/BookBatchMissionApi~batchDownloadTemplateUsingGETCallback
     * @param {String} error Error message, if any.
     * @param data This operation does not return a value.
     * @param {String} response The complete HTTP response.
     */

    /**
     * download batch book excel file
     * @param {module:api/BookBatchMissionApi~batchDownloadTemplateUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     */
    batchDownloadTemplateUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;
      return this.apiClient.callApi(
        '/api/book/batch/download-excel-template', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteMissionUsingDELETE operation.
     * @callback module:api/BookBatchMissionApi~deleteMissionUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete mission
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/BookBatchMissionApi~deleteMissionUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteMissionUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/book/batch/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the downloadBatchMissionExcelUsingGET operation.
     * @callback module:api/BookBatchMissionApi~downloadBatchMissionExcelUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Resource} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * download excel
     * @param {Number} batchMissionId batchMissionId
     * @param {module:api/BookBatchMissionApi~downloadBatchMissionExcelUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Resource}
     */
    downloadBatchMissionExcelUsingGET(batchMissionId, callback) {
      let postBody = null;
      // verify the required parameter 'batchMissionId' is set
      if (batchMissionId === undefined || batchMissionId === null) {
        throw new Error("Missing the required parameter 'batchMissionId' when calling downloadBatchMissionExcelUsingGET");
      }

      let pathParams = {
        'batchMissionId': batchMissionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Resource;
      return this.apiClient.callApi(
        '/api/book/batch/{batchMissionId}/download-excel', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the editMissionUsingPATCH operation.
     * @callback module:api/BookBatchMissionApi~editMissionUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BatchMissionDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * edit batch mission
     * @param {Number} batchMissionId batchMissionId
     * @param {Object} opts Optional parameters
     * @param {module:model/EditBatchMissionPayloadModel} opts.editBatchMissionPayloadModel 
     * @param {module:api/BookBatchMissionApi~editMissionUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BatchMissionDto}
     */
    editMissionUsingPATCH(batchMissionId, opts, callback) {
      opts = opts || {};
      let postBody = opts['editBatchMissionPayloadModel'];
      // verify the required parameter 'batchMissionId' is set
      if (batchMissionId === undefined || batchMissionId === null) {
        throw new Error("Missing the required parameter 'batchMissionId' when calling editMissionUsingPATCH");
      }

      let pathParams = {
        'batchMissionId': batchMissionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = BatchMissionDto;
      return this.apiClient.callApi(
        '/api/book/batch/{batchMissionId}/edit', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getMissionBookDataUsingGET operation.
     * @callback module:api/BookBatchMissionApi~getMissionBookDataUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/TemplateBookDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get mission book data
     * @param {Number} batchMissionId batchMissionId
     * @param {String} bookIsbn bookIsbn
     * @param {String} organization organization
     * @param {module:api/BookBatchMissionApi~getMissionBookDataUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/TemplateBookDto}
     */
    getMissionBookDataUsingGET(batchMissionId, bookIsbn, organization, callback) {
      let postBody = null;
      // verify the required parameter 'batchMissionId' is set
      if (batchMissionId === undefined || batchMissionId === null) {
        throw new Error("Missing the required parameter 'batchMissionId' when calling getMissionBookDataUsingGET");
      }
      // verify the required parameter 'bookIsbn' is set
      if (bookIsbn === undefined || bookIsbn === null) {
        throw new Error("Missing the required parameter 'bookIsbn' when calling getMissionBookDataUsingGET");
      }
      // verify the required parameter 'organization' is set
      if (organization === undefined || organization === null) {
        throw new Error("Missing the required parameter 'organization' when calling getMissionBookDataUsingGET");
      }

      let pathParams = {
        'batchMissionId': batchMissionId
      };
      let queryParams = {
        'bookIsbn': bookIsbn,
        'organization': organization
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = TemplateBookDto;
      return this.apiClient.callApi(
        '/api/book/batch/{batchMissionId}/book-data', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getMissionTempBookDataUsingGET operation.
     * @callback module:api/BookBatchMissionApi~getMissionTempBookDataUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BatchMissionWithBookDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get mission with temp book data
     * @param {Number} batchMissionId batchMissionId
     * @param {module:api/BookBatchMissionApi~getMissionTempBookDataUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BatchMissionWithBookDto}
     */
    getMissionTempBookDataUsingGET(batchMissionId, callback) {
      let postBody = null;
      // verify the required parameter 'batchMissionId' is set
      if (batchMissionId === undefined || batchMissionId === null) {
        throw new Error("Missing the required parameter 'batchMissionId' when calling getMissionTempBookDataUsingGET");
      }

      let pathParams = {
        'batchMissionId': batchMissionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = BatchMissionWithBookDto;
      return this.apiClient.callApi(
        '/api/book/batch/{batchMissionId}/get', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the listMissionUsingGET operation.
     * @callback module:api/BookBatchMissionApi~listMissionUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelBatchMissionDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * list mission
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/BookBatchMissionApi~listMissionUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelBatchMissionDto}
     */
    listMissionUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelBatchMissionDto;
      return this.apiClient.callApi(
        '/api/book/batch/list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the postUploadExcelAndCreateMissionUsingPOST operation.
     * @callback module:api/BookBatchMissionApi~postUploadExcelAndCreateMissionUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BatchMissionDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Upload excel and create mission
     * @param {File} file file
     * @param {Object} opts Optional parameters
     * @param {String} opts.name name
     * @param {module:api/BookBatchMissionApi~postUploadExcelAndCreateMissionUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BatchMissionDto}
     */
    postUploadExcelAndCreateMissionUsingPOST(file, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'file' is set
      if (file === undefined || file === null) {
        throw new Error("Missing the required parameter 'file' when calling postUploadExcelAndCreateMissionUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
        'name': opts['name']
      };
      let headerParams = {
      };
      let formParams = {
        'file': file
      };

      let authNames = ['Authorization'];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['*/*'];
      let returnType = BatchMissionDto;
      return this.apiClient.callApi(
        '/api/book/batch/upload-excel', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
