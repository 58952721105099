import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import loadingGif from "../../../../assets/images/loading.gif";
import BackButton from "../../../../components/Button/back/BackButton";
import Button from "../../../../components/Button/Button";
import DialogBox from "../../../../components/DialogBox/DialogBox";
import OrgSearchFilter from "../../../../components/SearchFilter/OrgSearchFilter";
import LibrarySheet from "../../../../components/Sheet/Book/Library/LibrarySheet";
import LibraryTableCardView from "../../../../components/Table/TableCardView/Book/Library/LibraryTableCardView";
import { OrganizationApi } from "../../../../fetcher";
import { handleUserInfo } from "../../../../redux/actions";
import {
  ADD,
  CODE_LIBRARY_ORGANIZATION_ADD,
  CODE_LIBRARY_ORGANIZATION_DELETE,
  CODE_LIBRARY_ORGANIZATION_UPDATE,
  DIALOG_COMPLETE,
  DIALOG_DELETE,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLE_CARD,
  STYLE_LIST,
  UPDATE,
} from "../../../../utils/Constants";
import { genApiConfig } from "../../../../utils/fetch-caller";
import style from "./LibraryOrgView.module.css";
import OrganizationObjectDto from "../../../../fetcher/model/OrganizationObjectDto";
import PageLoading from "../../../loading/PageLoading";

const LibraryOrgView = (props) => {
  const [libraries, setLibraries] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [styleType, setStyleType] = useState(STYLE_LIST);

  const [currentSelected, setCurrentSelected] = useState(
    new OrganizationObjectDto()
  );
  const [deleteAll, setDeleteAll] = useState(false);

  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleDialogCallBack = (value) => {
    if (value.action == DIALOG_COMPLETE) getApiData();

    if (value.action == DIALOG_DELETE) deleteSupplierAction();

    if (Object.keys(currentSelected).length > 0) {
      displayData.map((libraryObj) => {
        if (libraryObj.id === currentSelected.id) {
          libraryObj["isChecked"] = false;
        }
      });
      setCurrentSelected({});
    }

    setShowAddDialog(false);
    setShowEditDialog(false);
    setShowDeleteDialog(false);
    setDeleteAll(false);
  };

  useEffect(() => {
    getApiData();
  }, []);
  console.log("loading", loading);
  const getApiData = () => {
    setLoading(true);
    new OrganizationApi(genApiConfig())
      .getOrganizationsUsingGET({})
      .then((res) => {
        if (res.data != null) {
          console.log("resffff", res.data);
          const { result } = res.data;
          setLibraries(result);
          let arr = [];
          result
            .sort((a, b) => {
              return a.seq - b.seq;
            })
            .map((libraryObj) => {
              if (libraryObj.code === "LIBRARY") {
                arr.push(Object.assign(libraryObj, { isChecked: false }));
              }
            });
          setDisplayData(arr);
        }
      })
      .catch((err) => {
        alert(err.body.message);
        console.log("getSuppliersUsingGET", err.body.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteSupplierAction = () => {
    let opts = {};
    opts["commonDeletePayloadModel"] = [];

    if (deleteAll) {
      displayData
        .filter((it) => it.isChecked)
        .map((libraryObj) => {
          opts.commonDeletePayloadModel.push({
            delete: true,
            id: libraryObj.id,
          });
        });
    } else {
      opts.commonDeletePayloadModel.push({
        delete: true,
        id: currentSelected.id,
      });
    }

    new OrganizationApi(genApiConfig())
      .deleteOrganizationsUsingDELETE(opts)
      .then((res) => {
        if (Object.keys(res).length > 0) {
          if (res.response.body !== null) {
            alert("刪除成功");
          }
        }
      })
      .then(() => getApiData())
      .catch((err) => {
        alert(err.body.message);
        console.log("err", err.body.message);
      });
  };

  const handleSearchClick = (value) => {
    setDisplayData(
      libraries.filter((libraryObj) =>
        libraryObj.name.toUpperCase().includes(searchText.toUpperCase())
      )
    );
  };

  const handleListViewClick = (value) => {
    setStyleType(STYLE_LIST);
  };

  const handleCardViewClick = (value) => {
    setStyleType(STYLE_CARD);
  };

  const handleAddClick = (value) => {
    setShowAddDialog(value);
  };

  const handleDeleteClick = (value) => {
    if (displayData.filter((it) => it.isChecked).length > 0) {
      setDeleteAll(true);
      setShowDeleteDialog(value);
    } else {
      alert("沒有選擇供應商");
    }
  };

  //Select All Radio
  const tableHeaderCheck = (value) => {
    setDisplayData(
      displayData.map((libraryObj) => {
        return { ...libraryObj, isChecked: value };
      })
    );
  };

  const tableItemActionB = (obj) => {
    let library = displayData.filter((libraryObj) => libraryObj.id == obj.id);
    switch (obj.action) {
      case "checkbox":
        setDisplayData(
          displayData.map((libraryObj) => {
            if (libraryObj.id === obj.id) {
              libraryObj["isChecked"] = obj.value;
            }
            return { ...libraryObj };
          })
        );
        break;
      case "edit":
        if (library != null) {
          displayData.map((libraryObj) => {
            if (libraryObj.id === obj.id) {
              libraryObj["isChecked"] = true;
              setCurrentSelected(libraryObj);
            }
          });
          setShowEditDialog(true);
        }
        break;
      case "delete":
        if (library != null) {
          displayData.map((libraryObj) => {
            if (libraryObj.id === obj.id) {
              libraryObj["isChecked"] = true;
              setCurrentSelected(libraryObj);
            }
          });
          setShowDeleteDialog(true);
        }
        break;
    }
  };
  const tableItemAction = (obj) => {
    let library = displayData.filter((libraryObj) => libraryObj.id == obj.id);
    switch (obj.action) {
      case "checkbox":
        setDisplayData(
          displayData.map((libraryObj) => {
            if (libraryObj.id === obj.id) {
              libraryObj["isChecked"] = obj.value;
            }
            return { ...libraryObj };
          })
        );
        break;
      case "edit":
        if (library != null) {
          displayData.map((libraryObj) => {
            if (libraryObj.id === obj.id) {
              libraryObj["isChecked"] = true;
              setCurrentSelected(libraryObj);
            }
          });
          setShowEditDialog(true);
        }
        break;
      case "delete":
        if (library != null) {
          displayData.map((libraryObj) => {
            if (libraryObj.id === obj.id) {
              libraryObj["isChecked"] = true;
              setCurrentSelected(libraryObj);
            }
          });
          setShowDeleteDialog(true);
        }
        break;
    }
  };
  return (
    <>
      <div className={style.backbtn}>
        <BackButton />
      </div>
      <div className={style.container}>
        <div className={style.container_header}>
          <h4>圖書館供應商管理</h4>
          <div className={style.header}>
            {/* <div className={style.row}>
              <Input
                type="text"
                placeholder={"輸入名稱"}
                value={searchText}
                styleType={STYLETYPE_FIVE}
                callBack={(value) => setSearchText(value)}
              />
              <div className={style.rowBtn}>
                <Button
                  styleType={STYLETYPE_MAIN}
                  callBack={(value) => handleSearchClick(value)}
                >
                  搜尋
                </Button>
              </div>
            </div> */}

            <div className={style.row}></div>
            <div className={style.row}></div>
            <div
              className={style.rowSpan}
              // style={{
              //     gridTemplateColumns: "310px auto",
              // }}
            >
              {/* <div className={style.rowSpanItem}>
                <Button
                  styleType={STYLETYPE_IMG}
                  callBack={(value) => handleListViewClick(value)}
                >
                  <img src={listViewIcon} />
                </Button>
              </div> */}
              {/* <div className={style.rowSpanItem}>
                <Button
                  styleType={STYLETYPE_IMG}
                  callBack={(value) => handleCardViewClick(value)}
                >
                  <img src={titleViewIcon} />
                </Button>
              </div>

              <div className={style.border_line}></div> */}

              <div className={style.rowSpanItem}>
                <Button
                  styleType={STYLETYPE_SECOND}
                  code={CODE_LIBRARY_ORGANIZATION_ADD}
                  callBack={(value) => handleAddClick(value)}
                >
                  新增圖書館供應商
                </Button>
              </div>

              <div
                className={style.border_line}
                hidden={
                  displayData &&
                  !displayData.filter((it) => it.isChecked).length > 0
                }
              ></div>
              {displayData.filter((it) => it.isChecked).length > 0 ? (
                <div className={style.rowSpanItem}>
                  <Button
                    styleType={STYLETYPE_MAIN}
                    code={CODE_LIBRARY_ORGANIZATION_DELETE}
                    callBack={(value) => handleDeleteClick(value)}
                  >
                    刪除圖書館供應商
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {styleType === STYLE_LIST ? (
            <>
              <div className={style.tableHeader}>
                <div className={style.tableHeaderItemCol1}>
                  {/* <Input
                    type="checkbox"
                    showType={STYLETYPE_DEFAULT}
                    checked={
                      displayData && displayData.length > 0
                        ? displayData.filter(
                            (libraryObj) => libraryObj.isChecked == true
                          ).length == libraries.length
                        : false
                    }
                    callBack={(value) => tableHeaderCheck(value)}
                  /> */}
                </div>
                <div className={style.tableHeaderItemCol2}>
                  <h6>圖書館供應商名稱</h6>
                </div>
                <div className={style.tableHeaderItem}>
                  <h6>描述</h6>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={"smw"} style={{ padding: "8px" }}>
          {styleType === STYLE_LIST ? (
            !loading ? (
              displayData.length !== 0 ? (
                <>
                  <div className={style.dataContentView}>
                    <OrgSearchFilter
                      for={"smw"}
                      data={displayData}
                      multiple={false}
                      expand={true}
                      canEdit={
                        props.userInfo &&
                        props.userInfo.organizations
                          .filter((it) => it.isSelected)
                          .filter((role) =>
                            role.role.permissions.find(
                              (per) =>
                                per.code === CODE_LIBRARY_ORGANIZATION_UPDATE
                            )
                          ).length > 0
                      }
                      canDelete={
                        props.userInfo &&
                        props.userInfo.organizations
                          .filter((it) => it.isSelected)
                          .filter((role) =>
                            role.role.permissions.find(
                              (per) =>
                                per.code === CODE_LIBRARY_ORGANIZATION_DELETE
                            )
                          ).length > 0
                      }
                      callBack={(obj) => tableItemActionB(obj)}
                    />
                    {/* {displayData && displayData.length > 0
                  ? displayData.map((libraryObj) => {
                      return (
                        <Fragment key={libraryObj.id}>
                          <LibraryTableListView
                            content={libraryObj}
                            checked={libraryObj.isChecked}
                            callBack={(obj) =>
                              tableItemAction({ ...obj, id: libraryObj.id })
                            }
                          />
                        </Fragment>
                      );
                    })
                  : []} */}
                  </div>
                </>
              ) : (
                <>沒有記錄</>
              )
            ) : (
              <PageLoading />
            )
          ) : (
            <div className={style.cardViewContainer}>
              {displayData && displayData.length > 0
                ? displayData.map((libraryObj) => {
                    return (
                      <Fragment key={libraryObj.id}>
                        <LibraryTableCardView
                          content={libraryObj}
                          checked={libraryObj.isChecked}
                          callBack={(obj) =>
                            tableItemAction({ ...obj, id: libraryObj.id })
                          }
                        />
                      </Fragment>
                    );
                  })
                : []}
            </div>
          )}
        </div>
      </div>

      {/* Add */}
      <DialogBox showDialog={showAddDialog} title={"新增圖書館供應商資料"}>
        <LibrarySheet
          content={new OrganizationObjectDto()}
          actionType={ADD}
          callBack={(value) => handleDialogCallBack(value)}
        />
      </DialogBox>

      {/* Edit */}
      <DialogBox showDialog={showEditDialog} title={"更新圖書館供應商資料"}>
        <LibrarySheet
          content={currentSelected}
          actionType={UPDATE}
          callBack={(value) => handleDialogCallBack(value)}
        />
      </DialogBox>

      {/* Delete */}
      <DialogBox showDialog={showDeleteDialog} title={"刪除圖書館供應商資料"}>
        <div>
          <label>是否刪除以下圖書館供應商資料?</label>
          <br />
          <br />
          {deleteAll ? (
            <>
              <label>
                名稱︰
                <div className={style.overflow_content}>
                  {displayData
                    .filter((libraryObj) => libraryObj.isChecked)
                    .map((library) => {
                      return <div>{library.name}</div>;
                    })}
                </div>
              </label>
              <br />
              <div className={style.button_on_right}>
                <Button
                  styleType={STYLETYPE_SECOND}
                  callBack={(value) => handleDialogCallBack(value)}
                >
                  取消
                </Button>

                <Button
                  styleType={STYLETYPE_MAIN}
                  callBack={(value) =>
                    handleDialogCallBack({ action: DIALOG_DELETE })
                  }
                  disabled={loading}
                >
                  {loading ? "" : "確認"}
                  <img
                    src={loadingGif}
                    style={{ width: "20px", height: "20px" }}
                    hidden={!loading}
                  />
                </Button>
              </div>
            </>
          ) : Object.keys(currentSelected).length > 0 ? (
            <>
              <div className={style.overflow_content}>
                <label>名稱︰{currentSelected.name}</label>
              </div>
              <br />
              <div className={style.button_on_right}>
                <Button
                  styleType={STYLETYPE_SECOND}
                  callBack={(value) => handleDialogCallBack(value)}
                >
                  取消
                </Button>

                <Button
                  styleType={STYLETYPE_MAIN}
                  callBack={(value) =>
                    handleDialogCallBack({ action: DIALOG_DELETE })
                  }
                  disabled={loading}
                >
                  {loading ? "" : "確認"}
                  <img
                    src={loadingGif}
                    style={{ width: "20px", height: "20px" }}
                    hidden={!loading}
                  />
                </Button>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </DialogBox>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = { handleUserInfo };
export default connect(
  mapStateToProps,
  actionCreator
)(withRouter(LibraryOrgView));
