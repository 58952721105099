import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import loadingGif from "../../../assets/images/loading.gif";
import BasicInfo from "../../../components/Book/BasicInfo/BasicInfo";
import BackButton from "../../../components/Button/back/BackButton";
import Button from "../../../components/Button/Button";
import { BookApi } from "../../../fetcher";
import { handleUserInfo } from "../../../redux/actions";
import {
  ADD,
  ALL_BOOK,
  CODE_BOOK_SUBMITTED,
  CODE_BOOK_TEMP,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  SUBMITTED,
  TEMP,
} from "../../../utils/Constants";
import { genApiConfig } from "../../../utils/fetch-caller";
import { getArgsQuery } from "../../../utils/GlobalFunction";
import style from "./NewBookView.module.css";

const NewBookView = (props) => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(true);
  const [basicInfo, setBasicInfo] = useState({});
  const [submitData, setSubmitData] = useState({});

  const handleSubmit = (value) => {
    var submitData = submitFormData();

    if (submitData.bookPayloadModel.status == SUBMITTED) {
      if (submitData.bookPayloadModel.bookIsbn == "") {
        alert("請輸入ISBN /產品編號");
        return;
      }
      if (submitData.bookPayloadModel.author == "") {
        alert("請輸入作者名稱");
        return;
      }
      if (submitData.bookPayloadModel.name == "") {
        alert("請輸入產品名稱");
        return;
      }
      if (!submitData.bookPayloadModel.publishDate) {
        alert("請選擇出版年份/出版月份");
        return;
      }
      if (submitData.bookPayloadModel.organizationId == "") {
        alert("請選擇出版社");
        return;
      }
      if (submitData.bookPayloadModel.categoryIds.length <= 0) {
        alert("請選擇分類");
        return;
      }

      if (
        submitData.bookPayloadModel.productTypeId == "" ||
        submitData.bookPayloadModel.productTypeId == null
      ) {
        alert("請輸入產品類型");
        return;
      }
      if (
        submitData.bookPayloadModel.languageIds == null ||
        submitData.bookPayloadModel.languageIds == ""
      ) {
        alert("請選擇語言");
        return;
      }
      if (
        submitData.bookPayloadModel.price === "" ||
        submitData.bookPayloadModel.price === undefined
      ) {
        alert("請輸入售價");
        return;
      }
      /*
      if (
        submitData.bookPayloadModel.originalPrice === "" ||
        submitData.bookPayloadModel.originalPrice === undefined
      ) {
        alert("請輸入原價");
        return;
      }
      */
      if (submitData.bookPayloadModel.ctsid == "") {
        alert("請輸入CTS書號");
        return;
      }
      if (submitData.bookPayloadModel.description == "") {
        alert("請輸入產品簡介");
        return;
      }

      if (
        submitData.bookPayloadModel.file.epubProd.length <= 0 ||
        submitData.bookPayloadModel.file.epubProd.find((it) => it.select) ==
          undefined
      ) {
        alert("請選擇所需檔案後再提交。");
        return;
      }
      // 20240611 GC
      /*
      if (
        submitData.bookPayloadModel.file.epubPreview.length <= 0 ||
        submitData.bookPayloadModel.file.epubPreview.find((it) => it.select) ==
          undefined
      ) {
        alert("請選擇試閱檔案");
        return;
      }
      */
      // 20240611 GC end
      if (
        submitData.bookPayloadModel.file.cover.length <= 0 ||
        submitData.bookPayloadModel.file.cover.find((it) => it.select) ==
          undefined
      ) {
        alert("請選擇封面檔案");
        return;
      }
    }

    setLoading(true);
    //submitFormData();

    if (submitData !== null) {
      new BookApi(genApiConfig())
        .addBookUsingPOST(submitData)
        .then((res) => {
          if (res.response.ok) {
            if (res.data.status === SUBMITTED) {
              alert("書籍已提交審批。");
            } else {
              alert("新增書籍成功");
            }

            history.push(
              `${ALL_BOOK}?id=${
                props.userInfo.organizations.find((it) => it.isSelected)?.id
              }`
            );
          } else {
            alert(res.response.body.message);
          }
          //console.log(('addBookUsingPOST', res)
        })
        .catch((err) => {
          alert(err);
          //console.log(('addBookUsingPOST', err)
        });
    }

    setLoading(false);
  };

  useEffect(() => {
    if (props.location.state !== undefined) {
      const { state } = props.location;
      //console.log('state', state)
      setBasicInfo(Object.assign(state, { action: ADD }));
    }
  }, [props]);

  const submitFormData = () => {
    let opts = {};

    //let status = "TEMP";

    let status = submitData.status;

    //console.log(status);
    var currentPrice = submitData.price;
    //var currentInventory = submitData.inventory;

    if (currentPrice !== undefined && currentPrice !== "") {
      if (isNaN(submitData.price)) {
        alert("不是有效之售價輸入");
        return null;
      }
      currentPrice = parseFloat(currentPrice);
    }

    var originalPrice = submitData.originalPrice;
    //var currentInventory = submitData.inventory;

    if (originalPrice !== undefined && originalPrice !== "") {
      if (isNaN(submitData.originalPrice)) {
        alert("不是有效之原價輸入");
        return null;
      }
      originalPrice = parseFloat(originalPrice);
    }

    // if (currentInventory !== undefined && currentInventory !== "") {
    //   if (isNaN(submitData.inventory)) {
    //     alert("不是有效之貨存輸入");
    //     return null;
    //   }
    //   currentInventory = parseInt(currentInventory);
    // }

    // console.log(
    //     "true false",
    //     submitData.author !== "" &&
    //         submitData.ISBN !== "" &&
    //         submitData.name !== "" &&
    //         submitData.supplierId.length > 0 &&
    //         submitData.categoryId.length > 0 &&
    //         currentPrice > 0 &&
    //         submitData.file.cover.find((it) => it.select) !== undefined &&
    //         submitData.file.epubProd.find((it) => it.select) !== undefined
    // );

    // if (
    //     submitData.author !== "" &&
    //     submitData.ISBN !== "" &&
    //     submitData.name !== "" &&
    //     submitData.supplierId.length > 0 &&
    //     submitData.categoryId.length > 0 &&
    //     currentPrice > 0 &&
    //     submitData.file.cover.find((it) => it.select) !== undefined &&
    //     submitData.file.epubProd.find((it) => it.select) !== undefined
    // ) {
    //     status = SUBMITTED;
    // }

    opts["bookId"] = submitData.id;
    let _arr;
    if (submitData.categoryId != null || submitData.categoryId.length > 0) {
      _arr = submitData.categoryId.filter((it) => it.isSelected);
    }
    let _ids = [];
    _arr.map((it) => _ids.push(it.id));

    let languagearr;
    if (submitData.languageId != null || submitData.languageId.length > 0) {
      languagearr = submitData.languageId.filter((it) => it.isSelected);
    }
    let languageIdArr = [];
    languagearr.map((it) => languageIdArr.push(it.id));

    opts["bookPayloadModel"] = {
      status,
      author: submitData.author,
      bookIsbn: submitData.ISBN,
      categoryIds: _ids,
      organizationId:
        submitData.supplierId.length > 0 ? submitData.supplierId[0].id : null,
      languageIds: languageIdArr, //submitData.languageId.length > 0 ? submitData.languageId : null,
      productTypeId:
        submitData.productTypeId.length > 0
          ? submitData.productTypeId[0].id
          : null,
      ctsid: submitData.ctsid,
      description: submitData.description,
      name: submitData.name,
      price: currentPrice,
      originalPrice: originalPrice,
      publishDate: submitData.publishDate,
      isRoot: true,
      version: "v1.0",
      file: submitData.file,
      inventory: 999999, //currentInventory,
      bookComment: submitData.bookComment,
      translator:submitData.translator
    };
    console.log("New opts = ", opts);
    return opts;
  };

  const handleSubmitDataChange = (value) => {
    console.log("handleSubmitDataChange", value);
    setSubmitData(Object.assign(submitData, value));

    if (
      submitData.ISBN !== "" &&
      submitData.file.audioBook.filter((it) => it.fileId === null).length ===
        0 &&
      submitData.file.cover.filter((it) => it.fileId === null).length === 0 &&
      submitData.file.epubPreview.filter((it) => it.fileId === null).length ===
        0 &&
      submitData.file.epubProd.filter((it) => it.fileId === null).length ===
        0 &&
      submitData.file.multimedia.filter((it) => it.fileId === null).length ===
        0 &&
      submitData.file.thumb.filter((it) => it.fileId === null).length === 0
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  //console.log("aa", getArgsQuery(props.location.search).id);

  return (
    <div className={style.newBookView_container}>
      <BackButton />
      <h4>新增書籍</h4>
      <BasicInfo
        info={basicInfo}
        callBack={(value) => handleSubmitDataChange(value)}
      />
      <div className={style.btn}>
        <Button
          styleType={STYLETYPE_SECOND}
          callBack={(value) =>
            props.history.push(
              ALL_BOOK + "?id=" + getArgsQuery(props.location.search).id
            )
          }
        >
          取消
        </Button>

        <Button
          styleType={STYLETYPE_MAIN}
          // callBack={(value) => handleSubmit(value)}
          callBack={(value) => {
            Object.assign(submitData, { status: TEMP });
            handleSubmit(value);
          }}
          code={CODE_BOOK_TEMP}
          disabled={!canSubmit}
        >
          {loading ? "" : "儲存"}
          <img
            src={loadingGif}
            style={{ width: "20px", height: "20px" }}
            hidden={!loading}
            alt={loadingGif}
          />
        </Button>
        <Button
          styleType={STYLETYPE_MAIN}
          code={CODE_BOOK_SUBMITTED}
          callBack={(value) => {
            Object.assign(submitData, { status: SUBMITTED });
            handleSubmit(value);
          }}
          disabled={!canSubmit}
        >
          {loading ? "" : "提交審批"}
          <img
            src={loadingGif}
            style={{ width: "20px", height: "20px" }}
            hidden={!loading}
          />
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = { handleUserInfo };
export default connect(mapStateToProps, actionCreator)(withRouter(NewBookView));
