import { CHUNK_SIZE } from "../Constants";

var SparkMD5 = require("spark-md5");

class FileChunk {
  constructor(chunk, fileName, start, end, total, bookIsbn, bookType, md5) {
    this.chunk = chunk;
    this.fileName = fileName;
    this.start = start;
    this.end = end;
    this.total = total;
    this.chunkName = "";
    this.fileHash = "";
    this.index = 0;
    this.chunkNum = 0;
    this.bookIsbn = bookIsbn;
    this.bookType = bookType;
    this.md5 = md5;
    // 'READY', 'UPLOADING', 'SUCCESS', 'ERROR'
    this.status = "READY";
  }

  toFormData() {
    const formData = new FormData();
    formData.append("chunk", this.chunk);
    formData.append("fileHash", this.fileHash);
    formData.append("chunkName", this.chunkName);
    formData.append("chunkNum", this.chunkNum);
    formData.append("start", this.start);
    formData.append("end", this.end);
    formData.append("total", this.total);
    formData.append("index", this.index);
    formData.append("fileName", this.fileName);
    formData.append("bookIsbn", this.bookIsbn);
    formData.append("bookType", this.bookType);
    formData.append("md5", this.md5);

    return formData;
  }
}

const getHash = (fileChunkList) => {
  let count = 0;
  const spark = new SparkMD5.ArrayBuffer();

  console.log("adsaf", spark);

  const loadNext = (index) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(fileChunkList[index].chunk);
    fileReader.onload = (e) => {
      spark.append(e.target.result);
      count++;
      if (count === fileChunkList.length) {
        console.log("hashend", spark.end());
        return spark.end();
      }
      loadNext(count);
    };
  };
  loadNext(0);
};

const calculateFileHash = (fileChunkList) => {
  var hash = getHash(fileChunkList);
  return new Promise((resolve) => {
    resolve(hash);
  });
};

function createFileChunk(
  file,
  bookIsbn,
  bookType,
  md5,
  blockSize = CHUNK_SIZE
) {
  const fileChunkList = [];
  const { name, size } = file;
  let cur = 0;

  while (cur < size) {
    let end = cur + blockSize;

    if (end > size) {
      end = size;
    }

    fileChunkList.push(
      new FileChunk(
        file.slice(cur, end),
        name,
        cur,
        end,
        size,
        bookIsbn,
        bookType,
        md5
      )
    );

    cur += blockSize;
  }

  const chunkNum = fileChunkList.length;

  fileChunkList.forEach((chunkFile, index) => {
    chunkFile.index = index;
    chunkFile.chunkNum = chunkNum;
    chunkFile.chunkName = chunkFile.fileName + "_" + index;
  });

  return fileChunkList;
}

export { FileChunk, createFileChunk, calculateFileHash };
