export function handleUserInfo(userInfo) {
  return { type: "HANDLE_USER_INFO", payload: userInfo };
}

// export function handleUserInfo(userInfo) {
//     return { type: "HANDLE_USER_INFO_CHANGE", payload: userInfo };
// }

// export function handleSetUserInfo(userInfo) {
//     return { type: "HANDLE_SET_USER_INFO", payload: userInfo };
// }
