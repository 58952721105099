import localforage from "localforage";
import React from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import Bookmark from "../../../../model/Bookmark";
import {
  handleBookmarks,
  handleFetchBookmarks,
  handleHtmlBook,
  handleOpenMenu,
  handleReadingBook,
  handleReadingEpub,
  handleReadingState,
  handleSearch,
  handleShowBookmark,
  handleReaderLog,
} from "../../../../redux/actions/reader";
import ReadingTime from "../../../../utils/reader/readUtils/readingTime";
import RecordLocation from "../../../../utils/reader/readUtils/recordLocation";
import StorageUtil from "../../../../utils/reader/serviceUtils/storageUtil";
import { withRouter } from "../../../../utils/withRouter";
import "./operationPanel.css";

//declare var document: any;

class OperationPanel extends React.Component {
  timeStamp;
  speed;
  timer;
  key;

  constructor(props) {
    super(props);
    this.state = {
      isFullScreen:
        StorageUtil.getReaderConfig("isFullScreen") === "yes" ? true : false, // 是否進入全屏模式
      isBookmark: false, // 是否添加書簽
      time: 0,
      key: this.props.currentBook.key,
      currentPercentage: RecordLocation.getCfi(this.props.currentBook.key)
        ? RecordLocation.getCfi(this.props.currentBook.key).percentage
        : 0,
      timeLeft: 0,
    };
    this.timeStamp = Date.now();
    this.speed = 30000;
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.currentEpub.rendition &&
      nextProps.currentEpub.rendition.location &&
      this.props.currentEpub.rendition
    ) {
      const currentLocation =
        this.props.currentEpub.rendition.currentLocation();
      if (!currentLocation.start) {
        return;
      }

      this.speed = Date.now() - this.timeStamp;
      this.timeStamp = Date.now();

      this.setState({
        timeLeft:
          ((currentLocation.start.displayed.total -
            currentLocation.start.displayed.page) *
            this.speed) /
          1000,
      });
      // let nextPercentage = section.start.percentage;
    }
    if (nextProps.htmlBook) {
      let pageProgress = nextProps.htmlBook.rendition.getProgress();
      this.setState({
        timeLeft:
          ((pageProgress.totalPage - pageProgress.currentPage) * this.speed) /
          1000,
      });
    }
  }
  componentDidMount() {
    const exitHandler = () => {
      if (
        document.webkitIsFullScreen ||
        document.mozFullScreen ||
        document.msFullscreenElement !== null
      ) {
        this.setState({ isFullScreen: !this.state.isFullScreen });
        StorageUtil.setReaderConfig(
          "isFullScreen",
          this.state.isFullScreen ? "no" : "yes"
        );
      }
    };
    if (document.addEventListener) {
      document.addEventListener(
        "fullscreenchange",
        () => {
          exitHandler();
        },
        false
      );
      document.addEventListener(
        "mozfullscreenchange",
        () => {
          exitHandler();
        },
        false
      );
      document.addEventListener(
        "MSFullscreenChange",
        () => {
          exitHandler();
        },
        false
      );
      document.addEventListener(
        "webkitfullscreenchange",
        () => {
          exitHandler();
        },
        false
      );
    }
  }
  // 點擊切換全屏按鈕觸發
  handleScreen() {
    !this.state.isFullScreen
      ? this.handleFullScreen()
      : this.handleExitFullScreen();
  }
  // 點擊退出按鈕的處理程序
  handleExit() {
    StorageUtil.setReaderConfig("isFullScreen", "no");
    this.props.handleReadingState(false);
    window.speechSynthesis.cancel();
    ReadingTime.setTime(this.props.currentBook.key, this.props.time);
    this.handleExitFullScreen();
    if (this.props.htmlBook) {
      this.props.handleHtmlBook(null);
    }

    if (this.props.currentEpub && this.props.currentEpub.loaded) {
      this.props.handleReadingEpub({});
    }
  }
  //控制進入全屏
  handleFullScreen() {
    let de = document.documentElement;
    if (de.requestFullscreen) {
      de.requestFullscreen();
    } else if (de.mozRequestFullScreen) {
      de.mozRequestFullScreen();
    } else if (de.webkitRequestFullscreen) {
      de.webkitRequestFullscreen();
    } else if (de.msRequestFullscreen) {
      de.msRequestFullscreen();
    }

    // this.setState({ isFullScreen: true });
    // StorageUtil.setReaderConfig("isFullScreen", "yes");
  }
  // 退出全屏模式
  handleExitFullScreen() {
    //解決使用esc退出全屏，再退出閱讀時發生的bug
    if (!document.fullscreenElement) return;

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }

    // this.setState({ isFullScreen: false });
    // StorageUtil.setReaderConfig("isFullScreen", "no");
  }
  handleAddBookmark = async () => {
    let bookKey = this.props.currentBook.key;
    let text = "";
    let chapter = "";
    let cfi = "";
    let percentage = 0;
    if (this.props.currentBook.format === "EPUB") {
      const currentLocation =
        this.props.currentEpub.rendition.currentLocation();
      let chapterHref = currentLocation.start.href;
      chapter = "Unknown Chapter";
      let currentChapter = this.props.flattenChapters.filter(
        (item) =>
          chapterHref.indexOf(item.href.split("#")[0]) > -1 ||
          item.href.split("#")[0].indexOf(chapterHref) > -1
      )[0];
      if (currentChapter) {
        chapter = currentChapter.label.trim(" ");
      }
      const cfibase = currentLocation.start.cfi
        .replace(/!.*/, "")
        .replace("epubcfi(", "");
      const cfistart = currentLocation.start.cfi
        .replace(/.*!/, "")
        .replace(/\)/, "");
      const cfiend = currentLocation.end.cfi
        .replace(/.*!/, "")
        .replace(/\)/, "");
      const cfiRange = `epubcfi(${cfibase}!,${cfistart},${cfiend})`;
      cfi = RecordLocation.getCfi(this.props.currentBook.key).cfi;
      let range = await this.props.currentEpub.getRange(cfiRange);
      text = range.toString();
      percentage = RecordLocation.getCfi(this.props.currentBook.key).percentage
        ? RecordLocation.getCfi(this.props.currentBook.key).percentage
        : 0;
    } else {
      let bookLocation = RecordLocation.getHtmlLocation(bookKey);
      text = bookLocation.text;
      chapter = bookLocation.chapterTitle;
      percentage = bookLocation.percentage;

      cfi = JSON.stringify(bookLocation);
    }

    text = text
      .replace(/\s\s/g, "")
      .replace(/\r/g, "")
      .replace(/\n/g, "")
      .replace(/\t/g, "")
      .replace(/\f/g, "");

    let bookmark = new Bookmark(
      bookKey,
      cfi,
      text.substr(0, 200),
      percentage,
      chapter
    );
    let bookmarkArr = this.props.bookmarks ?? [];
    bookmarkArr.push(bookmark);
    this.props.handleBookmarks(bookmarkArr);
    localforage.setItem("bookmarks", bookmarkArr);
    this.setState({ isBookmark: true });
    //alert("Add Successfully");
    this.props.handleShowBookmark(true);
  };

  render() {
    let _count = Math.abs(
      Math.floor(
        (this.props.time - ReadingTime.getTime(this.props.currentBook.key)) / 60
      )
    );
    let _count_frt = Math.ceil(this.state.timeLeft / 60);

    let readerConfig = localStorage.getItem("readerConfig");
    const readerConfigObj = JSON.parse(readerConfig);
    const isRM = readerConfigObj.rm === "r" || readerConfigObj.rm === "o";

    let _temp_second = this.props.readerLog
      ? this.props.readerLog.time
        ? this.props.readerLog.time
        : 0
      : 0;
    let _count_reading_total_minute = Math.ceil(_temp_second / 60);

    return (
      <div className="book-operation-panel">
        <div className="book-opeartion-info">
          <div>
            {/* <span>
              {isRM && (
                <>
                  <Trans
                    i18nKey="Total Reading Time"
                    count={_count_reading_total_minute}
                  >
                    Finish Reading Time:{{ _count_reading_total_minute }} min
                  </Trans>
                </>
              )}
            </span> */}
          </div>
          {/*<div
            className="exit-reading-button"
            onClick={() => {
              this.handleExit();

              if (StorageUtil.getReaderConfig("isOpenInMain") === "yes") {
                this.props.history.push("/manager/home");
                document.title = "XXXXXX Reader";
              } else {
                window.close();
              }
            }}
          >
            <span className="icon-exit exit-reading-icon"></span>
            <span className="exit-reading-text">
              Exit
            </span>
          </div>*/}
          {/* <div
            className="add-bookmark-button"
            onClick={() => {
              this.handleAddBookmark();
            }}
          >
            <span className="icon-add add-bookmark-icon"></span>
            <span className="add-bookmark-text">
            <Trans>Add Bookmark</Trans>
            </span>
          </div> */}
          {/* <div
            className="enter-fullscreen-button"
            onClick={() => {
              this.handleScreen();
            }}
          >
            <span className="icon-fullscreen enter-fullscreen-icon"></span>
            {!this.state.isFullScreen ? (
              <span className="enter-fullscreen-text">
                <Trans>Enter Fullscreen</Trans>
              </span>
            ) : (
              <span className="enter-fullscreen-text">
                <Trans>Exit Fullscreen</Trans>
              </span>
            )}
          </div> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    currentBook: state.book.currentBook,
    bookmarks: state.reader.bookmarks,
    notes: state.reader.notes,
    books: state.manager.books,
    htmlBook: state.reader.htmlBook,
    locations: state.progressPanel.locations,
    flattenChapters: state.reader.flattenChapters,
    readerLog: state.reader.readerLog,
  };
};
const actionCreator = {
  handleBookmarks,
  handleReadingState,
  handleFetchBookmarks,
  handleOpenMenu,
  handleShowBookmark,
  handleSearch,
  handleHtmlBook,
  handleReadingBook,
  handleReadingEpub,
  handleReaderLog,
};
export default connect(
  mapStateToProps,
  actionCreator
)(withRouter(OperationPanel));
