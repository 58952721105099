/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import SearchingQueryResponseModelSupplierDto from '../model/SearchingQueryResponseModelSupplierDto';
import SupplierDto from '../model/SupplierDto';
import SupplierRequestModel from '../model/SupplierRequestModel';

/**
* Supplier service.
* @module api/SupplierApi
* @version v1.0.0
*/
export default class SupplierApi {

    /**
    * Constructs a new SupplierApi. 
    * @alias module:api/SupplierApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addSupplierUsingPOST operation.
     * @callback module:api/SupplierApi~addSupplierUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SupplierDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add supplier
     * @param {Object} opts Optional parameters
     * @param {module:model/SupplierRequestModel} opts.supplierRequestModel 
     * @param {module:api/SupplierApi~addSupplierUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SupplierDto}
     */
    addSupplierUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['supplierRequestModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = SupplierDto;
      return this.apiClient.callApi(
        '/api/supplier/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteSupplierUsingDELETE operation.
     * @callback module:api/SupplierApi~deleteSupplierUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete Supplier
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/SupplierApi~deleteSupplierUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteSupplierUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/supplier/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getSupplierUsingGET operation.
     * @callback module:api/SupplierApi~getSupplierUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SupplierDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get Supplier
     * @param {String} id id
     * @param {module:api/SupplierApi~getSupplierUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SupplierDto}
     */
    getSupplierUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getSupplierUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SupplierDto;
      return this.apiClient.callApi(
        '/api/supplier/get/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getSuppliersUsingGET operation.
     * @callback module:api/SupplierApi~getSuppliersUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelSupplierDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * search Supplier
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/SupplierApi~getSuppliersUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelSupplierDto}
     */
    getSuppliersUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelSupplierDto;
      return this.apiClient.callApi(
        '/api/supplier/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateSupplierUsingPATCH operation.
     * @callback module:api/SupplierApi~updateSupplierUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SupplierDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * update Supplier
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/SupplierRequestModel} opts.supplierRequestModel 
     * @param {module:api/SupplierApi~updateSupplierUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SupplierDto}
     */
    updateSupplierUsingPATCH(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['supplierRequestModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateSupplierUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = SupplierDto;
      return this.apiClient.callApi(
        '/api/supplier/update/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
