// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BackButton_backButtonContainer__Ti0f3{\r\n    display: inline-block;\r\n    width: 120px;\r\n    height: 42px;\r\n\r\n    background-color: white;\r\n\r\n    margin: 0;\r\n    padding: 0;\r\n\r\n    cursor: pointer;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Button/back/BackButton.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,YAAY;IACZ,YAAY;;IAEZ,uBAAuB;;IAEvB,SAAS;IACT,UAAU;;IAEV,eAAe;AACnB","sourcesContent":[".backButtonContainer{\r\n    display: inline-block;\r\n    width: 120px;\r\n    height: 42px;\r\n\r\n    background-color: white;\r\n\r\n    margin: 0;\r\n    padding: 0;\r\n\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButtonContainer": "BackButton_backButtonContainer__Ti0f3"
};
export default ___CSS_LOADER_EXPORT___;
