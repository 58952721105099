/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The Resource model module.
 * @module model/Resource
 * @version v1.0.0
 */
class Resource {
    /**
     * Constructs a new <code>Resource</code>.
     * @alias module:model/Resource
     */
    constructor() { 
        
        Resource.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>Resource</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/Resource} obj Optional instance to populate.
     * @return {module:model/Resource} The populated <code>Resource</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new Resource();

            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('file')) {
                obj['file'] = ApiClient.convertToType(data['file'], File);
            }
            if (data.hasOwnProperty('filename')) {
                obj['filename'] = ApiClient.convertToType(data['filename'], 'String');
            }
            if (data.hasOwnProperty('inputStream')) {
                obj['inputStream'] = ApiClient.convertToType(data['inputStream'], Object);
            }
            if (data.hasOwnProperty('open')) {
                obj['open'] = ApiClient.convertToType(data['open'], 'Boolean');
            }
            if (data.hasOwnProperty('readable')) {
                obj['readable'] = ApiClient.convertToType(data['readable'], 'Boolean');
            }
            if (data.hasOwnProperty('uri')) {
                obj['uri'] = ApiClient.convertToType(data['uri'], 'String');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} description
 */
Resource.prototype['description'] = undefined;

/**
 * @member {File} file
 */
Resource.prototype['file'] = undefined;

/**
 * @member {String} filename
 */
Resource.prototype['filename'] = undefined;

/**
 * @member {Object} inputStream
 */
Resource.prototype['inputStream'] = undefined;

/**
 * @member {Boolean} open
 */
Resource.prototype['open'] = undefined;

/**
 * @member {Boolean} readable
 */
Resource.prototype['readable'] = undefined;

/**
 * @member {String} uri
 */
Resource.prototype['uri'] = undefined;

/**
 * @member {String} url
 */
Resource.prototype['url'] = undefined;






export default Resource;

