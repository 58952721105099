/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import LanguageDto from '../model/LanguageDto';
import LanguagePayloadModel from '../model/LanguagePayloadModel';
import SearchingQueryResponseModelLanguageDto from '../model/SearchingQueryResponseModelLanguageDto';

/**
* Language service.
* @module api/LanguageApi
* @version v1.0.0
*/
export default class LanguageApi {

    /**
    * Constructs a new LanguageApi. 
    * @alias module:api/LanguageApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addLanguageUsingPOST operation.
     * @callback module:api/LanguageApi~addLanguageUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LanguageDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add language
     * @param {Object} opts Optional parameters
     * @param {module:model/LanguagePayloadModel} opts.languagePayloadModel 
     * @param {module:api/LanguageApi~addLanguageUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LanguageDto}
     */
    addLanguageUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['languagePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = LanguageDto;
      return this.apiClient.callApi(
        '/api/language/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteLanguageUsingDELETE operation.
     * @callback module:api/LanguageApi~deleteLanguageUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete language
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/LanguageApi~deleteLanguageUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteLanguageUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/language/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getLanguageUsingGET operation.
     * @callback module:api/LanguageApi~getLanguageUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LanguageDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get language
     * @param {String} id id
     * @param {module:api/LanguageApi~getLanguageUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LanguageDto}
     */
    getLanguageUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getLanguageUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = LanguageDto;
      return this.apiClient.callApi(
        '/api/language/get/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getLanguagesUsingGET operation.
     * @callback module:api/LanguageApi~getLanguagesUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelLanguageDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * search language
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/LanguageApi~getLanguagesUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelLanguageDto}
     */
    getLanguagesUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelLanguageDto;
      return this.apiClient.callApi(
        '/api/language/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateLanguageUsingPATCH operation.
     * @callback module:api/LanguageApi~updateLanguageUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LanguageDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * update language
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/LanguagePayloadModel} opts.languagePayloadModel 
     * @param {module:api/LanguageApi~updateLanguageUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LanguageDto}
     */
    updateLanguageUsingPATCH(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['languagePayloadModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateLanguageUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = LanguageDto;
      return this.apiClient.callApi(
        '/api/language/update/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
