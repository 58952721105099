/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import AddBookReaderResponsePayload from '../model/AddBookReaderResponsePayload';
import BookDto from '../model/BookDto';
import BookFilterSearchingQueryModel from '../model/BookFilterSearchingQueryModel';
import BookInfoByOidResponseModel from '../model/BookInfoByOidResponseModel';
import BookInfoResponseModel from '../model/BookInfoResponseModel';
import BookInfoSearchingQueryModel from '../model/BookInfoSearchingQueryModel';
import BookPayloadModel from '../model/BookPayloadModel';
import BookSearchingQueryModel from '../model/BookSearchingQueryModel';
import BookVersionListResponseModel from '../model/BookVersionListResponseModel';
import CategoryBookItemResponseModel from '../model/CategoryBookItemResponseModel';
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import CommonResponseModel from '../model/CommonResponseModel';
import OrganizationCategoryBookItemResponseModel from '../model/OrganizationCategoryBookItemResponseModel';
import SearchingQueryResponseModelBookBaseSimpleDto from '../model/SearchingQueryResponseModelBookBaseSimpleDto';
import SearchingQueryResponseModelBookDto from '../model/SearchingQueryResponseModelBookDto';

/**
* Book service.
* @module api/BookApi
* @version v1.0.0
*/
export default class BookApi {

    /**
    * Constructs a new BookApi. 
    * @alias module:api/BookApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addBookUsingPOST operation.
     * @callback module:api/BookApi~addBookUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add book
     * @param {Object} opts Optional parameters
     * @param {module:model/BookPayloadModel} opts.bookPayloadModel 
     * @param {module:api/BookApi~addBookUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookDto}
     */
    addBookUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['bookPayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = BookDto;
      return this.apiClient.callApi(
        '/api/book/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the changeBookStatusUsingPATCH operation.
     * @callback module:api/BookApi~changeBookStatusUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {Boolean} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * change book status
     * @param {String} bookId bookId
     * @param {module:model/String} bookStatus bookStatus
     * @param {module:api/BookApi~changeBookStatusUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Boolean}
     */
    changeBookStatusUsingPATCH(bookId, bookStatus, callback) {
      let postBody = null;
      // verify the required parameter 'bookId' is set
      if (bookId === undefined || bookId === null) {
        throw new Error("Missing the required parameter 'bookId' when calling changeBookStatusUsingPATCH");
      }
      // verify the required parameter 'bookStatus' is set
      if (bookStatus === undefined || bookStatus === null) {
        throw new Error("Missing the required parameter 'bookStatus' when calling changeBookStatusUsingPATCH");
      }

      let pathParams = {
        'bookId': bookId
      };
      let queryParams = {
        'bookStatus': bookStatus
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'Boolean';
      return this.apiClient.callApi(
        '/api/book/{bookId}/change-status', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteBookUsingDELETE operation.
     * @callback module:api/BookApi~deleteBookUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete book
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/CommonDeletePayloadModel} opts.commonDeletePayloadModel 
     * @param {module:api/BookApi~deleteBookUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    deleteBookUsingDELETE(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteBookUsingDELETE");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/book/delete/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteBooksUsingDELETE operation.
     * @callback module:api/BookApi~deleteBooksUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete books
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/BookApi~deleteBooksUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteBooksUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/book/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteFileByBookIdAndFileNameUsingDELETE operation.
     * @callback module:api/BookApi~deleteFileByBookIdAndFileNameUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete book by BookId and Filename
     * @param {String} bookId bookId
     * @param {String} fileName fileName
     * @param {module:api/BookApi~deleteFileByBookIdAndFileNameUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    deleteFileByBookIdAndFileNameUsingDELETE(bookId, fileName, callback) {
      let postBody = null;
      // verify the required parameter 'bookId' is set
      if (bookId === undefined || bookId === null) {
        throw new Error("Missing the required parameter 'bookId' when calling deleteFileByBookIdAndFileNameUsingDELETE");
      }
      // verify the required parameter 'fileName' is set
      if (fileName === undefined || fileName === null) {
        throw new Error("Missing the required parameter 'fileName' when calling deleteFileByBookIdAndFileNameUsingDELETE");
      }

      let pathParams = {
      };
      let queryParams = {
        'bookId': bookId,
        'fileName': fileName
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/book/deleteByBookIdAndFilename', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookByfilterUsingPOST operation.
     * @callback module:api/BookApi~getBookByfilterUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelBookDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * searching
     * @param {Object} opts Optional parameters
     * @param {module:model/BookFilterSearchingQueryModel} opts.bookFilterSearchingQueryModel 
     * @param {module:api/BookApi~getBookByfilterUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelBookDto}
     */
    getBookByfilterUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['bookFilterSearchingQueryModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelBookDto;
      return this.apiClient.callApi(
        '/api/book/filter-searching', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookDtoUsingGET operation.
     * @callback module:api/BookApi~getBookDtoUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get book
     * @param {String} id id
     * @param {module:api/BookApi~getBookDtoUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookDto}
     */
    getBookDtoUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getBookDtoUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = BookDto;
      return this.apiClient.callApi(
        '/api/book/get/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookGroupByCategoryGroupByOrganizationUsingGET operation.
     * @callback module:api/BookApi~getBookGroupByCategoryGroupByOrganizationUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/OrganizationCategoryBookItemResponseModel>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getBookGroupByCategoryGroupByOrganization
     * @param {module:api/BookApi~getBookGroupByCategoryGroupByOrganizationUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/OrganizationCategoryBookItemResponseModel>}
     */
    getBookGroupByCategoryGroupByOrganizationUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = [OrganizationCategoryBookItemResponseModel];
      return this.apiClient.callApi(
        '/api/book/organizations-categories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookGroupByCategoryUsingGET operation.
     * @callback module:api/BookApi~getBookGroupByCategoryUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/CategoryBookItemResponseModel>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getBookGroupByCategory
     * @param {module:api/BookApi~getBookGroupByCategoryUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/CategoryBookItemResponseModel>}
     */
    getBookGroupByCategoryUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = [CategoryBookItemResponseModel];
      return this.apiClient.callApi(
        '/api/book/categories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookInfoByOidUsingPOST operation.
     * @callback module:api/BookApi~getBookInfoByOidUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookInfoByOidResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get info for fill the form by Oid
     * @param {Object} opts Optional parameters
     * @param {String} opts.author 
     * @param {Number} opts.bookDBType 
     * @param {String} opts.bookIsbn 
     * @param {Number} opts.bookListId 
     * @param {module:model/String} opts.bookStatus 
     * @param {String} opts.categories0Id 
     * @param {String} opts.ctsid 
     * @param {String} opts.description 
     * @param {Number} opts.from 
     * @param {Number} opts.inventory 
     * @param {String} opts.languages0Id 
     * @param {Number} opts.limit 
     * @param {Number} opts.maxPrice 
     * @param {Number} opts.minPrice 
     * @param {String} opts.name 
     * @param {Number} opts.orgId orgId
     * @param {Number} opts.organizations0Id 
     * @param {String} opts.productTypes0Id 
     * @param {Number} opts.publishEndDate 
     * @param {Number} opts.publishStartDate 
     * @param {module:api/BookApi~getBookInfoByOidUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookInfoByOidResponseModel}
     */
    getBookInfoByOidUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'author': opts['author'],
        'bookDBType': opts['bookDBType'],
        'bookIsbn': opts['bookIsbn'],
        'bookListId': opts['bookListId'],
        'bookStatus': opts['bookStatus'],
        'categories[0].id': opts['categories0Id'],
        'ctsid': opts['ctsid'],
        'description': opts['description'],
        'from': opts['from'],
        'inventory': opts['inventory'],
        'languages[0].id': opts['languages0Id'],
        'limit': opts['limit'],
        'maxPrice': opts['maxPrice'],
        'minPrice': opts['minPrice'],
        'name': opts['name'],
        'orgId': opts['orgId'],
        'organizations[0].id': opts['organizations0Id'],
        'productTypes[0].id': opts['productTypes0Id'],
        'publishEndDate': opts['publishEndDate'],
        'publishStartDate': opts['publishStartDate']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = BookInfoByOidResponseModel;
      return this.apiClient.callApi(
        '/api/book/infoListByOid', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookInfoUsingGET operation.
     * @callback module:api/BookApi~getBookInfoUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookInfoResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get info for fill the form
     * @param {Object} opts Optional parameters
     * @param {Number} opts.orgId orgId
     * @param {module:api/BookApi~getBookInfoUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookInfoResponseModel}
     */
    getBookInfoUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'orgId': opts['orgId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = BookInfoResponseModel;
      return this.apiClient.callApi(
        '/api/book/infoList', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookInfoUsingPOST operation.
     * @callback module:api/BookApi~getBookInfoUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookInfoResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get info for fill the form
     * dbType : [\"All\", \"BookList\", \"Library\"],<br>bookListId <- available on dbType is \"BookList\"
     * @param {Object} opts Optional parameters
     * @param {module:model/BookInfoSearchingQueryModel} opts.bookInfoSearchingQueryModel 
     * @param {module:api/BookApi~getBookInfoUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookInfoResponseModel}
     */
    getBookInfoUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['bookInfoSearchingQueryModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = BookInfoResponseModel;
      return this.apiClient.callApi(
        '/api/book/infoList', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookIsbnVersionUsingGET operation.
     * @callback module:api/BookApi~getBookIsbnVersionUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookVersionListResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get book id, version, isRoot by bookIsbn
     * @param {String} bookIsbn bookIsbn
     * @param {module:api/BookApi~getBookIsbnVersionUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookVersionListResponseModel}
     */
    getBookIsbnVersionUsingGET(bookIsbn, callback) {
      let postBody = null;
      // verify the required parameter 'bookIsbn' is set
      if (bookIsbn === undefined || bookIsbn === null) {
        throw new Error("Missing the required parameter 'bookIsbn' when calling getBookIsbnVersionUsingGET");
      }

      let pathParams = {
        'bookIsbn': bookIsbn
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = BookVersionListResponseModel;
      return this.apiClient.callApi(
        '/api/book/{bookIsbn}/getVersionList', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getListBooksByPostMethodUsingPOST operation.
     * @callback module:api/BookApi~getListBooksByPostMethodUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelBookDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get book list by post
     * @param {Object} opts Optional parameters
     * @param {module:model/BookSearchingQueryModel} opts.bookSearchingQueryModel 
     * @param {module:api/BookApi~getListBooksByPostMethodUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelBookDto}
     */
    getListBooksByPostMethodUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['bookSearchingQueryModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelBookDto;
      return this.apiClient.callApi(
        '/api/book/list', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getListBooksJustWithIdUsingGET operation.
     * @callback module:api/BookApi~getListBooksJustWithIdUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelBookDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get book list just with id
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.categoryIds category Ids
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Number} opts.inventoryRangeMax 
     * @param {Number} opts.inventoryRangeMin 
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Array.<String>} opts.languageIds language Ids
     * @param {Number} opts.limit return size
     * @param {module:model/String} opts.order sorting order : 1/-1
     * @param {Array.<Number>} opts.organizationIds Organization Ids
     * @param {Number} opts.priceRangeMax 
     * @param {Number} opts.priceRangeMin 
     * @param {Array.<String>} opts.productTypeIds Product Type Ids
     * @param {Number} opts.publishDateRangeMax 
     * @param {Number} opts.publishDateRangeMin 
     * @param {module:model/String} opts.sortingKey sorting key
     * @param {module:model/String} opts.status status
     * @param {Number} opts.uploadDateRangeMax 
     * @param {Number} opts.uploadDateRangeMin 
     * @param {Boolean} opts.withRole 
     * @param {module:api/BookApi~getListBooksJustWithIdUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelBookDto}
     */
    getListBooksJustWithIdUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'categoryIds': this.apiClient.buildCollectionParam(opts['categoryIds'], 'pipes'),
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'inventoryRange.max': opts['inventoryRangeMax'],
        'inventoryRange.min': opts['inventoryRangeMin'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'languageIds': this.apiClient.buildCollectionParam(opts['languageIds'], 'pipes'),
        'limit': opts['limit'],
        'order': opts['order'],
        'organizationIds': this.apiClient.buildCollectionParam(opts['organizationIds'], 'pipes'),
        'priceRange.max': opts['priceRangeMax'],
        'priceRange.min': opts['priceRangeMin'],
        'productTypeIds': this.apiClient.buildCollectionParam(opts['productTypeIds'], 'pipes'),
        'publishDateRange.max': opts['publishDateRangeMax'],
        'publishDateRange.min': opts['publishDateRangeMin'],
        'sortingKey': opts['sortingKey'],
        'status': opts['status'],
        'uploadDateRange.max': opts['uploadDateRangeMax'],
        'uploadDateRange.min': opts['uploadDateRangeMin'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelBookDto;
      return this.apiClient.callApi(
        '/api/book/list-just-id', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getListBooksUsingGET operation.
     * @callback module:api/BookApi~getListBooksUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelBookDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get book list
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.categoryIds category Ids
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Number} opts.inventoryRangeMax 
     * @param {Number} opts.inventoryRangeMin 
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Array.<String>} opts.languageIds language Ids
     * @param {Number} opts.limit return size
     * @param {module:model/String} opts.order sorting order : 1/-1
     * @param {Array.<Number>} opts.organizationIds Organization Ids
     * @param {Number} opts.priceRangeMax 
     * @param {Number} opts.priceRangeMin 
     * @param {Array.<String>} opts.productTypeIds Product Type Ids
     * @param {Number} opts.publishDateRangeMax 
     * @param {Number} opts.publishDateRangeMin 
     * @param {module:model/String} opts.sortingKey sorting key
     * @param {module:model/String} opts.status status
     * @param {Number} opts.uploadDateRangeMax 
     * @param {Number} opts.uploadDateRangeMin 
     * @param {Boolean} opts.withRole 
     * @param {module:api/BookApi~getListBooksUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelBookDto}
     */
    getListBooksUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'categoryIds': this.apiClient.buildCollectionParam(opts['categoryIds'], 'pipes'),
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'inventoryRange.max': opts['inventoryRangeMax'],
        'inventoryRange.min': opts['inventoryRangeMin'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'languageIds': this.apiClient.buildCollectionParam(opts['languageIds'], 'pipes'),
        'limit': opts['limit'],
        'order': opts['order'],
        'organizationIds': this.apiClient.buildCollectionParam(opts['organizationIds'], 'pipes'),
        'priceRange.max': opts['priceRangeMax'],
        'priceRange.min': opts['priceRangeMin'],
        'productTypeIds': this.apiClient.buildCollectionParam(opts['productTypeIds'], 'pipes'),
        'publishDateRange.max': opts['publishDateRangeMax'],
        'publishDateRange.min': opts['publishDateRangeMin'],
        'sortingKey': opts['sortingKey'],
        'status': opts['status'],
        'uploadDateRange.max': opts['uploadDateRangeMax'],
        'uploadDateRange.min': opts['uploadDateRangeMin'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelBookDto;
      return this.apiClient.callApi(
        '/api/book/list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getSimpleListBooksUsingGET operation.
     * @callback module:api/BookApi~getSimpleListBooksUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelBookBaseSimpleDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get book simple list
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.categoryIds category Ids
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Number} opts.inventoryRangeMax 
     * @param {Number} opts.inventoryRangeMin 
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Array.<String>} opts.languageIds language Ids
     * @param {Number} opts.limit return size
     * @param {module:model/String} opts.order sorting order : 1/-1
     * @param {Array.<Number>} opts.organizationIds Organization Ids
     * @param {Number} opts.priceRangeMax 
     * @param {Number} opts.priceRangeMin 
     * @param {Array.<String>} opts.productTypeIds Product Type Ids
     * @param {Number} opts.publishDateRangeMax 
     * @param {Number} opts.publishDateRangeMin 
     * @param {module:model/String} opts.sortingKey sorting key
     * @param {module:model/String} opts.status status
     * @param {Number} opts.uploadDateRangeMax 
     * @param {Number} opts.uploadDateRangeMin 
     * @param {Boolean} opts.withRole 
     * @param {module:api/BookApi~getSimpleListBooksUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelBookBaseSimpleDto}
     */
    getSimpleListBooksUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'categoryIds': this.apiClient.buildCollectionParam(opts['categoryIds'], 'pipes'),
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'inventoryRange.max': opts['inventoryRangeMax'],
        'inventoryRange.min': opts['inventoryRangeMin'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'languageIds': this.apiClient.buildCollectionParam(opts['languageIds'], 'pipes'),
        'limit': opts['limit'],
        'order': opts['order'],
        'organizationIds': this.apiClient.buildCollectionParam(opts['organizationIds'], 'pipes'),
        'priceRange.max': opts['priceRangeMax'],
        'priceRange.min': opts['priceRangeMin'],
        'productTypeIds': this.apiClient.buildCollectionParam(opts['productTypeIds'], 'pipes'),
        'publishDateRange.max': opts['publishDateRangeMax'],
        'publishDateRange.min': opts['publishDateRangeMin'],
        'sortingKey': opts['sortingKey'],
        'status': opts['status'],
        'uploadDateRange.max': opts['uploadDateRangeMax'],
        'uploadDateRange.min': opts['uploadDateRangeMin'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelBookBaseSimpleDto;
      return this.apiClient.callApi(
        '/api/book/simpleBookList', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the insertReaderResponseUsingPOST operation.
     * @callback module:api/BookApi~insertReaderResponseUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * insertReaderResponse
     * @param {String} bookId bookId
     * @param {Object} opts Optional parameters
     * @param {module:model/AddBookReaderResponsePayload} opts.addBookReaderResponsePayload 
     * @param {module:api/BookApi~insertReaderResponseUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    insertReaderResponseUsingPOST(bookId, opts, callback) {
      opts = opts || {};
      let postBody = opts['addBookReaderResponsePayload'];
      // verify the required parameter 'bookId' is set
      if (bookId === undefined || bookId === null) {
        throw new Error("Missing the required parameter 'bookId' when calling insertReaderResponseUsingPOST");
      }

      let pathParams = {
        'bookId': bookId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/book/{bookId}/response', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateBookUsingPATCH operation.
     * @callback module:api/BookApi~updateBookUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * update book
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/BookPayloadModel} opts.bookPayloadModel 
     * @param {module:api/BookApi~updateBookUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookDto}
     */
    updateBookUsingPATCH(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['bookPayloadModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateBookUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = BookDto;
      return this.apiClient.callApi(
        '/api/book/update/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
