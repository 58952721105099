/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import DoubleRangeModel from './DoubleRangeModel';
import IntegerRangeModel from './IntegerRangeModel';
import LongRangeModel from './LongRangeModel';

/**
 * The BookSearchingQueryModel model module.
 * @module model/BookSearchingQueryModel
 * @version v1.0.0
 */
class BookSearchingQueryModel {
    /**
     * Constructs a new <code>BookSearchingQueryModel</code>.
     * @alias module:model/BookSearchingQueryModel
     */
    constructor() { 
        
        BookSearchingQueryModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BookSearchingQueryModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BookSearchingQueryModel} obj Optional instance to populate.
     * @return {module:model/BookSearchingQueryModel} The populated <code>BookSearchingQueryModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BookSearchingQueryModel();

            if (data.hasOwnProperty('categoryIds')) {
                obj['categoryIds'] = ApiClient.convertToType(data['categoryIds'], ['String']);
            }
            if (data.hasOwnProperty('from')) {
                obj['from'] = ApiClient.convertToType(data['from'], 'Number');
            }
            if (data.hasOwnProperty('getAll')) {
                obj['getAll'] = ApiClient.convertToType(data['getAll'], 'Boolean');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('inventoryRange')) {
                obj['inventoryRange'] = IntegerRangeModel.constructFromObject(data['inventoryRange']);
            }
            if (data.hasOwnProperty('isActive')) {
                obj['isActive'] = ApiClient.convertToType(data['isActive'], 'Boolean');
            }
            if (data.hasOwnProperty('isDeleted')) {
                obj['isDeleted'] = ApiClient.convertToType(data['isDeleted'], 'Boolean');
            }
            if (data.hasOwnProperty('keyword')) {
                obj['keyword'] = ApiClient.convertToType(data['keyword'], 'String');
            }
            if (data.hasOwnProperty('languageIds')) {
                obj['languageIds'] = ApiClient.convertToType(data['languageIds'], ['String']);
            }
            if (data.hasOwnProperty('limit')) {
                obj['limit'] = ApiClient.convertToType(data['limit'], 'Number');
            }
            if (data.hasOwnProperty('order')) {
                obj['order'] = ApiClient.convertToType(data['order'], 'String');
            }
            if (data.hasOwnProperty('organizationIds')) {
                obj['organizationIds'] = ApiClient.convertToType(data['organizationIds'], ['Number']);
            }
            if (data.hasOwnProperty('priceRange')) {
                obj['priceRange'] = DoubleRangeModel.constructFromObject(data['priceRange']);
            }
            if (data.hasOwnProperty('productTypeIds')) {
                obj['productTypeIds'] = ApiClient.convertToType(data['productTypeIds'], ['String']);
            }
            if (data.hasOwnProperty('publishDateRange')) {
                obj['publishDateRange'] = LongRangeModel.constructFromObject(data['publishDateRange']);
            }
            if (data.hasOwnProperty('sortingKey')) {
                obj['sortingKey'] = ApiClient.convertToType(data['sortingKey'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('uploadDateRange')) {
                obj['uploadDateRange'] = LongRangeModel.constructFromObject(data['uploadDateRange']);
            }
            if (data.hasOwnProperty('withRole')) {
                obj['withRole'] = ApiClient.convertToType(data['withRole'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * category Ids
 * @member {Array.<String>} categoryIds
 */
BookSearchingQueryModel.prototype['categoryIds'] = undefined;

/**
 * start from
 * @member {Number} from
 */
BookSearchingQueryModel.prototype['from'] = undefined;

/**
 * @member {Boolean} getAll
 */
BookSearchingQueryModel.prototype['getAll'] = undefined;

/**
 * id
 * @member {String} id
 */
BookSearchingQueryModel.prototype['id'] = undefined;

/**
 * @member {module:model/IntegerRangeModel} inventoryRange
 */
BookSearchingQueryModel.prototype['inventoryRange'] = undefined;

/**
 * active status
 * @member {Boolean} isActive
 */
BookSearchingQueryModel.prototype['isActive'] = undefined;

/**
 * delete status
 * @member {Boolean} isDeleted
 */
BookSearchingQueryModel.prototype['isDeleted'] = undefined;

/**
 * search keyword
 * @member {String} keyword
 */
BookSearchingQueryModel.prototype['keyword'] = undefined;

/**
 * language Ids
 * @member {Array.<String>} languageIds
 */
BookSearchingQueryModel.prototype['languageIds'] = undefined;

/**
 * return size
 * @member {Number} limit
 */
BookSearchingQueryModel.prototype['limit'] = undefined;

/**
 * sorting order : 1/-1
 * @member {module:model/BookSearchingQueryModel.OrderEnum} order
 */
BookSearchingQueryModel.prototype['order'] = undefined;

/**
 * Organization Ids
 * @member {Array.<Number>} organizationIds
 */
BookSearchingQueryModel.prototype['organizationIds'] = undefined;

/**
 * @member {module:model/DoubleRangeModel} priceRange
 */
BookSearchingQueryModel.prototype['priceRange'] = undefined;

/**
 * Product Type Ids
 * @member {Array.<String>} productTypeIds
 */
BookSearchingQueryModel.prototype['productTypeIds'] = undefined;

/**
 * @member {module:model/LongRangeModel} publishDateRange
 */
BookSearchingQueryModel.prototype['publishDateRange'] = undefined;

/**
 * sorting key
 * @member {module:model/BookSearchingQueryModel.SortingKeyEnum} sortingKey
 */
BookSearchingQueryModel.prototype['sortingKey'] = undefined;

/**
 * status
 * @member {module:model/BookSearchingQueryModel.StatusEnum} status
 */
BookSearchingQueryModel.prototype['status'] = undefined;

/**
 * @member {module:model/LongRangeModel} uploadDateRange
 */
BookSearchingQueryModel.prototype['uploadDateRange'] = undefined;

/**
 * @member {Boolean} withRole
 */
BookSearchingQueryModel.prototype['withRole'] = undefined;





/**
 * Allowed values for the <code>order</code> property.
 * @enum {String}
 * @readonly
 */
BookSearchingQueryModel['OrderEnum'] = {

    /**
     * value: "ASC"
     * @const
     */
    "ASC": "ASC",

    /**
     * value: "DESC"
     * @const
     */
    "DESC": "DESC"
};


/**
 * Allowed values for the <code>sortingKey</code> property.
 * @enum {String}
 * @readonly
 */
BookSearchingQueryModel['SortingKeyEnum'] = {

    /**
     * value: "createAt"
     * @const
     */
    "createAt": "createAt",

    /**
     * value: "inventory"
     * @const
     */
    "inventory": "inventory",

    /**
     * value: "price"
     * @const
     */
    "price": "price",

    /**
     * value: "publishDate"
     * @const
     */
    "publishDate": "publishDate",

    /**
     * value: "updateAt"
     * @const
     */
    "updateAt": "updateAt"
};


/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
BookSearchingQueryModel['StatusEnum'] = {

    /**
     * value: "APPLY_UNAVAILABLE"
     * @const
     */
    "APPLY_UNAVAILABLE": "APPLY_UNAVAILABLE",

    /**
     * value: "APPROVED"
     * @const
     */
    "APPROVED": "APPROVED",

    /**
     * value: "AVAILABLE"
     * @const
     */
    "AVAILABLE": "AVAILABLE",

    /**
     * value: "REJECTED"
     * @const
     */
    "REJECTED": "REJECTED",

    /**
     * value: "SUBMITTED"
     * @const
     */
    "SUBMITTED": "SUBMITTED",

    /**
     * value: "TEMP"
     * @const
     */
    "TEMP": "TEMP",

    /**
     * value: "UNAVAILABLE"
     * @const
     */
    "UNAVAILABLE": "UNAVAILABLE"
};



export default BookSearchingQueryModel;

