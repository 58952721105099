import localforage from "localforage";
import React, { useEffect, useState } from "react";
import loadingGif from "../../../src/assets/images/loading.gif";
import placeHolderIcon from "../../../src/assets/images/placeholder.png"; //"../../../assets/images/placeholder.png";
import Button from "../../components/Button/Button";
import DownloadButton from "../../components/Button/download/DownloadButton";
import Fancybox from "../../components/fancybox/fancybox";
import Input from "../../components/Input/Input";
import { FileApi } from "../../fetcher";
import {
  COVER,
  EPUBPREVIEW,
  EPUBPROD,
  MULTIMEDIA,
  STYLETYPE_CUSTOM,
  STYLETYPE_MAIN,
  STYLETYPE_SELECT_FILE,
  THUMB,
} from "../../utils/Constants";
import { genApiConfig } from "../../utils/fetch-caller";
import { UPLOAD_CHUNK_FILE_API } from "../../utils/fileUtils/FileRequest";
import Uploader from "../../utils/fileUtils/FileUploader";
import BookUtil from "../../utils/reader/fileUtils/bookUtil";
import { addEpub } from "../../utils/reader/fileUtils/epubUtil";
import { fetchImageFromURL } from "../../utils/reader/fileUtils/fileUtil";
import {
  genBookData,
  getMd5WithBrowser,
} from "../../utils/reader/fileUtils/md5Util";
import RecentBooks from "../../utils/reader/readUtils/recordRecent";
import StorageUtil from "../../utils/reader/serviceUtils/storageUtil";
const TestUploadFile = () => {
  const [epubProdFileState, setEpubProdFileState] = useState([]);
  const [epubPreviewFileState, setEpubPreviewFileState] = useState([]);
  const [coverFileState, setCoverFileState] = useState([]);
  const [multimediaFileState, setMultimediaFileState] = useState([]);
  const [thumbFileState, setThumbFileState] = useState([]);

  //#region Upload File
  const openBookClick = (bookObj, itemObj) => {
    if (StorageUtil.getReaderConfig("isOpenInMain") === "yes") {
      //props.history.push(BookUtil.getBookUrl(book));
      //props.handleReadingBook(book);
    } else {
      //localStorage.setItem("tempBook", JSON.stringify(book));
      BookUtil.RedirectBook(bookObj, itemObj.file.name).then(() => {
        switch (itemObj.bookType) {
          case EPUBPROD:
            var newEpubProdFileArr = [];
            epubProdFileState.forEach((it) => {
              if (it.fileId === itemObj.fileId) {
                it.isLoading = false;
              }
              newEpubProdFileArr.push(it);
            });
            setEpubProdFileState([...newEpubProdFileArr]);
            break;
          case EPUBPREVIEW:
            var newEpubPreviewFileArr = [];
            epubPreviewFileState.forEach((it) => {
              if (it.fileId === itemObj.fileId) {
                it.isLoading = false;
              }
              newEpubProdFileArr.push(it);
            });
            setEpubPreviewFileState([...newEpubPreviewFileArr]);
            break;
        }
      });
    }
  };

  const handleBookPreView = (itemObj) => {
    if (itemObj !== undefined && itemObj !== null) {
      if (itemObj["fileUrl"] !== undefined) {
        try {
          switch (itemObj.bookType) {
            case EPUBPROD:
              var newEpubProdArr = [];
              epubProdFileState.forEach((it) => {
                if (it.fileId === itemObj.fileId) {
                  it.isLoading = true;
                }
                newEpubProdArr.push(it);
              });
              setEpubProdFileState([...newEpubProdArr]);
              break;
            case EPUBPREVIEW:
              var newEpubPreviewArr = [];
              epubPreviewFileState.forEach((it) => {
                if (it.fileId === itemObj.fileId) {
                  it.isLoading = true;
                }
                newEpubPreviewArr.push(it);
              });
              setEpubPreviewFileState([...newEpubPreviewArr]);
              break;
          }

          if (itemObj.extension === "pdf") {
            fetch(`${process.env.REACT_APP_BACKEND_URL}${itemObj.fileUrl}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/pdf",
              },
            })
              .then((res) => {
                console.log("rrr", res);
                res.blob().then(async (blob) => {
                  let reader = new FileReader();
                  reader.readAsArrayBuffer(blob);
                  reader.onload = async (event) => {
                    const file_content = event.target.result;
                    BookUtil.generateBook(
                      itemObj.file.name,
                      itemObj.extension,
                      itemObj.md5,
                      blob.size,
                      itemObj.file.name,
                      file_content
                    ).then(async (bookObj) => {
                      console.log("ffff", bookObj);
                      await BookUtil.addBook(bookObj.key, file_content);
                      RecentBooks.setRecent(bookObj.key);
                      localforage.setItem("books", bookObj);
                      openBookClick(bookObj, itemObj);
                    });
                  };
                });
              })
              .catch((err) => {
                alert(err);
              });
          }

          if (itemObj.extension === "epub") {
            fetch(`${process.env.REACT_APP_BACKEND_URL}${itemObj.fileUrl}`, {
              method: "GET",
              headers: {
                "Content-Type": "application/epub+zip",
              },
            })
              .then((epubResult) => {
                epubResult.blob().then(async (blob) => {
                  const file = new File([blob], itemObj.file.name, {
                    lastModified: new Date().getTime(),
                  });
                  addEpub(file, itemObj.md5, file.name).then(
                    async (bookObj) => {
                      await BookUtil.addBook(bookObj.key, file);
                      RecentBooks.setRecent(bookObj.key);
                      localforage.setItem("books", bookObj);
                      openBookClick(bookObj, itemObj);
                    }
                  );
                });
              })
              .catch((err) => {
                alert(err);
              });
          }
        } catch (err) {
          alert(err);
        }
      }
    }
  };

  const handleSelectFile = (itemObj, value) => {
    switch (itemObj.bookType) {
      case EPUBPROD:
        var newEpubProdArr = [];
        epubProdFileState.forEach((it) => {
          if (it.file.name === itemObj.file.name) {
            it.isSelected = !it.isSelected;
          } else {
            it.isSelected = false;
          }
          newEpubProdArr.push(it);
        });
        setEpubProdFileState([...newEpubProdArr]);
        break;
      case EPUBPREVIEW:
        var newEpubPreviewArr = [];
        epubPreviewFileState.forEach((it) => {
          if (it.file.name === itemObj.file.name) {
            it.isSelected = !it.isSelected;
          } else {
            it.isSelected = false;
          }
          newEpubPreviewArr.push(it);
        });
        setEpubPreviewFileState([...newEpubPreviewArr]);
        break;
      case COVER:
        var newCoverArr = [];
        coverFileState.forEach((it) => {
          if (it.file.name === itemObj.file.name) {
            it.isSelected = !it.isSelected;
          } else {
            it.isSelected = false;
          }
          newCoverArr.push(it);
        });
        setCoverFileState([...newCoverArr]);
        break;
      case MULTIMEDIA:
        var newMultiMediaArr = [];
        multimediaFileState.forEach((it) => {
          if (it.file.name === itemObj.file.name) {
            it.isSelected = !it.isSelected;
          }
          newMultiMediaArr.push(it);
        });
        setMultimediaFileState([...newMultiMediaArr]);
        break;
      case THUMB:
        var newThumbArr = [];
        thumbFileState.forEach((it) => {
          if (it.file.name === itemObj.file.name) {
            it.isSelected = !it.isSelected;
          }
          newThumbArr.push(it);
        });
        setThumbFileState([...newThumbArr]);
        break;
    }
  };

  const uploadFileButton = (file, type) => {
    switch (type) {
      case EPUBPROD:
        var checkResult = checkDuplicateFile(file, type);
        if (checkResult.msg !== "") {
          alert(checkResult.msg);
        }
        if (checkResult.data.length > 0) {
          setEpubProdFileState([...epubProdFileState, ...checkResult.data]);
        }
        break;
      case EPUBPREVIEW:
        var checkResult = checkDuplicateFile(file, type);
        if (checkResult.msg !== "") {
          alert(checkResult.msg);
        }
        if (checkResult.data.length > 0) {
          setEpubPreviewFileState([
            ...epubPreviewFileState,
            ...checkResult.data,
          ]);
        }
        break;
      case COVER:
        var checkResult = checkDuplicateFile(file, type);
        if (checkResult.msg !== "") {
          alert(checkResult.msg);
        }
        if (checkResult.data.length > 0) {
          setCoverFileState([...coverFileState, ...checkResult.data]);
        }
        break;
      case MULTIMEDIA:
        var checkResult = checkDuplicateFile(file, type);
        if (checkResult.msg !== "") {
          alert(checkResult.msg);
        }
        if (checkResult.data.length > 0) {
          setMultimediaFileState([...multimediaFileState, ...checkResult.data]);
        }
        break;
      case THUMB:
        var checkResult = checkDuplicateFile(file, type);
        if (checkResult.msg !== "") {
          alert(checkResult.msg);
        }
        if (checkResult.data.length > 0) {
          setThumbFileState([...thumbFileState, ...checkResult.data]);
        }
        break;
    }
  };

  const genFilePath = (file) => {
    if (file instanceof Object) {
      return URL.createObjectURL(file);
    } else {
      return `${process.env.REACT_APP_BACKEND_URL}${file}`;
    }
  };

  const handleItemDelete = (value) => {
    if (value !== "") {
      let opts = {};

      opts["commonDeletePayloadModel"] = [
        {
          delete: true,
          id: value,
        },
      ];

      new FileApi(genApiConfig())
        .deleteFileUsingDELETE(opts)
        .then((res) => {
          if (res.response.ok) {
            for (const [key, value] of Object.entries(res.data)) {
              if (key !== undefined && key !== null) {
                var newEpubProdFileArr = [];
                epubProdFileState.forEach((it) => {
                  if (it.fileId !== key) {
                    newEpubProdFileArr.push(it);
                  }
                });

                setEpubProdFileState([...newEpubProdFileArr]);

                var newEpubPerviewFileArr = [];
                epubPreviewFileState.forEach((it) => {
                  if (it.fileId !== key) {
                    newEpubPerviewFileArr.push(it);
                  }
                });

                setEpubPreviewFileState([...newEpubPerviewFileArr]);

                var newCoverFileArr = [];
                coverFileState.forEach((it) => {
                  if (it.fileId !== key) {
                    newCoverFileArr.push(it);
                  }
                });
                setCoverFileState([...newCoverFileArr]);

                var newMultiMediaArr = [];
                multimediaFileState.forEach((it) => {
                  if (it.fileId !== key) {
                    newMultiMediaArr.push(it);
                  }
                });
                setMultimediaFileState([...newMultiMediaArr]);

                var newThumbArr = [];

                thumbFileState.forEach((it) => {
                  if (it.fileId !== key) {
                    newThumbArr.push(it);
                  }
                });
                setThumbFileState([...newThumbArr]);
              }
            }

            alert("刪除成功");

            // if (res.data.hasOwnProperty(value)) {

            //   var itemObj = sliceProgressDetail.findIndex(
            //     (it) => it.fileId === value
            //   );
            //   if (itemObj > -1) {
            //     var newArr = [...sliceProgressDetail];
            //     newArr.splice(itemObj, 1);
            //     setSliceProgressDetail(newArr, () => console.log(res));
            //     alert("刪除成功");
            //   }
            // }
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const checkDuplicateFile = (file, type) => {
    var result = {
      data: [],
      msg: "",
    };

    let currentArr = [];
    for (var key = 0; key < file.length; key++) {
      switch (type) {
        case EPUBPROD:
          currentArr = epubProdFileState;
          break;
        case EPUBPREVIEW:
          currentArr = epubPreviewFileState;
          break;
        case COVER:
          currentArr = coverFileState;
          break;
        case MULTIMEDIA:
          currentArr = multimediaFileState;
          break;
        case THUMB:
          currentArr = thumbFileState;
          break;
      }

      var foundItem = currentArr.find(
        (it) => it.file.name === file[key]["name"]
      );
      if (foundItem === undefined) {
        result.data.push(setNewObj(file[key], type));
      } else {
        if (result.msg === "") {
          result.msg += "重覆上傳文件\u000a";
        }
        result.msg += file[key]["name"] + "\u000a";
      }
    }
    return result;
  };

  const setNewObj = (file, type) => {
    return {
      file: file,
      md5: null,
      book: null,
      percent: 0,
      coverUrl: null,
      coverId: null,
      fileUrl: null,
      fileId: null,
      bookType: type,
      isSelected: false,
      isNew: true,
      totalUploadTime: 0,
      totalHashTime: 0,
      extension: file["name"].split(".")[1].toLowerCase(),
    };
  };

  const getFileHtml = (itemObj) => {
    if (itemObj !== undefined && itemObj !== null) {
      return (
        <div className="cardListItem" key={itemObj.name}>
          <div className="cardListItemCheckBox">
            <Input
              showType={STYLETYPE_CUSTOM}
              type="checkbox"
              checked={itemObj.isSelected}
              callBack={(value) => handleSelectFile(itemObj, value)}
            />
            <span className="checkmark"></span>
          </div>
          <div className="cardListItemFilename">
            <DownloadButton
              filename={itemObj.file.name}
              percent={itemObj.percent}
            />
          </div>
          <div className="cardListItemPreview">
            <img src={placeHolderIcon} alt="" />
            <Button styleType={STYLETYPE_MAIN} disabled={true}>
              預覽
            </Button>
          </div>
        </div>
      );
    }
  };

  const getBookHtml = (itemObj) => {
    if (itemObj !== undefined && itemObj !== null) {
      return (
        <div className="cardListItem">
          <div className="cardListItemCheckBox">
            <Input
              showType={STYLETYPE_CUSTOM}
              type="checkbox"
              checked={itemObj.isSelected}
              callBack={(value) => handleSelectFile(itemObj, value)}
            />
            <span className="checkmark"></span>
          </div>
          <div className="cardListItemFilename">
            <DownloadButton
              filename={itemObj.fileName}
              percent={itemObj.percent}
            />
          </div>
          <div className="cardListItemPreview">
            <img
              src={
                itemObj.coverUrl === null
                  ? placeHolderIcon
                  : genFilePath(itemObj.coverUrl)
              }
              alt=""
              onClick={() => handleBookPreView(itemObj)}
            />
            <Button
              styleType={STYLETYPE_MAIN}
              callBack={(value) => handleBookPreView(itemObj)}
              disabled={itemObj.isLoading}
            >
              {itemObj.isLoading ? "" : "預覽"}
              <img
                src={loadingGif}
                style={{ width: "20px", height: "20px" }}
                hidden={!itemObj.isLoading}
                alt={loadingGif}
              />
            </Button>
          </div>
          <div
            className="cardListItemDelete"
            onClick={() => handleItemDelete(itemObj.fileId)}
          ></div>
        </div>
      );
    }
  };

  const getImageFileHtml = (itemObj) => {
    if (itemObj !== undefined && itemObj !== null) {
      return (
        <>
          <div className="cardListItem">
            <div className="cardListItemCheckBox">
              <Input
                showType={STYLETYPE_CUSTOM}
                type="checkbox"
                checked={itemObj.isSelected}
                callBack={(value) => handleSelectFile(itemObj, value)}
              />
              <span className="checkmark"></span>
            </div>
            <div className="cardListItemFilename">
              <DownloadButton
                filename={itemObj.file.name}
                percent={itemObj.percent}
              />
            </div>
            <div className="cardListItemPreview">
              <Fancybox>
                <a data-fancybox="gallery" href={genFilePath(itemObj.fileUrl)}>
                  <img
                    src={genFilePath(itemObj.fileUrl)}
                    alt={genFilePath(itemObj.fileUrl)}
                  />
                </a>
              </Fancybox>
            </div>
          </div>
        </>
      );
    }
  };

  const getUploadedImageHtml = (itemObj) => {
    if (itemObj !== undefined && itemObj !== null) {
      return (
        <>
          <div className="cardListItem">
            <div className="cardListItemCheckBox">
              <Input
                showType={STYLETYPE_CUSTOM}
                type="checkbox"
                checked={itemObj.isSelected}
                callBack={(value) => handleSelectFile(itemObj, value)}
              />
              <span className="checkmark"></span>
            </div>
            <div className="cardListItemFilename">
              <DownloadButton
                filename={itemObj.file.name}
                percent={itemObj.percent}
              />
            </div>
            <div className="cardListItemPreview">
              <Fancybox>
                <a data-fancybox="gallery" href={genFilePath(itemObj.fileUrl)}>
                  <img
                    src={genFilePath(itemObj.fileUrl)}
                    alt={genFilePath(itemObj.fileUrl)}
                  />
                </a>
              </Fancybox>
            </div>
            <div
              className="cardListItemDelete"
              onClick={() => handleItemDelete(itemObj.fileId)}
            ></div>
          </div>
        </>
      );
    }
  };

  const getMediaFileHtml = (itemObj) => {
    if (itemObj !== undefined && itemObj !== null) {
      return (
        <div className="cardListItem type_mp4">
          <div className="cardListItemCheckBox">
            <Input
              showType={STYLETYPE_CUSTOM}
              type="checkbox"
              checked={itemObj.isSelected}
              callBack={(value) => handleSelectFile(itemObj, value)}
            />
            <span className="checkmark"></span>
          </div>
          <div className="cardListItemFilename">
            <DownloadButton
              filename={itemObj.file.name}
              percent={itemObj.percent}
            />
          </div>
          <div className="cardListItemPreview">
            <video controls width="200" height="100">
              <source
                src={genFilePath(itemObj.fileUrl)}
                type="video/mp4"
              ></source>
            </video>
          </div>
        </div>
      );
    }
  };

  const getUploadedMediaHtml = (itemObj) => {
    if (itemObj !== undefined && itemObj !== null) {
      return (
        <div className="cardListItem type_mp4">
          <div className="cardListItemCheckBox">
            <Input
              showType={STYLETYPE_CUSTOM}
              type="checkbox"
              checked={itemObj.isSelected}
              callBack={(value) => handleSelectFile(itemObj, value)}
            />
            <span className="checkmark"></span>
          </div>
          <div className="cardListItemFilename">
            <DownloadButton
              filename={itemObj.file.name}
              percent={itemObj.percent}
            />
          </div>
          <div className="cardListItemPreview">
            <video controls width="200" height="100">
              <source
                src={genFilePath(itemObj.fileUrl)}
                type="video/mp4"
              ></source>
            </video>
          </div>
          <div
            className="cardListItemDelete"
            onClick={() => handleItemDelete(itemObj.fileId)}
          ></div>
        </div>
      );
    }
  };

  const uploadFileToServer = (itemObj) => {
    if (itemObj !== null && itemObj !== undefined) {
      let tempISBN = Math.floor(Math.random() * 100000);
      let totalUploadTime = 0;
      let totalHashTime = 0;

      var coverBase64 =
        itemObj.book.cover === undefined ||
        itemObj.book.cover === null ||
        itemObj.book.cover === ""
          ? placeHolderIcon
          : itemObj.book.cover;

      var coverFileName = `${itemObj.file.name.split(".")[0]}_cover.jpg`;

      new Promise(async (resolve, reject) => {
        await fetchImageFromURL(coverBase64, coverFileName).then(
          (bookCover) => {
            new Uploader(UPLOAD_CHUNK_FILE_API, {
              showProgress: false,
              enableSlice: true,
              bookIsbn: tempISBN,
              bookType: itemObj.bookType,
              bookInfo: coverFileName,
              md5: itemObj.md5,
            })
              .upload(bookCover)
              .onComplete((data) => {
                if (
                  data["hashTime"] !== undefined &&
                  data["uploadTime"] !== undefined
                ) {
                  resolve(data);
                }
              });
          }
        );
      })
        .then((data) => {
          try {
            var resultObj = {
              uploadTime: data.uploadTime,
              hashTime: data.hashTime,
              response: JSON.parse(data.response),
            };
            return resultObj;
          } catch (err) {
            //TODO Handler call api Delete file
            alert(err);
          }
        })
        .then((coverData) => {
          new Uploader(UPLOAD_CHUNK_FILE_API, {
            showProgress: true,
            enableSlice: true,
            bookIsbn: tempISBN,
            bookType: itemObj.bookType,
            bookInfo: itemObj.file.name,
            md5: itemObj.md5,
          })
            .upload(itemObj.file)
            .onProgress(({ percentage, progressDetail }) => {
              if (progressDetail.length > 0 && !Number.isNaN(percentage)) {
                switch (itemObj.bookType) {
                  case EPUBPROD:
                    var currentArr = epubProdFileState;
                    var foundItemIndex = currentArr.findIndex(
                      (it) => it.file.name === progressDetail[0].fileName
                    );

                    if (foundItemIndex > -1) {
                      if (percentage >= 100) {
                        currentArr[foundItemIndex]["percent"] = 100;
                        setEpubProdFileState([...currentArr]);
                      } else {
                        currentArr[foundItemIndex]["percent"] = percentage;
                        setEpubProdFileState([...currentArr]);
                      }
                    }
                    break;
                  case EPUBPREVIEW:
                    var currentArr = epubPreviewFileState;
                    var foundItemIndex = currentArr.findIndex(
                      (it) => it.file.name === progressDetail[0].fileName
                    );

                    if (foundItemIndex > -1) {
                      if (percentage >= 100) {
                        currentArr[foundItemIndex]["percent"] = 100;
                        setEpubPreviewFileState([...currentArr]);
                      } else {
                        currentArr[foundItemIndex]["percent"] = percentage;
                        setEpubPreviewFileState([...currentArr]);
                      }
                    }
                    break;
                }
              }
            })
            .onComplete((fileData) => {
              if (
                fileData.hasOwnProperty("response") &&
                coverData.hasOwnProperty("response")
              ) {
                switch (itemObj.bookType) {
                  case EPUBPROD:
                    try {
                      var fileJSONResult = JSON.parse(fileData.response);
                      var currentArr = epubProdFileState;
                      var foundItemIndex = currentArr.findIndex(
                        (it) => it.file.name === fileJSONResult.fileName
                      );
                      if (foundItemIndex > -1) {
                        currentArr[foundItemIndex]["coverUrl"] =
                          coverData.response.url;
                        currentArr[foundItemIndex]["coverId"] =
                          coverData.response.fileId;
                        currentArr[foundItemIndex]["fileUrl"] =
                          fileJSONResult.url;
                        currentArr[foundItemIndex]["fileId"] =
                          fileJSONResult.fileId;
                      }
                      setEpubProdFileState([...currentArr]);
                    } catch (err) {
                      alert(err);
                    }
                    break;
                  case EPUBPREVIEW:
                    try {
                      var fileJSONResult = JSON.parse(fileData.response);
                      var currentArr = epubPreviewFileState;
                      var foundItemIndex = currentArr.findIndex(
                        (it) => it.file.name === fileJSONResult.fileName
                      );
                      if (foundItemIndex > -1) {
                        currentArr[foundItemIndex]["coverUrl"] =
                          coverData.response.url;
                        currentArr[foundItemIndex]["coverId"] =
                          coverData.response.fileId;
                        currentArr[foundItemIndex]["fileUrl"] =
                          fileJSONResult.url;
                        currentArr[foundItemIndex]["fileId"] =
                          fileJSONResult.fileId;
                      }
                      setEpubPreviewFileState([...currentArr]);
                    } catch (err) {
                      alert(err);
                    }
                    break;
                }
              }
            });
        });
    }
  };

  const uploadMediaFileToServer = (itemObj) => {
    if (itemObj !== null && itemObj !== undefined) {
      let tempISBN = Math.floor(Math.random() * 100000);
      let totalUploadTime = 0;
      let totalHashTime = 0;

      new Uploader(UPLOAD_CHUNK_FILE_API, {
        showProgress: true,
        enableSlice: true,
        bookIsbn: tempISBN,
        bookType: itemObj.bookType,
        bookInfo: itemObj.file.name,
        md5: itemObj.md5,
      })
        .upload(itemObj.file)
        .onProgress(({ percentage, progressDetail }) => {
          if (progressDetail.length > 0 && !Number.isNaN(percentage)) {
            switch (itemObj.bookType) {
              case COVER:
                var currentArr = coverFileState;
                var foundItemIndex = currentArr.findIndex(
                  (it) => it.file.name === progressDetail[0].fileName
                );

                if (foundItemIndex > -1) {
                  if (percentage >= 100) {
                    currentArr[foundItemIndex]["percent"] = 100;
                    setCoverFileState([...currentArr]);
                  } else {
                    currentArr[foundItemIndex]["percent"] = percentage;
                    setCoverFileState([...currentArr]);
                  }
                }
                break;
              case MULTIMEDIA:
                var currentArr = multimediaFileState;
                var foundItemIndex = currentArr.findIndex(
                  (it) => it.file.name === progressDetail[0].fileName
                );

                if (foundItemIndex > -1) {
                  if (percentage >= 100) {
                    currentArr[foundItemIndex]["percent"] = 100;
                    setMultimediaFileState([...currentArr]);
                  } else {
                    currentArr[foundItemIndex]["percent"] = percentage;
                    setMultimediaFileState([...currentArr]);
                  }
                }
                break;
              case THUMB:
                var currentArr = thumbFileState;
                var foundItemIndex = currentArr.findIndex(
                  (it) => it.file.name === progressDetail[0].fileName
                );

                if (foundItemIndex > -1) {
                  if (percentage >= 100) {
                    currentArr[foundItemIndex]["percent"] = 100;
                    setThumbFileState([...currentArr]);
                  } else {
                    currentArr[foundItemIndex]["percent"] = percentage;
                    setThumbFileState([...currentArr]);
                  }
                }
                break;
            }
          }
        })
        .onComplete((fileData) => {
          if (fileData.hasOwnProperty("response")) {
            console.log("505", fileData);
            switch (itemObj.bookType) {
              case COVER:
                try {
                  var fileJSONResult = JSON.parse(fileData.response);
                  var currentArr = coverFileState;
                  var foundItemIndex = currentArr.findIndex(
                    (it) => it.file.name === fileJSONResult.fileName
                  );
                  if (foundItemIndex > -1) {
                    currentArr[foundItemIndex]["fileUrl"] = fileJSONResult.url;
                    currentArr[foundItemIndex]["fileId"] =
                      fileJSONResult.fileId;
                  }
                  setCoverFileState([...currentArr]);
                } catch (err) {
                  alert(err);
                }
                break;
              case MULTIMEDIA:
                try {
                  var fileJSONResult = JSON.parse(fileData.response);
                  var currentArr = multimediaFileState;
                  var foundItemIndex = currentArr.findIndex(
                    (it) => it.file.name === fileJSONResult.fileName
                  );
                  if (foundItemIndex > -1) {
                    currentArr[foundItemIndex]["fileUrl"] = fileJSONResult.url;
                    currentArr[foundItemIndex]["fileId"] =
                      fileJSONResult.fileId;
                  }
                  setMultimediaFileState([...currentArr]);
                } catch (err) {
                  alert(err);
                }
                break;
              case THUMB:
                try {
                  var fileJSONResult = JSON.parse(fileData.response);
                  var currentArr = thumbFileState;
                  var foundItemIndex = currentArr.findIndex(
                    (it) => it.file.name === fileJSONResult.fileName
                  );
                  if (foundItemIndex > -1) {
                    currentArr[foundItemIndex]["fileUrl"] = fileJSONResult.url;
                    currentArr[foundItemIndex]["fileId"] =
                      fileJSONResult.fileId;
                  }
                  setThumbFileState([...currentArr]);
                } catch (err) {
                  alert(err);
                }
                break;
            }
          }
        });
    }
  };

  useEffect(async () => {
    let newCurrentFileArr = [];
    var countNewItem = epubProdFileState.filter(
      (it) => it.isNew && it.md5 === null
    );

    if (countNewItem.length > 0) {
      await Promise.all(
        epubProdFileState.map(async (it) => {
          if (it.isNew && it.md5 === null) {
            await getMd5WithBrowser(it.file).then(async (md5) => {
              it["md5"] = md5;
              it["isNew"] = false;
              await genBookData(it.file, md5)
                .then((bookObj) => {
                  it["book"] = bookObj;
                })
                .then(() => {
                  uploadFileToServer(it);
                });
            });
          }
          newCurrentFileArr.push(it);
        })
      );
      setEpubProdFileState(newCurrentFileArr);
    }
  }, [epubProdFileState]);

  useEffect(async () => {
    let newCurrentFileArr = [];
    var countNewItem = epubPreviewFileState.filter(
      (it) => it.isNew && it.md5 === null
    );

    if (countNewItem.length > 0) {
      await Promise.all(
        epubPreviewFileState.map(async (it) => {
          if (it.isNew && it.md5 === null) {
            await getMd5WithBrowser(it.file).then(async (md5) => {
              it["md5"] = md5;
              it["isNew"] = false;
              await genBookData(it.file, md5)
                .then((bookObj) => {
                  it["book"] = bookObj;
                })
                .then(() => {
                  uploadFileToServer(it);
                });
            });
          }
          newCurrentFileArr.push(it);
        })
      );
      setEpubPreviewFileState(newCurrentFileArr);
    }
  }, [epubPreviewFileState]);

  useEffect(async () => {
    let newCurrentFileArr = [];
    var countNewItem = coverFileState.filter(
      (it) => it.isNew && it.md5 === null
    );

    if (countNewItem.length > 0) {
      await Promise.all(
        coverFileState.map(async (it) => {
          if (it.isNew && it.md5 === null) {
            await getMd5WithBrowser(it.file).then(async (md5) => {
              it["md5"] = md5;
              it["isNew"] = false;
              uploadMediaFileToServer(it);
            });
          }
          newCurrentFileArr.push(it);
        })
      );
      setCoverFileState(newCurrentFileArr);
    }
  }, [coverFileState]);

  useEffect(async () => {
    let newCurrentFileArr = [];
    var countNewItem = multimediaFileState.filter(
      (it) => it.isNew && it.md5 === null
    );

    if (countNewItem.length > 0) {
      await Promise.all(
        multimediaFileState.map(async (it) => {
          if (it.isNew && it.md5 === null) {
            await getMd5WithBrowser(it.file).then(async (md5) => {
              it["md5"] = md5;
              it["isNew"] = false;
              uploadMediaFileToServer(it);
            });
          }
          newCurrentFileArr.push(it);
        })
      );
      setMultimediaFileState(newCurrentFileArr);
    }
  }, [multimediaFileState]);

  useEffect(async () => {
    let newCurrentFileArr = [];
    var countNewItem = thumbFileState.filter(
      (it) => it.isNew && it.md5 === null
    );

    if (countNewItem.length > 0) {
      await Promise.all(
        thumbFileState.map(async (it) => {
          if (it.isNew && it.md5 === null) {
            await getMd5WithBrowser(it.file).then(async (md5) => {
              it["md5"] = md5;
              it["isNew"] = false;
              uploadMediaFileToServer(it);
            });
          }
          newCurrentFileArr.push(it);
        })
      );
      setThumbFileState(newCurrentFileArr);
    }
  }, [thumbFileState]);
  //#endregion Upload File

  return (
    <>
      <div className="fileRow">
        {/* hasPreview  */}
        <div className="fileColumn fileColumnEPUBPROD">
          <div className="cardItem">
            <div className="card">
              <div className="cardTitleContainer">
                <div className="cardTitle">書本檔案 （只限ePub/PDF）</div>
                <div className="cardAddButton">
                  <label
                    className="upload-label upload-epubprod-file-label"
                    htmlFor={"upload-epubprod-file"}
                  >
                    選擇檔案{/* epubProd */}
                  </label>
                  <Input
                    type="file"
                    id={"upload-epubprod-file"}
                    placeholder={"選擇檔案"}
                    styleType={STYLETYPE_SELECT_FILE}
                    multiple={true}
                    accept={[".epub", ".pdf"]}
                    hidden={true}
                    callBack={(value) => uploadFileButton(value, EPUBPROD)}
                  />
                </div>
              </div>
              <div className="cardPreviewContainer">
                <div className="cardPreviewItem">
                  <img className="cardPreviewItemImg" alt={"description"} />
                </div>
              </div>
              <div className="cardListContainer">
                {epubProdFileState.length > 0 ? (
                  epubProdFileState.map((item) => {
                    return (
                      //check it is file or bookObj

                      item["fileUrl"] === null
                        ? getFileHtml(item)
                        : getBookHtml(item)
                    );
                  })
                ) : (
                  <>沒有上傳檔案</>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="fileColumn fileColumnEPUBPREVIEW">
          <div className="cardItem">
            <div className="card">
              <div className="cardTitleContainer">
                <div className="cardTitle">試閱檔案 （只限ePub/PDF）</div>
                <div className="cardAddButton">
                  <label
                    className="upload-label upload-epubview-file-label"
                    htmlFor={"upload-epubview-file"}
                  >
                    選擇檔案{/* epubProd */}
                  </label>
                  <Input
                    type="file"
                    id={"upload-epubview-file"}
                    placeholder={"選擇檔案"}
                    styleType={STYLETYPE_SELECT_FILE}
                    multiple={true}
                    accept={[".epub", ".pdf"]}
                    hidden={true}
                    callBack={(value) => uploadFileButton(value, EPUBPREVIEW)}
                  />
                </div>
              </div>
              <div className="cardPreviewContainer"></div>
              <div className="cardListContainer">
                {epubPreviewFileState.length > 0 ? (
                  epubPreviewFileState.map((item) => {
                    return (
                      //check it is file or bookObj

                      item["fileUrl"] === null
                        ? getFileHtml(item)
                        : getBookHtml(item)
                    );
                  })
                ) : (
                  <>沒有上傳檔案</>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="fileColumn fileColumnCOVER">
          <div className="cardItem">
            <div className="card">
              <div className="cardTitleContainer">
                <div className="cardTitle">封面檔案 （只限JPG/PNG）</div>
                <div className="cardAddButton">
                  <label
                    className="upload-label upload-cover-file-label"
                    htmlFor={"upload-cover-file"}
                  >
                    選擇檔案{/* cover*/}
                  </label>
                  <Input
                    type="file"
                    id={"upload-cover-file"}
                    placeholder={"選擇檔案"}
                    styleType={STYLETYPE_SELECT_FILE}
                    multiple={true}
                    accept={"image/*"}
                    hidden={true}
                    callBack={(value) => uploadFileButton(value, COVER)}
                  />
                </div>
              </div>
              <div className="cardPreviewContainer"></div>
              <div className="cardListContainer">
                {coverFileState.length > 0 ? (
                  coverFileState.map((item) => {
                    return item["fileUrl"] === null
                      ? getImageFileHtml(item)
                      : getUploadedImageHtml(item);
                  })
                ) : (
                  <>沒有上傳檔案</>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="fileColumn fileColumnMULTIMEDIA">
          <div className="cardItem">
            <div className="card">
              <div className="cardTitleContainer">
                <div className="cardTitle">多媒體檔案（只限MP3/MP4）</div>
                <div className="cardAddButton">
                  <label
                    className="upload-label upload-multimedia-file-label"
                    htmlFor={"upload-multimedia-file"}
                  >
                    選擇檔案{/* multimedia*/}
                  </label>
                  <Input
                    type="file"
                    id={"upload-multimedia-file"}
                    placeholder={"選擇檔案"}
                    styleType={STYLETYPE_SELECT_FILE}
                    multiple={true}
                    accept={".mp4,.mp3"}
                    hidden={true}
                    callBack={(value) => uploadFileButton(value, MULTIMEDIA)}
                  />
                </div>
              </div>
              <div className="cardPreviewContainer"></div>
              <div className="cardListContainer">
                {multimediaFileState.length > 0 ? (
                  multimediaFileState.map((item) => {
                    return (
                      //check it is file or bookObj

                      item["fileUrl"] === null
                        ? getMediaFileHtml(item)
                        : getUploadedMediaHtml(item)
                    );
                  })
                ) : (
                  <>沒有上傳檔案</>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="fileColumn fileColumnTHUMB">
          <div className="cardItem">
            <div className="card">
              <div className="cardTitleContainer">
                <div className="cardTitle">產品照片 （只限JPG/PNG）</div>
                <div className="cardAddButton">
                  <label
                    className="upload-label upload-thumb-file-label"
                    htmlFor={"upload-thumb-file"}
                  >
                    選擇檔案{/* thumb*/}
                  </label>
                  <Input
                    type="file"
                    id={"upload-thumb-file"}
                    placeholder={"選擇檔案"}
                    styleType={STYLETYPE_SELECT_FILE}
                    multiple={true}
                    accept={"image/*"}
                    hidden={true}
                    callBack={(value) => uploadFileButton(value, THUMB)}
                  />
                </div>
              </div>
              <div className="cardPreviewContainer"></div>
              <div className="cardListContainer">
                {thumbFileState.length > 0 ? (
                  thumbFileState.map((item) => {
                    return (
                      //check it is file or bookObj

                      item["fileUrl"] === null
                        ? getImageFileHtml(item)
                        : getUploadedImageHtml(item)
                    );
                  })
                ) : (
                  <>沒有上傳檔案</>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestUploadFile;
