/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The AddPurchaseMissionPayloadModel model module.
 * @module model/AddPurchaseMissionPayloadModel
 * @version v1.0.0
 */
class AddPurchaseMissionPayloadModel {
    /**
     * Constructs a new <code>AddPurchaseMissionPayloadModel</code>.
     * @alias module:model/AddPurchaseMissionPayloadModel
     */
    constructor() { 
        
        AddPurchaseMissionPayloadModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>AddPurchaseMissionPayloadModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AddPurchaseMissionPayloadModel} obj Optional instance to populate.
     * @return {module:model/AddPurchaseMissionPayloadModel} The populated <code>AddPurchaseMissionPayloadModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AddPurchaseMissionPayloadModel();

            if (data.hasOwnProperty('bookListIds')) {
                obj['bookListIds'] = ApiClient.convertToType(data['bookListIds'], ['Number']);
            }
            if (data.hasOwnProperty('customerId')) {
                obj['customerId'] = ApiClient.convertToType(data['customerId'], 'Number');
            }
            if (data.hasOwnProperty('markUp')) {
                obj['markUp'] = ApiClient.convertToType(data['markUp'], 'Number');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<Number>} bookListIds
 */
AddPurchaseMissionPayloadModel.prototype['bookListIds'] = undefined;

/**
 * @member {Number} customerId
 */
AddPurchaseMissionPayloadModel.prototype['customerId'] = undefined;

/**
 * @member {Number} markUp
 */
AddPurchaseMissionPayloadModel.prototype['markUp'] = undefined;

/**
 * @member {module:model/AddPurchaseMissionPayloadModel.StatusEnum} status
 */
AddPurchaseMissionPayloadModel.prototype['status'] = undefined;

/**
 * @member {String} title
 */
AddPurchaseMissionPayloadModel.prototype['title'] = undefined;





/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
AddPurchaseMissionPayloadModel['StatusEnum'] = {

    /**
     * value: "Confirm"
     * @const
     */
    "Confirm": "Confirm",

    /**
     * value: "Draft"
     * @const
     */
    "Draft": "Draft",

    /**
     * value: "Finish"
     * @const
     */
    "Finish": "Finish",

    /**
     * value: "Published"
     * @const
     */
    "Published": "Published",

    /**
     * value: "Sent"
     * @const
     */
    "Sent": "Sent"
};



export default AddPurchaseMissionPayloadModel;

