import React, { useState, useEffect, Component, Fragment } from "react";
import { ConsoleView } from "react-device-detect";
import "./TestAudioBookPage.css";
import style from "./TestAudioBookPage.module.css";
import axios from 'axios';

const TestAudioBookPage = () => {
  const [json, setJson] = useState({});
  const [index, setIndex] = useState(0);
  const [subtitle, setSubtitle] = useState("");
  const [flagPlay, setFlagPlay] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [isLast, setIsLast] = useState(false);
  const [showTOC, setShowTOC] = useState(false);

  useEffect(async () => {
    const fetchData = async () => {
      const result = await axios(
        './assets/sample_audio_book/book.json',
      );

      //console.log(result.status);
      //console.log(result.data);
      setJson(result.data);
    };
    console.log("Start fetchData");
    fetchData();
    console.log("End fetchData");
  }, []);


  function initTindex(_index) {
    if (json && json.list) {
      setSubtitle(json.list[index].title);
      
      console.log(json.list[index]);
    }
  }
  const handlePrev = () => {
    let _index = index;
    _index -= 1;
    if (_index <= 0) {
      _index = 0;
      setIsFirst(true);
      setIsLast(false);
    } else {
      setIsFirst(false);
      setIsLast(false);
    }
    setIndex(_index);
    console.log(index);
  }
  const handleNext = () => {
    console.log("handleNext", index);
    let _index = index;
    _index += 1;
    if (_index >= json.list.length - 1) {
      _index = json.list.length - 1;
      setIsFirst(false);
      setIsLast(true);
    } else {
      setIsFirst(false);
      setIsLast(false);
    }
    setIndex(_index);
    console.log(index);
  }

  useEffect(() => {
    
  }, [isFirst, isLast])

  useEffect(() => {
    console.log("Start initTindex");
    initTindex();
    console.log("End initTindex");
  }, [index]);

  function toggleTOC() {
    console.log("toggleTOC");
    let _showTOC = !showTOC;
    setShowTOC(_showTOC);
  }

  return (
    <div className="audioBookContainer">
      <div className="audioBookpage">
        <div className="audioBookpageRow">
          <div className="audioBookpageTitle">{json && json.bookname ? json.bookname : ""}</div>
          <div className="audioBookpageTOCBtn" onClick={toggleTOC}>TOC</div>
        </div>
        {
          json && json.cover && (
            <div className="audioBookpageCover">
              <img src={json && json.path + json.cover} />
            </div>
          )
        }
        <div className="audioBookpageSubTitle">{subtitle}</div>
        <div className="audioBookpageSrtContainer">
          <div>字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕字幕</div>
          <div>字幕</div>
          <div>字幕</div>
          <div>字幕</div>
          <div>字幕</div>
          <div>字幕</div>
        </div>
        
        <div className="audioBookpageControl">
          {!isFirst && (
            <div className="audioBookpageControlBtn audioBookpagePrev"
              onClick={() =>
                handlePrev()
              }
            >Prev</div>
          )}
          {!flagPlay && (
           <div className="audioBookpageControlBtn audioBookpagePlay">Play</div>
          )}
          {flagPlay && (
          <div className="audioBookpageControlBtn audioBookpagePause">Pause</div>
          )}
          {!isLast && (
            <div className="audioBookpageControlBtn audioBookpageNext"
              onClick={() =>
                handleNext()
              }
            >Next</div>
          )}
        </div>
      </div>
      {
        showTOC && (
          <div className="audioBookTOCPage">
            {
              //json && 
            }
          </div>
        )
      }
    </div>
  )
}

export default TestAudioBookPage
