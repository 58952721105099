import localforage from "localforage";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "../../../utils/withRouter";
//import "../../assets/reader/react.css";
import Reader from "../../../containers/Reader/epubReader/component";
import {
  handleActionDialog,
  handleReadingBook,
  handleReadingEpub,
  handleReadingState,
  handleReaderMode,
} from "../../../redux/actions/reader";
import { getArgsQuery } from "../../../utils/GlobalFunction";
import BookUtil from "../../../utils/reader/fileUtils/bookUtil";
import RecentBooks from "../../../utils/reader/readUtils/recordRecent";
import LoadingView from "../../loading/LoadingView";

class EpubReader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingBook: true,
    };
  }

  componentDidMount() {
    console.log(
      "EpubReaderEpubReaderEpubReaderEpubReaderEpubReaderEpubReaderEpubReaderEpubReader",
      this.props
    );
    if (this.props.hasOwnProperty("history")) {
      let url = document.location.href.split("/");
      let key = url[url.length - 1].split("?")[0];
      // console.log('props', this.props)
      //console.log('uuuu', url)
      //console.log('kkkk', key)

      localforage.getItem("books").then((result) => {
        //console.log('result', result)
        console.log("epub", result);
        let book = result;
        // result[_.findIndex(result, { key })] ||
        // JSON.parse(localStorage.getItem("tempBook") || "{}");

        BookUtil.fetchBook(key, false, book.path).then((result) => {
          if (!result) {
            alert("Book not exsits");
            return;
          }

          //console.log('book', book)
          //console.log('result1', result)
          this.props.handleReadingBook(book);
          this.props.handleReadingEpub(window.ePub(result, {}));
          this.props.handleReadingState(true);
          RecentBooks.setRecent(key);
          //console.log('epubPage props', this.props)
          //console.log('epub  Reading', window.ePub(result, {}))
          let documentTitleName =
            getArgsQuery(this.props.history.location.search).title !== undefined
              ? getArgsQuery(this.props.history.location.search).title
              : book.name;
          document.title = documentTitleName;
          this.setState({ ...this.state, isLoadingBook: false });
        });
      });
    }

    // if (StorageUtil.getReaderConfig("isMergeWord") === "yes") {
    //   document
    //     .querySelector("body")
    //     ?.setAttribute("style", "background-color: rgba(0,0,0,0)");
    // }
  }

  render() {
    return this.state.isLoadingBook ? <LoadingView /> : <Reader />;

    // if (!this.props.isReading) {
    //   return null;
    // }
    // return (
    //   <>
    //     <Reader />
    //   </>
    // );
  }
}

const mapStateToProps = (state) => {
  return {
    isOpenActionDialog: state.book.isOpenActionDialog,
    currentBook: state.book.currentBook,
    currentEpub: state.book.currentEpub,
    isReading: state.book.isReading,
    reader: state.reader,
  };
};
const actionCreator = {
  handleReadingState,
  handleReadingBook,
  handleReadingEpub,
  handleActionDialog,
};
export default connect(mapStateToProps, actionCreator)(withRouter(EpubReader));
