/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import BookListDto from '../model/BookListDto';
import BookListPayloadModel from '../model/BookListPayloadModel';
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import CommonResponseModel from '../model/CommonResponseModel';
import SearchingQueryResponseModelBookListDto from '../model/SearchingQueryResponseModelBookListDto';

/**
* BookList service.
* @module api/BookListApi
* @version v1.0.0
*/
export default class BookListApi {

    /**
    * Constructs a new BookListApi. 
    * @alias module:api/BookListApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addBookListUsingPOST operation.
     * @callback module:api/BookListApi~addBookListUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookListDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * create book list
     * @param {Object} opts Optional parameters
     * @param {module:model/BookListPayloadModel} opts.bookListPayloadModel 
     * @param {module:api/BookListApi~addBookListUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookListDto}
     */
    addBookListUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['bookListPayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = BookListDto;
      return this.apiClient.callApi(
        '/api/book-list/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the addBookToBookListsUsingPOST operation.
     * @callback module:api/BookListApi~addBookToBookListsUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add book to bookLists
     * @param {String} bookId bookId
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} opts.requestBody 
     * @param {module:api/BookListApi~addBookToBookListsUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    addBookToBookListsUsingPOST(bookId, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'bookId' is set
      if (bookId === undefined || bookId === null) {
        throw new Error("Missing the required parameter 'bookId' when calling addBookToBookListsUsingPOST");
      }

      let pathParams = {
        'bookId': bookId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/book-list/book/{bookId}/add-book-lists', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the addBooksToBookListUsingPOST operation.
     * @callback module:api/BookListApi~addBooksToBookListUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookListDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add books to bookList
     * @param {Number} bookListId bookListId
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.requestBody 
     * @param {module:api/BookListApi~addBooksToBookListUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookListDto}
     */
    addBooksToBookListUsingPOST(bookListId, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'bookListId' is set
      if (bookListId === undefined || bookListId === null) {
        throw new Error("Missing the required parameter 'bookListId' when calling addBooksToBookListUsingPOST");
      }

      let pathParams = {
        'bookListId': bookListId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = BookListDto;
      return this.apiClient.callApi(
        '/api/book-list/{bookListId}/add-book', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the cloneBookListUsingPOST operation.
     * @callback module:api/BookListApi~cloneBookListUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookListDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * clone book List
     * @param {Number} originBookListId originBookListId
     * @param {module:api/BookListApi~cloneBookListUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookListDto}
     */
    cloneBookListUsingPOST(originBookListId, callback) {
      let postBody = null;
      // verify the required parameter 'originBookListId' is set
      if (originBookListId === undefined || originBookListId === null) {
        throw new Error("Missing the required parameter 'originBookListId' when calling cloneBookListUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
        'originBookListId': originBookListId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = BookListDto;
      return this.apiClient.callApi(
        '/api/book-list/clone', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteBookListUsingDELETE operation.
     * @callback module:api/BookListApi~deleteBookListUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete book list
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/BookListApi~deleteBookListUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteBookListUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/book-list/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the editBookListUsingPATCH operation.
     * @callback module:api/BookListApi~editBookListUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookListDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * edit book list
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/BookListPayloadModel} opts.bookListPayloadModel 
     * @param {module:api/BookListApi~editBookListUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookListDto}
     */
    editBookListUsingPATCH(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['bookListPayloadModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling editBookListUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = BookListDto;
      return this.apiClient.callApi(
        '/api/book-list/edit/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookListUsingGET operation.
     * @callback module:api/BookListApi~getBookListUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelBookListDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get book list
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {module:model/String} opts.status status
     * @param {Boolean} opts.withRole 
     * @param {module:api/BookListApi~getBookListUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelBookListDto}
     */
    getBookListUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'status': opts['status'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelBookListDto;
      return this.apiClient.callApi(
        '/api/book-list/list', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getSingleBookListUsingPOST operation.
     * @callback module:api/BookListApi~getSingleBookListUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelBookListDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get one book list
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.categoryIds category Ids
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Number} opts.inventoryRangeMax 
     * @param {Number} opts.inventoryRangeMin 
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Array.<String>} opts.languageIds language Ids
     * @param {Number} opts.limit return size
     * @param {module:model/String} opts.order sorting order : 1/-1
     * @param {Array.<Number>} opts.organizationIds Organization Ids
     * @param {Number} opts.priceRangeMax 
     * @param {Number} opts.priceRangeMin 
     * @param {Array.<String>} opts.productTypeIds Product Type Ids
     * @param {Number} opts.publishDateRangeMax 
     * @param {Number} opts.publishDateRangeMin 
     * @param {module:model/String} opts.sortingKey sorting key
     * @param {module:model/String} opts.status status
     * @param {Number} opts.uploadDateRangeMax 
     * @param {Number} opts.uploadDateRangeMin 
     * @param {Boolean} opts.withRole 
     * @param {module:api/BookListApi~getSingleBookListUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelBookListDto}
     */
    getSingleBookListUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'categoryIds': this.apiClient.buildCollectionParam(opts['categoryIds'], 'pipes'),
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'inventoryRange.max': opts['inventoryRangeMax'],
        'inventoryRange.min': opts['inventoryRangeMin'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'languageIds': this.apiClient.buildCollectionParam(opts['languageIds'], 'pipes'),
        'limit': opts['limit'],
        'order': opts['order'],
        'organizationIds': this.apiClient.buildCollectionParam(opts['organizationIds'], 'pipes'),
        'priceRange.max': opts['priceRangeMax'],
        'priceRange.min': opts['priceRangeMin'],
        'productTypeIds': this.apiClient.buildCollectionParam(opts['productTypeIds'], 'pipes'),
        'publishDateRange.max': opts['publishDateRangeMax'],
        'publishDateRange.min': opts['publishDateRangeMin'],
        'sortingKey': opts['sortingKey'],
        'status': opts['status'],
        'uploadDateRange.max': opts['uploadDateRangeMax'],
        'uploadDateRange.min': opts['uploadDateRangeMin'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelBookListDto;
      return this.apiClient.callApi(
        '/api/book-list/get/{id}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the removeBookInBookListsUsingDELETE operation.
     * @callback module:api/BookListApi~removeBookInBookListsUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * remove book from bookLists
     * @param {String} bookId bookId
     * @param {Object} opts Optional parameters
     * @param {Array.<Number>} opts.requestBody 
     * @param {module:api/BookListApi~removeBookInBookListsUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    removeBookInBookListsUsingDELETE(bookId, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'bookId' is set
      if (bookId === undefined || bookId === null) {
        throw new Error("Missing the required parameter 'bookId' when calling removeBookInBookListsUsingDELETE");
      }

      let pathParams = {
        'bookId': bookId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/book-list/book/{bookId}/remove-book-lists', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the removeBooksFromBookListUsingDELETE operation.
     * @callback module:api/BookListApi~removeBooksFromBookListUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/BookListDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * remove books from bookList
     * @param {Number} bookListId bookListId
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.requestBody 
     * @param {module:api/BookListApi~removeBooksFromBookListUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/BookListDto}
     */
    removeBooksFromBookListUsingDELETE(bookListId, opts, callback) {
      opts = opts || {};
      let postBody = opts['requestBody'];
      // verify the required parameter 'bookListId' is set
      if (bookListId === undefined || bookListId === null) {
        throw new Error("Missing the required parameter 'bookListId' when calling removeBooksFromBookListUsingDELETE");
      }

      let pathParams = {
        'bookListId': bookListId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = BookListDto;
      return this.apiClient.callApi(
        '/api/book-list/{bookListId}/remove-book', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
