/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BookListDto from './BookListDto';
import OrganizationObjectBaseDto from './OrganizationObjectBaseDto';
import PurchaseMissionMarkUpRecordDto from './PurchaseMissionMarkUpRecordDto';

/**
 * The PurchaseMissionDto model module.
 * @module model/PurchaseMissionDto
 * @version v1.0.0
 */
class PurchaseMissionDto {
    /**
     * Constructs a new <code>PurchaseMissionDto</code>.
     * @alias module:model/PurchaseMissionDto
     */
    constructor() { 
        
        PurchaseMissionDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PurchaseMissionDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PurchaseMissionDto} obj Optional instance to populate.
     * @return {module:model/PurchaseMissionDto} The populated <code>PurchaseMissionDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PurchaseMissionDto();

            if (data.hasOwnProperty('bookList')) {
                obj['bookList'] = ApiClient.convertToType(data['bookList'], [BookListDto]);
            }
            if (data.hasOwnProperty('createAt')) {
                obj['createAt'] = ApiClient.convertToType(data['createAt'], 'Number');
            }
            if (data.hasOwnProperty('customer')) {
                obj['customer'] = OrganizationObjectBaseDto.constructFromObject(data['customer']);
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('markUp')) {
                obj['markUp'] = ApiClient.convertToType(data['markUp'], 'Number');
            }
            if (data.hasOwnProperty('markUpRecords')) {
                obj['markUpRecords'] = ApiClient.convertToType(data['markUpRecords'], [PurchaseMissionMarkUpRecordDto]);
            }
            if (data.hasOwnProperty('referenceNo')) {
                obj['referenceNo'] = ApiClient.convertToType(data['referenceNo'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = ApiClient.convertToType(data['status'], 'String');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
            if (data.hasOwnProperty('updateAt')) {
                obj['updateAt'] = ApiClient.convertToType(data['updateAt'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/BookListDto>} bookList
 */
PurchaseMissionDto.prototype['bookList'] = undefined;

/**
 * @member {Number} createAt
 */
PurchaseMissionDto.prototype['createAt'] = undefined;

/**
 * @member {module:model/OrganizationObjectBaseDto} customer
 */
PurchaseMissionDto.prototype['customer'] = undefined;

/**
 * @member {Number} id
 */
PurchaseMissionDto.prototype['id'] = undefined;

/**
 * @member {Number} markUp
 */
PurchaseMissionDto.prototype['markUp'] = undefined;

/**
 * @member {Array.<module:model/PurchaseMissionMarkUpRecordDto>} markUpRecords
 */
PurchaseMissionDto.prototype['markUpRecords'] = undefined;

/**
 * @member {String} referenceNo
 */
PurchaseMissionDto.prototype['referenceNo'] = undefined;

/**
 * @member {module:model/PurchaseMissionDto.StatusEnum} status
 */
PurchaseMissionDto.prototype['status'] = undefined;

/**
 * @member {String} title
 */
PurchaseMissionDto.prototype['title'] = undefined;

/**
 * @member {Number} updateAt
 */
PurchaseMissionDto.prototype['updateAt'] = undefined;





/**
 * Allowed values for the <code>status</code> property.
 * @enum {String}
 * @readonly
 */
PurchaseMissionDto['StatusEnum'] = {

    /**
     * value: "Confirm"
     * @const
     */
    "Confirm": "Confirm",

    /**
     * value: "Draft"
     * @const
     */
    "Draft": "Draft",

    /**
     * value: "Finish"
     * @const
     */
    "Finish": "Finish",

    /**
     * value: "Published"
     * @const
     */
    "Published": "Published",

    /**
     * value: "Sent"
     * @const
     */
    "Sent": "Sent"
};



export default PurchaseMissionDto;

