/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CustomerShoppingCartBookListDto from '../model/CustomerShoppingCartBookListDto';
import CustomerShoppingCartDto from '../model/CustomerShoppingCartDto';
import CustomerShoppingCartNoBookDto from '../model/CustomerShoppingCartNoBookDto';
import SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto from '../model/SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto';
import SetShoppingCartBookModel from '../model/SetShoppingCartBookModel';
import ShoppingCartSummaryDto from '../model/ShoppingCartSummaryDto';

/**
* CustomerShoppingCart service.
* @module api/CustomerShoppingCartApi
* @version v1.0.0
*/
export default class CustomerShoppingCartApi {

    /**
    * Constructs a new CustomerShoppingCartApi. 
    * @alias module:api/CustomerShoppingCartApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the cleanShoppingCartByPurchaseMissionUsingDELETE operation.
     * @callback module:api/CustomerShoppingCartApi~cleanShoppingCartByPurchaseMissionUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/CustomerShoppingCartDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Clean Shopping Cart In This Purchase Mission And Matched Book List
     * @param {Number} purchaseMissionId purchaseMissionId
     * @param {Number} bookListId bookListId
     * @param {Number} customerId customerId
     * @param {module:api/CustomerShoppingCartApi~cleanShoppingCartByPurchaseMissionUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CustomerShoppingCartDto}
     */
    cleanShoppingCartByPurchaseMissionUsingDELETE(purchaseMissionId, bookListId, customerId, callback) {
      let postBody = null;
      // verify the required parameter 'purchaseMissionId' is set
      if (purchaseMissionId === undefined || purchaseMissionId === null) {
        throw new Error("Missing the required parameter 'purchaseMissionId' when calling cleanShoppingCartByPurchaseMissionUsingDELETE");
      }
      // verify the required parameter 'bookListId' is set
      if (bookListId === undefined || bookListId === null) {
        throw new Error("Missing the required parameter 'bookListId' when calling cleanShoppingCartByPurchaseMissionUsingDELETE");
      }
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling cleanShoppingCartByPurchaseMissionUsingDELETE");
      }

      let pathParams = {
        'purchaseMissionId': purchaseMissionId,
        'bookListId': bookListId
      };
      let queryParams = {
        'customerId': customerId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = CustomerShoppingCartDto;
      return this.apiClient.callApi(
        '/api/purchase/customer/shopping-cart/clean/{purchaseMissionId}/{bookListId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the cleanShoppingCartByPurchaseMissionUsingDELETE1 operation.
     * @callback module:api/CustomerShoppingCartApi~cleanShoppingCartByPurchaseMissionUsingDELETE1Callback
     * @param {String} error Error message, if any.
     * @param {module:model/CustomerShoppingCartDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Clean Shopping Cart In This Purchase Mission
     * @param {Number} purchaseMissionId purchaseMissionId
     * @param {Number} customerId customerId
     * @param {module:api/CustomerShoppingCartApi~cleanShoppingCartByPurchaseMissionUsingDELETE1Callback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CustomerShoppingCartDto}
     */
    cleanShoppingCartByPurchaseMissionUsingDELETE1(purchaseMissionId, customerId, callback) {
      let postBody = null;
      // verify the required parameter 'purchaseMissionId' is set
      if (purchaseMissionId === undefined || purchaseMissionId === null) {
        throw new Error("Missing the required parameter 'purchaseMissionId' when calling cleanShoppingCartByPurchaseMissionUsingDELETE1");
      }
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling cleanShoppingCartByPurchaseMissionUsingDELETE1");
      }

      let pathParams = {
        'purchaseMissionId': purchaseMissionId
      };
      let queryParams = {
        'customerId': customerId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = CustomerShoppingCartDto;
      return this.apiClient.callApi(
        '/api/purchase/customer/shopping-cart/clean/{purchaseMissionId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the genSummaryUsingPOST operation.
     * @callback module:api/CustomerShoppingCartApi~genSummaryUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/ShoppingCartSummaryDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * shopping cart summary
     * @param {Number} purchaseMissionId purchaseMissionId
     * @param {module:api/CustomerShoppingCartApi~genSummaryUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/ShoppingCartSummaryDto}
     */
    genSummaryUsingPOST(purchaseMissionId, callback) {
      let postBody = null;
      // verify the required parameter 'purchaseMissionId' is set
      if (purchaseMissionId === undefined || purchaseMissionId === null) {
        throw new Error("Missing the required parameter 'purchaseMissionId' when calling genSummaryUsingPOST");
      }

      let pathParams = {
        'purchaseMissionId': purchaseMissionId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = ShoppingCartSummaryDto;
      return this.apiClient.callApi(
        '/api/purchase/customer/shopping-cart/summary/{purchaseMissionId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getShoppingCartByPurchaseMissionUsingGET operation.
     * @callback module:api/CustomerShoppingCartApi~getShoppingCartByPurchaseMissionUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CustomerShoppingCartNoBookDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Shopping Cart By Purchase Mission Id
     * @param {Number} purchaseMissionId purchaseMissionId
     * @param {Number} customerId customerId
     * @param {module:api/CustomerShoppingCartApi~getShoppingCartByPurchaseMissionUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CustomerShoppingCartNoBookDto}
     */
    getShoppingCartByPurchaseMissionUsingGET(purchaseMissionId, customerId, callback) {
      let postBody = null;
      // verify the required parameter 'purchaseMissionId' is set
      if (purchaseMissionId === undefined || purchaseMissionId === null) {
        throw new Error("Missing the required parameter 'purchaseMissionId' when calling getShoppingCartByPurchaseMissionUsingGET");
      }
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling getShoppingCartByPurchaseMissionUsingGET");
      }

      let pathParams = {
        'purchaseMissionId': purchaseMissionId
      };
      let queryParams = {
        'customerId': customerId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = CustomerShoppingCartNoBookDto;
      return this.apiClient.callApi(
        '/api/purchase/customer/shopping-cart/get/{purchaseMissionId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getShoppingCartByPurchaseMissionWithBookListUsingGET operation.
     * @callback module:api/CustomerShoppingCartApi~getShoppingCartByPurchaseMissionWithBookListUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CustomerShoppingCartBookListDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get BookList With Book Data With ShoppingCart
     * @param {Number} purchaseMissionId purchaseMissionId
     * @param {Number} bookListId bookListId
     * @param {Number} customerId customerId
     * @param {Object} opts Optional parameters
     * @param {Array.<String>} opts.categoryIds category Ids
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Number} opts.inventoryRangeMax 
     * @param {Number} opts.inventoryRangeMin 
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Array.<String>} opts.languageIds language Ids
     * @param {Number} opts.limit return size
     * @param {module:model/String} opts.order sorting order : 1/-1
     * @param {Array.<Number>} opts.organizationIds Organization Ids
     * @param {Number} opts.priceRangeMax 
     * @param {Number} opts.priceRangeMin 
     * @param {Array.<String>} opts.productTypeIds Product Type Ids
     * @param {Number} opts.publishDateRangeMax 
     * @param {Number} opts.publishDateRangeMin 
     * @param {module:model/String} opts.sortingKey sorting key
     * @param {module:model/String} opts.status status
     * @param {Number} opts.uploadDateRangeMax 
     * @param {Number} opts.uploadDateRangeMin 
     * @param {Boolean} opts.withRole 
     * @param {module:api/CustomerShoppingCartApi~getShoppingCartByPurchaseMissionWithBookListUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CustomerShoppingCartBookListDto}
     */
    getShoppingCartByPurchaseMissionWithBookListUsingGET(purchaseMissionId, bookListId, customerId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'purchaseMissionId' is set
      if (purchaseMissionId === undefined || purchaseMissionId === null) {
        throw new Error("Missing the required parameter 'purchaseMissionId' when calling getShoppingCartByPurchaseMissionWithBookListUsingGET");
      }
      // verify the required parameter 'bookListId' is set
      if (bookListId === undefined || bookListId === null) {
        throw new Error("Missing the required parameter 'bookListId' when calling getShoppingCartByPurchaseMissionWithBookListUsingGET");
      }
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling getShoppingCartByPurchaseMissionWithBookListUsingGET");
      }

      let pathParams = {
        'purchaseMissionId': purchaseMissionId,
        'bookListId': bookListId
      };
      let queryParams = {
        'categoryIds': this.apiClient.buildCollectionParam(opts['categoryIds'], 'pipes'),
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'inventoryRange.max': opts['inventoryRangeMax'],
        'inventoryRange.min': opts['inventoryRangeMin'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'languageIds': this.apiClient.buildCollectionParam(opts['languageIds'], 'pipes'),
        'limit': opts['limit'],
        'order': opts['order'],
        'organizationIds': this.apiClient.buildCollectionParam(opts['organizationIds'], 'pipes'),
        'priceRange.max': opts['priceRangeMax'],
        'priceRange.min': opts['priceRangeMin'],
        'productTypeIds': this.apiClient.buildCollectionParam(opts['productTypeIds'], 'pipes'),
        'publishDateRange.max': opts['publishDateRangeMax'],
        'publishDateRange.min': opts['publishDateRangeMin'],
        'sortingKey': opts['sortingKey'],
        'status': opts['status'],
        'uploadDateRange.max': opts['uploadDateRangeMax'],
        'uploadDateRange.min': opts['uploadDateRangeMin'],
        'withRole': opts['withRole'],
        'customerId': customerId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = CustomerShoppingCartBookListDto;
      return this.apiClient.callApi(
        '/api/purchase/customer/shopping-cart/get/{purchaseMissionId}/{bookListId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getShoppingCartsUsingGET operation.
     * @callback module:api/CustomerShoppingCartApi~getShoppingCartsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Shopping Cart
     * @param {Number} customerId customerId
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/CustomerShoppingCartApi~getShoppingCartsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto}
     */
    getShoppingCartsUsingGET(customerId, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling getShoppingCartsUsingGET");
      }

      let pathParams = {
      };
      let queryParams = {
        'customerId': customerId,
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelCustomerShoppingCartPurchaseMissionDto;
      return this.apiClient.callApi(
        '/api/purchase/customer/shopping-cart/get', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setShoppingCartByPurchaseMissionWithBookUsingPOST operation.
     * @callback module:api/CustomerShoppingCartApi~setShoppingCartByPurchaseMissionWithBookUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CustomerShoppingCartDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set BookList with Book Into Shopping Cart
     * @param {Number} purchaseMissionId purchaseMissionId
     * @param {Number} customerId customerId
     * @param {Object} opts Optional parameters
     * @param {module:model/SetShoppingCartBookModel} opts.setShoppingCartBookModel 
     * @param {module:api/CustomerShoppingCartApi~setShoppingCartByPurchaseMissionWithBookUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CustomerShoppingCartDto}
     */
    setShoppingCartByPurchaseMissionWithBookUsingPOST(purchaseMissionId, customerId, opts, callback) {
      opts = opts || {};
      let postBody = opts['setShoppingCartBookModel'];
      // verify the required parameter 'purchaseMissionId' is set
      if (purchaseMissionId === undefined || purchaseMissionId === null) {
        throw new Error("Missing the required parameter 'purchaseMissionId' when calling setShoppingCartByPurchaseMissionWithBookUsingPOST");
      }
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling setShoppingCartByPurchaseMissionWithBookUsingPOST");
      }

      let pathParams = {
        'purchaseMissionId': purchaseMissionId
      };
      let queryParams = {
        'customerId': customerId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CustomerShoppingCartDto;
      return this.apiClient.callApi(
        '/api/purchase/customer/shopping-cart/set/{purchaseMissionId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setsShoppingCartByPurchaseMissionWithBookUsingPOST operation.
     * @callback module:api/CustomerShoppingCartApi~setsShoppingCartByPurchaseMissionWithBookUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/CustomerShoppingCartDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set BookList with Book Into Shopping Cart
     * @param {Number} purchaseMissionId purchaseMissionId
     * @param {Number} customerId customerId
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/SetShoppingCartBookModel>} opts.setShoppingCartBookModel 
     * @param {module:api/CustomerShoppingCartApi~setsShoppingCartByPurchaseMissionWithBookUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CustomerShoppingCartDto}
     */
    setsShoppingCartByPurchaseMissionWithBookUsingPOST(purchaseMissionId, customerId, opts, callback) {
      opts = opts || {};
      let postBody = opts['setShoppingCartBookModel'];
      // verify the required parameter 'purchaseMissionId' is set
      if (purchaseMissionId === undefined || purchaseMissionId === null) {
        throw new Error("Missing the required parameter 'purchaseMissionId' when calling setsShoppingCartByPurchaseMissionWithBookUsingPOST");
      }
      // verify the required parameter 'customerId' is set
      if (customerId === undefined || customerId === null) {
        throw new Error("Missing the required parameter 'customerId' when calling setsShoppingCartByPurchaseMissionWithBookUsingPOST");
      }

      let pathParams = {
        'purchaseMissionId': purchaseMissionId
      };
      let queryParams = {
        'customerId': customerId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CustomerShoppingCartDto;
      return this.apiClient.callApi(
        '/api/purchase/customer/shopping-cart/sets/{purchaseMissionId}', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
