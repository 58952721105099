/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The SystemSettingCronJobModel model module.
 * @module model/SystemSettingCronJobModel
 * @version v1.0.0
 */
class SystemSettingCronJobModel {
    /**
     * Constructs a new <code>SystemSettingCronJobModel</code>.
     * @alias module:model/SystemSettingCronJobModel
     */
    constructor() { 
        
        SystemSettingCronJobModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>SystemSettingCronJobModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/SystemSettingCronJobModel} obj Optional instance to populate.
     * @return {module:model/SystemSettingCronJobModel} The populated <code>SystemSettingCronJobModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new SystemSettingCronJobModel();

            if (data.hasOwnProperty('dayOfMonth')) {
                obj['dayOfMonth'] = ApiClient.convertToType(data['dayOfMonth'], 'Number');
            }
            if (data.hasOwnProperty('hoursOfDay')) {
                obj['hoursOfDay'] = ApiClient.convertToType(data['hoursOfDay'], 'Number');
            }
            if (data.hasOwnProperty('minutes')) {
                obj['minutes'] = ApiClient.convertToType(data['minutes'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * Day of Month [1-31]
 * @member {Number} dayOfMonth
 */
SystemSettingCronJobModel.prototype['dayOfMonth'] = undefined;

/**
 * Hours of Day [0-23]
 * @member {Number} hoursOfDay
 */
SystemSettingCronJobModel.prototype['hoursOfDay'] = undefined;

/**
 * Minutes [0-59]
 * @member {Number} minutes
 */
SystemSettingCronJobModel.prototype['minutes'] = undefined;






export default SystemSettingCronJobModel;

