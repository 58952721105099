import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { STYLETYPE_DEFAULT, STYLETYPE_MAIN } from "../../utils/Constants";
import style from "./TextArea.module.css";
export default class TextArea extends Component {
  state = {
    textLength: 0,
  };

  static propTypes = {
    showTitleHolder: PropTypes.bool,
    styleType: PropTypes.string,
    cols: PropTypes.number,
    rows: PropTypes.number,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    maxLength: PropTypes.number,
    callBack: PropTypes.func,
    resize: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    cols: 50,
    rows: 5,
    resize: true,
    value: "",
    placeholder: "",
    showTitleHolder: true,
    styleType: STYLETYPE_DEFAULT,
  };

  hanldeOnChangeText = (event) => {
    this.props.callBack(event.target.value);
    this.setState({ textLength: event.target.value.length });
  };

  render() {
    const {
      styleType,
      resize,
      cols,
      rows,
      value,
      placeholder,
      callBack,
      showTitleHolder,
      maxLength,
      disabled,
    } = this.props;
    const { textLength } = this.state;
    return (
      <Fragment>
        {(textLength > 0 || (value && value.length > 0)) && showTitleHolder ? (
          <label className={style.customBadge}>{placeholder}</label>
        ) : (
          <></>
        )}
        <textarea
          disabled={disabled}
          cols={cols}
          rows={rows}
          value={value}
          maxLength={maxLength}
          placeholder={placeholder}
          className={styleType == STYLETYPE_MAIN ? style.textAreaPrimary : ""}
          style={{ resize: resize ? "" : "none" }}
          onChange={(value) => this.hanldeOnChangeText(value)}
        />
      </Fragment>
    );
  }
}
