/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OrgWithRolePayloadModel model module.
 * @module model/OrgWithRolePayloadModel
 * @version v1.0.0
 */
class OrgWithRolePayloadModel {
    /**
     * Constructs a new <code>OrgWithRolePayloadModel</code>.
     * @alias module:model/OrgWithRolePayloadModel
     * @param organizationId {Number} organization Id  (required)
     * @param roleId {Number} role Id  (required)
     */
    constructor(organizationId, roleId) { 
        
        OrgWithRolePayloadModel.initialize(this, organizationId, roleId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, organizationId, roleId) { 
        obj['organizationId'] = organizationId;
        obj['roleId'] = roleId;
    }

    /**
     * Constructs a <code>OrgWithRolePayloadModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrgWithRolePayloadModel} obj Optional instance to populate.
     * @return {module:model/OrgWithRolePayloadModel} The populated <code>OrgWithRolePayloadModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrgWithRolePayloadModel();

            if (data.hasOwnProperty('organizationId')) {
                obj['organizationId'] = ApiClient.convertToType(data['organizationId'], 'Number');
            }
            if (data.hasOwnProperty('roleId')) {
                obj['roleId'] = ApiClient.convertToType(data['roleId'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * organization Id  (required)
 * @member {Number} organizationId
 */
OrgWithRolePayloadModel.prototype['organizationId'] = undefined;

/**
 * role Id  (required)
 * @member {Number} roleId
 */
OrgWithRolePayloadModel.prototype['roleId'] = undefined;






export default OrgWithRolePayloadModel;

