import localforage from "localforage";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import loadingGif from "../../../assets/images/loading.gif";
import placeHolderIcon from "../../../assets/images/placeholder.png"; //'../../../assets/images/placeholder.png'
import { FileApi } from "../../../fetcher";
import {
  ADD,
  COVER,
  EDIT,
  EPUBPREVIEW,
  EPUBPROD,
  MULTIMEDIA,
  STYLETYPE_CUSTOM,
  STYLETYPE_MAIN,
  STYLETYPE_SELECT_FILE,
  THUMB,
} from "../../../utils/Constants";
import { genApiConfig } from "../../../utils/fetch-caller";
import { UPLOAD_CHUNK_FILE_API } from "../../../utils/fileUtils/FileRequest";
import Uploader from "../../../utils/fileUtils/FileUploader";
import { getCurrentOrganization } from "../../../utils/LocalStorage";
import BookUtil from "../../../utils/reader/fileUtils/bookUtil";
import { addEpub } from "../../../utils/reader/fileUtils/epubUtil";
import { fetchImageFromURL } from "../../../utils/reader/fileUtils/fileUtil";
import {
  fetchMD5,
  genBookData,
  getMd5WithBrowser,
} from "../../../utils/reader/fileUtils/md5Util";
import RecentBooks from "../../../utils/reader/readUtils/recordRecent";
import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
import Button from "../../Button/Button";
import DownloadButton from "../../Button/download/DownloadButton";
import Fancybox from "../../fancybox/fancybox.js";
import Input from "../../Input/Input";
import TextArea from "../../Input/TextArea";
import MultiSelectOption from "../../SelectOption/MultiSelectOption";
import SelectOption from "../../SelectOption/SelectOption";
import "./BasicInfo.css";
import style from "./BasicInfo.module.css";

const BasicInfo = (props) => {
  const [myMonth, setMyMonth] = useState();
  const [myYear, setMyYear] = useState();

  const [author, setAuthor] = useState(""); // 作者
  const [name, setName] = useState(""); // 產品名稱
  const [ISBN, setISBN] = useState(""); // ISBN/產品編號
  const [price, setPrice] = useState(); // 價錢
  const [originalPrice, setOriginalPrice] = useState(); // 原價
  const [supplierId, setSupplierId] = useState([]); // 出版社/子出版社
  const [categoryId, setCategoryId] = useState([]); // 分類
  const [publishDateYear, setPublishDateYear] = useState(""); // 出版日期
  const [publishDateMonth, setPublishDateMonth] = useState(""); // 出版日期
  const [description, setDescription] = useState(""); // 產品簡介(約150-300字)
  const [ctsid, setCtsid] = useState(""); //CTS書號
  const [productTypeId, setProductTypeId] = useState([]); //產品類形
  const [languageId, setLanguageId] = useState([]); //語言
  const [comment, setComment] = useState("");
  const [translator, setTranslator] = useState("");

  const [inventory, setInventory] = useState();
  const [haveFile, setHaveFile] = useState(false);
  const [canSave, setCanSave] = useState([]);
  const [isLoadFile, setIsLoadFile] = useState(false);

  const [hiddenCat, setHiddenCat] = useState(true);
  const [hiddenSup, setHiddenSup] = useState(true);
  const [hiddenLang, setHiddenLang] = useState(true);
  const [hiddenType, setHiddenType] = useState(true);

  //file
  const [duplicateAlert, setDuplicateAlert] = useState([]);
  const [sliceProgressDetail, setSliceProgressDetail] = useState([]);
  const [sliceUsedTime, setSliceUsedTime] = useState(null);
  //file

  const handleOpenReader = (book) => {
    // for cms
    let readerConfig = localStorage.getItem("readerConfig");
    console.log("CP1 readerConfig", readerConfig);
    if (readerConfig === null) {
      readerConfig = "{}";
    }
    let readerConfigObj = JSON.parse(readerConfig);
    console.log("CP1 readerConfigObj", readerConfigObj);
    // 強制將 readerConfigObj.rm 設置為 "r"
    readerConfigObj.rm = "r";
    localStorage.setItem("readerConfig", JSON.stringify(readerConfigObj));
    // for cms end

    if (StorageUtil.getReaderConfig("isOpenInMain") === "yes") {
      props.history.push(BookUtil.getBookUrl(book));
      props.handleReadingBook(book);
    } else {
      //localStorage.setItem("tempBook", JSON.stringify(book));
      BookUtil.RedirectBook(book, name).then(() => {
        var newArr = sliceProgressDetail.map((it) => {
          it.isLoading = false;
          return it;
        });
        setSliceProgressDetail(newArr);
      });
    }
  };

  // const handleAddFile = (value, type) => {
  //   switch (value.msg) {
  //     case "Add Successfully":
  //       selectedFileCallBack([value.file], type, value.book);
  //       break;
  //     case "Duplicate Book":
  //       break;
  //     case "acceptedFiles":
  //       setCanSave([...canSave, ...value.fileCount]);
  //       break;
  //     default:
  //       alert(value.msg);
  //       break;
  //   }
  // };

  const uploadDetailForBook = async (file, type) => {
    var newFileArrDetails = [];
    var newArrData = [];

    for (let key = 0; key < file.length; key++) {
      newFileArrDetails.push({
        file: file[key],
      });
    }

    await Promise.all(
      newFileArrDetails.map(async (f) => {
        await getMd5WithBrowser(f.file).then(async (md5) => {
          await genBookData(f.file, md5).then((bookObj) => {
            newArrData.push({
              md5: md5,
              fileName: f.file.name,
              file: f.file,
              book: { ...bookObj, file: f.file },
              percent: 0,
              coverUrl: "",
              coverId: "",
              fileUrl: "",
              fileId: "",
              bookType: type,
              isSelected: false,
              processFile: false,
              isNew: true,
              extension: bookObj.format.toLowerCase(),
            });
          });
          //}
        });
      })
    ).catch((err) => {
      console.log(err);
    });

    var finalArr = [];
    var duplicateBook = "";

    newArrData.map((newItem) => {
      try {
        const newObj = sliceProgressDetail.find((oldItem) => {
          if (
            newItem.md5 === oldItem.md5 &&
            newItem.bookType === oldItem.bookType
          ) {
            return newItem;
          }
        });

        if (newObj === undefined) {
          finalArr.push(newItem);
        } else {
          if (duplicateBook === "") {
            duplicateBook += "重覆上傳書本\u000a";
          }
          duplicateBook += newItem.fileName + "\u000a";
        }
      } catch (err) {
        console.log("err", err);
      }
    });

    if (duplicateBook !== "") {
      alert(duplicateBook);
    }
    return finalArr;
  };

  const uploadDetailForFile = async (file, type) => {
    var newFileArrDetails = [];
    var newArrData = [];

    for (let key = 0; key < file.length; key++) {
      newFileArrDetails.push({
        file: file[key],
      });
    }

    await Promise.all(
      newFileArrDetails.map(async (f) => {
        await getMd5WithBrowser(f.file).then(async (md5) => {
          newArrData.push({
            md5: md5,
            file: f.file,
            fileName: f.file.name,
            book: null,
            percent: 0,
            coverUrl: "",
            coverId: "",
            fileUrl: "",
            fileId: "",
            bookType: type,
            isSelected: false,
            processFile: false,
            isNew: true,
            extension: f.file.name.split(".")[1].toLowerCase(),
          });
        });
      })
    );

    var finalArr = [];
    var duplicateBook = "";

    newArrData.map((newItem) => {
      try {
        const newObj = sliceProgressDetail.find((oldItem) => {
          if (
            newItem.md5 === oldItem.md5 &&
            newItem.bookType === oldItem.bookType
          ) {
            return newItem;
          }
        });

        if (newObj === undefined) {
          finalArr.push(newItem);
        } else {
          if (duplicateBook === "") {
            duplicateBook += "重覆檔案\u000a";
          }
          duplicateBook += newItem.fileName + "\u000a";
        }
      } catch (err) {
        console.log("err", err);
      }
    });

    if (duplicateBook !== "") {
      alert(duplicateBook);
    }
    return finalArr;
  };

  const selectedFileCallBack = async (file, type) => {
    console.log("selectedFileCallBack", file, type);

    setHaveFile(true);

    switch (type) {
      case EPUBPROD:

      case EPUBPREVIEW:
        var finalArr = await uploadDetailForBook(file, type);
        setSliceProgressDetail([...sliceProgressDetail, ...finalArr]);
        break;
      case COVER:
      case THUMB:
      case MULTIMEDIA:
        var finalArr = await uploadDetailForFile(file, type);
        setSliceProgressDetail([...sliceProgressDetail, ...finalArr]);
        break;
    }
  };

  const uploadBookFile = (file) => {
    setSliceProgressDetail(
      sliceProgressDetail.map((it) => {
        if (it.fileName === file.fileName) {
          it.isNew = false;
        }
        return it;
      })
    );

    //Upload Book File Cover

    let coverUrl = "";
    let coverId = "";
    let fileUrl = "";
    let fileId = "";

    console.log("file", file);

    if (file !== undefined || file !== null) {
      var coverBase64 =
        file.book.cover === undefined ||
        file.book.cover === null ||
        file.book.cover === ""
          ? placeHolderIcon
          : file.book.cover;
      var coverFileName = `${file.fileName.replace(".", "_")}_cover.jpg`;
      //Upload book cover
      new Promise(async (resolve, reject) => {
        await fetchImageFromURL(coverBase64, coverFileName).then(
          (bookCover) => {
            new Uploader(UPLOAD_CHUNK_FILE_API, {
              showProgress: false,
              enableSlice: true,
              bookIsbn: ISBN,
              bookType: file.bookType,
              bookInfo: coverFileName,
              md5: file.md5,
            })
              .upload(bookCover)
              .onComplete(({ uploadTime, hashTime, response }) => {
                setSliceUsedTime({
                  uploadTime,
                  hashTime,
                });

                if (response !== undefined) {
                  try {
                    var result = JSON.parse(response);
                    return resolve({
                      coverUrl: result.url,
                      coverId: result.fileId,
                    });
                  } catch (error) {
                    return resolve({
                      coverUrl: response.url,
                      coverId: response.fileId,
                    });
                  }
                }
              });
          }
        );
      })
        .then((coverData) => {
          console.log("coverData", coverData);
          console.log("mmmmm", file.md5);
          new Promise(async (resolve, reject) => {
            new Uploader(UPLOAD_CHUNK_FILE_API, {
              showProgress: true,
              enableSlice: true,
              bookIsbn: ISBN,
              bookType: file.bookType,
              bookInfo: file.fileName,
              md5: file.md5,
            })
              .upload(file.book.file)
              .onProgress(({ percentage, progressDetail }) => {
                if (progressDetail.length > 0 && !Number.isNaN(percentage)) {
                  const currentIndex = sliceProgressDetail.findIndex(
                    (it) =>
                      it["fileName"] === progressDetail[0]["fileName"] &&
                      it["bookType"] === progressDetail[0]["bookType"]
                  );
                  if (currentIndex > -1) {
                    if (percentage >= 100) {
                      sliceProgressDetail[currentIndex]["percent"] = 100;
                      setSliceProgressDetail([...sliceProgressDetail]);
                    } else {
                      sliceProgressDetail[currentIndex]["percent"] = percentage;
                      setSliceProgressDetail([...sliceProgressDetail]);
                    }
                  }
                }
              })
              .onComplete(({ uploadTime, hashTime, response }) => {
                setSliceUsedTime({
                  uploadTime,
                  hashTime,
                });

                if (response !== undefined) {
                  try {
                    var result = JSON.parse(response);
                    return resolve({
                      coverUrl: coverData.coverUrl,
                      coverId: coverData.coverId,
                      fileUrl: result.url,
                      fileId: result.fileId,
                    });
                  } catch (error) {
                    return resolve({
                      coverUrl: coverData.coverUrl,
                      coverId: coverData.coverId,
                      fileUrl: response.url,
                      fileId: response.fileId,
                    });
                  }
                }
              });
          }).then((allData) => {
            coverUrl = allData.coverUrl;
            coverId = allData.coverId;
            fileUrl = allData.fileUrl;
            fileId = allData.fileId;
            const newArr = sliceProgressDetail.map((it) => {
              if (
                it.fileName === file.fileName &&
                it.bookType === file.bookType
              ) {
                it.isNew = false;
                it.coverId = coverId;
                it.coverUrl = coverUrl;
                it.fileUrl = fileUrl;
                it.fileId = fileId;
              }
              return it;
            });

            setSliceProgressDetail(newArr);
          });
        })
        .catch((err) => {
          console.log("dfaefaes", err);
        });
    }
  };

  const uploadBookOtherFile = (file) => {
    setSliceProgressDetail(
      sliceProgressDetail.map((it) => {
        if (it.fileName === file.fileName) {
          it.isNew = false;
        }
        return it;
      })
    );

    //Upload Book File Cover

    let coverUrl = "";
    let coverId = "";
    let fileUrl = "";
    let fileId = "";

    if (file !== undefined || file !== null) {
      new Uploader(UPLOAD_CHUNK_FILE_API, {
        showProgress: true,
        enableSlice: true,
        bookIsbn: ISBN,
        bookType: file.bookType,
        bookInfo: file.fileName,
        md5: file.md5,
      })
        .upload(file.file)
        .onProgress(({ percentage, progressDetail }) => {
          if (progressDetail.length > 0 && !Number.isNaN(percentage)) {
            const currentIndex = sliceProgressDetail.findIndex(
              (it) =>
                it["fileName"] === progressDetail[0]["fileName"] &&
                it["bookType"] === progressDetail[0]["bookType"]
            );
            if (currentIndex > -1) {
              if (percentage >= 100) {
                sliceProgressDetail[currentIndex]["percent"] = 100;
                setSliceProgressDetail([...sliceProgressDetail]);
              } else {
                sliceProgressDetail[currentIndex]["percent"] = percentage;
                setSliceProgressDetail([...sliceProgressDetail]);
              }
            }
          }
        })
        .onComplete(({ uploadTime, hashTime, response }) => {
          setSliceUsedTime({
            uploadTime,
            hashTime,
          });

          if (response !== undefined) {
            try {
              let result;
              if (typeof response == "object") {
                result = response;
              } else {
                result = JSON.parse(response);
              }
              coverUrl = result.coverUrl;
              coverId = result.coverId;
              fileUrl = result.url;
              fileId = result.fileId;
            } catch (err) {
              console.log("eeeeeerrrr", err);
            }
          }

          const newArr = sliceProgressDetail.map((it) => {
            if (
              it.fileName === file.fileName &&
              it.bookType === file.bookType
            ) {
              it.isNew = false;
              it.coverId = "";
              it.coverUrl = "";
              it.fileUrl = fileUrl;
              it.fileId = fileId;
            }
            return it;
          });

          setSliceProgressDetail(newArr);
        });
    }
  };

  useEffect(() => {
    if (sliceProgressDetail.length > 0) {
      setHaveFile(true);
    }

    sliceProgressDetail.map((it) => {
      if (it.percent === 100 && !it.isNew) {
        setCanSave([]);
      } else {
        setCanSave([1]);
      }
    });

    sliceProgressDetail
      .filter((it) => it.isNew)
      .map((book) => {
        console.log("f", book);
        if (book.bookType === EPUBPREVIEW || book.bookType === EPUBPROD) {
          uploadBookFile(book);
        }

        if (
          book.bookType === MULTIMEDIA ||
          book.bookType === COVER ||
          book.bookType === THUMB
        ) {
          uploadBookOtherFile(book);
        }
      });
  }, [sliceProgressDetail]);

  useEffect(() => {
    var monthData = [];
    var yearData = [];

    for (var i = 1; i <= 12; i++) {
      if (i < 10) {
        monthData.push({ id: i, name: `0${i}` });
      } else {
        monthData.push({ id: i, name: i.toString() });
      }
    }

    var now = new Date();

    for (var i = 1900; i <= now.getFullYear() + 3; i++) {
      yearData.push({ id: i, name: i.toString() });
    }

    setMyMonth(monthData);
    setMyYear(yearData.reverse());

    if (props.info !== null) {
      switch (props.info.action) {
        case ADD:
          const { categories, organizations, languages, productTypes } =
            props.info;

          if (
            !productTypeId.hasOwnProperty("isSelected") &&
            productTypeId.length === 0
          ) {
            setProductTypeId(
              productTypes ? productTypes.map((obj) => setObj(obj)) : []
            );
          }
          if (
            !supplierId.hasOwnProperty("isSelected") &&
            supplierId.length === 0
          ) {
            var currentOrganizationId = getCurrentOrganization().id;
            setSupplierId(
              organizations
                ? organizations
                    .filter((it) => it.id === currentOrganizationId)
                    .map((obj) => {
                      if (obj.id === currentOrganizationId) {
                        return {
                          id: obj.id,
                          seq: obj.seq,
                          value: obj.name,
                          isSelected: true,
                          isSearch: true,
                        };
                      } else {
                        return {
                          id: obj.id,
                          seq: obj.seq,
                          value: obj.name,
                          isSelected: false,
                          isSearch: true,
                        };
                      }
                    })
                : []
            );
          }

          if (
            !languageId.hasOwnProperty("isSelected") &&
            languageId.length === 0
          ) {
            setLanguageId(languages ? languages.map((obj) => setObj(obj)) : []);
          }

          // setCategory(categories ? categories.map((obj) => setObj(obj)) : []);

          if (
            !categoryId.hasOwnProperty("isSelected") &&
            categoryId.length === 0
          ) {
            setCategoryId(
              categories ? categories.map((obj) => setObj(obj)) : []
            );
          }

          break;
        case EDIT:
          const { info } = props;
          const { display } = info;

          if (info.bookIsbn === undefined || info.bookIsbn === null) {
            setISBN("");
          } else {
            setISBN(info.bookIsbn);
            setHaveFile(true);
          }
          info.author === undefined || info.author === null
            ? setAuthor("")
            : setAuthor(info.author);
          info.name === undefined || info.name === null
            ? setName("")
            : setName(info.name);

          info.publishDate === undefined || info.publishDate === null
            ? setPublishDateYear("")
            : setPublishDateYear(
                moment(parseInt(info.publishDate)).format("YYYY")
              );

          info.publishDate === undefined || info.publishDate === null
            ? setPublishDateMonth("")
            : setPublishDateMonth(
                moment(parseInt(info.publishDate)).format("MM")
              );

          console.log("adsfasdf", info);
          console.log("adsfasdf", info.price);
          console.log("adsfasdf", info.inventory);
          setPrice(info.price === 0 ? "0" : info.price);
          setOriginalPrice(info.originalPrice === 0 ? "" : info.originalPrice);
          setCtsid(info.ctsid);
          setDescription(info.description);
          setComment(info.bookComment);
          setTranslator(info.translator);
          setInventory(info.inventory === 0 ? "" : info.inventory);

          if (info.organization === undefined || info.organization === null) {
            setSupplierId(
              display.organizations
                .filter((it) => it.id === getCurrentOrganization()?.id)
                .map((obj) => setObj(obj))
            );
          } else {
            var newArr = display.organizations
              .filter((it) => it.id === getCurrentOrganization()?.id)
              .map((obj) => setObj(obj))
              .map((it) => {
                if (info.organization.id === it.id) {
                  it.isSelected = true;
                  return it;
                }
                return it;
              });
            setSupplierId(newArr);
          }

          if (info.categories === undefined || info.categories === null) {
            setCategoryId(display.categories.map((obj) => setObj(obj)));
          } else {
            var newArr = display.categories
              .map((obj) => setObj(obj))
              .map((it) => {
                if (
                  info.categories.find((obj) => obj.id === it.id) !== undefined
                ) {
                  it.isSelected = true;
                  return it;
                }
                return it;
              });
            //console.log(newArr);
            setCategoryId(newArr);
          }

          if (info.languages === undefined || info.languages === null) {
            setLanguageId(display.languages.map((obj) => setObj(obj)));
          } else {
            var newArr = display.languages
              .map((obj) => setObj(obj))
              .map((it) => {
                if (
                  info.languages.find((lang) => lang.id === it.id) !== undefined
                ) {
                  it.isSelected = true;
                }
                return it;
              });

            console.log("nnnn", newArr);
            setLanguageId(newArr);
          }

          if (info.productType === undefined || info.productType === null) {
            setProductTypeId(display.productTypes.map((obj) => setObj(obj)));
          } else {
            var newArr = display.productTypes
              .map((obj) => setObj(obj))
              .map((it) => {
                if (info.productType.id === it.id) {
                  it.isSelected = true;
                  return it;
                }
                return it;
              });
            setProductTypeId(newArr);
          }

          fetchFile(info.uploadedFiles);

          break;
        default:
      }
    }
  }, [props]);

  const fetchFile = async (uploadedFiles) => {
    if (sliceProgressDetail.length === 0) {
      setIsLoadFile(true);
      setCanSave([false]);
      let currentFileList = [];
      let currentLinkList = [];

      let resultList = [];

      localStorage.removeItem("recentBooks");
      localStorage.removeItem("readerConfig");
      localStorage.removeItem("pdfjs.history");
      localStorage.removeItem("tempBook");
      localforage.clear();

      currentLinkList.push(...uploadedFiles.cover);
      currentLinkList.push(...uploadedFiles.multimedia);
      currentLinkList.push(...uploadedFiles.thumb);

      currentFileList.push(...uploadedFiles.epubPreview);
      currentFileList.push(...uploadedFiles.epubProd);

      currentFileList.map((it) => {
        switch (it.bookType) {
          case EPUBPREVIEW:
            resultList.push({
              fileName: it.filename,
              percent: 100,
              file: it.url,
              coverId: it.coverFile.id !== undefined ? it.coverFile.id : null,
              coverUrl: it.coverFile !== undefined ? it.coverFile.url : null,
              book: null,
              bookType: EPUBPREVIEW,
              isSelected: it.select,
              isLoading: false,
              fileId: it.id,
              extension: it.extension,
              processFile: false,
              md5: it.md5,
            });
            break;
          case EPUBPROD:
            resultList.push({
              fileName: it.filename,
              percent: 100,
              file: it.url,
              coverId: it.coverFile.id !== undefined ? it.coverFile.id : null,
              coverUrl: it.coverFile !== undefined ? it.coverFile.url : null,
              book: null,
              bookType: EPUBPROD,
              isSelected: it.select,
              isLoading: false,
              fileId: it.id,
              extension: it.extension,
              processFile: false,
              md5: it.md5,
            });
            break;
          default:
        }
      });

      currentLinkList.map((it) => {
        switch (it.bookType) {
          case COVER:
            resultList.push({
              fileName: it.filename,
              percent: 100,
              file: it.url,
              book: null,
              bookType: COVER,
              isSelected: it.select,
              isLoading: false,
              fileId: it.id,
              extension: it.extension,
              processFile: false,
              md5: it.md5,
            });
            break;
          case MULTIMEDIA:
            resultList.push({
              fileName: it.filename,
              percent: 100,
              file: it.url,
              book: null,
              bookType: MULTIMEDIA,
              isSelected: it.select,
              isLoading: false,
              fileId: it.id,
              extension: it.extension,
              processFile: false,
              md5: it.md5,
            });
            break;
          case THUMB:
            resultList.push({
              fileName: it.filename,
              percent: 100,
              file: it.url,
              book: null,
              bookType: THUMB,
              isSelected: it.select,
              isLoading: false,
              fileId: it.id,
              extension: it.extension,
              processFile: false,
              md5: it.md5,
            });
            break;
          default:
        }
      });
      setSliceProgressDetail(resultList);
      setCanSave([]);
    }
  };

  const setObj = (obj) => {
    return {
      id: obj.id,
      seq: obj.seq,
      value: obj.name,
      isSelected: false,
      isSearch: true,
    };
  };

  useEffect(() => {
    var audioBook = [];
    var cover = [];
    var epubPreview = [];
    var epubProd = [];
    var multimedia = [];
    var thumb = [];

    sliceProgressDetail.map((it) => {
      switch (it.bookType) {
        case EPUBPREVIEW:
          epubPreview.push({
            fileId: it.fileId,
            coverId: it.coverId,
            select: it.isSelected,
          });
          break;
        case EPUBPROD:
          epubProd.push({
            fileId: it.fileId,
            coverId: it.coverId,
            select: it.isSelected,
          });
          break;
        case COVER:
          cover.push({ fileId: it.fileId, select: it.isSelected });
          break;
        case MULTIMEDIA:
          multimedia.push({ fileId: it.fileId, select: it.isSelected });
          break;
        case THUMB:
          thumb.push({ fileId: it.fileId, select: it.isSelected });
          break;
        default:
      }
    });

    var file = {
      audioBook,
      cover,
      epubPreview,
      epubProd,
      multimedia,
      thumb,
    };
    console.log("translator", translator);

    // Add publishDate validation
    const publishDate = publishDateYear && publishDateMonth
      ? moment(publishDateYear + "-" + publishDateMonth + "-01")
        .valueOf() + 1000 * 60 * 60 * 8
      : "";
    
    props.callBack({
      author: author,
      name: name,
      ISBN: ISBN,
      supplierId: supplierId.length > 0 ? supplierId : null,
      productTypeId: productTypeId.length > 0 ? productTypeId : null,
      languageId: languageId.length > 0 ? languageId : null,
      publishDate: publishDate,
      description: description,
      categoryId: categoryId.length > 0 ? categoryId : null,
      price: price,
      originalPrice: originalPrice,
      ctsid: ctsid,
      canSave: canSave,
      file: file,
      id: props.info != null ? props.info.id : null,
      inventory: inventory,
      bookComment: comment,
      translator: translator,
    });
  }, [
    props,
    author,
    name,
    ISBN,
    supplierId,
    languageId,
    productTypeId,
    publishDateYear,
    publishDateMonth,
    description,
    categoryId,
    price,
    originalPrice,
    ctsid,
    canSave,
    sliceProgressDetail,
    inventory,
    comment,
    translator,
  ]);

  const handleItemDelete = (value) => {
    if (value !== "") {
      let opts = {};

      opts["commonDeletePayloadModel"] = [
        {
          delete: true,
          id: value,
        },
      ];

      new FileApi(genApiConfig())
        .deleteFileUsingDELETE(opts)
        .then((res) => {
          if (res.data.hasOwnProperty(value)) {
            var itemObj = sliceProgressDetail.findIndex(
              (it) => it.fileId === value
            );
            if (itemObj > -1) {
              var newArr = [...sliceProgressDetail];
              newArr.splice(itemObj, 1);
              setSliceProgressDetail(newArr, () => console.log(res));
              alert("刪除成功");
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    }
  };

  const genFilePath = (file) => {
    if (file instanceof Object) {
      return URL.createObjectURL(file);
    } else {
      return `${process.env.REACT_APP_BACKEND_URL}${file}`;
    }

    // else if (file === undefined) {
    //   return placeHolderIcon;
    // }
  };

  const handleSelectedFile = (file, value) => {
    switch (file.bookType) {
      case COVER:
      case EPUBPREVIEW:
      case EPUBPROD:
        singleSelect(file, value);
        break;
      case MULTIMEDIA:
      case THUMB:
        multiSelect(file, value);
        break;
      default:
    }
  };

  const singleSelect = (file, value) => {
    let newArr = sliceProgressDetail
      .filter((it) => it.bookType === file.bookType)
      .map((it) => {
        if (it.fileName === file.fileName) {
          it.isSelected = value;
        } else {
          it.isSelected = false;
        }
        return it;
      });
    setSliceProgressDetail([
      ...sliceProgressDetail.filter((it) => it.bookType !== file.bookType),
      ...newArr,
    ]);
  };

  const multiSelect = (file, value) => {
    let newArr = sliceProgressDetail
      .filter((it) => it.bookType === file.bookType)
      .map((it) => {
        if (it.fileName === file.fileName) {
          it.isSelected = value;
        }
        return it;
      });
    setSliceProgressDetail([
      ...sliceProgressDetail.filter((it) => it.bookType !== file.bookType),
      ...newArr,
    ]);
  };

  const handleBookPreView = (obj) => {
    if (typeof obj.file === "string") {
      let bookPreViewObj = sliceProgressDetail.find((obj) => obj.fileId);
      if (bookPreViewObj !== undefined) {
        Object.assign(bookPreViewObj, {
          processFile: false,
          isSelected: bookPreViewObj.isSelected,
        });
        let newArr = Object.assign(sliceProgressDetail, bookPreViewObj);
        setSliceProgressDetail(newArr);
      }

      if (obj.extension === "pdf") {
        //console.log('obj1', obj)
        fetch(`${process.env.REACT_APP_BACKEND_URL}${obj.file}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/pdf",
          },
        }).then((res) => {
          res.blob().then(async (blob) => {
            let reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onload = async (event) => {
              const file_content = event.target.result;

              BookUtil.generateBook(
                obj.fileName,
                obj.extension,
                "",
                blob.size,
                obj.fileName,
                file_content
              ).then(async (bookObj) => {
                await BookUtil.addBook(bookObj.key, file_content);
                RecentBooks.setRecent(bookObj.key);
                localforage.setItem("books", bookObj);
                handleOpenReader(bookObj, obj);
              });
            };
          });
        });
      }

      if (obj.extension === "epub") {
        fetch(`${process.env.REACT_APP_BACKEND_URL}${obj.file}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/epub+zip",
          },
        }).then((epubResult) => {
          epubResult.blob().then(async (blob) => {
            const file = new File([blob], obj.fileName, {
              lastModified: new Date().getTime(),
            });
            await fetchMD5(file).then((md5) => {
              addEpub(file, md5, file.name).then(async (bookObj) => {
                await BookUtil.addBook(bookObj.key, file);
                RecentBooks.setRecent(bookObj.key);
                localforage.setItem("books", bookObj);
                handleOpenReader(bookObj, obj);
              });
            });
          });
        });
      }
    } else {
      if (typeof obj.fileId === "string") {
        let bookPreViewObj = sliceProgressDetail.find((obj) => obj.fileId);
        if (bookPreViewObj !== undefined) {
          Object.assign(bookPreViewObj, {
            processFile: false,
            isSelected: bookPreViewObj.isSelected,
          });
          let newArr = Object.assign(sliceProgressDetail, bookPreViewObj);
          setSliceProgressDetail(newArr);
        }

        if (obj.extension === "pdf") {
          //console.log('obj1', obj)
          fetch(`${process.env.REACT_APP_BACKEND_URL}${obj.fileUrl}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/pdf",
            },
          }).then((res) => {
            res.blob().then(async (blob) => {
              let reader = new FileReader();
              reader.readAsArrayBuffer(blob);
              reader.onload = async (event) => {
                const file_content = event.target.result;

                BookUtil.generateBook(
                  obj.fileName,
                  obj.extension,
                  "",
                  blob.size,
                  obj.fileName,
                  file_content
                ).then(async (bookObj) => {
                  await BookUtil.addBook(bookObj.key, file_content);
                  RecentBooks.setRecent(bookObj.key);
                  localforage.setItem("books", bookObj);
                  handleOpenReader(bookObj, obj);
                });
              };
            });
          });
        }

        if (obj.extension === "epub") {
          fetch(`${process.env.REACT_APP_BACKEND_URL}${obj.fileUrl}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/epub+zip",
            },
          }).then((epubResult) => {
            epubResult.blob().then(async (blob) => {
              const file = new File([blob], obj.fileName, {
                lastModified: new Date().getTime(),
              });
              await fetchMD5(file).then((md5) => {
                addEpub(file, md5, file.name).then(async (bookObj) => {
                  await BookUtil.addBook(bookObj.key, file);
                  RecentBooks.setRecent(bookObj.key);
                  localforage.setItem("books", bookObj);
                  handleOpenReader(bookObj, obj);
                });
              });
            });
          });
        }
      } else {
        // edit 後加書
        handleOpenReader(obj.book, obj);
      }
    }
    // switch (props.info.action) {
    //   case ADD:
    //     if (typeof obj.fileId === "string") {
    //       let bookPreViewObj = sliceProgressDetail.find((obj) => obj.fileId);
    //       if (bookPreViewObj !== undefined) {
    //         Object.assign(bookPreViewObj, {
    //           processFile: false,
    //           isSelected: bookPreViewObj.isSelected,
    //         });
    //         let newArr = Object.assign(sliceProgressDetail, bookPreViewObj);
    //         setSliceProgressDetail(newArr);
    //       }

    //       if (obj.extension === "pdf") {
    //         //console.log('obj1', obj)
    //         fetch(`${process.env.REACT_APP_BACKEND_URL}${obj.fileUrl}`, {
    //           method: "GET",
    //           headers: {
    //             "Content-Type": "application/pdf",
    //           },
    //         }).then((res) => {
    //           res.blob().then(async (blob) => {
    //             let reader = new FileReader();
    //             reader.readAsArrayBuffer(blob);
    //             reader.onload = async (event) => {
    //               const file_content = event.target.result;

    //               BookUtil.generateBook(
    //                 obj.fileName,
    //                 obj.extension,
    //                 "",
    //                 blob.size,
    //                 obj.fileName,
    //                 file_content
    //               ).then(async (bookObj) => {
    //                 await BookUtil.addBook(bookObj.key, file_content);
    //                 RecentBooks.setRecent(bookObj.key);
    //                 localforage.setItem("books", bookObj);
    //                 handleOpenReader(bookObj, obj);
    //               });
    //             };
    //           });
    //         });
    //       }

    //       if (obj.extension === "epub") {
    //         fetch(`${process.env.REACT_APP_BACKEND_URL}${obj.fileUrl}`, {
    //           method: "GET",
    //           headers: {
    //             "Content-Type": "application/epub+zip",
    //           },
    //         }).then((epubResult) => {
    //           epubResult.blob().then(async (blob) => {
    //             const file = new File([blob], obj.fileName, {
    //               lastModified: new Date().getTime(),
    //             });
    //             await fetchMD5(file).then((md5) => {
    //               addEpub(file, md5, file.name).then(async (bookObj) => {
    //                 await BookUtil.addBook(bookObj.key, file);
    //                 RecentBooks.setRecent(bookObj.key);
    //                 localforage.setItem("books", bookObj);
    //                 handleOpenReader(bookObj, obj);
    //               });
    //             });
    //           });
    //         });
    //       }
    //     } else {
    //       // edit 後加書
    //       handleOpenReader(obj.book, obj);
    //     }
    //     break;
    //   case EDIT:
    //     if (typeof obj.file === "string") {
    //       let bookPreViewObj = sliceProgressDetail.find((obj) => obj.fileId);
    //       if (bookPreViewObj !== undefined) {
    //         Object.assign(bookPreViewObj, {
    //           processFile: false,
    //           isSelected: bookPreViewObj.isSelected,
    //         });
    //         let newArr = Object.assign(sliceProgressDetail, bookPreViewObj);
    //         setSliceProgressDetail(newArr);
    //       }

    //       if (obj.extension === "pdf") {
    //         //console.log('obj1', obj)
    //         fetch(`${process.env.REACT_APP_BACKEND_URL}${obj.file}`, {
    //           method: "GET",
    //           headers: {
    //             "Content-Type": "application/pdf",
    //           },
    //         }).then((res) => {
    //           res.blob().then(async (blob) => {
    //             let reader = new FileReader();
    //             reader.readAsArrayBuffer(blob);
    //             reader.onload = async (event) => {
    //               const file_content = event.target.result;

    //               BookUtil.generateBook(
    //                 obj.fileName,
    //                 obj.extension,
    //                 "",
    //                 blob.size,
    //                 obj.fileName,
    //                 file_content
    //               ).then(async (bookObj) => {
    //                 await BookUtil.addBook(bookObj.key, file_content);
    //                 RecentBooks.setRecent(bookObj.key);
    //                 localforage.setItem("books", bookObj);
    //                 handleOpenReader(bookObj, obj);
    //               });
    //             };
    //           });
    //         });
    //       }

    //       if (obj.extension === "epub") {
    //         fetch(`${process.env.REACT_APP_BACKEND_URL}${obj.file}`, {
    //           method: "GET",
    //           headers: {
    //             "Content-Type": "application/epub+zip",
    //           },
    //         }).then((epubResult) => {
    //           epubResult.blob().then(async (blob) => {
    //             const file = new File([blob], obj.fileName, {
    //               lastModified: new Date().getTime(),
    //             });
    //             await fetchMD5(file).then((md5) => {
    //               addEpub(file, md5, file.name).then(async (bookObj) => {
    //                 await BookUtil.addBook(bookObj.key, file);
    //                 RecentBooks.setRecent(bookObj.key);
    //                 localforage.setItem("books", bookObj);
    //                 handleOpenReader(bookObj, obj);
    //               });
    //             });
    //           });
    //         });
    //       }
    //     } else {
    //       // edit 後加書
    //       handleOpenReader(obj.book, obj);
    //     }

    //     break;
    //   default:
    // }

    var newArr = sliceProgressDetail.map((it) => {
      if (it.fileId === obj.fileId) {
        it.isLoading = true;
      }
      return it;
    });

    setSliceProgressDetail(newArr);
  };

  return (
    <>
      {/* <label>{sliceFilePercent}%</label> */}
      {/* <div>預備時間︰{sliceUsedTime && sliceUsedTime.hashTime}</div>
            <div>上傳總時間︰{sliceUsedTime && sliceUsedTime.uploadTime}</div> */}

      <div className={style.container}>
        <div className={style.header}>
          <h5>書本資料</h5>
        </div>
        <div className={style.content}>
          <div className={style.row}>
            <div className={style.checking_row}>
              <Input
                type="text"
                placeholder={"ISBN/產品編號（如曾經上傳檔案/儲存後不能修改）"}
                value={ISBN}
                disable={haveFile}
                styleType={STYLETYPE_MAIN}
                callBack={(value) => setISBN(value.trim())}
              />
            </div>
          </div>
          <div className={style.row}>
            <Input
              type="text"
              placeholder={"作者"}
              value={author}
              styleType={STYLETYPE_MAIN}
              callBack={(value) => setAuthor(value)}
            />
          </div>
          <div className={style.row}>
            <Input
              type="text"
              placeholder={"譯者"}
              value={translator}
              styleType={STYLETYPE_MAIN}
              callBack={(value) => setTranslator(value)}
            />
          </div>
          <div className={style.row}>
            <Input
              type="text"
              placeholder={"產品名稱"}
              value={name}
              styleType={STYLETYPE_MAIN}
              callBack={(value) => setName(value)}
            />
          </div>

          <div className={style.rowDate}>
            <div className={style.row}>
              <SelectOption
                placeholder={"出版年份"}
                items={myYear}
                value={publishDateYear}
                callBack={(value) => setPublishDateYear(value)}
              />
            </div>
            <div className={style.row}>
              <SelectOption
                placeholder={"出版月份"}
                items={myMonth}
                value={publishDateMonth}
                callBack={(value) => setPublishDateMonth(value)}
              />
            </div>
          </div>

          <div className={style.row}>
            <MultiSelectOption
              placeholder={"出版社"}
              multi={false}
              info={supplierId}
            />
            {/* <MultiSelectOption
              placeholder={"出版社"}
              info={supplierId}
              myMenuName={hiddenSup}
              action={props.info.action}
              multi={false}
              callBack={(value) => {
                handleMultiSelectOptionCallBack(
                  Object.assign(value, { element: "supplierId" })
                );
              }}
            /> */}
          </div>

          <div className={style.row}>
            <MultiSelectOption
              placeholder={"分類"}
              multi={true}
              info={categoryId}
            />
            {/* 
            <MultiSelectOption
              placeholder={"分類"}
              info={categories}
              multi={true}
              action={props.info.action}
              myMenuName={hiddenCat}
              callBack={(value) =>
                handleMultiSelectOptionCallBack(
                  Object.assign(value, { element: "categories" })
                )
              }
            /> */}
          </div>

          <div className={style.row}>
            <MultiSelectOption
              placeholder={"產品類型"}
              multi={false}
              info={productTypeId}
            />

            {/* <MultiSelectOption
              placeholder={"產品類型"}
              info={productTypeId}
              multi={false}
              action={props.info.action}
              myMenuName={hiddenType}
              callBack={(value) =>
                handleMultiSelectOptionCallBack(
                  Object.assign(value, { element: "productTypeId" })
                )
              }
            /> */}
          </div>

          <div className={style.row}>
            <MultiSelectOption
              placeholder={"語言"}
              multi={true}
              info={languageId}
            />
          </div>

          <div className={style.row}>
            <Input
              type="text"
              placeholder={"售價"}
              step={0.01}
              min={0}
              value={price}
              styleType={STYLETYPE_MAIN}
              callBack={(value) => {
                setPrice(value);
              }}
            />
          </div>
          <div className={style.row}>
            <Input
              type="text"
              placeholder={"原價"}
              step={0.01}
              min={0}
              value={originalPrice}
              styleType={STYLETYPE_MAIN}
              callBack={(value) => {
                setOriginalPrice(value);
              }}
            />
          </div>
          {/* <div className={style.row}>
            <Input
              type="text"
              placeholder={"貨存"}
              value={inventory}
              styleType={STYLETYPE_MAIN}
              callBack={(value) => {
                setInventory(value);
              }}
            />
          </div> */}
          <div className={style.row}>
            <Input
              type="text"
              placeholder={"CTS書號"}
              value={ctsid}
              styleType={STYLETYPE_MAIN}
              callBack={(value) => setCtsid(value.trim())}
            />
          </div>
        </div>
        <div className={style.rowSpan}>
          <TextArea
            resize={false}
            maxLength={300}
            placeholder={
              description.length === 0
                ? "產品簡介 300 字"
                : "產品簡介 " + description.length + " / 300"
            }
            value={description}
            styleType={STYLETYPE_MAIN}
            callBack={(value) => setDescription(value)}
          />
        </div>
        <div className={style.rowSpan}>
          <TextArea
            resize={false}
            maxLength={300}
            placeholder={
              comment
                ? comment.length === 0
                  ? "書評 300 字"
                  : "書評 " + comment.length + " / 300"
                : "書評 300 字"
            }
            value={comment}
            styleType={STYLETYPE_MAIN}
            callBack={(value) => setComment(value)}
          />
        </div>
        <div className={style.row}>
          <div
            className={style.row}
            style={{
              display: ISBN === "" ? "none" : "block",
              width: "calc(100% - 80px)",
              margin: "20px 40px 20px",
            }}
          >
            <div className="fileRow">
              {/* hasPreview  */}
              <div className="fileColumn fileColumnEPUBPROD">
                <div className="cardItem">
                  <div className="card">
                    <div className="cardTitleContainer">
                      <div className="cardTitle">書本檔案 （只限ePub/PDF）</div>
                      <div className="cardAddButton">
                        {/* <ImportLocal
                          {...{
                            handleDrag: () => false,
                          }}
                          callBack={(value) => handleAddFile(value, EPUBPROD)}
                        >
                          選擇檔案 (ePubProd)
                        </ImportLocal> */}

                        <label
                          className="upload-label upload-epubprod-file-label"
                          htmlFor={"upload-epubprod-file"}
                        >
                          選擇檔案{/* epubProd */}
                        </label>
                        <Input
                          type="file"
                          id={"upload-epubprod-file"}
                          placeholder={"選擇檔案"}
                          styleType={STYLETYPE_SELECT_FILE}
                          multiple={true}
                          accept={[".epub", ".pdf"]}
                          hidden={true}
                          callBack={(value) =>
                            selectedFileCallBack(value, EPUBPROD)
                          }
                        />
                      </div>
                    </div>
                    <div className="cardPreviewContainer">
                      <div className="cardPreviewItem">
                        <img
                          className="cardPreviewItemImg"
                          alt={"description"}
                        />
                      </div>
                    </div>
                    <div className="cardListContainer">
                      {sliceProgressDetail
                        .filter((it) => it.bookType === EPUBPROD)
                        .map((it) => {
                          return (
                            <div className="cardListItem">
                              <div className="cardListItemCheckBox">
                                <Input
                                  showType={STYLETYPE_CUSTOM}
                                  type="checkbox"
                                  checked={it.isSelected}
                                  callBack={(value) =>
                                    handleSelectedFile(it, value)
                                  }
                                />
                                <span className="checkmark"></span>
                              </div>
                              <div className="cardListItemFilename">
                                <DownloadButton
                                  filename={it.fileName}
                                  percent={it.percent}
                                />
                              </div>
                              <div className="cardListItemPreview">
                                <img
                                  src={
                                    it.coverUrl === null
                                      ? placeHolderIcon
                                      : genFilePath(it.coverUrl)
                                  }
                                  alt=""
                                  onClick={() => handleBookPreView(it)}
                                />
                                <Button
                                  styleType={STYLETYPE_MAIN}
                                  callBack={(value) => handleBookPreView(it)}
                                  disabled={it.isLoading}
                                >
                                  {it.isLoading ? "" : "預覽"}
                                  <img
                                    src={loadingGif}
                                    style={{ width: "20px", height: "20px" }}
                                    hidden={!it.isLoading}
                                    alt={loadingGif}
                                  />
                                </Button>
                              </div>
                              <div
                                className="cardListItemDelete"
                                onClick={() => handleItemDelete(it.fileId)}
                              ></div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="fileColumn fileColumnEPUBPREVIEW">
                <div className="cardItem">
                  <div className="card">
                    <div className="cardTitleContainer">
                      <div className="cardTitle">試閱檔案 （只限ePub/PDF）</div>
                      <div className="cardAddButton">
                        {/* <ImportLocal
                          {...{
                            handleDrag: () => false,
                          }}
                          callBack={(value) =>
                            handleAddFile(value, EPUBPREVIEW)
                          }
                        >
                          選擇檔案 (ePubView)
                        </ImportLocal> */}

                        <label
                          className="upload-label upload-epubview-file-label"
                          htmlFor={"upload-epubview-file"}
                        >
                          選擇檔案{/* epubProd */}
                        </label>
                        <Input
                          type="file"
                          id={"upload-epubview-file"}
                          placeholder={"選擇檔案"}
                          styleType={STYLETYPE_SELECT_FILE}
                          multiple={true}
                          accept={[".epub", ".pdf"]}
                          hidden={true}
                          callBack={(value) =>
                            selectedFileCallBack(value, EPUBPREVIEW)
                          }
                        />
                      </div>
                    </div>
                    <div className="cardPreviewContainer"></div>
                    <div className="cardListContainer">
                      {sliceProgressDetail
                        .filter((it) => it.bookType === EPUBPREVIEW)
                        .map((it) => {
                          return (
                            <div className="cardListItem">
                              <div className="cardListItemCheckBox">
                                <Input
                                  showType={STYLETYPE_CUSTOM}
                                  type="checkbox"
                                  checked={it.isSelected}
                                  callBack={(value) =>
                                    handleSelectedFile(it, value)
                                  }
                                />
                                <span className="checkmark"></span>
                              </div>
                              <div className="cardListItemFilename">
                                <DownloadButton
                                  filename={it.fileName}
                                  percent={it.percent}
                                />
                              </div>
                              <div className="cardListItemPreview">
                                <img
                                  src={
                                    it.coverUrl === null
                                      ? placeHolderIcon
                                      : genFilePath(it.coverUrl)
                                  }
                                  alt=""
                                  onClick={() => handleBookPreView(it)}
                                />

                                <Button
                                  styleType={STYLETYPE_MAIN}
                                  callBack={(value) => handleBookPreView(it)}
                                  disabled={it.isLoading}
                                >
                                  {it.isLoading ? "" : "預覽"}
                                  <img
                                    src={loadingGif}
                                    style={{ width: "20px", height: "20px" }}
                                    hidden={!it.isLoading}
                                    alt={loadingGif}
                                  />
                                </Button>
                              </div>
                              <div
                                className="cardListItemDelete"
                                onClick={() => handleItemDelete(it.fileId)}
                              ></div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="fileColumn fileColumnCOVER">
                <div className="cardItem">
                  <div className="card">
                    <div className="cardTitleContainer">
                      <div className="cardTitle">封面檔案 （只限JPG/PNG）</div>
                      <div className="cardAddButton">
                        <label
                          className="upload-label upload-cover-file-label"
                          htmlFor={"upload-cover-file"}
                        >
                          選擇檔案{/* cover*/}
                        </label>
                        <Input
                          type="file"
                          id={"upload-cover-file"}
                          placeholder={"選擇檔案"}
                          styleType={STYLETYPE_SELECT_FILE}
                          multiple={true}
                          accept={"image/*"}
                          hidden={true}
                          callBack={(value) =>
                            selectedFileCallBack(value, COVER)
                          }
                        />
                      </div>
                    </div>
                    <div className="cardPreviewContainer"></div>
                    <div className="cardListContainer">
                      {sliceProgressDetail
                        .filter((it) => it.bookType === COVER)
                        .map((it) => {
                          return (
                            <div className="cardListItem">
                              <div className="cardListItemCheckBox">
                                <Input
                                  showType={STYLETYPE_CUSTOM}
                                  type="checkbox"
                                  checked={it.isSelected}
                                  callBack={(value) =>
                                    handleSelectedFile(it, value)
                                  }
                                />
                                <span className="checkmark"></span>
                              </div>
                              <div className="cardListItemFilename">
                                <DownloadButton
                                  filename={it.fileName}
                                  percent={it.percent}
                                />
                                {/* {it.fileName} */}
                              </div>
                              <div className="cardListItemPreview">
                                <Fancybox>
                                  <a
                                    data-fancybox="gallery"
                                    href={genFilePath(it.file)}
                                  >
                                    <img
                                      src={genFilePath(it.file)}
                                      alt={genFilePath(it.file)}
                                    />
                                  </a>
                                </Fancybox>
                              </div>
                              <div
                                className="cardListItemDelete"
                                onClick={() => handleItemDelete(it.fileId)}
                              ></div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              {/*
              </div>
              <div className="fileRow">
              */}
              <div className="fileColumn fileColumnMULTIMEDIA">
                <div className="cardItem">
                  <div className="card">
                    <div className="cardTitleContainer">
                      <div className="cardTitle">多媒體檔案（只限MP3/MP4）</div>
                      <div className="cardAddButton">
                        <label
                          className="upload-label upload-multimedia-file-label"
                          htmlFor={"upload-multimedia-file"}
                        >
                          選擇檔案{/* multimedia*/}
                        </label>
                        <Input
                          type="file"
                          id={"upload-multimedia-file"}
                          placeholder={"選擇檔案"}
                          styleType={STYLETYPE_SELECT_FILE}
                          multiple={true}
                          accept={".mp4,.mp3"}
                          hidden={true}
                          callBack={(value) =>
                            selectedFileCallBack(value, MULTIMEDIA)
                          }
                        />
                      </div>
                    </div>
                    <div className="cardPreviewContainer"></div>
                    <div className="cardListContainer">
                      {
                        // controlCount.multimedia.total <= 0 ?
                        //     <>沒有上傳資料</>
                        //     :
                        //     controlCount.multimedia.loadCount >= 0 ?
                        //         <>載入資料中...</>
                        //         :
                        sliceProgressDetail &&
                          sliceProgressDetail
                            .filter((it) => it.bookType === MULTIMEDIA)
                            .map((it) => {
                              return (
                                <>
                                  {it.fileName.split(".")[
                                    it.fileName.split(".").length - 1
                                  ] === "mp4" ? (
                                    <div className="cardListItem type_mp4">
                                      <div className="cardListItemCheckBox">
                                        <Input
                                          showType={STYLETYPE_CUSTOM}
                                          type="checkbox"
                                          checked={it.isSelected}
                                          callBack={(value) =>
                                            handleSelectedFile(it, value)
                                          }
                                        />
                                        <span className="checkmark"></span>
                                      </div>
                                      <div className="cardListItemFilename">
                                        <DownloadButton
                                          filename={it.fileName}
                                          percent={it.percent}
                                        />
                                        {/* {it.filename} */}
                                      </div>
                                      <div className="cardListItemPreview">
                                        <video
                                          preload="none"
                                          controls
                                          width="200"
                                          height="100"
                                        >
                                          <source
                                            src={genFilePath(it.file)}
                                            type="video/mp4"
                                          ></source>
                                        </video>
                                      </div>
                                      <div
                                        className="cardListItemDelete"
                                        onClick={() =>
                                          handleItemDelete(it.fileId)
                                        }
                                      ></div>
                                    </div>
                                  ) : it.fileName.split(".")[
                                      it.fileName.split(".").length - 1
                                    ] === "mp3" ? (
                                    <div className="cardListItem type_mp3">
                                      <div className="cardListItemCheckBox">
                                        <Input
                                          showType={STYLETYPE_CUSTOM}
                                          type="checkbox"
                                          checked={it.isSelected}
                                          callBack={(value) =>
                                            handleSelectedFile(it, value)
                                          }
                                        />
                                        <span className="checkmark"></span>
                                      </div>
                                      <div className="cardListItemFilename">
                                        <DownloadButton
                                          filename={it.fileName}
                                          percent={it.percent}
                                        />
                                        {/* {it.filename} */}
                                      </div>
                                      <div className="cardListItemPreview">
                                        <video
                                          preload="none"
                                          controls
                                          width="200"
                                          height="100"
                                        >
                                          <source
                                            src={genFilePath(it.file)}
                                            type="audio/mpeg"
                                          ></source>
                                        </video>
                                      </div>
                                      <div
                                        className="cardListItemDelete"
                                        onClick={() =>
                                          handleItemDelete(it.fileId)
                                        }
                                      ></div>
                                    </div>
                                  ) : (
                                    <div className="cardListItem">
                                      <div className="cardListItemCheckBox">
                                        <Input
                                          showType={STYLETYPE_CUSTOM}
                                          type="checkbox"
                                          checked={it.isSelected}
                                          callBack={(value) =>
                                            handleSelectedFile(it, value)
                                          }
                                        />
                                        <span className="checkmark"></span>
                                      </div>
                                      <div className="cardListItemFilename">
                                        <DownloadButton
                                          filename={it.fileName}
                                          percent={it.percent}
                                        />
                                        {/* {it.fileName} */}
                                      </div>
                                      <div className="cardListItemPreview"></div>
                                      <div
                                        className="cardListItemDelete"
                                        onClick={() =>
                                          handleItemDelete(it.fileId)
                                        }
                                      ></div>
                                    </div>
                                  )}
                                </>
                              );
                            })
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="fileColumn fileColumnTHUMB">
                <div className="cardItem">
                  <div className="card">
                    <div className="cardTitleContainer">
                      <div className="cardTitle">產品照片 （只限JPG/PNG）</div>
                      <div className="cardAddButton">
                        <label
                          className="upload-label upload-thumb-file-label"
                          htmlFor={"upload-thumb-file"}
                        >
                          選擇檔案{/* thumb*/}
                        </label>
                        <Input
                          type="file"
                          id={"upload-thumb-file"}
                          placeholder={"選擇檔案"}
                          styleType={STYLETYPE_SELECT_FILE}
                          multiple={true}
                          accept={"image/*"}
                          hidden={true}
                          callBack={(value) =>
                            selectedFileCallBack(value, THUMB)
                          }
                        />
                      </div>
                    </div>
                    <div className="cardPreviewContainer"></div>
                    <div className="cardListContainer">
                      {
                        // controlCount.thumb.total <= 0 ?
                        //     <>沒有上傳資料</>
                        //     :
                        //     controlCount.thumb.loadCount >= 0 ?
                        //         <>載入資料中...</>
                        //         :
                        sliceProgressDetail
                          .filter((it) => it.bookType === THUMB)
                          .map((it) => {
                            return (
                              <div className="cardListItem">
                                <div className="cardListItemCheckBox">
                                  <Input
                                    showType={STYLETYPE_CUSTOM}
                                    type="checkbox"
                                    checked={it.isSelected}
                                    callBack={(value) =>
                                      handleSelectedFile(it, value)
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </div>
                                <div className="cardListItemFilename">
                                  <DownloadButton
                                    filename={it.fileName}
                                    percent={it.percent}
                                  />
                                  {/* {it.fileName} */}
                                </div>
                                <div className="cardListItemPreview">
                                  <Fancybox>
                                    <a
                                      data-fancybox="gallery"
                                      href={genFilePath(it.file)}
                                    >
                                      <img
                                        src={genFilePath(it.file)}
                                        alt={genFilePath(it.file)}
                                        width="10"
                                      />
                                    </a>
                                  </Fancybox>
                                </div>
                                <div
                                  className="cardListItemDelete"
                                  onClick={() => handleItemDelete(it.fileId)}
                                ></div>
                              </div>
                            );
                          })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(BasicInfo);
