/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import FileDto from './FileDto';

/**
 * The BookFileItemDto model module.
 * @module model/BookFileItemDto
 * @version v1.0.0
 */
class BookFileItemDto {
    /**
     * Constructs a new <code>BookFileItemDto</code>.
     * @alias module:model/BookFileItemDto
     */
    constructor() { 
        
        BookFileItemDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>BookFileItemDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/BookFileItemDto} obj Optional instance to populate.
     * @return {module:model/BookFileItemDto} The populated <code>BookFileItemDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new BookFileItemDto();

            if (data.hasOwnProperty('bookIsbn')) {
                obj['bookIsbn'] = ApiClient.convertToType(data['bookIsbn'], 'String');
            }
            if (data.hasOwnProperty('bookType')) {
                obj['bookType'] = ApiClient.convertToType(data['bookType'], 'String');
            }
            if (data.hasOwnProperty('coverFile')) {
                obj['coverFile'] = FileDto.constructFromObject(data['coverFile']);
            }
            if (data.hasOwnProperty('extension')) {
                obj['extension'] = ApiClient.convertToType(data['extension'], 'String');
            }
            if (data.hasOwnProperty('filename')) {
                obj['filename'] = ApiClient.convertToType(data['filename'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('md5')) {
                obj['md5'] = ApiClient.convertToType(data['md5'], 'String');
            }
            if (data.hasOwnProperty('select')) {
                obj['select'] = ApiClient.convertToType(data['select'], 'Boolean');
            }
            if (data.hasOwnProperty('url')) {
                obj['url'] = ApiClient.convertToType(data['url'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Book Id
 * @member {String} bookIsbn
 */
BookFileItemDto.prototype['bookIsbn'] = undefined;

/**
 * Book Type
 * @member {String} bookType
 */
BookFileItemDto.prototype['bookType'] = undefined;

/**
 * @member {module:model/FileDto} coverFile
 */
BookFileItemDto.prototype['coverFile'] = undefined;

/**
 * File extension
 * @member {String} extension
 */
BookFileItemDto.prototype['extension'] = undefined;

/**
 * File name
 * @member {String} filename
 */
BookFileItemDto.prototype['filename'] = undefined;

/**
 * File id
 * @member {String} id
 */
BookFileItemDto.prototype['id'] = undefined;

/**
 * md5
 * @member {String} md5
 */
BookFileItemDto.prototype['md5'] = undefined;

/**
 * @member {Boolean} select
 */
BookFileItemDto.prototype['select'] = undefined;

/**
 * url
 * @member {String} url
 */
BookFileItemDto.prototype['url'] = undefined;






export default BookFileItemDto;

