import { getUserInfo } from "../../utils/LocalStorage";

const initState = {
  ...getUserInfo(),
};
export function userInfo(state = initState, action) {
  switch (action.type) {
    case "HANDLE_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    // case "HANDLE_SET_USER_INFO":
    //   return {
    //     ...state,
    //     userInfo: action.payload,
    //   };
    default:
      return state;
  }
}
