import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import loadingGif from "../../../../assets/images/loading.gif";
import placeHolderIcon from "../../../../assets/images/placeholder.png";
import OrganizationApi from "../../../../fetcher/api/OrganizationApi";
import {
  ADD,
  DIALOG_CANCEL,
  DIALOG_COMPLETE,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_SELECT_FILE,
  UPDATE,
} from "../../../../utils/Constants";
import { genApiConfig } from "../../../../utils/fetch-caller";
import { UPLOAD_SINGLE_FILE_API } from "../../../../utils/fileUtils/FileRequest";
import Uploader from "../../../../utils/fileUtils/FileUploader";
import Button from "../../../Button/Button";
import Input from "../../../Input/Input";
import TextArea from "../../../Input/TextArea";
import style from "./LibrarySheet.module.css";

class LibrarySheet extends Component {
  state = {
    content: {},
    showError: false,
    loading: false,
  };

  static propTypes = {
    content: PropTypes.any,
    actionType: PropTypes.string.isRequired,
    callBack: PropTypes.func,
  };

  static defaultProps = {
    content: {
      name: "",
      description: "",
      seq: 0,
    },
  };

  componentWillReceiveProps(nextProps) {
    this.resetState();

    if (nextProps !== this.props) {
      //console.log("nextPropsnextPropsnextProps", nextProps);
      const { content, actionType } = nextProps;
      if (Object.keys(content).length > 1) {
        Object.assign(content, { code: "LIBRARY" });
        this.setState({ ...this.state, content, actionType });
      } else {
        Object.assign(content, { code: "LIBRARY" });
        this.setState({
          ...this.state,
          content: {
            name: "",
            description: "",
            seq: 0,
            code: "LIBRARY",
          },
          actionType,
        });
      }
      return;
    }
  }

  handleCancel = (value) => {
    this.resetState();
    this.props.callBack({ action: DIALOG_CANCEL });
  };

  makeOpts = () => {
    let opts = {};
    if (this.state.content.code !== undefined || this.state.content.code !== "")
      opts["code"] = this.state.content.code;

    if (this.state.content.description !== undefined)
      opts["description"] = this.state.content.description;

    if (this.state.content.fullName !== undefined)
      opts["fullName"] = this.state.content.fullName;

    if (this.state.content.logoFileId !== undefined)
      opts["logoFileId"] = this.state.content.logoFileId;

    if (this.state.content.bannerFileId !== undefined)
      opts["bannerFileId"] = this.state.content.bannerFileId;

    if (this.state.content.mainId !== undefined)
      opts["mainId"] = this.state.content.mainId;

    if (this.state.content.shortName !== undefined)
      opts["shortName"] = this.state.content.shortName;

    //console.log("opts", opts);
    return opts;
  };

  handleSubmit = (value) => {
    if (!this.checkFormValid()) return;
    this.setState({ ...this.state, loading: true });

    if (this.state.actionType === ADD) {
      new OrganizationApi(genApiConfig())
        .addOrganizationUsingPOST(
          this.state.content.name,
          this.state.content.seq,
          this.makeOpts(),
          this.submitFormData()
        )
        .then((res) => {
          if (res.response.ok) {
            alert("新增成功");
            this.resetState();
            this.props.callBack({ action: DIALOG_COMPLETE });
          }
        })
        .catch((err) => {
          alert(err.body.message);
          console.log("addOrganizationUsingPOST", err.body.message);
          this.resetState();
        });
      return;
    }

    if (this.state.actionType === UPDATE) {
      new OrganizationApi(genApiConfig())
        .updateOrganizationUsingPATCH(
          this.state.content.id,
          this.state.content.name,
          this.state.content.seq,
          this.submitFormData()
        )
        .then((res) => {
          if (res.data !== null) {
            alert("更新成功");
            this.props.callBack({ action: DIALOG_COMPLETE });
          }
        })
        .catch((err) => {
          alert(err.body.message);
          console.log("updateSupplierUsingPATCH", err.body.message);
        });
      this.resetState();
      return;
    }
  };

  submitFormData = () => {
    const {
      code,
      description,
      fullName,
      name,
      logoFileId,
      bannerFileId,
      mainId,
      shortName,
    } = this.state.content;

    let opts = {};
    opts["code"] = code;
    opts["description"] = description;
    opts["fullName"] = name;
    opts["logoFileId"] = logoFileId;
    opts["bannerFileId"] = bannerFileId;
    opts["mainId"] = mainId;
    opts["shortName"] = shortName;
    //console.log("opts", opts);
    return opts;
  };

  resetState = () => {
    this.setState({
      content: {},
      showError: false,
      loading: false,
    });
  };

  checkFormValid = () => {
    if (this.state.content.name === "") {
      this.setState({ ...this.state, showError: true });
      return false;
    }
    return true;
  };

  singleUploader = new Uploader(UPLOAD_SINGLE_FILE_API, {
    showProgress: true,
  });

  genFilePath = (file) => {
    if (file !== undefined) {
      if (file instanceof Object) {
        return URL.createObjectURL(file);
      } else {
        return `${process.env.REACT_APP_BACKEND_URL}/${file}`;
      }
    }
    return placeHolderIcon;
  };

  selectedFileCallBack = (value, type) => {
    var imageSrc = value[0];
    this.singleUploader
      .upload(value[0])
      .onProgress(({ percentage }) => {
        //setSingleFilePercent(percentage);
      })
      .onComplete((data) => {
        //setSingleUploadTime(uploadTime);

        const { content } = this.state;
        if (type === "icon") {
          Object.assign(content, {
            logoFileId: data.response,
            logoFile: imageSrc,
          });
        }
        if (type === "banner") {
          Object.assign(content, {
            bannerFileId: data.response,
            bannerFile: imageSrc,
          });
        }

        this.setState({ ...this.state, content });
      });
  };

  render() {
    const { content, showError, loading } = this.state;

    return (
      <Fragment>
        <div className={style.content_height}>
          <div className={style.container}>
            <div className={style.gridRowErrorMsg}>
              <label
                hidden={
                  showError ? (content.name.length === 0 ? false : true) : true
                }
                style={{ color: "red" }}
              >
                名稱必須輸入
              </label>
            </div>
            <label>名稱</label>
            <Input
              value={content.name}
              styleType={STYLETYPE_MAIN}
              callBack={(value) => {
                this.setState({
                  ...this.state,
                  content: Object.assign(content, { name: value }),
                });
              }}
            />

            <label>描述</label>
            <TextArea
              resize={false}
              value={content.description == null ? "" : content.description}
              styleType={STYLETYPE_MAIN}
              callBack={(value) => {
                this.setState({
                  ...this.state,
                  content: Object.assign(content, { description: value }),
                });
              }}
            />

            <label>排序位置</label>
            <Input
              type={"number"}
              min={0}
              value={content.seq}
              styleType={STYLETYPE_MAIN}
              callBack={(value) => {
                this.setState({
                  ...this.state,
                  content: Object.assign(content, {
                    seq: value > 9999 ? 9999 : value,
                  }),
                });
              }}
            />
            <div className={style.filerow}>
              <label>標誌檔案</label>
              <img
                style={{ width: "50px", height: "50px" }}
                src={
                  content.logoFile !== undefined
                    ? this.genFilePath(content.logoFile)
                    : content.logoUrl !== ""
                    ? this.genFilePath(content.logoUrl)
                    : placeHolderIcon
                }
              />
            </div>
            <Input
              type="file"
              id={"upload-icon-file"}
              placeholder={"選擇檔案"}
              styleType={STYLETYPE_SELECT_FILE}
              multiple={false}
              accept={"image/*"}
              callBack={(value) => this.selectedFileCallBack(value, "icon")}
            />

            <div className={style.filerow}>
              <label>橫幅檔案</label>
              <img
                style={{ width: "50px", height: "50px" }}
                src={
                  content.bannerFile !== undefined
                    ? this.genFilePath(content.bannerFile)
                    : content.bannerUrl !== ""
                    ? this.genFilePath(content.bannerUrl)
                    : placeHolderIcon
                }
              />
            </div>
            <Input
              type="file"
              id={"upload-banner-file"}
              placeholder={"選擇檔案"}
              styleType={STYLETYPE_SELECT_FILE}
              multiple={false}
              accept={"image/*"}
              callBack={(value) => this.selectedFileCallBack(value, "banner")}
            />
          </div>
        </div>

        <div className={style.button_on_right}>
          <Button
            styleType={STYLETYPE_SECOND}
            callBack={(value) => this.handleCancel(value)}
          >
            取消
          </Button>

          <Button
            styleType={STYLETYPE_MAIN}
            callBack={(value) => this.handleSubmit(value)}
            disabled={loading}
          >
            {loading ? "" : "儲存"}
            <img
              src={loadingGif}
              style={{ width: "20px", height: "20px" }}
              hidden={!loading}
            />
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default LibrarySheet;
