import React, { Suspense } from 'react';
import { isElectron } from "react-device-detect";
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import "react-tippy/dist/tippy.css";
import App from './App';
// import './assets/reader/react.css';
import "./assets/reader/styles/global.css";
import "./assets/reader/styles/style.css";
import { dropdownList } from './constants/reader/dropdownList';
import "./i18n";
import './index.css';
import LoadingView from './layout/loading/LoadingView';
import store from './redux/store';
import StyleUtil from './utils/reader/readUtils/styleUtil';
import { initSystemFont, initTheme } from './utils/reader/serviceUtils/launchUtil';
import StorageUtil from './utils/reader/serviceUtils/storageUtil';


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Suspense fallback={<LoadingView />}>
            <App />
          </Suspense>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
if (isElectron) {
  const fontList = window.require("font-list");
  fontList.getFonts({ disableQuoting: true }).then((result) => {
    if (!result || result.length === 0) return;
    dropdownList[0].option = result;
    dropdownList[0].option.push("Built-in font");
  });
}
StyleUtil.applyTheme();