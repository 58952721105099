/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The TemplateBookDto model module.
 * @module model/TemplateBookDto
 * @version v1.0.0
 */
class TemplateBookDto {
    /**
     * Constructs a new <code>TemplateBookDto</code>.
     * @alias module:model/TemplateBookDto
     */
    constructor() { 
        
        TemplateBookDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>TemplateBookDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/TemplateBookDto} obj Optional instance to populate.
     * @return {module:model/TemplateBookDto} The populated <code>TemplateBookDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new TemplateBookDto();

            if (data.hasOwnProperty('author')) {
                obj['author'] = ApiClient.convertToType(data['author'], 'String');
            }
            if (data.hasOwnProperty('bookComment')) {
                obj['bookComment'] = ApiClient.convertToType(data['bookComment'], 'String');
            }
            if (data.hasOwnProperty('bookIsbn')) {
                obj['bookIsbn'] = ApiClient.convertToType(data['bookIsbn'], 'String');
            }
            if (data.hasOwnProperty('category')) {
                obj['category'] = ApiClient.convertToType(data['category'], ['String']);
            }
            if (data.hasOwnProperty('ctsId')) {
                obj['ctsId'] = ApiClient.convertToType(data['ctsId'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('inventory')) {
                obj['inventory'] = ApiClient.convertToType(data['inventory'], 'Number');
            }
            if (data.hasOwnProperty('language')) {
                obj['language'] = ApiClient.convertToType(data['language'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('organization')) {
                obj['organization'] = ApiClient.convertToType(data['organization'], 'String');
            }
            if (data.hasOwnProperty('originalPrice')) {
                obj['originalPrice'] = ApiClient.convertToType(data['originalPrice'], 'Number');
            }
            if (data.hasOwnProperty('price')) {
                obj['price'] = ApiClient.convertToType(data['price'], 'Number');
            }
            if (data.hasOwnProperty('productType')) {
                obj['productType'] = ApiClient.convertToType(data['productType'], 'String');
            }
            if (data.hasOwnProperty('publishDate')) {
                obj['publishDate'] = ApiClient.convertToType(data['publishDate'], 'Date');
            }
        }
        return obj;
    }


}

/**
 * 作者
 * @member {String} author
 */
TemplateBookDto.prototype['author'] = undefined;

/**
 * 書評
 * @member {String} bookComment
 */
TemplateBookDto.prototype['bookComment'] = undefined;

/**
 * ISBN/產品編號
 * @member {String} bookIsbn
 */
TemplateBookDto.prototype['bookIsbn'] = undefined;

/**
 * 分類
 * @member {Array.<String>} category
 */
TemplateBookDto.prototype['category'] = undefined;

/**
 * CTS書號
 * @member {String} ctsId
 */
TemplateBookDto.prototype['ctsId'] = undefined;

/**
 * 產品簡介
 * @member {String} description
 */
TemplateBookDto.prototype['description'] = undefined;

/**
 * 貨存
 * @member {Number} inventory
 */
TemplateBookDto.prototype['inventory'] = undefined;

/**
 * 語言
 * @member {String} language
 */
TemplateBookDto.prototype['language'] = undefined;

/**
 * 產品名稱
 * @member {String} name
 */
TemplateBookDto.prototype['name'] = undefined;

/**
 * 出版社
 * @member {String} organization
 */
TemplateBookDto.prototype['organization'] = undefined;

/**
 * 原價
 * @member {Number} originalPrice
 */
TemplateBookDto.prototype['originalPrice'] = undefined;

/**
 * 售價
 * @member {Number} price
 */
TemplateBookDto.prototype['price'] = undefined;

/**
 * 產品類型
 * @member {String} productType
 */
TemplateBookDto.prototype['productType'] = undefined;

/**
 * 出版年份
 * @member {Date} publishDate
 */
TemplateBookDto.prototype['publishDate'] = undefined;






export default TemplateBookDto;

