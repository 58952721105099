import React, { useEffect, useState } from "react";
import style from "./DownloadButton.module.css";

//props : {filename, percent}
const DownloadButton = (props) => {
  const [filename, setFilename] = useState("");
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    //console.log(props);
    if (props.filename) {
      setFilename(props.filename);
    }

    if (props.percent) {
      setPercent(props.percent);
    }
  }, [props]);

  return (
    <div
      className={style.downloadButtonContainer}
      onClick={() => console.log("CLICK")}
    >
      <div className={style.processbar} style={{ width: percent + "%" }}></div>
      <div className={style.filename}>{filename}</div>
      <div className={style.percent}>{percent}%</div>
    </div>
  );
};

export default DownloadButton;
