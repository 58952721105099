import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import loadingGif from "../../../assets/images/loading.gif";
import BasicInfo from "../../../components/Book/BasicInfo/BasicInfo";
import BackButton from "../../../components/Button/back/BackButton";
import Button from "../../../components/Button/Button";
import DialogBox from "../../../components/DialogBox/DialogBox";
import SelectOption from "../../../components/SelectOption/SelectOption";
import { BookApi } from "../../../fetcher";
import { handleUserInfo } from "../../../redux/actions";
import {
  ALL_BOOK,
  APPLY_UNAVAILABLE,
  APPROVED,
  AVAILABLE,
  CODE_BOOK_ADD,
  CODE_BOOK_APPROVED,
  CODE_BOOK_AVAILABLE,
  CODE_BOOK_DELETE,
  CODE_BOOK_REJECTED,
  CODE_BOOK_SUBMITTED,
  CODE_BOOK_TEMP,
  CODE_BOOK_UPDATE,
  CODE_BOOK_UNAVAILABLE,
  REJECTED,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  SUBMITTED,
  TEMP,
  UNAVAILABLE,
} from "../../../utils/Constants";
import { genApiConfig } from "../../../utils/fetch-caller";
import { getArgsQuery } from "../../../utils/GlobalFunction";
import { getUserInfo } from "../../../utils/LocalStorage";
import style from "./UpdateBookView.module.css";

const UpdateBookView = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [basicInfo, setBasicInfo] = useState({});
  const [submitData, setSubmitData] = useState({});
  const [canSubmit, setCanSubmit] = useState(true);
  const [userInfo, setUserInfo] = useState([]);
  const [isInternalUser, setIsInternalUser] = useState(false);
  const [isOrganizationMember, setIsOrganizationMember] = useState(false);
  const [showUnavailableDialog, setShowUnavailableDialog] = useState(false);
  const [DateYear, setDateYear] = useState(""); // 日期
  const [DateMonth, setDateMonth] = useState(""); // 日期
  const [settingMonth, setSettingMonth] = useState();
  const [settingYear, setSettingYear] = useState();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  
  // 20240625
  const [flag_BOOK_ADD, setFlag_BOOK_ADD] = useState(false);
  const [flag_BOOK_UPDATE, setFlag_BOOK_UPDATE] = useState(false);
  const [flag_BOOK_DELETE, setFlag_BOOK_DELETE] = useState(false);
  const [flag_BOOK_TEMP, setFlag_BOOK_TEMP] = useState(false);
  const [flag_BOOK_SUBMITTED, setFlag_BOOK_SUBMITTED] = useState(false);
  const [flag_BOOK_APPROVED, setFlag_BOOK_APPROVED] = useState(false);
  const [flag_BOOK_REJECTED, setFlag_BOOK_REJECTED] = useState(false);
  const [flag_BOOK_AVAILABLE, setFlag_BOOK_AVAILABLE] = useState(false);
  const [flag_BOOK_UNAVAILABLE, setFlag_BOOK_UNAVAILABLE] = useState(false);
  // 20240625 end

  const handleSubmit = (value) => {
    var submitData = submitFormData();

    if (
      submitData.bookPayloadModel.status == SUBMITTED
      || submitData.bookPayloadModel.status == APPROVED
      || submitData.bookPayloadModel.status == AVAILABLE
    ) {
      if (submitData.bookPayloadModel.bookIsbn == "") {
        alert("請輸入ISBN /產品編號");
        return;
      }
      if (submitData.bookPayloadModel.author == "") {
        alert("請輸入作者名稱");
        return;
      }
      if (submitData.bookPayloadModel.name == "") {
        alert("請輸入產品名稱");
        return;
      }
      if (!submitData.bookPayloadModel.publishDate) {
        alert("請選擇出版年份/出版月份");
        return;
      }
      if (submitData.bookPayloadModel.organizationId == "") {
        alert("請選擇出版社");
        return;
      }
      if (submitData.bookPayloadModel.categoryIds.length <= 0) {
        alert("請選擇分類");
        return;
      }

      if (
        submitData.bookPayloadModel.productTypeId == "" ||
        submitData.bookPayloadModel.productTypeId == null
      ) {
        alert("請輸入產品類型");
        return;
      }
      if (
        submitData.bookPayloadModel.languageIds == null ||
        submitData.bookPayloadModel.languageIds == ""
      ) {
        alert("請選擇語言");
        return;
      }
      if (
        submitData.bookPayloadModel.price === "" ||
        submitData.bookPayloadModel.price === undefined
      ) {
        alert("請輸入售價");
        return;
      }
      /*
      if (
        submitData.bookPayloadModel.originalPrice === "" ||
        submitData.bookPayloadModel.originalPrice === undefined
      ) {
        alert("請輸入原價");
        return;
      }
      */
      if (submitData.bookPayloadModel.ctsid == "") {
        alert("請輸入CTS書號");
        return;
      }
      if (submitData.bookPayloadModel.description == "") {
        alert("請輸入產品簡介");
        return;
      }

      if (
        submitData.bookPayloadModel.file.epubProd.length <= 0 ||
        submitData.bookPayloadModel.file.epubProd.find((it) => it.select) ==
          undefined
      ) {
        alert("請選擇所需檔案後再提交。");
        return;
      }
      // 20240611 GC
      /*
      if (
        submitData.bookPayloadModel.file.epubPreview.length <= 0 ||
        submitData.bookPayloadModel.file.epubPreview.find((it) => it.select) ==
          undefined
      ) {
        alert("請選擇試閱檔案");
        return;
      }
      */
      // 20240611 GC end
      if (
        submitData.bookPayloadModel.file.cover.length <= 0 ||
        submitData.bookPayloadModel.file.cover.find((it) => it.select) ==
          undefined
      ) {
        alert("請選擇封面檔案");
        return;
      }
    }

    setLoading(true);
    if (submitData !== null && submitData !== undefined) {
      if (submitData.bookId !== undefined || submitData.bookId !== null) {
        console.log("submitData223", submitData);
        new BookApi(genApiConfig())
          .updateBookUsingPATCH(submitData.bookId, submitData)
          .then((res) => {
            console.log("更新書籍成功", res);
            if (res.data != null) {
              if (res.data.status === SUBMITTED) {
                alert("書籍已提交審批。");
              } else if (res.data.status === REJECTED) {
                alert("已拒絕所上載的書籍");
              } else if (res.data.status === APPROVED) {
                alert("書籍已核准");
              } else if (res.data.status === AVAILABLE) {
                alert("書籍已上架");
              } else if (res.data.status === UNAVAILABLE) {
                alert("書籍已下架");
              } else {
                alert("更新書籍成功");
              }

              history.push(
                `${ALL_BOOK}?id=${getArgsQuery(props.location.search)?.id}`
              );
            } else {
              alert(res.response.body.message);
            }
            //console.log(res);
          })
          .catch((err) => {
            let error = JSON.parse(err.error.message);
            console.log(
              "changeBookStatusUsingPATCH Error 1 : ",
              JSON.parse(error.message)
            );
            console.log("changeBookStatusUsingPATCH Error err : ", err);
            setErrorMessage(JSON.parse(error.message));
            setShowErrorMessage(true);
          });
      } else {
        alert("Id not found");
      }
    } else {
      alert("找不到書本資料");
    }

    setLoading(false);
  };

  const submitFormData = () => {
    //console.log(submitData);
    let opts = {};

    let status = submitData.status !== "TEMP" ? submitData.status : "TEMP";

    var currentPrice = submitData.price;
    //var currentInventory = submitData.inventory;

    if (currentPrice !== undefined && currentPrice !== "") {
      if (isNaN(submitData.price)) {
        alert("不是有效之售價輸入");
        return null;
      }
      currentPrice = parseFloat(currentPrice);
    }

    var originalPrice = submitData.originalPrice;
    //var currentInventory = submitData.inventory;

    if (originalPrice !== undefined && currentPrice !== "") {
      if (isNaN(submitData.originalPrice)) {
        alert("不是有效之原價輸入");
        return null;
      }
      originalPrice = parseFloat(originalPrice);
    }

    // if (currentInventory !== undefined && currentInventory !== "") {
    //   if (isNaN(submitData.inventory)) {
    //     alert("不是有效之貨存輸入");
    //     return null;
    //   }
    //   currentInventory = parseInt(currentInventory);
    // }

    // if (
    //     submitData.author !== "" &&
    //     submitData.ISBN !== "" &&
    //     submitData.name !== "" &&
    //     submitData.supplierId !== undefined &&
    //     submitData.categoryId.length > 0 &&
    //     submitData.price >= 0 &&
    //     submitData.file.cover.find((it) => it.select) !== undefined &&
    //     submitData.file.epubProd.find((it) => it.select) !== undefined &&
    //     submitData.status === TEMP
    // ) {
    //     status = SUBMITTED;
    // }
    opts["bookId"] = submitData.id;
    let _arr;
    if (submitData.categoryId != null || submitData.categoryId.length > 0) {
      _arr = submitData.categoryId.filter((it) => it.isSelected);
    }
    let _ids = [];
    _arr.map((it) => _ids.push(it.id));

    let languageIdarr;
    if (submitData.languageId != null || submitData.languageId.length > 0) {
      languageIdarr = submitData.languageId.filter((it) => it.isSelected);
    }
    let languageId = [];
    languageIdarr.map((it) => languageId.push(it.id));

    opts["bookPayloadModel"] = {
      status,
      author: submitData.author,
      bookIsbn: submitData.ISBN,
      categoryIds: _ids,
      organizationId:
        submitData.supplierId.length > 0 ? submitData.supplierId[0].id : null,
      languageIds: languageId,
      productTypeId:
        submitData.productTypeId.length > 0
          ? submitData.productTypeId[0].id
          : null,
      ctsid: submitData.ctsid,
      description: submitData.description,
      name: submitData.name,
      price: currentPrice,
      originalPrice: originalPrice,
      publishDate: submitData.publishDate,
      isRoot: true,
      version: "v1.0",
      file: submitData.file,
      inventory: 999999, //currentInventory,
      bookComment: submitData.bookComment,
      translator: submitData.translator,
    };
    console.log("New opts = ", opts);
    return opts;
  };

  const handleSubmitDataChange = (value) => {
    setSubmitData(Object.assign(submitData, value));
    console.log("handleSubmitDataChange", value);

    if (
      submitData.ISBN !== "" &&
      submitData.file.audioBook.filter((it) => it.fileId === null).length ===
        0 &&
      submitData.file.cover.filter((it) => it.fileId === null).length === 0 &&
      submitData.file.epubPreview.filter((it) => it.fileId === null).length ===
        0 &&
      submitData.file.epubProd.filter((it) => it.fileId === null).length ===
        0 &&
      submitData.file.multimedia.filter((it) => it.fileId === null).length ===
        0 &&
      submitData.file.thumb.filter((it) => it.fileId === null).length === 0
    ) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  const handleUnavailableSubmit = (value) => {};

  useEffect(() => {
    // setIsInternalUser(true);
    /*
    setTimeout(function () {
        let orgInternalList = userInfo.organizations?.filter((it) => {
            return it.code === "INTERNAL";
        });
        if (orgInternalList !== undefined) {
            if (orgInternalList.length > 0) setIsInternalUser(true);
        }
    }, 1000);
    */
   
    // 20240625
    let _isInternalUser = false;
    let orgInternalList = userInfo.organizations?.filter((it) => {
      return it.code === "INTERNAL";
    });
    if (orgInternalList !== undefined) {
        if (orgInternalList.length > 0) {
          _isInternalUser = true;
          setIsInternalUser(true);
        }
    }

    //-----

    let _isOrganizationMember = false;

    let currentOrg = getUserInfo();

    let _organizations = [];
    let _permissions = [];

    let _flag_BOOK_ADD = false;
    let _flag_BOOK_UPDATE = false;
    let _flag_BOOK_DELETE = false;
    let _flag_BOOK_TEMP = false;
    let _flag_BOOK_SUBMITTED = false;
    let _flag_BOOK_APPROVED = false;
    let _flag_BOOK_REJECTED = false;
    let _flag_BOOK_AVAILABLE = false;
    let _flag_BOOK_UNAVAILABLE = false;
    if (currentOrg) {
      _organizations = currentOrg.organizations.find((it) => {
        return it.isSelected === true;
      });
      if (_organizations) {
        _isOrganizationMember = true;
        setIsOrganizationMember(true);

        // CODE_BOOK_ADD
        _permissions = _organizations.role.permissions.find((per) => {
          return (
            per.code === CODE_BOOK_ADD
          );
        });
        if (_permissions) {
          _flag_BOOK_ADD = true;
        }

        // CODE_BOOK_UPDATE
        _permissions = _organizations.role.permissions.find((per) => {
          return (
            per.code === CODE_BOOK_UPDATE
          );
        });
        if (_permissions) {
          _flag_BOOK_UPDATE = true;
        }

        // CODE_BOOK_DELETE
        _permissions = _organizations.role.permissions.find((per) => {
          return (
            per.code === CODE_BOOK_DELETE
          );
        });
        if (_permissions) {
          _flag_BOOK_DELETE = true;
        }

        // CODE_BOOK_TEMP
        _permissions = _organizations.role.permissions.find((per) => {
          return (
            per.code === CODE_BOOK_TEMP
          );
        });
        if (_permissions) {
          _flag_BOOK_TEMP = true;
        }

        // CODE_BOOK_SUBMITTED
        _permissions = _organizations.role.permissions.find((per) => {
          return (
            per.code === CODE_BOOK_SUBMITTED
          );
        });
        if (_permissions) {
          _flag_BOOK_SUBMITTED = true;
        }

        // CODE_BOOK_APPROVED
        _permissions = _organizations.role.permissions.find((per) => {
          return (
            per.code === CODE_BOOK_APPROVED
          );
        });
        if (_permissions) {
          _flag_BOOK_APPROVED = true;
        }

        // CODE_BOOK_REJECTED
        _permissions = _organizations.role.permissions.find((per) => {
          return (
            per.code === CODE_BOOK_REJECTED
          );
        });
        if (_permissions) {
          _flag_BOOK_REJECTED = true;
        }

        // CODE_BOOK_AVAILABLE
        _permissions = _organizations.role.permissions.find((per) => {
          return (
            per.code === CODE_BOOK_AVAILABLE
          );
        });
        if (_permissions) {
          _flag_BOOK_AVAILABLE = true;
        }

        // CODE_BOOK_UNAVAILABLE
        _permissions = _organizations.role.permissions.find((per) => {
          return (
            per.code === CODE_BOOK_UNAVAILABLE
          );
        });
        if (_permissions) {
          _flag_BOOK_UNAVAILABLE = true;
        }
      }
    }
    
    // CODE_BOOK_AVAILABLE
    if (_isInternalUser) _flag_BOOK_AVAILABLE = true;
    setFlag_BOOK_AVAILABLE(_flag_BOOK_AVAILABLE);

    // CODE_BOOK_UNAVAILABLE
    if (_isInternalUser) _flag_BOOK_UNAVAILABLE = true;
    setFlag_BOOK_UNAVAILABLE(_flag_BOOK_UNAVAILABLE);

    // CODE_BOOK_ADD
    if (_isInternalUser) _flag_BOOK_ADD = true;
    setFlag_BOOK_ADD(_flag_BOOK_ADD);

    // CODE_BOOK_UPDATE
    if (_isInternalUser) _flag_BOOK_UPDATE = true;
    setFlag_BOOK_UPDATE(_flag_BOOK_UPDATE);

     // CODE_BOOK_DELETE
    if (_isInternalUser) _flag_BOOK_DELETE = true;
    setFlag_BOOK_DELETE(_flag_BOOK_DELETE);

    // CODE_BOOK_TEMP
    if (props.location.state.status === UNAVAILABLE) {
      if (_flag_BOOK_AVAILABLE) {

      } else {
        _flag_BOOK_TEMP = false;
      }
    }
    if (_isInternalUser) _flag_BOOK_TEMP = true;
    setFlag_BOOK_TEMP(_flag_BOOK_TEMP);

    // CODE_BOOK_SUBMITTED
    if (props.location.state.status === UNAVAILABLE) {
      if (_flag_BOOK_AVAILABLE) {

      } else {
        _flag_BOOK_SUBMITTED = false;
      }
    }
    if (_isInternalUser) _flag_BOOK_SUBMITTED = true;
    setFlag_BOOK_SUBMITTED(_flag_BOOK_SUBMITTED);

    // CODE_BOOK_APPROVED
    if (_isInternalUser) _flag_BOOK_APPROVED = true;
    setFlag_BOOK_APPROVED(_flag_BOOK_APPROVED);

    // CODE_BOOK_REJECTED
    if (_isInternalUser) _flag_BOOK_REJECTED = true;
    setFlag_BOOK_REJECTED(_flag_BOOK_REJECTED);

    // 20240625 end
  }, [userInfo]);

  useEffect(() => {
    console.log("props.location.state", props.location.state);
    setUserInfo(props.userInfo);
    setBasicInfo(props.location.state);
  }, [props]);

  //console.log("aa", getArgsQuery(props.location.search).id);

  return (
    <>
      <div className={style.backbtn}>
        <BackButton />
      </div>
      <div className={style.updateBookView_container}>
        <h4>更新書籍</h4>
        <BasicInfo
          info={basicInfo}
          callBack={(value) => handleSubmitDataChange(value)}
        />
        <div className={style.btn}>
          <Button
            styleType={STYLETYPE_SECOND}
            callBack={(value) =>
              props.history.push(
                ALL_BOOK + "?id=" + getArgsQuery(props.location.search).id
              )
            }
          >
            取消
          </Button>
          {props.location.state !== null ? (
            <>{
              (isInternalUser || isOrganizationMember)
              ? (
                props.location.state.status === SUBMITTED ? (
                  <>
                  {
                      flag_BOOK_REJECTED
                      ? (
                        <Button
                          styleType={STYLETYPE_MAIN}
                          code={CODE_BOOK_REJECTED}
                          callBack={(value) => {
                            Object.assign(submitData, { status: REJECTED });
                            handleSubmit(value);
                          }}
                          disabled={!canSubmit}
                        >
                          {loading ? "" : "拒絕"}
                          <img
                            src={loadingGif}
                            style={{ width: "20px", height: "20px" }}
                            hidden={!loading}
                          />
                        </Button>
                      )
                      :
                        <></>
                  }
                  {
                      flag_BOOK_APPROVED
                      ? (
                        <Button
                          styleType={STYLETYPE_MAIN}
                          code={CODE_BOOK_APPROVED}
                          callBack={(value) => {
                            Object.assign(submitData, { status: APPROVED });
                            handleSubmit(value);
                          }}
                          disabled={!canSubmit}
                        >
                          {loading ? "" : "核准"}
                          <img
                            src={loadingGif}
                            style={{ width: "20px", height: "20px" }}
                            hidden={!loading}
                          />
                        </Button>
                      )
                      :
                        <></>
                  }
                  {/*
                    <Button
                      styleType={STYLETYPE_MAIN}
                      code={CODE_BOOK_TEMP}
                      callBack={(value) => {
                        Object.assign(submitData, { status: TEMP });
                        handleSubmit(value);
                      }}
                      disabled={!canSubmit}
                    >
                      {loading ? "" : "儲存"}
                      <img src={loadingGif} style={{ width: "20px", height: "20px" }} hidden={!loading} />
                    </Button>
                    */}
                  </>
                ) : props.location.state.status === APPROVED ? (
                  <>
                  {
                      flag_BOOK_REJECTED
                      ? (
                        <Button
                          styleType={STYLETYPE_MAIN}
                          code={CODE_BOOK_REJECTED}
                          callBack={(value) => {
                            Object.assign(submitData, { status: REJECTED });
                            handleSubmit(value);
                          }}
                          disabled={!canSubmit}
                        >
                          {loading ? "" : "拒絕"}
                          <img
                            src={loadingGif}
                            style={{ width: "20px", height: "20px" }}
                            hidden={!loading}
                          />
                        </Button>
                      )
                      :
                        <></>
                  }
                  {
                      flag_BOOK_APPROVED
                      ? (
                        <Button
                          styleType={STYLETYPE_MAIN}
                          code={CODE_BOOK_AVAILABLE}
                          callBack={(value) => {
                            Object.assign(submitData, { status: AVAILABLE });
                            handleSubmit(value);
                          }}
                          disabled={!canSubmit}
                        >
                          {loading ? "" : "上架"}
                          <img
                            src={loadingGif}
                            style={{ width: "20px", height: "20px" }}
                            hidden={!loading}
                          />
                        </Button>
                      )
                      :
                        <></>
                  }
                  {/*
                  <Button
                    styleType={STYLETYPE_MAIN}
                    code={CODE_BOOK_TEMP}
                    callBack={(value) => {
                        Object.assign(submitData, { status: TEMP });
                        handleSubmit(value);
                    }}
                    disabled={!canSubmit}
                  >
                    {loading ? "" : "儲存"}
                    <img src={loadingGif} style={{ width: "20px", height: "20px" }} hidden={!loading} />
                  </Button>
                  */}
                  </>
                ) : props.location.state.status === AVAILABLE ? (
                  <>
                  {/*
                    <Button
                      styleType={STYLETYPE_MAIN}
                      code={CODE_BOOK_UNAVAILABLE}
                      callBack={(value) => setShowUnavailableDialog(true)}
                      disabled={!canSubmit}
                    >
                      {loading ? "" : "下架"}
                      <img
                        src={loadingGif}
                        style={{ width: "20px", height: "20px" }}
                        hidden={!loading}
                      />
                    </Button>
                  */}
                  {
                      flag_BOOK_UNAVAILABLE
                      ? (
                        <Button
                          styleType={STYLETYPE_MAIN}
                          code={CODE_BOOK_UNAVAILABLE}
                          callBack={(value) => {
                            Object.assign(submitData, { status: UNAVAILABLE });
                            handleSubmit(value);
                          }}
                          disabled={!canSubmit}
                        >
                          {
                            loading ? "" : "下架" // "下架11"
                          }
                          <img
                            src={loadingGif}
                            style={{ width: "20px", height: "20px" }}
                            hidden={!loading}
                          />
                        </Button>
                      )
                      :
                        <></>
                  }
                    {/*
                    <Button
                      styleType={STYLETYPE_MAIN}
                      code={CODE_BOOK_TEMP}
                      callBack={(value) => {
                          Object.assign(submitData, { status: TEMP });
                          handleSubmit(value);
                      }}
                      disabled={!canSubmit}
                    >
                      {loading ? "" : "儲存"}
                      <img src={loadingGif} style={{ width: "20px", height: "20px" }} hidden={!loading} />
                    </Button>
                    */}
                  </>
                ) : props.location.state.status === UNAVAILABLE ? (
                  <>
                  {
                      flag_BOOK_AVAILABLE
                      ? (
                        <Button
                          styleType={STYLETYPE_MAIN}
                          code={CODE_BOOK_AVAILABLE}
                          callBack={(value) => {
                            Object.assign(submitData, { status: AVAILABLE });
                            handleSubmit(value);
                          }}
                          disabled={!canSubmit}
                        >
                          {loading ? "" : "上架"}
                          <img
                            src={loadingGif}
                            style={{ width: "20px", height: "20px" }}
                            hidden={!loading}
                          />
                        </Button>
                      )
                      :
                        <></>
                  }
                  {
                      flag_BOOK_TEMP
                      ? (
                        <Button
                          styleType={STYLETYPE_MAIN}
                          code={CODE_BOOK_TEMP}
                          callBack={(value) => {
                            Object.assign(submitData, { status: TEMP });
                            handleSubmit(value);
                          }}
                          disabled={!canSubmit}
                        >
                          {loading ? "" : "儲存"}
                          <img
                            src={loadingGif}
                            style={{ width: "20px", height: "20px" }}
                            hidden={!loading}
                          />
                        </Button>
                      )
                      :
                        <></>
                  }
                  {
                      flag_BOOK_SUBMITTED
                      ? (
                        <Button
                          styleType={STYLETYPE_MAIN}
                          code={CODE_BOOK_SUBMITTED}
                          callBack={(value) => {
                            Object.assign(submitData, { status: SUBMITTED });
                            handleSubmit(value);
                          }}
                          disabled={!canSubmit}
                        >
                          {loading ? "" : "提交審批"}
                          <img
                            src={loadingGif}
                            style={{ width: "20px", height: "20px" }}
                            hidden={!loading}
                          />
                        </Button>
                      )
                      :
                        <></>
                  }
                  </>
                ) : props.location.state.status === TEMP ||
                  props.location.state.status === REJECTED ? (
                  <>
                  {
                      flag_BOOK_TEMP
                      ? (
                        <Button
                          styleType={STYLETYPE_MAIN}
                          code={CODE_BOOK_TEMP}
                          callBack={(value) => {
                            Object.assign(submitData, { status: TEMP });
                            handleSubmit(value);
                          }}
                          disabled={!canSubmit}
                        >
                          {loading ? "" : "儲存"}
                          <img
                            src={loadingGif}
                            style={{ width: "20px", height: "20px" }}
                            hidden={!loading}
                          />
                        </Button>
                      )
                      :
                        <></>
                  }
                  {
                      flag_BOOK_SUBMITTED
                      ? (
                        <Button
                          styleType={STYLETYPE_MAIN}
                          code={CODE_BOOK_SUBMITTED}
                          callBack={(value) => {
                            Object.assign(submitData, { status: SUBMITTED });
                            handleSubmit(value);
                          }}
                          disabled={!canSubmit}
                        >
                          {loading ? "" : "提交審批"}
                          <img
                            src={loadingGif}
                            style={{ width: "20px", height: "20px" }}
                            hidden={!loading}
                          />
                        </Button>
                      )
                      :
                        <></>
                  }
                  </>
                ) : props.location.state.status === APPLY_UNAVAILABLE ? ( 
                    <>
                    {
                        flag_BOOK_UNAVAILABLE
                        ? (
                          <Button
                            styleType={STYLETYPE_MAIN}
                            code={CODE_BOOK_UNAVAILABLE}
                            callBack={(value) => {
                              Object.assign(submitData, { status: UNAVAILABLE });
                              handleSubmit(value);
                            }}
                            disabled={!canSubmit}
                          >
                            {loading ? "" : "下架"}
                            <img
                              src={loadingGif}
                              style={{ width: "20px", height: "20px" }}
                              hidden={!loading}
                            />
                          </Button>
                        )
                        :
                          <></>
                    }
                    </>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <DialogBox showDialog={showErrorMessage} title={"下架失敗"}>
        <label>請先下架下列書籍</label>
        {errorMessage.map((msg) => (
          <div className={style.overflow_content}>
            <label>名稱︰{msg.libraryName}</label>
            <br></br>
            <label>URL ︰ {msg.libraryUrl}</label>
            <br></br>

            {msg.offShelfModel.offShelfProducts.map((p) => (
              <div>
                <label>Id︰{p.productId}</label>&nbsp;
                <label>書名 : {p.productName}</label>
              </div>
            ))}
          </div>
        ))}
        <div>
          <div className={style.button_on_right}>
            <Button
              styleType={STYLETYPE_SECOND}
              callBack={(value) => setShowErrorMessage(false)}
            >
              取消
            </Button>
          </div>
        </div>
      </DialogBox>
      {/*
      <DialogBox styleType={STYLETYPE_SECOND} showDialog={showUnavailableDialog} title={"下架"}>
          <div>
              <label></label>
              <br />
              <div className={style.Dialogcontent}>
                  <div className={style.rowdate}>
                      <SelectOption placeholder={"下架年份"} items={settingYear} value={DateYear} callBack={(value) => setDateYear(value)} />
                  </div>
                  <div className={style.rowdate}>
                      <SelectOption placeholder={"下架月份"} items={settingMonth} value={DateMonth} callBack={(value) => setDateMonth(value)} />
                  </div>
              </div>
              <br />
              <div className={style.button_on_right}>
                  <Button styleType={STYLETYPE_SECOND} callBack={(value) => setShowUnavailableDialog(false)}>
                      取消
                  </Button>

                  <Button
                      styleType={STYLETYPE_MAIN}
                      code={CODE_BOOK_UNAVAILABLE}
                      callBack={(value) => {
                          Object.assign(submitData, { status: UNAVAILABLE });
                          handleSubmit(value);
                      }}
                      disabled={!canSubmit}
                  >
                      {loading ? "" : "下架"}
                      <img src={loadingGif} style={{ width: "20px", height: "20px" }} hidden={!loading} />
                  </Button>
              </div>
          </div>
      </DialogBox>
      */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  };
};
const actionCreator = { handleUserInfo };
export default connect(
  mapStateToProps,
  actionCreator
)(withRouter(UpdateBookView));
