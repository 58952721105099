/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BookDto from './BookDto';
import CategoryDto from './CategoryDto';

/**
 * The CategoryBookItemResponseModel model module.
 * @module model/CategoryBookItemResponseModel
 * @version v1.0.0
 */
class CategoryBookItemResponseModel {
    /**
     * Constructs a new <code>CategoryBookItemResponseModel</code>.
     * @alias module:model/CategoryBookItemResponseModel
     */
    constructor() { 
        
        CategoryBookItemResponseModel.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CategoryBookItemResponseModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CategoryBookItemResponseModel} obj Optional instance to populate.
     * @return {module:model/CategoryBookItemResponseModel} The populated <code>CategoryBookItemResponseModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CategoryBookItemResponseModel();

            if (data.hasOwnProperty('books')) {
                obj['books'] = ApiClient.convertToType(data['books'], [BookDto]);
            }
            if (data.hasOwnProperty('category')) {
                obj['category'] = CategoryDto.constructFromObject(data['category']);
            }
        }
        return obj;
    }


}

/**
 * @member {Array.<module:model/BookDto>} books
 */
CategoryBookItemResponseModel.prototype['books'] = undefined;

/**
 * @member {module:model/CategoryDto} category
 */
CategoryBookItemResponseModel.prototype['category'] = undefined;






export default CategoryBookItemResponseModel;

