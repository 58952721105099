import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import {
  STYLETYPE_CUSTOM,
  STYLETYPE_MAIN,
  STYLETYPE_SECOND,
  STYLETYPE_THIRD,
  STYLETYPE_FOUR,
  STYLETYPE_FIVE,
} from "../../utils/Constants";
import style from "./SelectOption.module.css";

export default class SelectOption extends Component {
  state = {
    currentSelected: "",
  };

  static propTypes = {
    firstValue: PropTypes.string.isRequired,
    items: PropTypes.array,
    value: PropTypes.string,
    styleType: PropTypes.string,
    placeholder: PropTypes.string,
    callBack: PropTypes.func,
    multi: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    firstValue: "請選擇",
    value: "",
    styleType: STYLETYPE_MAIN,
  };

  componentWillReceiveProps(nextProps) {
    //console.log("next", nextProps);
    // console.log("state", this.state);
    if (nextProps !== this.props) {
      if (nextProps.value !== null) {
        this.setState({
          currentSelected: nextProps.value,
        });
      }
      return;
    }
  }

  handleSelectOnChange = (event) => {
    this.setState({ currentSelected: event.target.value });
    this.props.callBack(event.target.value);
  };

  render() {
    const {
      firstValue,
      styleType,
      items,
      value,
      multi,
      placeholder,
      disabled,
    } = this.props;
    const { currentSelected } = this.state;
    return (
      <Fragment>
        {styleType === STYLETYPE_MAIN ? (
          <div
            className={style.mainSelect}
            style={{ pointerEvents: disabled ? "none" : "initial" }}
          >
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? placeholder : ""}
            </label>
            <select
              onChange={this.handleSelectOnChange}
              defaultValue={value !== "" ? value : ""}
              autoComplete={"off"}
            >
              {currentSelected.length > 0 ? (
                items &&
                items.map((item) => {
                  return (
                    <>
                      {item.name === currentSelected ? (
                        <option key={item.id} value={item.name} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  <option key={"-1"} value="-1">
                    {firstValue}
                  </option>
                  {items &&
                    items.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </>
              )}
            </select>
          </div>
        ) : styleType === STYLETYPE_SECOND ? (
          <div
            className={style.secondSelect}
            style={{ pointerEvents: disabled ? "none" : "initial" }}
          >
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? placeholder : ""}
            </label>
            <select
              onChange={this.handleSelectOnChange}
              defaultValue={value !== "" ? value : ""}
              autoComplete={"off"}
              multiple={multi}
            >
              {currentSelected.length > 0 ? (
                items &&
                items.map((item) => {
                  return (
                    <>
                      {item.name === currentSelected ? (
                        <option key={item.id} value={item.name} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  <option key={"-1"} value="">
                    {firstValue}
                  </option>
                  {items &&
                    items.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </>
              )}
            </select>
          </div>
        ) : styleType === STYLETYPE_THIRD ? (
          <div
            className={style.thirdSelect}
            style={{ pointerEvents: disabled ? "none" : "initial" }}
          >
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? placeholder : ""}
            </label>
            <select
              onChange={this.handleSelectOnChange}
              defaultValue={value !== "" ? value : ""}
              autoComplete={"off"}
              multiple={multi}
            >
              {currentSelected.length > 0 ? (
                items &&
                items.map((item) => {
                  return (
                    <>
                      {item.name === currentSelected ? (
                        <option key={item.id} value={item.name} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  <option key={"-1"} value="">
                    {firstValue}
                  </option>
                  {items &&
                    items.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </>
              )}
            </select>
          </div>
        ) : styleType === STYLETYPE_FOUR ? (
          <div
            className={style.fourSelect}
            style={{ pointerEvents: disabled ? "none" : "initial" }}
          >
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? placeholder : ""}
            </label>
            <select
              onChange={this.handleSelectOnChange}
              defaultValue={value !== "" ? value : ""}
              autoComplete={"off"}
              multiple={multi}
            >
              {currentSelected.length > 0 ? (
                items &&
                items.map((item) => {
                  //  console.log(item.name, currentSelected);
                  return (
                    <>
                      {item.name === currentSelected ? (
                        <option key={item.id} value={item.name} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  {items &&
                    items.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </>
              )}
            </select>
          </div>
        ) : styleType === STYLETYPE_FIVE ? (
          <div
            className={style.fiveSelect}
            style={{ pointerEvents: disabled ? "none" : "initial" }}
          >
            <label className={style.customBadge}>
              {placeholder !== "" || placeholder !== null ? placeholder : ""}
            </label>
            <select
              onChange={this.handleSelectOnChange}
              defaultValue={value !== "" ? value : ""}
              autoComplete={"off"}
            >
              {currentSelected !== "" ? (
                items &&
                items.map((item, index) => {
                  return (
                    <>
                      {index === 0 ? (
                        <option key={"-1"} value="">
                          {firstValue}
                        </option>
                      ) : (
                        <></>
                      )}
                      {item.name === currentSelected ? (
                        <option key={item.id} value={item.name} selected>
                          {item.name}
                        </option>
                      ) : (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  <option key={"-1"} value="">
                    {firstValue}
                  </option>
                  {items &&
                    items.map((item) => {
                      return (
                        <option key={item.id} value={item.name}>
                          {item.name}
                        </option>
                      );
                    })}
                </>
              )}
            </select>
          </div>
        ) : styleType === STYLETYPE_CUSTOM ? (
          <></>
        ) : (
          <></>
        )}
      </Fragment>
    );
  }
}
