/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import GetSystemSettingsResponseModel from '../model/GetSystemSettingsResponseModel';
import SystemSettingCronJobModel from '../model/SystemSettingCronJobModel';
import SystemSettingDoubleModel from '../model/SystemSettingDoubleModel';
import SystemSettingIntegerModel from '../model/SystemSettingIntegerModel';

/**
* SystemSetting service.
* @module api/SystemSettingApi
* @version v1.0.0
*/
export default class SystemSettingApi {

    /**
    * Constructs a new SystemSettingApi. 
    * @alias module:api/SystemSettingApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the getAllSystemSettingsUsingGET operation.
     * @callback module:api/SystemSettingApi~getAllSystemSettingsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/GetSystemSettingsResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get All System Setting
     * @param {module:api/SystemSettingApi~getAllSystemSettingsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/GetSystemSettingsResponseModel}
     */
    getAllSystemSettingsUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = GetSystemSettingsResponseModel;
      return this.apiClient.callApi(
        '/api/system-setting/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookApplyAvailableUsingGET operation.
     * @callback module:api/SystemSettingApi~getBookApplyAvailableUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Book Apply Unavailable Email Template
     * @param {module:api/SystemSettingApi~getBookApplyAvailableUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getBookApplyAvailableUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/book-apply-unavailable', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookAvailableEmailTemplateUsingGET operation.
     * @callback module:api/SystemSettingApi~getBookAvailableEmailTemplateUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Book Available Email Template
     * @param {module:api/SystemSettingApi~getBookAvailableEmailTemplateUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getBookAvailableEmailTemplateUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/book-available', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookRejectedEmailTemplateUsingGET operation.
     * @callback module:api/SystemSettingApi~getBookRejectedEmailTemplateUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Book rejected Email Template
     * @param {module:api/SystemSettingApi~getBookRejectedEmailTemplateUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getBookRejectedEmailTemplateUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/book-rejected', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookSubmittedEmailTemplateUsingGET operation.
     * @callback module:api/SystemSettingApi~getBookSubmittedEmailTemplateUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Book submitted Email Template
     * @param {module:api/SystemSettingApi~getBookSubmittedEmailTemplateUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getBookSubmittedEmailTemplateUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/book-submitted', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getBookUnavailableEmailTemplateUsingGET operation.
     * @callback module:api/SystemSettingApi~getBookUnavailableEmailTemplateUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Book Unavailable Email Template
     * @param {module:api/SystemSettingApi~getBookUnavailableEmailTemplateUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getBookUnavailableEmailTemplateUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/book-unavailable', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getPurchaseMissionPublishEmailUsingGET operation.
     * @callback module:api/SystemSettingApi~getPurchaseMissionPublishEmailUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Purchase Mission Publish Email Template
     * @param {module:api/SystemSettingApi~getPurchaseMissionPublishEmailUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getPurchaseMissionPublishEmailUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/purchase-mission-publish', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getResetPasswordEmailTemplateUsingGET operation.
     * @callback module:api/SystemSettingApi~getResetPasswordEmailTemplateUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Reset Password Email Template
     * @param {module:api/SystemSettingApi~getResetPasswordEmailTemplateUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getResetPasswordEmailTemplateUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/reset-password', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getVerifyEmailTemplateUsingGET operation.
     * @callback module:api/SystemSettingApi~getVerifyEmailTemplateUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Get Verify Email Template
     * @param {module:api/SystemSettingApi~getVerifyEmailTemplateUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    getVerifyEmailTemplateUsingGET(callback) {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/verify', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setBackUpCronJobValueUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setBackUpCronJobValueUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SystemSettingCronJobModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set BackUp CronJob Value
     * @param {Object} opts Optional parameters
     * @param {module:model/SystemSettingCronJobModel} opts.systemSettingCronJobModel 
     * @param {module:api/SystemSettingApi~setBackUpCronJobValueUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SystemSettingCronJobModel}
     */
    setBackUpCronJobValueUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['systemSettingCronJobModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = SystemSettingCronJobModel;
      return this.apiClient.callApi(
        '/api/system-setting/cron-job/backup', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setBookApplyUnavailableEmailTemplateUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setBookApplyUnavailableEmailTemplateUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set Book Apply Unavailable Email Template
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/SystemSettingApi~setBookApplyUnavailableEmailTemplateUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    setBookApplyUnavailableEmailTemplateUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/book-apply-unavailable', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setBookAvailableEmailTemplateUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setBookAvailableEmailTemplateUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set Book Available Email Template
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/SystemSettingApi~setBookAvailableEmailTemplateUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    setBookAvailableEmailTemplateUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/book-available', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setBookRejectedEmailTemplateUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setBookRejectedEmailTemplateUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set Book rejected Email Template
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/SystemSettingApi~setBookRejectedEmailTemplateUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    setBookRejectedEmailTemplateUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/book-rejected', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setBookSubmittedEmailTemplateUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setBookSubmittedEmailTemplateUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set Book submitted Email Template
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/SystemSettingApi~setBookSubmittedEmailTemplateUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    setBookSubmittedEmailTemplateUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/book-submitted', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setBookUnavailableEmailTemplateUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setBookUnavailableEmailTemplateUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set Book Unavailable Email Template
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/SystemSettingApi~setBookUnavailableEmailTemplateUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    setBookUnavailableEmailTemplateUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/book-unavailable', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setCurrencyRateUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setCurrencyRateUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SystemSettingDoubleModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set Currency Rate
     * @param {String} code code
     * @param {Object} opts Optional parameters
     * @param {Number} opts.body 
     * @param {module:api/SystemSettingApi~setCurrencyRateUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SystemSettingDoubleModel}
     */
    setCurrencyRateUsingPATCH(code, opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'code' is set
      if (code === undefined || code === null) {
        throw new Error("Missing the required parameter 'code' when calling setCurrencyRateUsingPATCH");
      }

      let pathParams = {
        'code': code
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = SystemSettingDoubleModel;
      return this.apiClient.callApi(
        '/api/system-setting/currency/{code}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setDefaultPageSizeUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setDefaultPageSizeUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SystemSettingIntegerModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set Default Page Size
     * @param {Object} opts Optional parameters
     * @param {module:model/SystemSettingIntegerModel} opts.systemSettingIntegerModel 
     * @param {module:api/SystemSettingApi~setDefaultPageSizeUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SystemSettingIntegerModel}
     */
    setDefaultPageSizeUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['systemSettingIntegerModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = SystemSettingIntegerModel;
      return this.apiClient.callApi(
        '/api/system-setting/pagination/default-page-size', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setPurchaseMissionPublishEmailUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setPurchaseMissionPublishEmailUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set Purchase Mission Publish Email Template
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/SystemSettingApi~setPurchaseMissionPublishEmailUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    setPurchaseMissionPublishEmailUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/purchase-mission-publish', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setResetPasswordEmailTemplateUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setResetPasswordEmailTemplateUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set Reset Password Email Template
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/SystemSettingApi~setResetPasswordEmailTemplateUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    setResetPasswordEmailTemplateUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/reset-password', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setScanRejectDayUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setScanRejectDayUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SystemSettingIntegerModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set Scan Reject Day
     * @param {Object} opts Optional parameters
     * @param {Number} opts.body 
     * @param {module:api/SystemSettingApi~setScanRejectDayUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SystemSettingIntegerModel}
     */
    setScanRejectDayUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = SystemSettingIntegerModel;
      return this.apiClient.callApi(
        '/api/system-setting/cron-job/scan-reject-days', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the setVerifyEmailTemplateUsingPATCH operation.
     * @callback module:api/SystemSettingApi~setVerifyEmailTemplateUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {String} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * Set Verify Email Template
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @param {module:api/SystemSettingApi~setVerifyEmailTemplateUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link String}
     */
    setVerifyEmailTemplateUsingPATCH(opts, callback) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = 'String';
      return this.apiClient.callApi(
        '/api/system-setting/email-template/verify', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
