import React from "react";
import { isElectron } from "react-device-detect";
import { connect } from "react-redux";
import StorageUtil from "../../../../utils/reader/serviceUtils/storageUtil";
import "./modeControl.css";
import { Tooltip } from "react-tippy";
import { Trans } from "react-i18next";

class ModeControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readerMode: StorageUtil.getReaderConfig("readerMode") || "double",
    };
  }

  handleChangeMode = (mode) => {
    this.setState({ readerMode: mode });
    StorageUtil.setReaderConfig("readerMode", mode);
    if (isElectron) {
      alert("Take effect at next startup");
    } else {
      window.location.reload();
    }
  };
  render() {
    return (
      <div className="background-color-setting">
        <div
          className="background-color-text"
          style={{ position: "relative", bottom: "15px" }}
        >
          <Trans>View Mode</Trans>
        </div>
        <div className="single-control-container">
          <Tooltip
            title={"Single-Page Mode"}
            position="top"
            trigger="mouseenter"
          >
            <div
              className="single-mode-container"
              onClick={() => {
                this.handleChangeMode("single");
              }}
              style={this.state.readerMode === "single" ? {} : { opacity: 0.4 }}
            >
              <span className="icon-single-page single-page-icon"></span>
            </div>
          </Tooltip>
          <Tooltip
            title={"Double-Page Mode"}
            position="top"
            trigger="mouseenter"
          >
            <div
              className="double-mode-container"
              onClick={() => {
                this.handleChangeMode("double");
              }}
              style={this.state.readerMode === "double" ? {} : { opacity: 0.4 }}
            >
              <span className="icon-two-page two-page-icon"></span>
            </div>
          </Tooltip>
          <Tooltip title={"Scroll Mode"} position="top" trigger="mouseenter">
            <div
              className="double-mode-container"
              onClick={() => {
                this.handleChangeMode("scroll");
              }}
              style={this.state.readerMode === "scroll" ? {} : { opacity: 0.4 }}
            >
              <span className="icon-scroll two-page-icon"></span>
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentEpub: state.book.currentEpub,
    renderFunc: state.book.renderFunc,
  };
};
const actionCreator = {};
export default connect(mapStateToProps, actionCreator)(ModeControl);
