/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The OrganizationObjectSubDto model module.
 * @module model/OrganizationObjectSubDto
 * @version v1.0.0
 */
class OrganizationObjectSubDto {
    /**
     * Constructs a new <code>OrganizationObjectSubDto</code>.
     * @alias module:model/OrganizationObjectSubDto
     */
    constructor() { 
        
        OrganizationObjectSubDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>OrganizationObjectSubDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/OrganizationObjectSubDto} obj Optional instance to populate.
     * @return {module:model/OrganizationObjectSubDto} The populated <code>OrganizationObjectSubDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new OrganizationObjectSubDto();

            if (data.hasOwnProperty('bannerUrl')) {
                obj['bannerUrl'] = ApiClient.convertToType(data['bannerUrl'], 'String');
            }
            if (data.hasOwnProperty('code')) {
                obj['code'] = ApiClient.convertToType(data['code'], 'String');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('fullName')) {
                obj['fullName'] = ApiClient.convertToType(data['fullName'], 'String');
            }
            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'Number');
            }
            if (data.hasOwnProperty('libraryBackendBasicUrl')) {
                obj['libraryBackendBasicUrl'] = ApiClient.convertToType(data['libraryBackendBasicUrl'], 'String');
            }
            if (data.hasOwnProperty('libraryFrontendBasicUrl')) {
                obj['libraryFrontendBasicUrl'] = ApiClient.convertToType(data['libraryFrontendBasicUrl'], 'String');
            }
            if (data.hasOwnProperty('logoUrl')) {
                obj['logoUrl'] = ApiClient.convertToType(data['logoUrl'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('seq')) {
                obj['seq'] = ApiClient.convertToType(data['seq'], 'Number');
            }
            if (data.hasOwnProperty('shortName')) {
                obj['shortName'] = ApiClient.convertToType(data['shortName'], 'String');
            }
            if (data.hasOwnProperty('sub')) {
                obj['sub'] = ApiClient.convertToType(data['sub'], [OrganizationObjectSubDto]);
            }
            if (data.hasOwnProperty('webUrl')) {
                obj['webUrl'] = ApiClient.convertToType(data['webUrl'], 'String');
            }
        }
        return obj;
    }


}

/**
 * banner url
 * @member {String} bannerUrl
 */
OrganizationObjectSubDto.prototype['bannerUrl'] = undefined;

/**
 * Organization code
 * @member {String} code
 */
OrganizationObjectSubDto.prototype['code'] = undefined;

/**
 * Organization description
 * @member {String} description
 */
OrganizationObjectSubDto.prototype['description'] = undefined;

/**
 * Organization full name
 * @member {String} fullName
 */
OrganizationObjectSubDto.prototype['fullName'] = undefined;

/**
 * Organization id
 * @member {Number} id
 */
OrganizationObjectSubDto.prototype['id'] = undefined;

/**
 * backend url
 * @member {String} libraryBackendBasicUrl
 */
OrganizationObjectSubDto.prototype['libraryBackendBasicUrl'] = undefined;

/**
 * backend url
 * @member {String} libraryFrontendBasicUrl
 */
OrganizationObjectSubDto.prototype['libraryFrontendBasicUrl'] = undefined;

/**
 * logo url
 * @member {String} logoUrl
 */
OrganizationObjectSubDto.prototype['logoUrl'] = undefined;

/**
 * Organization name
 * @member {String} name
 */
OrganizationObjectSubDto.prototype['name'] = undefined;

/**
 * seq
 * @member {Number} seq
 */
OrganizationObjectSubDto.prototype['seq'] = undefined;

/**
 * Organization short name
 * @member {String} shortName
 */
OrganizationObjectSubDto.prototype['shortName'] = undefined;

/**
 * sub Organizations
 * @member {Array.<module:model/OrganizationObjectSubDto>} sub
 */
OrganizationObjectSubDto.prototype['sub'] = undefined;

/**
 * web url
 * @member {String} webUrl
 */
OrganizationObjectSubDto.prototype['webUrl'] = undefined;






export default OrganizationObjectSubDto;

