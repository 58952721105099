/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import LocationDto from '../model/LocationDto';
import LocationPayloadModel from '../model/LocationPayloadModel';
import SearchingQueryResponseModelLocationDto from '../model/SearchingQueryResponseModelLocationDto';

/**
* Location service.
* @module api/LocationApi
* @version v1.0.0
*/
export default class LocationApi {

    /**
    * Constructs a new LocationApi. 
    * @alias module:api/LocationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addLocationUsingPOST operation.
     * @callback module:api/LocationApi~addLocationUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LocationDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add Location
     * @param {Object} opts Optional parameters
     * @param {module:model/LocationPayloadModel} opts.locationPayloadModel 
     * @param {module:api/LocationApi~addLocationUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LocationDto}
     */
    addLocationUsingPOST(opts, callback) {
      opts = opts || {};
      let postBody = opts['locationPayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = LocationDto;
      return this.apiClient.callApi(
        '/api/location/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteLocationUsingDELETE operation.
     * @callback module:api/LocationApi~deleteLocationUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete Location
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/LocationApi~deleteLocationUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteLocationUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/location/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getLocationUsingGET operation.
     * @callback module:api/LocationApi~getLocationUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LocationDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get location
     * @param {String} id id
     * @param {module:api/LocationApi~getLocationUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LocationDto}
     */
    getLocationUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getLocationUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = LocationDto;
      return this.apiClient.callApi(
        '/api/location/get/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getLocationsUsingGET operation.
     * @callback module:api/LocationApi~getLocationsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelLocationDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * search Location
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/LocationApi~getLocationsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelLocationDto}
     */
    getLocationsUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelLocationDto;
      return this.apiClient.callApi(
        '/api/location/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateLocationUsingPATCH operation.
     * @callback module:api/LocationApi~updateLocationUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/LocationDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * update Location
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/LocationPayloadModel} opts.locationPayloadModel 
     * @param {module:api/LocationApi~updateLocationUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/LocationDto}
     */
    updateLocationUsingPATCH(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['locationPayloadModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateLocationUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = LocationDto;
      return this.apiClient.callApi(
        '/api/location/update/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
