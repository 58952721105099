import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import backIcon from "../../../assets/images/btn/backWithText.svg";
import style from "./BackButton.module.css";

const BackButton = (props) => {
  const history = useHistory();

  const goback = () => {
    if (Object.keys(props).length === 0) {
      history.length == 1 ? history.push("/") : history.goBack();
    } else {
      history.push(props.myLink);
    }
  };
  return (
    <div className={style.backButtonContainer} onClick={() => goback()}>
      <img src={backIcon} />
    </div>
  );
};

export default BackButton;
