/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The CustomerShoppingCartBookListNoBookDto model module.
 * @module model/CustomerShoppingCartBookListNoBookDto
 * @version v1.0.0
 */
class CustomerShoppingCartBookListNoBookDto {
    /**
     * Constructs a new <code>CustomerShoppingCartBookListNoBookDto</code>.
     * @alias module:model/CustomerShoppingCartBookListNoBookDto
     */
    constructor() { 
        
        CustomerShoppingCartBookListNoBookDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>CustomerShoppingCartBookListNoBookDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/CustomerShoppingCartBookListNoBookDto} obj Optional instance to populate.
     * @return {module:model/CustomerShoppingCartBookListNoBookDto} The populated <code>CustomerShoppingCartBookListNoBookDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new CustomerShoppingCartBookListNoBookDto();

            if (data.hasOwnProperty('bookCount')) {
                obj['bookCount'] = ApiClient.convertToType(data['bookCount'], 'Number');
            }
            if (data.hasOwnProperty('bookListId')) {
                obj['bookListId'] = ApiClient.convertToType(data['bookListId'], 'Number');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('selectedBookCount')) {
                obj['selectedBookCount'] = ApiClient.convertToType(data['selectedBookCount'], 'Number');
            }
            if (data.hasOwnProperty('selectedBookQuantityCount')) {
                obj['selectedBookQuantityCount'] = ApiClient.convertToType(data['selectedBookQuantityCount'], 'Number');
            }
            if (data.hasOwnProperty('title')) {
                obj['title'] = ApiClient.convertToType(data['title'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} bookCount
 */
CustomerShoppingCartBookListNoBookDto.prototype['bookCount'] = undefined;

/**
 * @member {Number} bookListId
 */
CustomerShoppingCartBookListNoBookDto.prototype['bookListId'] = undefined;

/**
 * @member {String} description
 */
CustomerShoppingCartBookListNoBookDto.prototype['description'] = undefined;

/**
 * @member {Number} selectedBookCount
 */
CustomerShoppingCartBookListNoBookDto.prototype['selectedBookCount'] = undefined;

/**
 * @member {Number} selectedBookQuantityCount
 */
CustomerShoppingCartBookListNoBookDto.prototype['selectedBookQuantityCount'] = undefined;

/**
 * @member {String} title
 */
CustomerShoppingCartBookListNoBookDto.prototype['title'] = undefined;






export default CustomerShoppingCartBookListNoBookDto;

