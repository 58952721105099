import React, { Fragment } from "react";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import {
  ADMINISTRATIVE,
  BOOKMANAGEMENT,
  HOME,
  LOGIN,
  ORGANIZATION,
  PURCHASE,
} from "../../../utils/Constants";
import style from "./MainSection.module.css";

const MainSection = (props) => {
  let count = 0;
  const currentPath = props.location.pathname;

  //console.log("props", props);
  return (
    <Fragment>
      {currentPath === HOME ||
      currentPath === ADMINISTRATIVE ||
      currentPath === PURCHASE ||
      currentPath === BOOKMANAGEMENT ||
      currentPath === ORGANIZATION ? (
        <ul className={style.MainSectionUl}>
          {props.children != null ? ( //&& userInfo != null ?
            <>
              {props.children.map((routeObj) => {
                const { route } = routeObj.props;
                let isActive = currentPath === route.path;
                isActive =
                  (currentPath === "/" && route.path === ADMINISTRATIVE) ||
                  currentPath === route.path;
                if (route.isNav && route.checkPermission) {
                  if (currentPath === "/" && count === 0) {
                    window.location.pathname = route.path;
                    count = 1;
                  }
                  return (
                    <li key={route.path} className={style.MainSectionLi}>
                      <NavLink
                        style={{
                          color: isActive ? "#47d5cd" : "",
                          borderBottom: isActive ? "4px solid #47d5cd" : "",
                        }}
                        to={route.path}
                      >
                        {route.breadcrumbName}
                      </NavLink>
                    </li>
                  );
                }
              })}
            </>
          ) : (
            <Redirect to={LOGIN} />
          )}
        </ul>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default withRouter(MainSection);
