import PropTypes from "prop-types";
import { Component, Fragment } from "react";
import closeImg from "../../assets/images/btn/close.svg";
import style from "./MultiSelectOption.module.css";
import downIcon from "../../assets/images/icons/down_white.svg";
import upIcon from "../../assets/images/icons/up_white.svg";

export default class MultiSelectOption extends Component {
    state = {
        searchText: "",
        displayData: [],
        hiddenMenu: true,
    };

    static propTypes = {
        placeholder: PropTypes.string,
        multi: PropTypes.bool.isRequired,
        info: PropTypes.array.isRequired,
        callBack: PropTypes.func,
        disabled: PropTypes.bool,
    };

    static defaultProps = {};

    componentDidMount() {
        //console.log("componentDidMount", this.props);
        if (this.props !== null) {
            this.setState({
                displayData: this.props.info,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            //console.log("receiveProps", nextProps);
            this.setState({
                //searchText: "",
                displayData: nextProps.info,
                hiddenMenu: true,
            });
        }
        return;
    }

    handleBlur = (event) => {
        // event.preventDefault();
        // event.stopPropagation();

        if (event._reactName === "onBlur" && !this.state.hiddenMenu) {
            setTimeout(() => {
                this.setState({ hiddenMenu: true });
            }, 200);
        }
    };

    handleClick = (value) => {
        const { action, item } = value;
        const { multi, disabled } = this.props;
        const { displayData } = this.state;

        let _this = this;
        switch (action) {
            case "expand":
                //console.log("expand", value);
                this.setState({ hiddenMenu: !this.state.hiddenMenu });
                break;
            case "select":
                //console.log("select", value);
                if (multi) {
                    this.setState({
                        displayData: displayData.map((it) => {
                            if (it.value === item.value) {
                                it.isSelected = true;
                            }
                            return it;
                        }),
                    });
                } else {
                    const selectedObj = displayData.find((it) => it.isSelected);
                    if (selectedObj === undefined) {
                        this.setState({
                            displayData: displayData.map((it) => {
                                if (it.value === item.value) {
                                    it.isSelected = true;
                                    //callBack({ action: "select", data: it });
                                }
                                return it;
                            }),
                        });
                    }
                    setTimeout(function () {
                        _this.setState({ hiddenMenu: true });
                    }, 0);
                }
                break;
            case "unSelect":
                //console.log("unSelect", value);
                this.setState({
                    displayData: displayData.map((it) => {
                        if (it.value === item.value) {
                            it.isSelected = false;
                            //callBack({ action: "unSelect", data: it });
                        }
                        return it;
                    }),
                });
                break;
        }
        this.setState({ searchText: "" });
    };

    handleSearch = (e) => {
        const searchStr = e.target.value;

        this.setState(
            {
                searchText: searchStr,
                displayData: this.state.displayData.map((it) => {
                    if (it["name"] !== undefined && it["name"] !== null) {
                        if (it.name.toUpperCase().includes(searchStr.toUpperCase())) {
                            it.isSearch = true;
                        } else {
                            it.isSearch = false;
                        }
                        return it;
                    }

                    if (it["value"] !== undefined && it["value"] !== null) {
                        if (it.value.toUpperCase().includes(searchStr.toUpperCase())) {
                            it.isSearch = true;
                        } else {
                            it.isSearch = false;
                        }
                        return it;
                    }
                }),
            },
            () => {
                console.log("aaaaaa", this.state);
            }
        );
    };

    handleClearAll = () => {
        this.setState({
            searchText: "",
            displayData: this.state.displayData.map((it) => {
                it.isSearch = true;
                it.isSelected = false;
                return it;
            }),
            hiddenMenu: true,
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.displayData !== this.setState.displayData) {
            if (this.props.callBack !== undefined) this.props.callBack(this.state.displayData);
        }
    }

    render() {
        const { displayData, searchText, hiddenMenu } = this.state;
        // console.log("search : ", searchText);
        const { placeholder, disabled } = this.props;
        return (
            <>
                {displayData !== null ? (
                    <>
                        <div onBlur={this.handleBlur}>
                            <div>
                                <label className={style.customBadge}>{placeholder}</label>
                            </div>
                            <div className={style.control} style={{ pointerEvents: disabled ? "none" : "initial" }}>
                                <div className={style.itemContanier}>
                                    {/* Loop Selected Item */}
                                    {displayData && displayData !== undefined ? (
                                        displayData
                                            .sort((a, b) => {
                                                return a.seq - b.seq;
                                            })
                                            .map((obj) => {
                                                return obj.isSelected ? (
                                                    <div
                                                        key={obj.id}
                                                        className={style.multiValue}
                                                        onClick={() => {
                                                            this.handleClick({
                                                                action: "unSelect",
                                                                item: { id: obj.id, value: obj.value },
                                                            });
                                                        }}
                                                    >
                                                        <div className={style.itemValue}>{obj.value}</div>
                                                        <div className={style.clearBtn}>
                                                            <img src={closeImg}></img>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                );
                                            })
                                    ) : (
                                        <></>
                                    )}
                                    {/* Loop Item */}
                                    <div className={style.inputContainer} onClick={() => this.setState({ hiddenMenu: !this.state.hiddenMenu })}>
                                        <input
                                            className={style.inputArea}
                                            value={searchText}
                                            onClick={() => this.setState({ hiddenMenu: !this.state.hiddenMenu })}
                                            onChange={(e) => this.handleSearch(e)}
                                        />
                                    </div>
                                </div>
                                <div className={style.clearAllBtn}>
                                    <img src={closeImg} onClick={() => this.handleClearAll()}></img>
                                </div>
                                <div className={style.selectTitleContainer}>
                                    <div tabIndex="0" className={style.selectTitleItem} onClick={() => this.handleClick({ action: "expand" })}>
                                        {/* "&#9660;" : "&#9650;" */}
                                        選擇{hiddenMenu ? <img src={downIcon} /> : <img src={upIcon} />}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div hidden={hiddenMenu}>
                            <div className={style.controlMenu}>
                                {/* Loop unSelected Item */}
                                {displayData && displayData.filter((it) => it.isSelected).length === displayData.length ? (
                                    <Fragment key={-1}>
                                        <div className={style.menuItem}>沒有項目選擇</div>
                                    </Fragment>
                                ) : displayData.filter((it) => !it.isSearch).length === displayData.length ? (
                                    <Fragment key={-2}>
                                        <div className={style.menuItem}>找不到項目</div>
                                    </Fragment>
                                ) : (
                                    displayData
                                        .sort((a, b) => {
                                            return a.seq - b.seq;
                                        })
                                        .map((obj, index) => {
                                            return obj.isSelected ? (
                                                <></>
                                            ) : obj.isSearch ? (
                                                <>
                                                    <div
                                                        key={obj.id}
                                                        onClick={(e) =>
                                                            this.handleClick({
                                                                action: "select",
                                                                item: { id: obj.id, value: obj.value },
                                                                event: e,
                                                            })
                                                        }
                                                        className={style.menuItem}
                                                    >
                                                        {obj.value}
                                                    </div>
                                                    {index !== displayData.filter((it) => it.isSearch).length - 1 && displayData.filter((it) => it.isSearch).length !== 1 ? (
                                                        <div className={style.borderLine}></div>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            ) : (
                                                <></>
                                            );
                                        })
                                )}
                                {/* Loop unSelected Item */}
                            </div>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </>
        );
    }
}
