/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The WhiteListIpDto model module.
 * @module model/WhiteListIpDto
 * @version v1.0.0
 */
class WhiteListIpDto {
    /**
     * Constructs a new <code>WhiteListIpDto</code>.
     * @alias module:model/WhiteListIpDto
     */
    constructor() { 
        
        WhiteListIpDto.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>WhiteListIpDto</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/WhiteListIpDto} obj Optional instance to populate.
     * @return {module:model/WhiteListIpDto} The populated <code>WhiteListIpDto</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new WhiteListIpDto();

            if (data.hasOwnProperty('ip')) {
                obj['ip'] = ApiClient.convertToType(data['ip'], 'String');
            }
            if (data.hasOwnProperty('userEmail')) {
                obj['userEmail'] = ApiClient.convertToType(data['userEmail'], 'String');
            }
            if (data.hasOwnProperty('userId')) {
                obj['userId'] = ApiClient.convertToType(data['userId'], 'Number');
            }
            if (data.hasOwnProperty('userName')) {
                obj['userName'] = ApiClient.convertToType(data['userName'], 'String');
            }
        }
        return obj;
    }


}

/**
 * ip address
 * @member {String} ip
 */
WhiteListIpDto.prototype['ip'] = undefined;

/**
 * User email
 * @member {String} userEmail
 */
WhiteListIpDto.prototype['userEmail'] = undefined;

/**
 * User id
 * @member {Number} userId
 */
WhiteListIpDto.prototype['userId'] = undefined;

/**
 * User name
 * @member {String} userName
 */
WhiteListIpDto.prototype['userName'] = undefined;






export default WhiteListIpDto;

