/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CommonDeletePayloadModel from '../model/CommonDeletePayloadModel';
import CommonResponseModel from '../model/CommonResponseModel';
import OrganizationObjectDto from '../model/OrganizationObjectDto';
import Resource from '../model/Resource';
import SearchingQueryResponseModelOrganizationIdDto from '../model/SearchingQueryResponseModelOrganizationIdDto';
import UserDto from '../model/UserDto';

/**
* Organization service.
* @module api/OrganizationApi
* @version v1.0.0
*/
export default class OrganizationApi {

    /**
    * Constructs a new OrganizationApi. 
    * @alias module:api/OrganizationApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }


    /**
     * Callback function to receive the result of the addOrganizationUsingPOST operation.
     * @callback module:api/OrganizationApi~addOrganizationUsingPOSTCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrganizationObjectDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * add organization
     * @param {String} name Name (required)
     * @param {Number} seq seq (required)
     * @param {Object} opts Optional parameters
     * @param {File} opts.bannerFile Banner
     * @param {String} opts.bannerFileId bannerFileId
     * @param {String} opts.code Code
     * @param {String} opts.description Description
     * @param {String} opts.fullName Full Name
     * @param {String} opts.libraryBackendBasicUrl library backend basic url
     * @param {String} opts.libraryFrontendBasicUrl library frontend basic url
     * @param {File} opts.logoFile Logo
     * @param {String} opts.logoFileId logoFileId
     * @param {Number} opts.mainId Foreign Key
     * @param {String} opts.nameEng Name eng (required)
     * @param {String} opts.nameZhCn Name zh cn(required)
     * @param {String} opts.nameZhHk Name zh hk (required)
     * @param {String} opts.shortName ShortName
     * @param {String} opts.webUrl organization web url
     * @param {module:api/OrganizationApi~addOrganizationUsingPOSTCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrganizationObjectDto}
     */
    addOrganizationUsingPOST(name, seq, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling addOrganizationUsingPOST");
      }
      // verify the required parameter 'seq' is set
      if (seq === undefined || seq === null) {
        throw new Error("Missing the required parameter 'seq' when calling addOrganizationUsingPOST");
      }

      let pathParams = {
      };
      let queryParams = {
        'bannerFile': opts['bannerFile'],
        'bannerFileId': opts['bannerFileId'],
        'code': opts['code'],
        'description': opts['description'],
        'fullName': opts['fullName'],
        'libraryBackendBasicUrl': opts['libraryBackendBasicUrl'],
        'libraryFrontendBasicUrl': opts['libraryFrontendBasicUrl'],
        'logoFile': opts['logoFile'],
        'logoFileId': opts['logoFileId'],
        'mainId': opts['mainId'],
        'name': name,
        'nameEng': opts['nameEng'],
        'nameZhCn': opts['nameZhCn'],
        'nameZhHk': opts['nameZhHk'],
        'seq': seq,
        'shortName': opts['shortName'],
        'webUrl': opts['webUrl']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = OrganizationObjectDto;
      return this.apiClient.callApi(
        '/api/organization/add', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteOrganizationUsingDELETE operation.
     * @callback module:api/OrganizationApi~deleteOrganizationUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {module:model/CommonResponseModel} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete organization
     * @param {String} id id
     * @param {Object} opts Optional parameters
     * @param {module:model/CommonDeletePayloadModel} opts.commonDeletePayloadModel 
     * @param {module:api/OrganizationApi~deleteOrganizationUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/CommonResponseModel}
     */
    deleteOrganizationUsingDELETE(id, opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling deleteOrganizationUsingDELETE");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = CommonResponseModel;
      return this.apiClient.callApi(
        '/api/organization/delete/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the deleteOrganizationsUsingDELETE operation.
     * @callback module:api/OrganizationApi~deleteOrganizationsUsingDELETECallback
     * @param {String} error Error message, if any.
     * @param {Object.<String, {String: Boolean}>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * delete organizations
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CommonDeletePayloadModel>} opts.commonDeletePayloadModel 
     * @param {module:api/OrganizationApi~deleteOrganizationsUsingDELETECallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Object.<String, {String: Boolean}>}
     */
    deleteOrganizationsUsingDELETE(opts, callback) {
      opts = opts || {};
      let postBody = opts['commonDeletePayloadModel'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = ['application/json'];
      let accepts = ['*/*'];
      let returnType = {'String': 'Boolean'};
      return this.apiClient.callApi(
        '/api/organization/delete', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getOrganizationBannerFileUsingGET operation.
     * @callback module:api/OrganizationApi~getOrganizationBannerFileUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Resource} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getOrganizationBannerFile
     * @param {String} id id
     * @param {module:api/OrganizationApi~getOrganizationBannerFileUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Resource}
     */
    getOrganizationBannerFileUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrganizationBannerFileUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Resource;
      return this.apiClient.callApi(
        '/api/organization/banner/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getOrganizationLogoFileUsingGET operation.
     * @callback module:api/OrganizationApi~getOrganizationLogoFileUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/Resource} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getOrganizationLogoFile
     * @param {String} id id
     * @param {module:api/OrganizationApi~getOrganizationLogoFileUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/Resource}
     */
    getOrganizationLogoFileUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrganizationLogoFileUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = Resource;
      return this.apiClient.callApi(
        '/api/organization/logo/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getOrganizationUserWithRoleUsingGET operation.
     * @callback module:api/OrganizationApi~getOrganizationUserWithRoleUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {Array.<module:model/UserDto>} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get users with role in param organizationId
     * @param {Number} organizationId organizationId
     * @param {module:api/OrganizationApi~getOrganizationUserWithRoleUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link Array.<module:model/UserDto>}
     */
    getOrganizationUserWithRoleUsingGET(organizationId, callback) {
      let postBody = null;
      // verify the required parameter 'organizationId' is set
      if (organizationId === undefined || organizationId === null) {
        throw new Error("Missing the required parameter 'organizationId' when calling getOrganizationUserWithRoleUsingGET");
      }

      let pathParams = {
      };
      let queryParams = {
        'organizationId': organizationId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = [UserDto];
      return this.apiClient.callApi(
        '/api/organization/users', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getOrganizationUsingGET operation.
     * @callback module:api/OrganizationApi~getOrganizationUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrganizationObjectDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * getOrganization
     * @param {String} id id
     * @param {module:api/OrganizationApi~getOrganizationUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrganizationObjectDto}
     */
    getOrganizationUsingGET(id, callback) {
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling getOrganizationUsingGET");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = OrganizationObjectDto;
      return this.apiClient.callApi(
        '/api/organization/get/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the getOrganizationsUsingGET operation.
     * @callback module:api/OrganizationApi~getOrganizationsUsingGETCallback
     * @param {String} error Error message, if any.
     * @param {module:model/SearchingQueryResponseModelOrganizationIdDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * get all organization
     * @param {Object} opts Optional parameters
     * @param {Number} opts.from start from
     * @param {Boolean} opts.getAll 
     * @param {String} opts.id id
     * @param {Boolean} opts.isActive active status
     * @param {Boolean} opts.isDeleted delete status
     * @param {String} opts.keyword search keyword
     * @param {Number} opts.limit return size
     * @param {Boolean} opts.withRole 
     * @param {module:api/OrganizationApi~getOrganizationsUsingGETCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/SearchingQueryResponseModelOrganizationIdDto}
     */
    getOrganizationsUsingGET(opts, callback) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'from': opts['from'],
        'getAll': opts['getAll'],
        'id': opts['id'],
        'isActive': opts['isActive'],
        'isDeleted': opts['isDeleted'],
        'keyword': opts['keyword'],
        'limit': opts['limit'],
        'withRole': opts['withRole']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = SearchingQueryResponseModelOrganizationIdDto;
      return this.apiClient.callApi(
        '/api/organization/all', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }

    /**
     * Callback function to receive the result of the updateOrganizationUsingPATCH operation.
     * @callback module:api/OrganizationApi~updateOrganizationUsingPATCHCallback
     * @param {String} error Error message, if any.
     * @param {module:model/OrganizationObjectDto} data The data returned by the service call.
     * @param {String} response The complete HTTP response.
     */

    /**
     * update organization
     * @param {String} id id
     * @param {String} name Name (required)
     * @param {Number} seq seq (required)
     * @param {Object} opts Optional parameters
     * @param {File} opts.bannerFile Banner
     * @param {String} opts.bannerFileId bannerFileId
     * @param {String} opts.code Code
     * @param {String} opts.description Description
     * @param {String} opts.fullName Full Name
     * @param {String} opts.libraryBackendBasicUrl library backend basic url
     * @param {String} opts.libraryFrontendBasicUrl library frontend basic url
     * @param {File} opts.logoFile Logo
     * @param {String} opts.logoFileId logoFileId
     * @param {Number} opts.mainId Foreign Key
     * @param {String} opts.nameEng Name eng (required)
     * @param {String} opts.nameZhCn Name zh cn(required)
     * @param {String} opts.nameZhHk Name zh hk (required)
     * @param {String} opts.shortName ShortName
     * @param {String} opts.webUrl organization web url
     * @param {module:api/OrganizationApi~updateOrganizationUsingPATCHCallback} callback The callback function, accepting three arguments: error, data, response
     * data is of type: {@link module:model/OrganizationObjectDto}
     */
    updateOrganizationUsingPATCH(id, name, seq, opts, callback) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateOrganizationUsingPATCH");
      }
      // verify the required parameter 'name' is set
      if (name === undefined || name === null) {
        throw new Error("Missing the required parameter 'name' when calling updateOrganizationUsingPATCH");
      }
      // verify the required parameter 'seq' is set
      if (seq === undefined || seq === null) {
        throw new Error("Missing the required parameter 'seq' when calling updateOrganizationUsingPATCH");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'bannerFile': opts['bannerFile'],
        'bannerFileId': opts['bannerFileId'],
        'code': opts['code'],
        'description': opts['description'],
        'fullName': opts['fullName'],
        'libraryBackendBasicUrl': opts['libraryBackendBasicUrl'],
        'libraryFrontendBasicUrl': opts['libraryFrontendBasicUrl'],
        'logoFile': opts['logoFile'],
        'logoFileId': opts['logoFileId'],
        'mainId': opts['mainId'],
        'name': name,
        'nameEng': opts['nameEng'],
        'nameZhCn': opts['nameZhCn'],
        'nameZhHk': opts['nameZhHk'],
        'seq': seq,
        'shortName': opts['shortName'],
        'webUrl': opts['webUrl']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['Authorization'];
      let contentTypes = [];
      let accepts = ['*/*'];
      let returnType = OrganizationObjectDto;
      return this.apiClient.callApi(
        '/api/organization/update/{id}', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null, callback
      );
    }


}
