/**
 * CTS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The LanguagePayloadModel model module.
 * @module model/LanguagePayloadModel
 * @version v1.0.0
 */
class LanguagePayloadModel {
    /**
     * Constructs a new <code>LanguagePayloadModel</code>.
     * @alias module:model/LanguagePayloadModel
     * @param name {String} Language name [required]
     * @param seq {Number} Seq [required]
     * @param type {module:model/LanguagePayloadModel.TypeEnum} Book Type [required]
     */
    constructor(name, seq, type) { 
        
        LanguagePayloadModel.initialize(this, name, seq, type);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, name, seq, type) { 
        obj['name'] = name;
        obj['seq'] = seq;
        obj['type'] = type;
    }

    /**
     * Constructs a <code>LanguagePayloadModel</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/LanguagePayloadModel} obj Optional instance to populate.
     * @return {module:model/LanguagePayloadModel} The populated <code>LanguagePayloadModel</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new LanguagePayloadModel();

            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('seq')) {
                obj['seq'] = ApiClient.convertToType(data['seq'], 'Number');
            }
            if (data.hasOwnProperty('type')) {
                obj['type'] = ApiClient.convertToType(data['type'], 'String');
            }
        }
        return obj;
    }


}

/**
 * Language name [required]
 * @member {String} name
 */
LanguagePayloadModel.prototype['name'] = undefined;

/**
 * Seq [required]
 * @member {Number} seq
 */
LanguagePayloadModel.prototype['seq'] = undefined;

/**
 * Book Type [required]
 * @member {module:model/LanguagePayloadModel.TypeEnum} type
 */
LanguagePayloadModel.prototype['type'] = undefined;





/**
 * Allowed values for the <code>type</code> property.
 * @enum {String}
 * @readonly
 */
LanguagePayloadModel['TypeEnum'] = {

    /**
     * value: "EBOOK"
     * @const
     */
    "EBOOK": "EBOOK",

    /**
     * value: "MEDIA"
     * @const
     */
    "MEDIA": "MEDIA",

    /**
     * value: "UNKNOWN"
     * @const
     */
    "UNKNOWN": "UNKNOWN"
};



export default LanguagePayloadModel;

