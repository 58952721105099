import React, { Fragment, useEffect, useState } from "react";

import DialogBox from "../../../../components/DialogBox/DialogBox";

import ChangePassWordSheet from "../../../../components/Sheet/User/ChangePassWordSheet";

import {
  ADMINISTRATIVE,
  DIALOG_CANCEL,
  DIALOG_COMPLETE,
  HOME,
  LOGIN,
  UPDATE,
} from "../../../../utils/Constants";
import {
  clearAccessToken,
  clearRefreshToken,
  clearUserInfo,
  getUserInfo,
} from "../../../../utils/LocalStorage";

import style from "./ChangePW.module.css";

const ChangePW = (props) => {
  const [showPassWordDialog, setShowPassWordDialog] = useState(true);

  const handleDialogCallBack = (value) => {
    //if (value.action == DIALOG_COMPLETE) getApiData();

    if (value.action == DIALOG_CANCEL) {
      props.history.push(ADMINISTRATIVE);
    }

    if (value.action == DIALOG_COMPLETE) {
      clearAccessToken();
      clearRefreshToken();
      clearUserInfo();
      props.history.push(LOGIN);
    }

    setShowPassWordDialog(false);
  };

  return (
    <>
      <DialogBox showDialog={showPassWordDialog} title={"更改我的密碼"}>
        <ChangePassWordSheet
          actionType={UPDATE}
          callBack={(value) => handleDialogCallBack(value)}
        />
      </DialogBox>
    </>
  );
};

export default ChangePW;
